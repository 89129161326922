import { CardMedia, Dialog } from '@mui/material'
import React from 'react'

function ImageDialog(props) {
    const { imageURL, open, setOpen } = props;
    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
            <CardMedia component="img" src={imageURL}/>
        </Dialog>
    )
}

export default ImageDialog