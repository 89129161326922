import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, Typography, TextField, AlertTitle, List, FormControl, InputLabel, Select, MenuItem, ListItemButton, ListItemIcon, ListItemText, Chip, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getUsersNames } from '../../api/Users/users';
import { addDays, addWeeks, format, getDate, getHours, getMinutes, isBefore, isValid, setDate, setHours, setMinutes, setSeconds } from 'date-fns';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { AccessTime, Block, ChevronLeft, ChevronRight, EuroSymbolSharp, Event, EventRepeat, ExpandMore, Group, LocationOn, PersonAdd, TextFields } from '@mui/icons-material';
import { de } from 'date-fns/locale';
import { Box } from '@mui/system';
import { setReservation } from '../../api/Reservations/reservations';
import SecondaryButton from '../../components/SecondaryButton';
import PrimaryButton from '../../components/PrimaryButton';
import { setSeries_api } from '../../api/Series/series';
import { useSnackbar } from '../../components/SnackbarContext';
import { closeCourts_api } from '../../api/Courts/courts';
import WeekDayCheckboxes from '../../components/WeekDayCheckboxes';
import { DatePicker } from '@mui/x-date-pickers';
import TertiaryButton from '../../components/TertiaryButton';


function AdminBookingDialog(props) {
  const { newBookingData, populateTable, settings, courtType, enddate, startdate, targetCourts, numBookingUnits } = props;

  const [users, setUsers] = useState([{}]);
  const [isFreeText, setIsFreeText] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [name, setName] = useState("");
  const [isSeries, setIsSeries] = useState(false);
  const [days, setDays] = useState({ monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false });
  const [isMember, setIsMember] = useState(false);
  const [repeatEnddate, setRepeatEnddate] = useState(new Date());
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [freetext, setFreetext] = useState("");
  const [isUserFreetext, setIsUserFreetext] = useState(false);
  const [seriesType, setSeriesType] = useState("other");
  const { showSnackbar } = useSnackbar();

  const [mate, setMate] = useState("");
  const [player, setPlayer] = useState("");

  const [mode, setMode] = useState("menu");

  const updateRepeatEnddate = () => {
    var tmp = enddate;
    var newRepeatEnddate = repeatEnddate;
    newRepeatEnddate = setHours(newRepeatEnddate, getHours(tmp));
    newRepeatEnddate = setMinutes(newRepeatEnddate, getMinutes(tmp));
    newRepeatEnddate = setSeconds(newRepeatEnddate, 0);
    console.log(newRepeatEnddate)
    return newRepeatEnddate;
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case "isFreeText":
        setIsFreeText(event.target.checked);
        break;
      case "freeText":
        setName(event.target.value);
        break;
      case "isGuest":
        setIsGuest(event.target.checked);

        if (!event.target.checked) {
          setMode("member");
        } else {
          setMode("guest");
          setIsUserFreetext(false)
        }
        break;
      case "isGuestText":
        setGuestName(event.target.value);
        break;
      case "isBulk":
        setIsBulk(event.target.checked);
        break;
      case "isSeries":
        setIsSeries(event.target.checked);
        break;
      case "isMember":
        setIsMember(event.target.checked);
        break;
      case "isUserFreetext":
        setIsUserFreetext(event.target.checked);
        if (!event.target.checked) {
          setMode("member");
        } else {
          setMode("userFreetext");
          setIsGuest(false)
        }
        break;
      default:
        break;
    }

  }

  const handlebook = () => {
    var promise = null;
    var data = null;
    if (mode == "freetext") {
      if (!name || name === "") {
        showSnackbar("Bitte geben Sie einen Namen an", "warning");
        return;
      }
      //mass booking freetext
      if (mode == "freetext") {
        // data = { court: targetCourts[0].id, time: format(startdate, "HH:mm:ii"), day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), name: name, endtime: format(enddate, "HH:mm:ss") };
        promise = setSeries_api(null, targetCourts, name, startdate, enddate, null, null, seriesType, courtType);
        promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
          .catch(error => { showSnackbar("Error: " + error, "error"); });
      }
      //freetxt single
      else {
        data = { court: targetCourts[0].id, time: format(startdate, "HH:mm:ii"), day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), name: name };
        promise = setReservation(data);
        promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
          .catch(error => { showSnackbar("Error: " + error, "error"); });
      }
    }
    else if (mode == "series") {
      var tmpRepeatEnddate = updateRepeatEnddate();
      promise = setSeries_api(days, targetCourts, name, startdate, enddate, tmpRepeatEnddate, null, seriesType, courtType);
      promise.then(() => { populateTable(); showSnackbar("Serie erfolgreich erstellt", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });
    }
    else if (mode == "guest") {
      const data = { court: targetCourts[0].id, time: format(startdate, "HH:mm:ii"), day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), player: player, guestname: guestName.trim(), endtime: enddate, starttime: startdate };
      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });
    }
    else if (mode == "userFreetext") {
      const data = { court: targetCourts[0].id, time: format(startdate, "HH:mm:ii"), day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), player: player, name: freetext.trim(), endtime: enddate, starttime: startdate, isFreetext: isUserFreetext };
      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });

    }
    else if (mode == "lock") {
      promise = closeCourts_api(targetCourts, enddate, startdate);
      promise.then((result) => { populateTable(); showSnackbar("Plätze erfolgreich gesperrt", "success"); props.onClose(); })
        .catch((error) => showSnackbar(error.response.data.message, "error"));
    }
    else {
      if (!mate && !player) {
        showSnackbar("Bitte wählen Sie einen Partner aus", "warning");
        return;
      }
      const data = { court: targetCourts[0].id, time: format(startdate, "HH:mm:ii"), day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), mate_id: mate, player: player, endtime: enddate, starttime: startdate };

      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });

    }
  }

  const fetchUsers = () => {
    const userPromise = getUsersNames();
    userPromise.then((result) => {

      var users_autocomplete = [];

      for (var i = 0; i < result.data.length; i++) {
        var user_id = result.data[i].id;
        var user_label = result.data[i].firstname + " " + result.data[i].surname + " | " + user_id;
        users_autocomplete.push({ label: user_label, id: user_id });
        //users_autocomplete.push(result.data[i].firstname + " " + result.data[i].surname)
      }
      setUsers(users_autocomplete)
    });
  }

  useEffect(() => {
    fetchUsers();
    setGuestName("");
    setName("");
    setIsGuest(false);
    setMate("");
    setPlayer("");
    setIsFreeText(false);
    setIsBulk(false);
    setIsSeries(false);
    setIsUserFreetext(false);
    setFreetext("");
    setIsMember(false);

    // if (props.open) {
    //   //parse new booking date object and assiign hours and minutes initially to end and repeat date
    //   var bookingDate = parse(format(props.dayFilter, "yyyy-MM-dd") + " " + newBookingData.time, "yyyy-MM-dd HH:mm", new Date())
    //   setParseDate(bookingDate);
    //   // setStartdate(bookingDate);
    //   var bookingEndDate = addMinutes(bookingDate, settings.booking_duration);
    //   // setEnddate(bookingEndDate)
    //   setRepeatEnddate(bookingEndDate);
    //   getCourt();
    //   var dayName = format(new Date(props.dayFilter.toString()), "EEEE").toLowerCase();;
    //   var tmp = days;
    //   tmp[dayName] = true;
    //   setDays(tmp);

    // }
    if (props.open) {
      setMode("menu");
      setIsFreeText(false);
      setIsUserFreetext(false);
      setIsGuest(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (isValid(new Date(startdate))) {
      var dayName = format(startdate, "EEEE").toLowerCase();
      var tmp = days;
      tmp[dayName] = true;
      setDays(tmp);
    }
  }, [startdate]);

  useEffect(() => {
    var modeFail = Boolean(mode == "menu");
    var memberFail = (mode == "member" && (!player || !mate || mate=="" || player ==""))
    var guestFail = ((mode == "guest" && (!player || !guestName || guestName == "")))
    var userfreetextFail = ((mode == "userFreetext" && (!player || !freetext || freetext == "")))
    var freetextFail = ((mode == "freetext" && (!name || name == "")))
    var seriesFail = (mode == "series" && (!name || name == "" || isBefore(repeatEnddate,addDays(startdate,6))))


    if (modeFail || memberFail || guestFail || userfreetextFail || freetextFail || seriesFail) {
      setSubmitDisabled(true);
    }
    else {
      setSubmitDisabled(false);
    }
  }, [mode, name, guestName, player,mate,freetext,guestName,repeatEnddate]);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} elevation={10} fullWidth maxWidth={"sm"}>
        <DialogTitle sx={{ backgroundColor: "#eee" }}>Platzbuchung</DialogTitle>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info"><AlertTitle>Buchungsinformationen</AlertTitle>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Event color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Typography variant="body1" sx={{ fontWeight: 400 }}>{format(new Date(props.dayFilter.toString()), "EEEE dd. MMMM ", { locale: de })}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <AccessTime color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Stack direction="row">
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(startdate) && format(startdate, 'HH:mm')}</Typography>
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(enddate) && " - " + format(enddate, "HH:mm")}</Typography>
                    </Stack>
                    {isSeries &&
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(repeatEnddate) && "wöchtl. Wiederholung bis " + format(repeatEnddate, 'EEEE dd. MMMM', { locale: de })}</Typography>
                    }
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"} >
                  <LocationOn color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack direction={"row"} spacing={1} flexWrap="wrap">
                    {targetCourts && targetCourts?.map((court, i) => (<Typography key={i} variant="body1" noWrap sx={{ fontWeight: 400 }}>{court.name}</Typography>))}
                  </Stack>

                </Stack>
                {/* <Typography variant="body1" sx={{ fontWeight: 500 }}>{+newBookingData.time + " Uhr auf " + newBookingData.name}</Typography> */}
                {/* {settings && (settings.member_booking_price != 0 || (isGuest && settings.guest_booking_price != 0)) ?
                  <> */}
                <Divider></Divider>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <EuroSymbolSharp color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    {mode == "guest" ?
                      <Typography variant="h6" fontWeight={500}>{settings && settings.guest_booking_price != 0 ? "Diese Buchung kostet " + (settings.guest_booking_price * numBookingUnits) + "€" : "kostenlos"}</Typography>
                      : mode == "member" ?
                        <Typography variant="h6" fontWeight={500}>{settings && settings.member_booking_price != 0 ? "Diese Buchung kostet " + (settings.member_booking_price * numBookingUnits) + "€" : "kostenlos"}</Typography>
                        :
                        <Typography variant="h6" fontWeight={500}>{"kostenlos"}</Typography>
                    }
                    <Typography variant="body2">{"Die Kosten werden dem Buchenden in Rechnung gestellt"}</Typography>

                  </Stack>
                </Stack>
                {/* </>
                  : <></>
                } */}
              </Stack>
            </Alert>

          </Grid>
        </Grid>
        <DialogContent >
          <Grid container direction="row" spacing={2}>
            {/* {mode !== "menu" && (props.settings && (props.settings['allow_freetext_bookings'] || props.settings['allow_guest_bookings'])) ?
              <TertiaryButton text="zurück zur Auswahl" startIcon={<ChevronLeft />} onClick={() => setMode("menu")} />
              :
              <></>
            } */}
            {mode == "menu" ?
              <List>
                <ListItemButton id={"btn-admbookingdialog-menu-memberbooking"} onClick={() => setMode("member")} disabled={targetCourts.length > 1}>
                  <ListItemIcon><Group color="primary" /></ListItemIcon>
                  <ListItemText primary={"Mitgliederbuchung"} secondary={"Eine Buchung mit Vereinsmitgliedern"} primaryTypographyProps={{ fontWeight: 500 }} />
                  <ChevronRight></ChevronRight>
                </ListItemButton>
                <ListItemButton id={"btn-admbookingdialog-menu-freetextbooking"} onClick={() => setMode("freetext")} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  <ListItemIcon><TextFields color="primary" /></ListItemIcon>
                  <ListItemText primary={"Freitextbuchung"} secondary={"Eine Buchung ohne Mitglieder unter Angabe eines Buchungszwecks"} primaryTypographyProps={{ fontWeight: 500 }} />
                  <ChevronRight></ChevronRight>
                </ListItemButton>
                <ListItemButton id={"btn-admbookingdialog-menu-seriesbooking"} onClick={() => setMode("series")} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  <ListItemIcon><EventRepeat color="primary" /></ListItemIcon>
                  <ListItemText primary={"Serienbuchung"} secondary={"Eine Buchung die sich über einen definerten Zeitraum wiederholt"} primaryTypographyProps={{ fontWeight: 500 }} />
                  <ChevronRight></ChevronRight>
                </ListItemButton>
                <ListItemButton id={"btn-admbookingdialog-menu-courtlock"} onClick={() => setMode("lock")} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                  <ListItemIcon><Block color="primary" /></ListItemIcon>
                  <ListItemText primary={"Platzsperrung"} secondary={"Plätze werden für einen definierten Zeitraum gesperrt."} primaryTypographyProps={{ fontWeight: 500 }} />
                  <ChevronRight></ChevronRight>
                </ListItemButton>
              </List>
              :
              <></>
            }
            {mode == "freetext" &&
              <>
                <Grid item xs={12}>
                  <TextField id="txf-admbookingdialog-freetext" required name="freeText" fullWidth label={"Name der Buchung"} value={name} onChange={handleChange}></TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="typeselect">Kategorie</InputLabel>
                    <Select required id="sel-typeselect" labelId="typeselect" value={seriesType} onChange={(event) => setSeriesType(event.target.value)} label="Kategorie">
                      <MenuItem id="btn-typeselect-teamatch" value="teammatch">Punktspiel</MenuItem>
                      <MenuItem id="btn-typeselect-training" value="training">Training</MenuItem>
                      <MenuItem id="btn-typeselect-other" value="other">Sonstige</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            }

            {mode == "series" &&
              <>

                <Grid item xs={12}>
                  <TextField id="txf-admbookingdialog-series" required name="seriesName" fullWidth label={"Name der Buchung"} value={name} onChange={(event) => setName(event.target.value)}></TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="typeselect">Kategorie</InputLabel>
                    <Select required id="sel-typeselect" labelId="typeselect" value={seriesType} onChange={(event) => setSeriesType(event.target.value)} label="Kategorie">
                      <MenuItem id="btn-typeselect-teamatch" value="teammatch">Punktspiel</MenuItem>
                      <MenuItem id="btn-typeselect-training" value="training">Training</MenuItem>
                      <MenuItem id="btn-typeselect-other" value="other">Sonstige</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      {/* <Stack>
                        <Typography variant="body1" fontWeight={500}>Serien-Enddatum</Typography>
                        
                      </Stack> */}

                      {/* <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                                                                <Chip size="small" label="1 Monat" onClick={() => updateDate(1)} />
                                                                <Chip size="small" label="6 Monat" onClick={() => updateDate(6)} />
                                                                <Chip size="small" label="1 Jahr" onClick={() => updateDate(12)} />
                                                            </Stack> */}
                      <Stack spacing={0.5}>
                        <DatePicker id="dtp-admbookingdialog-seriesend" label="Serien-Enddatum" size="medium" value={repeatEnddate} minDate={addWeeks(new Date(enddate), 1)} views={["year", "month", "day"]} onChange={(value) => setRepeatEnddate(value)} textReadOnly={true} />
                        <Typography sx={{ fontSize: "0.7rem", pl: 1 }} color="text.secondary">Bis wann soll die Platzbuchung wiederholt werden?</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={12}>
                        {days &&
                          <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                            <AccordionSummary expandIcon={<ExpandMore />}><Typography>Zusätzliche Tage ?</Typography></AccordionSummary>
                            <AccordionDetails><WeekDayCheckboxes days={days} setDays={setDays}></WeekDayCheckboxes></AccordionDetails>
                          </Accordion>
                        }
                      </Grid> */}


                </Grid>
              </>
            }


            {mode == "member" || mode == "guest" || mode == "userFreetext" ?
              <>

                {settings.allow_guest_bookings ?
                  <Grid container item xs={12} alignItems="center" justifyContent={"space-between"}>
                    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} width="100%">
                      <Stack direction="row" alignItems={"center"} spacing={3}>
                        <PersonAdd color="primary" />
                        <Stack>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{"Mitglied-Gastbuchung | " + (settings.guest_booking_price * numBookingUnits) + "€"}</Typography>
                          <Typography variant="body2" color="text.secondary">Buchung mit einem Gast oder der Ballmaschine?</Typography>
                        </Stack>
                      </Stack>
                      <Switch size="medium" name="isGuest" id="swi-admbookingdialog-guest" checked={isGuest} onChange={handleChange}></Switch>
                    </Stack>

                  </Grid>

                  :
                  <></>
                }
                {props.settings && props.settings['allow_freetext_bookings'] ?
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} width="100%">
                      <Stack direction="row" alignItems={"center"} spacing={3}>
                        <TextFields color="primary" />
                        <Stack>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Mitglied-Freitext | 0€</Typography>
                          <Typography variant="body2" color="text.secondary">Eine Buchung außerhalb des verf. Buchungskontingents unter Angabe des Zwecks</Typography>
                        </Stack>
                      </Stack>
                      <Switch size="medium" name="isUserFreetext" id="swi-admbookingdialog-userfreetext" checked={isUserFreetext} onChange={handleChange}></Switch>
                    </Stack>
                  </Grid>
                  :
                  <></>
                }
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <CustomAutocomplete id="atc-admbookingdialog-player1" options={users} label="Vereinsmitglied 1" setTarget={setPlayer} />
                </Grid>
                {isGuest ?
                  <Grid item xs={12}>
                    <TextField id="txf-admbookingdialog-guest" required name="isGuestText" fullWidth label={"Name des Gasts / Ballmaschine"} value={guestName} onChange={handleChange}></TextField>
                  </Grid>
                  : isUserFreetext ?
                    <Grid item xs={12}>
                      <TextField id="txf-admbookingdialog-userfreetext" required name="isUserFreetext" fullWidth label={"Buchungszweck"} value={freetext} onChange={(event) => setFreetext(event.target.value)}></TextField>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <CustomAutocomplete id="atc-admbookingdialog-player2" options={users} label="Vereinsmitglied 2" setTarget={setMate} />
                    </Grid>
                }
              </>
              :
              <></>
            }

            {mode == "lock" &&
              <Grid item>
                <Alert severity='warning'>
                  <AlertTitle>Platzsperrung</AlertTitle>
                  <Typography>Die ausgewählten Plätze werden zur definierten Uhrzeit gesperrt.</Typography>
                </Alert>
              </Grid>

            }
          </Grid>
        </DialogContent>
        <Divider></Divider>
        <DialogActions sx={{ justifyContent: "space-between" }}>

          <Stack direction="row" spacing={1}>
            <SecondaryButton text={"Abbrechen"} onClick={props.onClose} />
            {mode !== "menu" && (props.settings && (props.settings['allow_freetext_bookings'] || props.settings['allow_guest_bookings'])) ?
              <SecondaryButton text={"Zurück"} onClick={() => setMode("menu")} />
              :
              <></>
            }

          </Stack>
          <PrimaryButton id="btn-admbookingdialog-submit" disabled={Boolean(mode == "menu") || submitDisabled} text={mode == "member" ? `Für ${settings?.member_booking_price * numBookingUnits}€ kostenpflichtig buchen` : isGuest ? `Für ${settings?.guest_booking_price * numBookingUnits}€ kostenpflichtig buchen` : "kostenlos buchen"} onClick={handlebook} />
        </DialogActions>
      </Dialog >
    </>
  );
}

export default AdminBookingDialog;
