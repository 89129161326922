import { Breadcrumbs, CircularProgress, Container, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getPosts_api } from '../../api/Posts/post'
import Post from './Post';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import TitleCard from '../../components/TitleCard';
import { ChevronLeft, Feed } from '@mui/icons-material';
import Footer from '../../components/Footer';
import TertiaryButton from '../../components/TertiaryButton';
import { BLOG } from '../../navigation/CONSTANTS';
import MainContentContainer from '../../components/MainContentContainer';


function PostContainer(props) {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const getPosts = (query) => {
        setLoading(true);
        var promise
        promise = getPosts_api(query);
        promise.then((result) => { setPost(result.data[0]); setLoading(false); })
            .catch((error) => { setLoading(false); });

    }

    useEffect(() => {
        var query = "id__eq=" + id
        getPosts(query);
        return () => {
        };
    }, [id]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard title={process.env.REACT_APP_CLUB_ABBREVATION + " Blog"} subheader={"News vom " + process.env.REACT_APP_CLUB_NAME} icon={<Feed sx={{ width: 40, height: 40 }}></Feed>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer maxWidth={"lg"} px={0}>
                {post &&
                    <Grid container justifyContent={"center"}>
                        <Grid item xs={12}>
                            <TertiaryButton startIcon={<ChevronLeft/>} text="zurück zum Blog" onClick={()=>navigate(BLOG)}/>
                        </Grid>
                        <Grid item container xs={12} spacing={2} justifyContent={"center"}>
                            {loading == true ?
                                // show loading icon as long as posts are populated
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <CircularProgress sx={{ mt: 10 }}></CircularProgress>
                                    </Grid>
                                </Grid>
                                :
                                <>
                                    <Grid item xs={12}>
                                        <Post src={post.img_url} title={post.title} content={post.content} author={post.author_name} commentsCount={post.comments_count} post={post} collapsable={false}></Post>
                                    </Grid>

                                </>
                            }
                        </Grid>
                    </Grid>
                }
            </MainContentContainer>
            <Footer />
        </>
    )
}

export default PostContainer