import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, InputLabel, List,ListItemButton,   ListItemText,MenuItem, Select, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'
import { ChevronLeft, ChevronRight} from '@mui/icons-material'
import TertiaryButton from './TertiaryButton'
import { createBracket_api, getTournamentGroupPlayers_api} from '../api/Events/events'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from './SnackbarContext'
import TransferList from './TransferList'

function BracketDialog({ open, onClose, seeds, setSeeds, participants, event, ...props }) {

    const [withSeeds, setWithSeeds] = useState(false);
    const [temporarySeeds, setTemporarySeeds] = useState([]);
    const [mode, setMode] = useState("menu");
    const [bracketSize, setBracketSize] = useState(16);
    const [isAutoGenerated, setIsAutoGenerated] = useState(true);
    const [groupPlayers, setGroupPlayers] = useState([]);
    const [adjustParticipants, setAdjustParticipants] = useState(false);
    const [adjustedParticipantList,setAdjustedParticipantList] = useState(null);
    const [seedSourceList,setSeedSourceList] = useState(null);
    const bracketSizeOptions = [4, 8, 16, 32, 64, 128];
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const participantsLength = participants?.length;


    const createBracket = () => {
        var promise = createBracket_api(event.id, seeds, isAutoGenerated, bracketSize,adjustedParticipantList);
        promise.then((result) => { showSnackbar("Turnierbaum erfolgreich erstellt", "success"); navigate("/tournament/" + event.id + "?tab=bracket"); })
            .catch((error) => { showSnackbar("Fehler beim Erstellen des Turnierbaums", "error"); });
    }

    const getGroupRankings = () => {
        var promise = getTournamentGroupPlayers_api(event.id);
        promise.then((result) => setGroupPlayers(result.data))
            .catch((error) => console.log(error));
    }


    useEffect(() => {
        var tmp = []
        for (var i = 0; i < temporarySeeds.length; i++) {
            if(adjustParticipants)
            {
                tmp[i] = temporarySeeds[i].user_id;
            }
            else
            {
                tmp[i] = temporarySeeds[i].id;
            }
            
        }
        setSeeds(tmp)
    }, [temporarySeeds]);


    useEffect(() => {
        setTemporarySeeds([]);
        if (open) {
            getGroupRankings();
        }
    }, [open]);

    useEffect(() => {
        if (!withSeeds) {
            setSeeds([]);
        }
    }, [withSeeds]);

    useEffect(() => {
        if(adjustParticipants && adjustedParticipantList)
        {
            //peform a deep copy to avoid link between these two states -> copy only values instead of address area
            setSeedSourceList([...adjustedParticipantList]);
        }
        else{
            setSeedSourceList(participants);
        }
    }, [adjustParticipants,adjustedParticipantList,participants]);

    return (
        <Dialog open={open} maxWidth={"md"} fullWidth >
            <DialogTitle sx={{ backgroundColor: "#eee" }}>Turnierbaum erstellen</DialogTitle>
            <DialogContent sx={{ overflow: "auto" }}>


                <Grid container spacing={2}>
                    {mode === "menu" ?
                        <Grid item xs={12}>
                            <List>
                                <ListItemButton onClick={() => { setMode("manual"); }}>
                                    <ListItemText primary={"Benutzerdefinierter Turnierbaum"} secondary={"Soll die Turniergröße und die Auslosung manuell bestimmt werden?"} primaryTypographyProps={{ fontWeight: 500 }} />
                                    <ChevronRight color="text.secondary" />
                                </ListItemButton>
                                <ListItemButton onClick={() => { setMode("auto"); setBracketSize(null); }} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                    <ListItemText primary={"Automatischer Turnierbaum"} secondary={"Soll die die Turniergröße und die Auslosung automatisch estellt werden. Die Auslosung kann im Nachhinein angepasst werden."} primaryTypographyProps={{ fontWeight: 500 }} />
                                    <ChevronRight />
                                </ListItemButton>
                            </List>
                        </Grid>
                        :
                        <></>
                    }
                    {mode !== "menu" ?
                        <Grid item xs={12}>
                            <TertiaryButton text="zurück zur Auswahl" startIcon={<ChevronLeft />} onClick={() => setMode("menu")} />
                        </Grid>
                        :
                        <></>
                    }
                    {mode === "manual" ?
                        <>
                        {event.hasGroups?
                            <>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                           
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                    <Stack>
                                        <Typography sx={{ fontWeight: 700 }} variant="body1">Angepasste Teilnehmerliste</Typography>
                                        <Typography variant="body2">Soll die Teilnehmerliste der KO-Runde angepasst werden z.b. aus Gruppenplatzierungen? Ansonsten werden alle Teilnehmer des Turniers ausgewählt.</Typography>
                                    </Stack>
                                    <Switch checked={adjustParticipants} onChange={() => { setAdjustParticipants(!adjustParticipants); }}></Switch>
                                </Stack>
                                <Collapse in={adjustParticipants}>
                                <TransferList targetListName={"Teilnehmer KO-Runde"} sourceListName={"Teilnehmer Gruppe"} transferActionName={"hinzufügen"} targetList={adjustedParticipantList} setTargetList={setAdjustedParticipantList} sourceList={groupPlayers} setSourceList={setGroupPlayers} secondaryName={"Rang"} secondaryKey={"player_rank"}></TransferList>
                                </Collapse>
                            </Grid>
                            </>
                            :
                            <></>
}
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <Typography sx={{ fontWeight: 700 }} variant="body1">Turniergröße</Typography>
                                    <FormControl id="bracket_size" fullWidth>
                                        <InputLabel id="bracket_size">Anzahl Spieler</InputLabel>
                                        <Select label="Anzahl Spieler" id="bracket_size" value={bracketSize} onChange={(event) => setBracketSize(event.target.value)}>
                                            {bracketSizeOptions.map((size, i) => (
                                                <MenuItem key={i} value={size}>{size}</MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{"Es haben sich " +(adjustParticipants ? adjustedParticipantList?.length : participantsLength + seeds?.length )+ " Spieler angemeldet"}</FormHelperText>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                    <Stack>
                                        <Typography sx={{ fontWeight: 700 }} variant="body1">Automatsiche Auslosung</Typography>
                                        <Typography variant="body2">Sollen die Paarungen im Turnierbaum automatisch (zufallsgeneriert) erstellt werden?</Typography>
                                    </Stack>
                                    <Switch checked={isAutoGenerated} onChange={() => { setIsAutoGenerated(!isAutoGenerated); }}></Switch>
                                </Stack>
                            </Grid>
                        </>
                        :
                        <></>
                    }

                    {mode === "manual" || mode === "auto" ?
                        <>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                    <Stack>
                                        <Typography sx={{ fontWeight: 700 }} variant="body1">Setzliste</Typography>
                                        <Typography variant="body2">Sollen Spieler im Turnier gesetzt sein? (max. 4 Spieler)</Typography>
                                    </Stack>
                                    <Switch checked={withSeeds} onChange={() => { setWithSeeds(!withSeeds); }}></Switch>
                                </Stack>
                                <Collapse in={withSeeds} sx={{ maxHeight: "500px" }}>
                                    <Divider sx={{ mt: 1 }}></Divider>
                                    <TransferList targetListName={"Setzliste"} transferActionName={"setzen"} targetList={temporarySeeds} setTargetList={setTemporarySeeds} sourceList={seedSourceList} setSourceList={setSeedSourceList} secondaryName={adjustParticipants ? "Rang" :"LK"} secondaryKey={adjustParticipants ? "player_rank" : "nuliga_lk"}></TransferList>
                                </Collapse>
                            </Grid>

                        </>
                        :
                        <></>
                    }
                </Grid>

            </DialogContent >
            <Divider></Divider>
            <DialogActions>
                <SecondaryButton text="Abbrechen" onClick={onClose}></SecondaryButton>
                <PrimaryButton text="Bracket erstellen" disabled={mode == "menu"} onClick={() => createBracket()} />
            </DialogActions>
        </Dialog >
    )
}

export default BracketDialog