import axios from "axios";
import getSessionData from "../../utils/getSession";


export const uploadFile_api = async (data) => {
  const sessionData = getSessionData();

  return await axios.post(
    process.env.REACT_APP_API_URL + "/posts/upload",data, { headers: sessionData }
  );
};

export const getFiles_api = async (data) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/posts/upload", { headers: sessionData }
  );
};

