import axios from "axios";

export const singupUser = async (formData) => {
  
    const data={catpcha: formData.get('captcha'), firstName: formData.get('firstName'), lastName: formData.get('lastName'), password: formData.get('password'), email: formData.get('email')};

    return await axios
    .post(process.env.REACT_APP_API_URL + "/users/signup", data);
};

export default singupUser;
