import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'

function HelpAccordion({ summary, children, innerHTML}) {
  const typoRef = useRef();

  useEffect(() => {
    if(typoRef)
      {
        typoRef.current.innerHTML = innerHTML;
      }

  }, [innerHTML]);
  return (
    <Accordion sx={{ backgroundColor: "#eee", borderLeft: 2, borderColor: "primary.main" }}>
      <AccordionSummary expandIcon={<ExpandMore />} ><Typography variant="body2" fontWeight={500} color="primary.dark">{summary}</Typography></AccordionSummary>
      <AccordionDetails>
        <Divider></Divider>
        <Typography variant="body2" ref={typoRef}>
          {/* {details} */}
          {/* {children} */}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default HelpAccordion