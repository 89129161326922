import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { CalendarIcon, DateTimePicker, StaticDatePicker } from '@mui/x-date-pickers'; // MUI v5 import
import { IconButton, Popover} from '@mui/material';


export default function DatePickFilter({ dayFilter, setDayFilter, textReadOnly, hideInput,iconColor, ...props }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);

    };

    return (
        hideInput ? (
            <>
                <IconButton id="dpf-calendar-button" onClick={handleOpen} aria-label="open date picker">
                    <CalendarIcon color={iconColor}/>
                </IconButton>
                <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                    <StaticDatePicker
                        id="dpf-popover-datepicker"
                        {...props}
                        size={props.size || "small"}
                        label={props.label || "Datum"}
                        value={dayFilter}
                        inputFormat={props.inputFormat || "dd.MM.yyyy"}
                        disableMaskedInput
                        onChange={(newValue) => {
                            setDayFilter(newValue);
                            handleClose()
                        }}
                        onAccept={() => handleClose()}
                        onClose={() => handleClose()}
                        slots={{
                            toolbar: null, // Removes the toolbar
                        }}
                    />
                </Popover>
            </>
        ) : (
            <DateTimePicker
                value={dayFilter}
                onChange={(newValue) => setDayFilter(newValue)}
                slots={{
                    toolbar: null, // Removes the toolbar
                }}
                renderInput={(params) => textReadOnly ? <TextField size={props.size || "small"} fullWidth {...params} inputProps={{ ...params.inputProps, readOnly: true }} /> : <TextField size={props.size || "small"} fullWidth {...params} />} />
        )
    );
}

