import { Close, Collections, Language, UploadFile } from '@mui/icons-material';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Switch, Stack, CardMedia, ImageList, ImageListItem, IconButton, Stepper, Step, StepLabel, StepContent, StepIcon, List, ListItem, ListItemButton, ListItemText, ListItemIcon, AlertTitle } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { createPost_api, updatePost_api } from '../api/Posts/post';
import { getFiles_api, uploadFile_api } from '../api/Upload/upload';
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'
import QuillEditor from './QuillEditor';
import BlogPreviewDialog from './BlogPreviewDialog';
import { formatISO } from 'date-fns';
import TertiaryButton from './TertiaryButton';
import { useSnackbar } from './SnackbarContext';

function AddEditPostDialog(props) {

    const { open, onClose, post, isEdit, getPosts } = props;

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [image, setImage] = useState(null);
    const [images, setImages] = useState("");
    const [category, setCategory] = useState("");
    const [uploading, setUploading] = useState(false);
    const [pictureMode, setPictureMode] = useState(null);
    const [withPicture, setWithPicture] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewPost, setPreviewPost] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [step1IsError, setStep1IsError] = useState(false);
    const [step2IsError, setStep2IsError] = useState(false);
    const [step3IsError, setStep3IsError] = useState(false);
    const defaultImages = [{ url: "https://media.myclubportal.de/news.jpg" }];
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const {showSnackbar} = useSnackbar();

    const changeHandler = (event) => {
        switch (event.target.name) {
            case "title":
                setTitle(event.target.value);
                break;
            case "content":
                setContent(event.target.value);
                break;
            case "image":
                setImage(event.target.value);
                break;
            default:
                break;
        }
    }

    const handleFileUpload = (event) => {

        // if no file is given 
        if (!event.target.files) {
            return;
        }
        else {
            if (event.target.files[0].type.includes("image/")) {
                setUploading(true);
                // send file as formData
                const data = new FormData()
                data.append('file', event.target.files[0])
                var promise = uploadFile_api(data);
                promise.then((result) => { setImage(result.data[0].img_url); showSnackbar("Bild erfolgreich hochgeladen","success"); setUploading(false); })
                    .catch((error) => { showSnackbar("Fehler "+error,"error");setUploading(false); });
            }
           showSnackbar("Kein gültiges Bildformat","warning");
        }
    }

    const submitHandler = () => {
        var promise = null;
        if (image === "") {
            setImage(null);
        }
        if (!isEdit) {
            if (title === "" || title === null || category === "") {
                setStep1IsError(true)
            }
            else if (image === "" || image === null) {
                setStep2IsError(true)
            }
            else if (content === "" || content === null) {
                setStep3IsError(true)
            }
            else {
                setStep1IsError(false);
                setStep2IsError(false);
                setStep3IsError(false);
                promise = createPost_api(title, image, content, category);
                promise.then((result) => { onClose(); showSnackbar("Beitrag erfolgreich erstellt","success");  getPosts(); })
                    .catch((error) => { showSnackbar("Fehler","error");  });
            }
        }
        else {
            if (title === "" || title === null || category === "") {
                setStep1IsError(true)
            }
            else if (image === "" || image === null) {
                setStep2IsError(true)
            }
            else if (content === "" || content === null) {
                setStep3IsError(true)
            }
            else {
                setStep1IsError(false);
                setStep2IsError(false);
                setStep3IsError(false);
                promise = updatePost_api(post.id, title, image, content, category);
                promise.then((result) => { onClose(); showSnackbar("Beitrag erfolgreich geändert","success"); getPosts(); })
                    .catch((error) => { showSnackbar("Fehler","error"); });
            }
        }

    }

    const readFiles = () => {
        var promise = getFiles_api();
        promise.then((result) => { setImages([...defaultImages, ...result.data]); })
            .catch((error) => { showSnackbar("Bilder konnten nicht geladen werden!","error"); });
    }

    useEffect(() => {
        if (open) {
            if (isEdit && post) {
                setImage(post.img_url);
                if (post.img_url) {
                    setWithPicture(true);
                    setPictureMode(null);
                }
                setTitle(post.title);
                setContent(post.content);
                setCategory(post.category);

            }
            else {
                setImage("");
            }
            readFiles();
        }
        else {
            setImage(null);
            setTitle("");
            setContent("");
            setCategory("");
            setWithPicture(false);

        }

    }, [open]);

    useEffect(() => {
        setPreviewPost({ "reg_date": formatISO(new Date()), "category": category, "title": title, "src": image, "content": content })
    }, [openPreview]);

    useEffect(() => {
        if (activeStep === 1 && (title === "" || title === null || category === "")) {
            setStep1IsError(true)
        }
        else if (activeStep === 2 && (title === "" || title === null || category === "")) {
            setStep1IsError(true)
        }
        else {
            setStep1IsError(false);
        }
        if (activeStep === 2 && (image === "" || image === null)) {
            setStep2IsError(true)
        }
        else {
            setStep2IsError(false);
        }
    }, [activeStep]);

    useEffect(() => {
        if (step1IsError || step2IsError) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
        return () => {

        };
    }, [step1IsError, step2IsError]);

    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {!isEdit ? "Beitrag erstellen" : "Beitrag bearbeiten"}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Stepper activeStep={activeStep} orientation='vertical' sx={{ mt: 1 }}>
                        <Step onClick={() => setActiveStep(0)} >
                            <StepLabel error={step1IsError}><Typography variant="body1" fontWeight={500}>Titelzeile</Typography></StepLabel>
                            <StepContent>
                                <Grid container spacing={2} alignItems={"center"}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth required value={title} onChange={changeHandler} name="title" label="Titel des Beitrags"></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth id="category_select">
                                            <InputLabel id="category_select">Kategorie</InputLabel>
                                            <Select label="Kategorie" labelid="Kategorie" value={category} fullWidth onChange={(event) => { setCategory(event.target.value); }}>
                                                <MenuItem value={"aktuelles"}>Aktuelles</MenuItem>
                                                <MenuItem value={"termine"}>Termine</MenuItem>
                                                <MenuItem value={"punktspiele"}>Punktspiele</MenuItem>
                                                <MenuItem value={"jugend"}>Jugend</MenuItem>
                                                <MenuItem value={"turniere"}>Turniere</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                        <Step onClick={() => setActiveStep(1)}>
                            <StepLabel error={step2IsError}><Typography variant="body1" fontWeight={500}>Bild</Typography></StepLabel>
                            <StepContent>
                                <Grid container spacing={2} alignItems={"center"}>
                                    <Grid item xs={12}>
                                        <Alert severity='info'>
                                            <AlertTitle>Wichtig!</AlertTitle>
                                            <Typography variant="body2">Bitte beachte bei der Verwendung von Bildmaterial Urheberrechte und Datenschutz.</Typography>
                                        </Alert>
                                        <List>
                                            <ListItemButton onClick={() => { setPictureMode("existing") }}>
                                                <ListItemText primary={"Vorhandenes Bild"} secondary={"Wähle eine Bild aus den bisherigen Bildern aus"} primaryTypographyProps={{ fontWeight: 500 }} />
                                                <Collections color="primary"/>
                                            </ListItemButton >
                                            <label htmlFor="id-upload-input">
                                                <ListItemButton onClick={() => { setPictureMode(null) }}>
                                                    <ListItemText primary={"Neues Bild"} secondary={"Lade ein neues Bild von deinen lokalen Medien hoch"} primaryTypographyProps={{ fontWeight: 500 }} />
                                                    <UploadFile color="primary"/>
                                                    <input type="file" id="id-upload-input" hidden accept="image/*" onChange={(event) => handleFileUpload(event)} />
                                                </ListItemButton>
                                            </label>
                                            <ListItemButton onClick={() => { pictureMode==="url" ? setPictureMode(null):setPictureMode("url") }}>
                                                <ListItemText primary={"Bild URL"} secondary={"Verlinke ein Bild au dem Inernet"} primaryTypographyProps={{ fontWeight: 500 }} />
                                                <Language color="primary"/>
                                            </ListItemButton>
                                        </List>
                                    </Grid>
                                    {pictureMode === "existing" &&
                                        <Grid item xs={12}>
                                            <Stack direction={"row"} justifyContent={"center"}>
                                            <TertiaryButton startIcon={<Close></Close>} text="schließen" onClick={()=>setPictureMode(null)}></TertiaryButton>
                                            </Stack>
                                            <ImageList sx={{ height: "250px" }} >
                                                {images && images.map((image, i) => (

                                                    <ImageListItem key={i} onClick={() => { setImage(image.url); setPictureMode(null); }}>
                                                        <img srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${image.url}?w=248&fit=crop&auto=format`} loading="lazy" alt="" />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </Grid>
                                    }
                                    <Grid item container xs={12} justifyContent={"center"}>
                                        {image ?
                                            <CardMedia image={image} sx={{ width: 170, height: 96, objectFit: 'cover' }} />
                                            :
                                            // <Alert severity='info' sx={{ width: "100%" }}>
                                            //     <Typography>kein Bild ausgewählt</Typography>
                                            // </Alert>
                                            <></>
                                        }
                                    </Grid>
                                    {pictureMode ==="url" ?
                                        <Grid item xs={12}>
                                            <TextField fullWidth required value={image} onChange={changeHandler} name="image" label="Bild URL"></TextField>
                                        </Grid>
                                        :
                                        <></>
                                    }


                                </Grid>
                            </StepContent>
                        </Step>

                        <Step onClick={() => setActiveStep(2)}>
                            <StepLabel error={step3IsError}><Typography variant="body1" fontWeight={500}>Inhalt</Typography></StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <QuillEditor value={content} setValue={setContent}></QuillEditor>
                                </Grid>
                            </StepContent>
                        </Step>
                    </Stepper>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    {activeStep != 0 ?
                        <Stack direction="row" spacing={1}>
                            <SecondaryButton text="Zurück" onClick={() => { setActiveStep(activeStep - 1) }}></SecondaryButton>
                            <SecondaryButton text={"Vorschau"} onClick={() => setOpenPreview(true)}></SecondaryButton>
                        </Stack>
                        :
                        <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                    }
                    {activeStep < 2 ?
                        <PrimaryButton text="Weiter" onClick={() => { setActiveStep(activeStep + 1) }}></PrimaryButton>
                        :
                        <PrimaryButton text={"Bestätigen"} onClick={() => { submitHandler() }} disabled={submitDisabled}></PrimaryButton>
                    }
                </DialogActions>
            </Dialog>
            <BlogPreviewDialog open={openPreview} onClose={() => setOpenPreview(false)} title={title} author={"test"} src={image} content={content} post={previewPost}></BlogPreviewDialog>
        </>
    )
}

export default AddEditPostDialog