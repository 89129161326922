import { Check, Delete, InfoSharp } from '@mui/icons-material'
import { Avatar, Card, CardActions, CardContent, Chip, Grid, Typography, ButtonGroup, Divider, TableRow, TablePagination, TableHead, Table, Alert } from '@mui/material'
import { AdminPanelSettings, Person } from '@mui/icons-material';
import React, { useState } from 'react'
import PrimaryIconButton from '../../components/PrimaryIconButton';

function UserCard(props) {
    const { users, deleteHandler, detailsHandler, verifyUser } = props;

    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {users.length > 0 ?
                <>
                    <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"flex-end"}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[50, 100, 500]}
                                        count={users.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        labelRowsPerPage={"Anzahl"}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        sx={{ border: 0 }}
                                    />
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Grid>
                    <Grid item container xs={12} rowSpacing={2} columnSpacing={2}>
                        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, i) => (

                            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                                <Card raised sx={{ borderRadius: 2 }} >
                                    <CardContent>
                                        <Grid item xs={12} container direction="row" rowSpacing={1}>
                                            <Grid container item xs={12} direction="row" justifyContent={"flex-end"} spacing={2}>
                                                {/* <Grid item>
                                <Chip size="small" label="Active" sx={{ bgcolor: 'success.trans', color: 'success.main' }}></Chip>
                            </Grid> */}
                                                <Grid item>
                                                    {user.role === "admin" ? <Chip size="small" label={user.role} icon={<AdminPanelSettings />} sx={{ bgcolor: 'warning.trans', color: 'warning.main', fontWeight: 'bold' }} variant="primary" /> : <Chip size="small" label={user.role} icon={<Person />} sx={{ bgcolor: 'success.trans', color: 'success.main', fontWeight: 'bold' }} variant="primary" />}
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} direction="column" alignItems={"center"}>
                                                <Grid item>
                                                    <Avatar sx={{ bgcolor: "primary.main" }}>{user.firstname.charAt(0)}{user.surname.charAt(0)}</Avatar>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{user.firstname} {user.surname}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{user.email}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <Divider></Divider>
                                    <CardActions sx={{ justifyContent: "space-between" }}>
                                        <PrimaryIconButton icon={<Delete />} onClickAction={() => { deleteHandler(user.id) }} />
                                        <ButtonGroup>
                                            <PrimaryIconButton icon={<InfoSharp />} onClickAction={() => { detailsHandler(user.id) }} />
                                            {user.verified !== 1 && <PrimaryIconButton icon={<Check color="success" />} onClickAction={() => { verifyUser(user.id) }}></PrimaryIconButton>}
                                        </ButtonGroup>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </>
                :
                <Grid item xs={12}>
                    <Alert severity="info" variant="filled"><Typography> Keine Registrierungen vorhanden</Typography></Alert>
                </Grid>
            }
        </>
    );
}

export default UserCard