import { Group, PublishedWithChanges } from '@mui/icons-material'
import { IconButton, ListItem, ListItemIcon, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'

function SeasonChangeListItem({team, nuligaTeams, changeSeasonHandler=()=>{}}) {

    const [isChanged, setIsChanged] = useState(false); 

    return (
    <ListItem secondaryAction={
        <Stack
         direction="row" spacing={0}>
            <Tooltip title="Saison wechseln"><IconButton 
            onClick={() => {changeSeasonHandler(team.team, team.captain, nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name == team.team && nuligaTeam.season != decodeURIComponent(team.tnbchampionship)))[0].clubId, encodeURIComponent(nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name == team.team && nuligaTeam.season != decodeURIComponent(team.tnbchampionship)))[0].season), team.id, team.req_teammatch_size, team.type); setIsChanged(!isChanged);}} >
            <PublishedWithChanges fontSize="large" color={isChanged ? "success": "secondary"} /></IconButton></Tooltip>
        </Stack>}>
        <ListItemIcon ><Group color={isChanged ? "success": "secondary"} /></ListItemIcon>
        <Stack>
            <Typography noWrap fontWeight={500}>{team.team}</Typography>
            <Typography variant="body2" color="text.secondary" >{"von: " + decodeURIComponent(team.tnbchampionship)}</Typography>
            <Typography variant="body2" fontWeight={500} color="text.secondary" >{"zu : " + nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name == team.team && nuligaTeam.season != decodeURIComponent(team.tnbchampionship)))[0].season}</Typography>
        </Stack>
    </ListItem>
  )
}

export default SeasonChangeListItem