import React, { useState } from 'react'
import { Avatar, Box, Card, Chip, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material'
import TableFilter from '../../components/TableFilter';
import { myStyles } from '../../css/Sytles';
import DetailedLogDialog from './DetailedLogDialog';
import MyTab from '../../components/MyTab';
import { useTheme } from '@emotion/react';
import { makeStyles } from '@mui/styles';
import { format, isValid } from 'date-fns';


function LogTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [open, setOpen] = useState(false);
    const [logData, setLogData] = useState(null);
    const theme = useTheme();
    const useStyles = makeStyles({
        tableHeader: {
            // backgroundColor: theme.palette.primary.main,
            //  backgroundColor: "#F5F6FA",
            backgroundColor: "#E5E6EA",
            color: theme.palette.text.secondary,
            fontWeight: 600,
        }
    });
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [value, setValue] = React.useState("all");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == "successfull") {
            //props.setHttp_status(200)
            props.addParamToQuesryString("https_status", 200);
            //    props.setSearchParam(props.searchParam.append("https_status", 200));
            //    console.log(props.searchParam.toString());
        }
        else if (newValue == "failed") {
            //props.setHttp_status(500)
            props.addParamToQuesryString("https_status", 500);
            //props.setSearchParam(props.searchParam.append("https_status", 500));
        }
        else {
            //props.setHttp_status("")
            props.addParamToQuesryString("https_status", "");
            //props.setSearchParam(props.searchParam.append("https_status", ""));
        }
        props.setStatus(newValue);
    };



    return (
        <>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" rowSpacing={2}>
                {props.adminMode &&
                    <>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                <Tabs onChange={handleChange}
                                    value={value}
                                    aria-label="Tabs where each tab needs to be selected manually">
                                    <MyTab label="Alle" value="all" />
                                    <MyTab label="Erfolgreich" value="successfull" />
                                    <MyTab label="Fehler" value="failed" />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableFilter filter={props.filter} setFilter={props.setFilter}></TableFilter>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <TableContainer elevation={12} component={Card} sx={{ borderRadius: 2 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[20, 50, 100]}
                                        count={props.rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    >
                                    </TablePagination>
                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableHeader} align="center" >ID</TableCell>
                                    <TableCell className={classes.tableHeader} align="center" >Nachricht</TableCell>
                                    <TableCell className={classes.tableHeader} align="center" >Status</TableCell>
                                    <TableCell className={classes.tableHeader} align="center" >Datum</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows && props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                    <TableRow hover={true} key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => { setOpen(true); setLogData(row); }}>

                                        <TableCell>
                                            <Stack direction="row" justifyContent={"center"}>
                                                <Avatar sx={{ backgroundColor: "#E3F2FD" }}><Typography color="primary" sx={{ fontWeight: "bold" }}>{row.userid}</Typography></Avatar>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.action}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                            {row.https_status == "200" ? <Chip label={row.https_status} color="success" variant="primary" sx={{ color: "#fff" }} /> : <Chip label={row.https_status} color="error" variant="primary" />}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>{row && isValid(new Date(row.reg_date)) ? format(new Date(row.reg_date), "HH:mm:ss dd.MM.yy") : ""}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid >
            {
                props.adminMode &&
                <DetailedLogDialog open={open} onClose={() => { setOpen(false); }} logData={logData} />
            }
        </>
    );
}

export default LogTable;
