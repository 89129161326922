import { Add, FitnessCenter } from '@mui/icons-material';
import { Alert, Box, Chip,  Grid, Stack, Table, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getSeries } from '../../../api/Series/series';
import AddEditSeriesDialog from '../../../components/AddEditSeriesDialog';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header/Header'
import PrimaryButton from '../../../components/PrimaryButton';
import SeriesCard from '../../../components/SeriesCard';
import TitleCard from '../../../components/TitleCard';
import TableFilter from '../../../components/TableFilter';
import MyTab from '../../../components/MyTab';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from '../../../components/SnackbarContext';
import MainContentContainer from '../../../components/MainContentContainer';

function AdmSeriesContainer() {

    //SeriesDialog
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [selectedSerie, setSelectedSerie] = useState(null);

    const [series, setSeries] = useState([]);
    const [tabValue, setTabValue] = useState("");
    const [search, setSearch] = useState("");
    const [courtType, setCourtType] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const { showSnackbar } = useSnackbar();



    const openEditSeriesDialogHandler = (serie) => {
        setSelectedSerie(serie);
        setIsEdit(true);
        setOpenAddDialog(true);
    }

    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        searchParams.set("type__eq", newValue);
        setSearchParams(searchParams);
    }

    const getAllSeries = () => {
        var promise = getSeries(searchParams.toString());
        promise.then((result) => { setSeries(result.data); /*setCourts(JSON.parse(result.data[0].courts));*/ })
            .catch((error) => { });
    }

    //client side filtering
    function combinedFilter(series, courtType, targetObjectId) {
        return series.filter(serie => 
            // courtType filter
            serie.courttype.includes(courtType) && 
              // teammatch filter -> if teammatch searchparam is given filer for series assigned to that teammatch
            (!targetObjectId || (targetObjectId != null && serie.assignments.some(assignment => assignment.object_id == targetObjectId)))
        );
    }

    useEffect(() => {
        searchParams.set("name__like", search);
        setSearchParams(searchParams);
    }, [search]);

    useEffect(() => {
        getAllSeries();
    }, [searchParams]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-series" title={"Serienbuchungen"} subheader={"Verwalte einmalige und wiederholende Platzbuchungen"} icon={<FitnessCenter sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} >
                        <Grid container item direction="row" columnSpacing={2} justifyContent="space-between" sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                    <Tabs value={tabValue} onChange={handleTabChange}>
                                        <MyTab label="Alle" value="" />
                                        <MyTab label="Training" value="training" />
                                        <MyTab label="Punktspiele" value="teammatch" />
                                        <MyTab label="Sonstige" value="other" />
                                    </Tabs>
                                </Box>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
                                    <Tabs value={courtType} onChange={(e, value) => setCourtType(value)}>
                                        <MyTab label="Alle" value="" xsSize="body1" mdSize="h6" />
                                        <MyTab label="Außen" value="outdoor" xsSize="body1" mdSize="h6" />
                                        <MyTab label="Halle" value="indoor" xsSize="body1" mdSize="h6" />

                                    </Tabs>
                                </Box>
                            </Grid>

                            <Grid item xs>
                                <TableFilter id="txf-series-search" filter={search} setFilter={setSearch}></TableFilter>
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={1}>
                                    <PrimaryButton startIcon={<Add></Add>} onClick={() => { setIsEdit(false); setOpenAddDialog(true); }} text="Erstellen" />
                                </Stack>

                            </Grid>
                            <Grid item xs={12}>
                                {searchParams.get("teammatch") &&
                                    <Chip color={"primary"} sx={{mt: 1}} label={"Punktspiel: "+ decodeURI(searchParams.get("teammatch_name"))} onDelete={()=>{searchParams.delete("teammatch");searchParams.delete("teammatch_name");setSearchParams(searchParams);console.log("deleted")}}></Chip>

                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 50, 100]}
                                                count={series ? combinedFilter(series,courtType,searchParams.get("teammatch")).length : 0}
                                                rowsPerPage={rowsPerPage}
                                                labelRowsPerPage={"Anzahl"}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                sx={{ border: 0 }}
                                            />
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {series && series.length > 0 ? combinedFilter(series,courtType,searchParams.get("teammatch")).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((serie, i) => (
                            <Grid item key={i} xs={12} md={6}>
                                <SeriesCard key={i} serie={serie} getSeries={getAllSeries} openEditDialog={(serie) => openEditSeriesDialogHandler(serie)} adminMode={true}></SeriesCard>
                            </Grid>
                        ))
                            :
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled"><Typography>Erstelle wöchentliche Serienplatzbuchungen (z.B. Trainings)</Typography></Alert>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer />
            <AddEditSeriesDialog open={openAddDialog} onClose={() => { setOpenAddDialog(false); }} getSeries={getAllSeries} serie={selectedSerie} isEdit={isEdit} />
        </>
    )
}

export default AdmSeriesContainer