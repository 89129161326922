import { fetchMe } from "../api/Users/users";
import getSessionData from "./getSession";

function initializeUser(dispatch) {
  if (getSessionData()) {
    const mePromise = fetchMe();
     mePromise.then(function (result) {
       var isAdmin=false;
       var isCaptain=false;
       if(result.data[0].role=="admin" || result.data[0].role=="superuser")
       {
          isAdmin=true;
       }
       else if(result.data[0].isCaptain==1)
       {
          isCaptain = true;
       }
       else{

       }
      dispatch({
        type: "SIGNIN",
        payload: {
          isAuthenticated: true,
          firstname: result.data[0].firstname,
          lastname: result.data[0].surname,
          email: result.data[0].email,
          id: result.data[0].id,
          teamID: result.data[0].teamID,
          teamname: result.data[0].teamName,
          isAdmin: isAdmin,
          isCaptain: isCaptain,
          has_notifications: result.data[0].has_notifications
        },
      });
      //setUserData({firstname: result.data[0].firstname, lastname: result.data[0].surname});
    })
    .catch(function (error) {
      dispatch({
        type: "SIGNIN",
        payload: {
          isAuthenticated: false,
          firstname: undefined,
          lastname: undefined,
          email: undefined,
          id: undefined,
          isAdmin: undefined,
          isCaptain: undefined,
          teamID:undefined,
          teamname: undefined,
          has_notifications: undefined
        },
      })
    });
  } else {
    dispatch({
      type: "SIGNIN",
      payload: {
        isAuthenticated: false,
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        id: undefined,
        isAdmin: undefined,
        isCaptain: undefined,
        teamID:undefined,
        teamname: undefined,
        has_notifications: undefined
      },
    })
  }
}
export default initializeUser;
