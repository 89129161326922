import { ChevronRight } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react'

function MyMenuItem({ primaryText, secondaryText,icon, onClick }) {
    return (
        <ListItemButton onClick={onClick} divider>
            {icon &&
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            }
            <ListItemText primary={primaryText} secondary={secondaryText} primaryTypographyProps={{ fontWeight: 500 }} />
            <ChevronRight></ChevronRight>
        </ListItemButton>
    )
}

export default MyMenuItem