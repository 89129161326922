import { FitnessCenter, Launch, } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Divider, Alert, Stack, } from '@mui/material'

import React from 'react'

import { MYTRAININGGROUPS } from '../../navigation/CONSTANTS';
import BorderedIcon from '../../components/BorderedIcon';
import TrainingCard from '../../components/TrainingCard';
import { useNavigate } from 'react-router-dom';

function DashboardTrainingCard(props) {
    const { trainings, getTrainings } = props;
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Card raised sx={{ borderRadius: 2 }}>
                <CardContent >
                    <Grid container direction="row" alignItems="center" justifyContent={"space-between"} spacing={2}>
                        <Grid item align="left">
                            <Stack direction="row" spacing={3}>
                                <BorderedIcon icon={<FitnessCenter color="primary"></FitnessCenter>} />
                                <Typography variant="h6" >{"Trainings"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} align="right">
                            {/* <InternalLink linkname="Mehr" route={MYTRAININGGROUPS} /> */}
                            <Launch id="btn-trainingdashboard-link" color="primary" onClick={()=>navigate(MYTRAININGGROUPS)}></Launch>
                        </Grid>
                        {trainings && trainings.length >= 1 ? trainings.map((training, i) => (

                            <Grid item container direction="column" spacing={1} key={i}>
                                <Grid item xs={12} >
                                    <Divider ></Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TrainingCard training={training} getTrainings={getTrainings} elevation={0} borderRadius={0}></TrainingCard>
                                    </Grid>
                            </Grid>
                        ))
                            :
                            <>
                                <Grid item xs={12} >
                                    <Divider ></Divider>
                                </Grid>
                                <Grid item xs={12}>
                                    <Alert severity={"info"}><Typography>Keine Trainings vorhanden!</Typography></Alert>
                                </Grid>
                            </>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >
    )
}

export default DashboardTrainingCard