import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../components/SecondaryButton'
import PrimaryButton from '../../components/PrimaryButton'
import QuillEditor from '../../components/QuillEditor'

function CommentDialog({ open, onClose,userComment,handleSubmit, ...props }) {

    const [comment, setComment] = useState("")

   

    useEffect(() => {
        if (open) {
            setComment(userComment)
        }
    }, [open]);
    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>
                Kommentar
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <QuillEditor value={comment} setValue={setComment}></QuillEditor>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <SecondaryButton text="Abbrechen" onClick={() => onClose()} />
                <PrimaryButton text="Bestätigen" onClick={() => { handleSubmit(comment) }} />
            </DialogActions>
        </Dialog>
    )
}

export default CommentDialog