import React, { useEffect, useState } from 'react'
import { updateTeam } from '../../../api/Teams/teams';
import LoadingProgress from '../../../components/LoadingProgress';
import { Alert, AlertTitle, Collapse, List, ListSubheader, Stack, Typography } from '@mui/material';
import { PublishedWithChanges } from '@mui/icons-material';
import { useSnackbar } from '../../../components/SnackbarContext';
import SeasonChangeListItem from './SeasonChangeListItem';

function ChangeSeasonContainer({ nuligaTeams, teamNames, teams, isLoading, getAllTeams, subheader, showAlerts, ...props }) {

    const { showSnackbar } = useSnackbar();
    const [updatedTeams, setUpdatedTeams] = useState(false);

    const changeSeasonHandler = (teamname, targetUserId, tnbid, season, id, reqTeammatchSize, teamType) => {
        var promise = null;

        promise = updateTeam(teamname, targetUserId, tnbid, season, id, reqTeammatchSize, teamType);
        promise.then((result) => { showSnackbar("Saison erfolgreich gewechselt", "success"); getAllTeams(); setUpdatedTeams(true); })
            .catch(() => showSnackbar("Fehler beim Bearbieten", "error"));
    }

    return (
        <Stack>
            {isLoading ?
                <LoadingProgress isLoading={isLoading} loadingLabel={"Lade Saisons"}>
                </LoadingProgress>
                :
                <>
                    {/* {showAlerts &&
                        <><Collapse in={!updatedTeams}>
                            <Alert severity='info' icon={<PublishedWithChanges fontSize='small'></PublishedWithChanges>}>
                                <AlertTitle>Saisonwechsel</AlertTitle>
                                <Typography variant='body2'>Nur die Punktspiele der aktuell eingestellten Saison sind für die Mitglieder sichtbar.</Typography>
                            </Alert>
                        </Collapse>
                            <Collapse in={updatedTeams}>
                                <Alert>
                                    <AlertTitle>Saison geändert</AlertTitle>
                                    <Typography variant="body2">Du hast die Saison geändert. Synchronisiere die Punktspiele bei Bedarf manuell, alternativ werden die NuLiga-Informationen automatisch in der kommenden Nacht synchronisiert.</Typography>
                                </Alert>
                            </Collapse>
                        </>} */}
                    <List>
                        {subheader && <ListSubheader>Mögliche Saisonwechsel</ListSubheader>}
                        {teams && teams.map((team, i) => (
                            nuligaTeams && nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name == team.team && nuligaTeam.season != decodeURIComponent(team.tnbchampionship))).length > 0 &&
                                <SeasonChangeListItem key={i} nuligaTeams={nuligaTeams} team={team} changeSeasonHandler={changeSeasonHandler} ></SeasonChangeListItem>
                        ))
                        }
                    </List>
                </>
            }

        </Stack>

    )
}

export default ChangeSeasonContainer