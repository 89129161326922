import React, { useEffect, useState } from 'react'
import { Alert, Card, CardContent, Container,Grid, Link, Table, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material'
import SupportCard from '../../components/SupportCard';
import { getSupportCases_api } from '../../api/Support/support';
import PrimaryButton from '../../components/PrimaryButton';

import { FEEDBACK } from '../../navigation/CONSTANTS';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import TitleCard from '../../components/TitleCard';
import { Add, LiveHelp } from '@mui/icons-material';
import MyTab from '../../components/MyTab';
import MainContentContainer from '../../components/MainContentContainer';
import Footer from '../../components/Footer';

function SupportContainer(props) {
    const [cases, setCases] = useState(null);
    const [value, setValue] = useState("all");
    const [query, setQuery] = useState("");

    const handleChange = (event, newValue) => {
        if (newValue === "all") {
            setQuery("");
            setValue(newValue);
        }
        else {
            var queryStart = "?status__eq=";
            setQuery(queryStart.concat(newValue));
            setValue(newValue);
        }
    }

    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const navigate = useNavigate();
    const createTicket = () => {
        navigate(FEEDBACK);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //get support cases (optional filtered by status)
    const getSupportCases = (value) => {
        var promise = getSupportCases_api(value);
        promise.then((result) => { setCases(result.data) })
            .catch((error) => console.log(error));
    }


    useEffect(() => {
        getSupportCases(query);
        return () => {
        };
    }, [value]);
    return (
        <>
            <Header />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-support" title="Support" icon={<LiveHelp sx={{ width: 40, height: 40 }} />} subheader="Verwalte deine Support-Tickets"></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer px={0}>
                <Grid container>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent >
                                <Container>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={12} container spacing={2} sx={{ justifyContent: { xs: "center", md: 'flex-start' } }} >
                                            <Grid item xs={12}>
                                                <Tabs centered value={value} onChange={handleChange} sx={{ borderBottom: '1px solid #e8e8e8' }}>
                                                    <MyTab value="all" label="Alle"></MyTab>
                                                    <MyTab value="new" label="Neu"></MyTab>
                                                    <MyTab value="open" label="Offen"></MyTab>
                                                    <MyTab value="closed" label="Erledigt"></MyTab>
                                                </Tabs>
                                            </Grid>
                                            <Grid item container justifyContent="space-between" alignItems={"center"} xs={12}>
                                                <Grid item>
                                                <Link rel="noreferrer" target="_blank" href={"https://myclubportal.de/faq"}><Typography variant="body1">Häufig gestellte Fragen</Typography></Link>
                                                </Grid>

                                                <Grid item>
                                                    <PrimaryButton startIcon={<Add></Add>} text="Ticket erstellen" onClick={() => createTicket()} />
                                                </Grid>

                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <Divider></Divider>
                                            </Grid> */}
                                            <Grid container item justifyContent={"space-between"}>
                                                <Grid item>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TablePagination
                                                                    rowsPerPageOptions={[20, 50, 100]}
                                                                    count={cases && cases.length || 0}
                                                                    rowsPerPage={rowsPerPage}
                                                                    labelRowsPerPage={"Anzahl"}
                                                                    page={page}
                                                                    onPageChange={handleChangePage}
                                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                                    sx={{ border: 0 }}
                                                                />
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                            {
                                                cases && cases.length > 0 ?
                                                    cases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((supportcase, i) => (
                                                        <Grid item key={i} xs={12}>
                                                            <SupportCard supportcase={supportcase} getSupportCases={getSupportCases}></SupportCard>
                                                        </Grid>
                                                    ))
                                                    :
                                                    <Grid item xs={12}>
                                                        <Alert severity="info"><Typography>Es sind keine Support-Tickets vorhanden</Typography></Alert>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer/>
        </>
    )
}

export default SupportContainer