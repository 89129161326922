import { Person, SupportAgent } from '@mui/icons-material';
import { Divider, Paper, Stack, Typography } from '@mui/material'
import { format } from 'date-fns';
import React from 'react'

function SupportMessage(props) {

  const { message } = props;
  return (
    <Paper elevation={0} sx={message.author === "supporter" ? { backgroundColor: "#eee", p: 1 } : { border: 1, borderRadius: 1, borderColor: "#bbb", p: 1 }}>
    <Stack spacing={1}>
      <Stack direction={message.author === "supporter" ? "row" : "row"} justifyContent={"space-between"} spacing={2}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          {message.author === "supporter" ? <SupportAgent></SupportAgent> : <Person color="primary"></Person>}
          <Typography variant={"body2"} color={message.author === "supporter" ? "text.secondary" : "primary"}>{message.author === "supporter" ? "Support:" : "Requester:"}</Typography>
        </Stack>
        <Typography variant={"body2"} color="text.secondary">{format(new Date(message.reg_date), "HH:ii - dd.MM.yy")}</Typography>
      </Stack>
      <Divider></Divider>
      <Typography sx={{ whiteSpace: 'pre-line', pt: 1 }}>{message.message}</Typography>
    </Stack>
  </Paper>
  )
}

export default SupportMessage