import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getPosts_api = async (query) => {
  const sessionData = getSessionData();

  if(query)
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/posts?"+query, {
      headers: sessionData,
    });
  }
  else
  {
    return await axios.get(process.env.REACT_APP_API_URL + "/posts", {
      headers: sessionData,
    });
  }  
};

export const createPost_api = async (title, image, content,category) => {
  const sessionData = getSessionData();

  var data = {
    title: title,
    image: image,
    content: content,
    category: category
  };

  return await axios.post(process.env.REACT_APP_API_URL + "/posts", data, {
    headers: sessionData,
  });
};

export const updatePost_api = async (postID,title, image, content,category) => {
  const sessionData = getSessionData();

  var data = {
    title: title,
    image: image,
    content: content,
    category: category
  };

  return await axios.patch(process.env.REACT_APP_API_URL + "/posts/"+postID, data, {
    headers: sessionData,
  });
};

export const deletePost_api = async (postID) => {
  const sessionData = getSessionData();


  return await axios.delete(process.env.REACT_APP_API_URL + "/posts/"+postID, {
    headers: sessionData,
  });
};
