import { AccessTime, Delete, DeleteSweep, Edit, FormatListNumbered, Group, Info, Lock, Login, Logout, Mail, PlaylistPlay, LockPersonSharp, Description, HistorySharp, LockClockSharp, PersonAddDisabledSharp, LockPerson, PersonAdd, GroupAdd, Launch, GridOn } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardMedia, Collapse, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { compareAsc, format, formatDistanceToNow } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux';
import { closeRegistration_api, createGroups_Api, deleteBracket_api, deleteEventParticipant_api, deleteEvents_api, deleteGroups_Api, getEventParticipants_api, getEventsViewPermissionsResolved_api, setEventParticipant_api } from '../api/Events/events';
import EventMemberCard from './EventMemberCard';
import DescriptionCard from './DescriptionCard';
import DynamicExpandIcon from './DynamicExpandIcon';
import StatusChip from './StatusChip';
import { useNavigate } from 'react-router-dom';
import Trophy_Image from "../assets/trophy.jpg"
import Event_Image from "../assets/claycourt.jpg"
import { de } from 'date-fns/locale';
import PrimaryIconButton from './PrimaryIconButton';
import DeleteDialog from './DeleteDialog';
import BracketDialog from './BracketDialog';
import { useSnackbar } from './SnackbarContext';
import EventTimelineItem from './EventTimelineItem';
import ActionMenu from './ActionMenu';
import ActionMenuItem from './ActionMenuItem';
import ResponsiveDrawer from './ResponsiveDrawer';
import GroupsDialog from './GroupsDialog';


function EventActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isAdmin = useSelector((state)=>state.isAdmin);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();


    return (
        <ActionMenu id="btn-event-menu" isDrawer={props.isDrawer} drawerTitle={props.drawerTitle} drawerSubtitle={props.drawerSubtitle} iconSize="small" color={"grey"}>

            {props.event.withParticipants === 1 && compareAsc(new Date(props.event.deadline), new Date()) > 0 ?
                props.event.isParticipant === null ?

                    <ActionMenuItem itemText={"Anmelden"} icon={<Login fontSize="small" />} onClick={() => { setAnchorEl(null); props.signInAction(); }} />
                    :
                    <ActionMenuItem itemText={"Abmelden"} icon={<Login fontSize="small" />} onClick={() => { setAnchorEl(null); props.signOutAction(); }} />
                :
                <div></div>
            }
            {props.isDrawer != true ?
                <ActionMenuItem itemText={"Details"} icon={<Info fontSize="small" />} onClick={() => { setAnchorEl(null); props.openDetails() }} />
                :
                <div></div>
            }
            {isAdmin && props.adminMode && props.event.withParticipants === 1 ?

                <ActionMenuItem itemText={"Anmeldung schließen"} icon={<LockPerson fontSize="small" />} onClick={() => { setAnchorEl(null); props.closeSignInAction(); }} />
                :
                <div></div>
            }
            <ActionMenuItem itemText={"Beschreibung anzeigen"} icon={<Description fontSize="small" />} onClick={() => { setAnchorEl(null); props.setOpenDescription(true); }} />            {props.event.withParticipants === 1 ?
                <ActionMenuItem itemText={"Teilnehmer anzeigen"} icon={<Group fontSize="small" />} onClick={() => { setAnchorEl(null); props.setOpenParticipants(true); }} />
                :
                <div></div>
            }
            {isAdmin && props.adminMode ?
                <div>
                    <ActionMenuItem itemText={"Nachricht an Teilnehmer"} icon={<Mail fontSize="small" />} onClick={() => { setAnchorEl(null); props.mailAction(); }} />
                    <ActionMenuItem itemText={"Bearbeiten"} icon={<Edit fontSize="small" />} onClick={() => { setAnchorEl(null); props.editAction(); }} />
                    <ActionMenuItem id="btn-eventcardmenu-delete" itemText={"Löschen"} icon={<Delete fontSize="small" color="error" />} onClick={() => { setAnchorEl(null); props.deleteAction(); }} />
                </div>
                :
                <div></div>
            }
            {props.event.type == "Turnier" ?
                <Divider ><Typography>Turnierbaum</Typography></Divider>
                :
                <div></div>
            }
            {isAdmin && props.event.type == "Turnier" && props.adminMode ?
                <ActionMenuItem disabled={props.event.hasBracket == 0 && (props.event.totalParticipants >= 2 || !props.event.withParticipants) ? false : true} itemText={"Turnierbaum erstellen"} icon={<FormatListNumbered fontSize="small" />} onClick={() => { setAnchorEl(null); props.createBracket() }} />
                :
                <div></div>
            }
            {props.event.type == "Turnier" ?
                <ActionMenuItem disabled={props.event.hasBracket == 0} itemText={"Turnierbaum öffnen"} icon={<PlaylistPlay fontSize="small" />} onClick={() => { setAnchorEl(null); navigate("/tournament/" + props.event.id + "?tab=bracket") }} />
                :
                <div></div>
            }
            {isAdmin && props.event.type == "Turnier" && props.adminMode ?
                <ActionMenuItem disabled={props.event.hasBracket == 0} itemText={"Turnierbaum löschen"} icon={<DeleteSweep fontSize="small" />} onClick={() => { setAnchorEl(null); props.deleteBracket(); }} />
                :
                <div></div>
            }

            {props.event.type == "Turnier" ?
                <Divider ><Typography>Turniergruppen</Typography></Divider>
                :
                <div></div>
            }
            {isAdmin && props.event.type == "Turnier" && props.adminMode ?
                <ActionMenuItem disabled={props.event.hasGroups == 0 ? false : true} itemText={"Turniergruppen erstellen"} icon={<GroupAdd fontSize="small" />} onClick={() => { setAnchorEl(null); props.createGroups() }} />
                :
                <div></div>
            }
            {isAdmin && props.event.type == "Turnier" ?
                <ActionMenuItem disabled={props.event.hasGroups ? false : true} itemText={"Turniergruppen anzeigen"} icon={<GridOn fontSize="small" />} onClick={() => { setAnchorEl(null); navigate("/tournament/" + props.event.id + "?tab=groups") }} />
                :
                <div></div>
            }

            {isAdmin && props.event.type == "Turnier" && props.adminMode ?
                <ActionMenuItem disabled={props.event.hasGroups ? false : true} itemText={"Turniergruppen löschen"} icon={<Delete fontSize="small" />} onClick={() => { setAnchorEl(null); props.deleteGroups() }} />
                :
                <div></div>
            }
        </ActionMenu>
    );
}


function EventsCard(props) {
    const { event, getEvents, adminMode, openEditDialog, isTimeline, hideConnector } = props;
    const [state, setState] = useState();
    const [expandState, setExpandState] = useState({ event: false, description: false, participants: false })
    const [courts, setCourts] = useState(undefined);
    const [participants, setParticipants] = useState(undefined);
    const [invitees, setInvitees] = useState(undefined);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Title", warningText: "warning", openDeleteDialog, deleteObjectName: "object", deleteHandler: () => { } });
    const [openBracketDialog, setOpenBracketDialog] = useState(false);
    const [openParticipantsDrawer, setOpenParticipantsDrawer] = useState(false);
    const [openDescriptionDrawer, setOpenDescriptionDrawer] = useState(false);
    const [openGroupsDialog, setOpenGroupsDialog] = useState(false);
    const navigate = useNavigate();
    const ref = useRef()
    const [seeds, setSeeds] = useState([]);
    const isAdmin = useSelector((state)=>state.isAdmin);
    const userID = useSelector((state)=>state.id);

    const { showSnackbar } = useSnackbar();

    const expandHandler = () => {
        setExpandState(expandState => ({ event: !expandState.event, description: false, participants: false }))
    }

    const descriptionExpandHandle = () => {
        if (expandState.event) {
            setExpandState(expandState => ({ event: !expandState.event, description: false, participants: false }))
        }
        else {
            setExpandState(expandState => ({ event: !expandState.event, description: true, participants: false }))
        }
    }

    const participantExpandHandle = () => {
        if (expandState.event) {
            setExpandState(expandState => ({ event: !expandState.event, description: false, participants: false }))
        }
        else {
            setExpandState(expandState => ({ event: !expandState.event, description: false, participants: true }))
        }
    }

    const deleteEvent = () => {
        var promise = deleteEvents_api(event.id)
        promise.then((result) => { getEvents(); setExpandState(expandState => ({ event: false, ...expandState })); showSnackbar("Event erfolgreich gelöscht", "success"); })
            .catch((error) => showSnackbar("Etwas ist schief gelaufen", "error"));
    }

    const getEventParticipants = () => {
        var promise = getEventParticipants_api(event.id);
        promise.then((result) => { setParticipants(result.data) })
            .catch();
    }

    const deleteEventParticipant = () => {
        var promise = deleteEventParticipant_api(event.id, userID);
        promise.then((result) => { getEventParticipants(); getEvents(); })
            .catch();
    }
    const setEventParticipant = (userid) => {
        var promise = setEventParticipant_api(event.id, userid);
        promise.then((result) => { getEventParticipants(); getEvents(); })
            .catch((error) => { showSnackbar(error.response.data.error, "warning"); });

    }

    const deleteBracket = () => {
        var promise = deleteBracket_api(event.id);
        promise.then((result) => { showSnackbar("Turnierbaum erfolgreich gelöscht", "success"); getEvents(); })
            .catch((error) => { showSnackbar("Fehler beim Löschen des Turnierbaums", "error"); getEvents(); })
    }

    const openDeleteBracket = () => {
        setDeleteDialogState({ title: "Bracket löschen", openDeleteDialog: true, deleteHandler: () => deleteBracket(), deleteObjectName: "Bracket " + event.id })
    }

    const openDeleteEvent = () => {
        setDeleteDialogState({ title: "Event löschen", openDeleteDialog: true, deleteHandler: () => deleteEvent(), deleteObjectName: event.name })
    }

    const openDeleteGroup = () => {
        setDeleteDialogState({ title: "Gruppen löschen", openDeleteDialog: true, deleteHandler: () => deleteGroups(), deleteObjectName: "Gruppen " })
    }

    const getViewPermissionsResolved = () => {
        var promise = getEventsViewPermissionsResolved_api(event.id);
        promise.then((result) => { setInvitees(result.data); })
            .catch(() => { });
    }

    const closeRegistration = () => {
        var promise = closeRegistration_api(event.id);
        promise.then((result) => { getEvents(); showSnackbar("Anmeldung erfolgreich geschlossen", "success") })
            .catch((error) => { showSnackbar("Fehler beim schließen der Anmeldung", "error") });
    }


    const deleteGroups = () => {
        var promise = deleteGroups_Api(event.id);
        promise.then((result) => { getEvents(); showSnackbar("Turniergruppen erfolgreich gelöscht", "success") })
            .catch((error) => showSnackbar("Fehler beim Löschen der Gruppen", "error"));
    }

    const openGroupsDialogAction = () => {
        setOpenGroupsDialog(true);
    }

    const closeGroupsDialog = () => {
        setOpenGroupsDialog(false);
        getEvents();
    }

    useEffect(() => {
        if (event && (expandState.event || openParticipantsDrawer)) {
            try {
                setCourts(JSON.parse(event.courts));
            } catch (error) {
                // console.log(error);
            }
            // if (event.withParticipants === 1) {
            //     getEventParticipants();
            // }
            if (isAdmin) {
                getViewPermissionsResolved();

            }
            getEventParticipants();
        }
        return () => {
            setState(null);
        };
    }, [expandState.event, openParticipantsDrawer]);

    useEffect(() => {
        if ((openBracketDialog || openGroupsDialog) && event) {
            getEventParticipants();
            //getViewPermissionsResolved();
        }
    }, [openBracketDialog, openGroupsDialog]);

    return (
        <>
            {isTimeline ?
                <EventTimelineItem event={event} getEvents={getEvents} hideConnector={hideConnector} expanded={expandState.participants} deadline={event && "Frist: " + format(new Date(event.deadline), "dd.MM.yy")} isAdmin={isAdmin} setOpenDescription={setOpenDescriptionDrawer} setOpenParticipants={setOpenParticipantsDrawer}
                    eventMenu={<EventActionMenu isDrawer drawerTitle={event.name} deleteGroups={openDeleteGroup} createGroups={openGroupsDialogAction} drawerSubtitle={"Veranstaltungsmenü"} event={event} isAdmin={isAdmin} adminMode={adminMode} closeSignInAction={() => closeRegistration()} openDetails={() => expandHandler()} signOutAction={() => deleteEventParticipant()} signInAction={() => setEventParticipant(props.userid)} deleteAction={() => { openDeleteEvent() }} editAction={() => { openEditDialog(event) }} mailAction={() => { props.setOpenMailDialog(true) }} createBracket={() => { setOpenBracketDialog(true); }} deleteBracket={() => { openDeleteBracket() }} setDeleteDialogState={setDeleteDialogState} setOpenDescription={setOpenDescriptionDrawer} setOpenParticipants={setOpenParticipantsDrawer} />}>
                </EventTimelineItem>
                :
                <Card id="crd-event" raised sx={{ borderRadius: 2 }} ref={ref}>
                    {event.type != "Turnier" ?
                        <Box sx={{ position: "relative", width: "100%" }}>
                            <CardMedia image={Event_Image} sx={{ height: "180px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>

                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 16px)", p: 1 }}>
                                <Typography variant="h6" fontWeight={"Regular"}>{event.name}</Typography>
                                <Stack direction="row" justifyContent={"space-between"}>
                                    <Typography variant="body1" >{event.ende && (format(new Date(event.start), "d. MMMM yyyy", { locale: de }))}</Typography>
                                    <Typography variant="body1" >{event.start && formatDistanceToNow(new Date(event.start), { locale: de, addSuffix: "in" })}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                        :
                        <Box sx={{ position: "relative", width: "100%" }}>
                            <CardMedia image={Trophy_Image} sx={{ height: "180px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>

                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 16px)", p: 1 }}>
                                <Typography variant="h6" fontWeight={"Regular"}>{event.name}</Typography>
                                <Stack direction="row" justifyContent={"space-between"}>
                                    <Typography variant="body1" >{event.ende && (format(new Date(event.start), "d. MMMM yyyy", { locale: de }))}</Typography>
                                    <Typography variant="body1" >{event.start && formatDistanceToNow(new Date(event.start), { locale: de, addSuffix: "in" })}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                    }

                    <CardContent
                        sx={{
                            p: 1, "&:last-child": {
                                paddingBottom: 1
                            }
                        }}>

                        <Grid container direction="row" alignItems="center" rowSpacing={1} columnSpacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", mb: "-8px" }}>
                                    <PrimaryIconButton icon={<Tooltip title="Beschreibung anzeigen"><Description color="primary"></Description></Tooltip>} onClickAction={() => { descriptionExpandHandle(); }}></PrimaryIconButton>
                                    {event.hasGroups && event.hasGroups > 0 ?
                                        <Grid item>
                                            <PrimaryIconButton icon={<Tooltip title="Turngruppen anzeigen"><GridOn color="primary" fontSize='small' /></Tooltip>} onClickAction={() => navigate("/tournament/" + event.id + "?tab=groups")}></PrimaryIconButton>
                                        </Grid>
                                        :
                                        <></>
                                    }

                                    {event.hasBracket && event.hasBracket > 0 ?
                                        <Grid item>
                                            <PrimaryIconButton icon={<Tooltip title="Turnierbaum öffnen"><FormatListNumbered color="primary" /></Tooltip>} onClickAction={() => navigate("/tournament/" + event.id + "?tab=bracket")}></PrimaryIconButton>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    <Stack alignItems={"center"} spacing={1}>
                                        <Stack id="btn-event-particpianthandler" direction="row" spacing={1} onClick={() => { participantExpandHandle() }}>
                                            <Tooltip title={(event.global ? "Vereinsweites Event" : "Privates Event") + (event.withParticipants === 1 ? " | Teilnehmer" : "")}>
                                                <Group color="primary" />
                                            </Tooltip>
                                            {event.withParticipants === 1 ?
                                                event.maxRegistrations != 0 && event.maxRegistrations != null && event.maxRegistrations != "" ?
                                                    <Typography id="txt-event-totalparticipants" sx={{ color: "primary.main" }}>{event.totalParticipants + "/" + event.maxRegistrations}</Typography>
                                                    :
                                                    <Typography id="txt-event-totalparticipants" sx={{ color: "primary.main" }}>{event.totalParticipants}</Typography>
                                                :
                                                <></>
                                            }
                                        </Stack>
                                    </Stack>

                                    {event.withParticipants === 1 ?
                                        compareAsc(new Date(event.deadline), new Date()) > 0 ?
                                            event.isParticipant ?
                                                <PrimaryIconButton id="btn-event-logout" icon={<Logout color="error"></Logout>} onClickAction={() => deleteEventParticipant()}></PrimaryIconButton>
                                                : event.totalParticipants < event.maxRegistrations || event.maxRegistrations == 0 || event.maxRegistrations == null || event.maxRegistrations == "" ?
                                                    <PrimaryIconButton id="btn-event-login" icon={<Login color="success"></Login>} onClickAction={() => setEventParticipant(userID)}></PrimaryIconButton>
                                                    :
                                                    <Tooltip title="Maximale Anzahl an Teilnehmern erreicht">
                                                        <LockPersonSharp color="primary" />
                                                    </Tooltip>
                                            :
                                            <Tooltip title="Anmeldung abgelaufen">
                                                <LockClockSharp color="primary" />
                                            </Tooltip>
                                        :
                                        <Tooltip title="Anmeldung nicht notwendig">
                                            <Lock color="primary"></Lock>
                                        </Tooltip>
                                    }
                                    <EventActionMenu event={event} isAdmin={isAdmin} adminMode={adminMode} deleteGroups={openDeleteGroup} createGroups={openGroupsDialogAction} closeSignInAction={() => closeRegistration()} openDetails={() => expandHandler()} signOutAction={() => deleteEventParticipant()} signInAction={() => setEventParticipant()} deleteAction={() => { openDeleteEvent() }} editAction={() => { openEditDialog(event) }} mailAction={() => { props.setOpenMailDialog(event) }} createBracket={() => { setOpenBracketDialog(true); }} deleteBracket={() => { openDeleteBracket() }} setDeleteDialogState={setDeleteDialogState} setOpenDescription={setOpenDescriptionDrawer} setOpenParticipants={setOpenParticipantsDrawer}></EventActionMenu>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ pt: "unset" }}>
                                <Divider>
                                    {event.withParticipants ?

                                        <StatusChip status={event.isParticipant}></StatusChip>

                                        :
                                        <DynamicExpandIcon expanded={expandState.event} onClick={() => { expandHandler() }} sx={{ p: 0 }} />
                                    }
                                </Divider>
                            </Grid>
                            <Collapse in={expandState.event} timeout="auto" unmountOnExit onClick={() => { expandHandler(); }} sx={{ width: "100%" }}>
                                {/* <CardContent sx={{ bgcolor: "#eeeeee" }} width="100%"> */}

                                <Grid item container xs={12} direction="row" rowSpacing={2} columnSpacing={2} >
                                   
                                        {event.description &&
                                            <Grid item xs={12}>
                                                <DescriptionCard
                                                    expanded={expandState.description}
                                                    content={event.description}
                                                    subHeader={event.ende && ("Datum: " + format(new Date(event.start), "dd.MM.kk 'Uhr'") + " - " + format(new Date(event.ende), "dd.MM.kk 'Uhr'"))}
                                                    maxHeight={"500px"}>
                                                </DescriptionCard>
                                            </Grid>
                                        }
                                   
                                    <Grid item xs={12}>
                                        {participants && <EventMemberCard isDelete maxHeight={"500px"} expanded={expandState.participants} getEvents={getEvents} participants={participants} getParticipants={getEventParticipants} setParticipant={setEventParticipant} invitees={invitees} getInvitees={getViewPermissionsResolved} event={event} deadline={event && "Frist: " + format(new Date(event.deadline), "dd.MM.yy")}></EventMemberCard>}
                                    </Grid>

                                </Grid>
                                {/* </CardContent> */}
                            </Collapse>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ backgroundColor: "#E3F2FD" }}><AccessTime color="primary"></AccessTime></Avatar>
                                    <Stack>
                                        <Typography variant="body1">{event.ende && (format(new Date(event.start), "EEEE, dd.MM.yyyy kk 'Uhr'", { locale: de }))}</Typography>
                                        <Typography variant="body2" color="text.secondary">Startzeit</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                {event.withParticipants ?
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockPersonSharp color="primary"></LockPersonSharp></Avatar>
                                        <Stack>
                                            <Typography>{event.deadline && (format(new Date(event.deadline), "EEEE, dd.MM kk 'Uhr'", { locale: de }))}</Typography>
                                            <Typography variant="body2" color="text.secondary">Anmeldefrist</Typography>
                                        </Stack>
                                    </Stack>
                                    :
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Avatar sx={{ backgroundColor: "#E3F2FD" }}><HistorySharp color="primary"></HistorySharp></Avatar>
                                        <Stack>
                                            <Typography>{event.ende && (format(new Date(event.ende), "EEEE, dd.MM kk 'Uhr'", { locale: de }))}</Typography>
                                            <Typography variant="body2" color="text.secondary">Endzeit</Typography>
                                        </Stack>
                                    </Stack>
                                }

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            }

            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
            <BracketDialog event={event} participants={participants} open={openBracketDialog} onClose={() => {setOpenBracketDialog(false);getEvents();}} seeds={seeds} setSeeds={setSeeds} setSnackBar={showSnackbar} />
            <GroupsDialog event={event} participants={participants} open={openGroupsDialog} onClose={closeGroupsDialog} getEvents={() => getEvents()}></GroupsDialog>
            <ResponsiveDrawer openDrawer={openParticipantsDrawer} setOpenDrawer={setOpenParticipantsDrawer} drawerSubtitle={"Teilnehmerliste"} drawerTitle={event.name}>
                {participants && <EventMemberCard isDelete expanded={true} getEvents={getEvents} participants={participants} getParticipants={getEventParticipants} setParticipant={setEventParticipant} invitees={invitees || undefined} getInvitees={getViewPermissionsResolved} event={event} deadline={event && "Frist: " + format(new Date(event.deadline), "dd.MM.yy")}></EventMemberCard>}
            </ResponsiveDrawer>

            <ResponsiveDrawer openDrawer={openDescriptionDrawer} setOpenDrawer={setOpenDescriptionDrawer} drawerSubtitle={"Beschreibung"} drawerTitle={event.name}>
                <Stack spacing={1}>
                    <CardContent>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar sx={{ backgroundColor: "#E3F2FD" }}><AccessTime color="primary"></AccessTime></Avatar>
                                <Stack>
                                    <Typography variant="body1">{event.ende && (format(new Date(event.start), "EEEE, dd.MM.yyyy kk 'Uhr'", { locale: de }))}</Typography>
                                    <Typography variant="body2" color="text.secondary">Startzeit</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar sx={{ backgroundColor: "#E3F2FD" }}><HistorySharp color="primary"></HistorySharp></Avatar>
                                <Stack>
                                    <Typography>{event.ende && (format(new Date(event.ende), "EEEE, dd.MM kk 'Uhr'", { locale: de }))}</Typography>
                                    <Typography variant="body2" color="text.secondary">Endzeit</Typography>
                                </Stack>
                            </Stack>
                            {event.withParticipants ?
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockPersonSharp color="primary"></LockPersonSharp></Avatar>
                                    <Stack>
                                        <Typography>{event.deadline && (format(new Date(event.deadline), "EEEE, dd.MM kk 'Uhr'", { locale: de }))}</Typography>
                                        <Typography variant="body2" color="text.secondary">Anmeldefrist</Typography>
                                    </Stack>
                                </Stack>
                                :
                                <></>
                            }
                        </Stack>
                    </CardContent>
                    <DescriptionCard
                        expanded={true}
                        content={event.description}
                        subHeader={event.ende && ("Datum: " + format(new Date(event.start), "dd.MM.kk 'Uhr'") + " - " + format(new Date(event.ende), "dd.MM.kk 'Uhr'"))}>
                    </DescriptionCard>
                </Stack>
            </ResponsiveDrawer>
        </>
    )
}

export default EventsCard