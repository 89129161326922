import axios from "axios";
import getSessionData from "../../utils/getSession";

export const sendFeedback_api = async (message) => {
  const sessionData = getSessionData();

  var data ={
      message: message
  }
  
  //fetch reservation filtered by day data
  return await axios.post(
    process.env.REACT_APP_API_URL + "/feedback",data,
    { headers: sessionData }
  );
};