import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getLogs = async () => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/log", { headers: sessionData })
};

export const getLogsFiltered = async (queryString) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/log?"+queryString, { headers: sessionData })
};

export const getLogsLimited_api = async (limit) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/log?limit="+limit, { headers: sessionData })
};

export const getUserLogs_api = async () => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/users/logs", { headers: sessionData })
};

export const getLogDetails_api = async (id) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/log/" +id +"/details", { headers: sessionData })
};