import { useTheme } from '@emotion/react'
import { ChevronLeft, ChevronRight, Code, Edit, EmojiEvents, Fullscreen } from '@mui/icons-material'
import { Alert, Avatar, Box, Card, CardContent, Chip, Container, Divider, Fab, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Stack, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { getBracketMatches_api, getEvents_api, getTournamentSeeds_api } from '../../api/Events/events'
import BracketMatchCard from '../../components/BracketMatchCard'
import Footer from '../../components/Footer'
import Header from '../../components/Header/Header'
import TitleCard from '../../components/TitleCard'
import MatchDialog from './MatchDialog'
import MyTab from '../../components/MyTab'
import ActionMenu from '../../components/ActionMenu'
import PrimaryButton from '../../components/PrimaryButton'
import GroupsContainer from './GroupsContainer'
import { useSelector } from 'react-redux'
import MainContentContainer from '../../components/MainContentContainer'
function BracketMenu(props) {
    return (
        <ActionMenu button buttonName='Menu'>
            <MenuItem onClick={() => { props.setEditMode(!props.editMode); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                {!props.editMode ? "Turnierbaum editieren": "Editierung beenden"}
            </MenuItem>
            {/* <MenuItem onClick={() => { props.updateTeammatches(); }}>
          <ListItemIcon>
            <Refresh fontSize="small" />
          </ListItemIcon>
          Spiele aktualisieren
        </MenuItem> */}

        </ActionMenu>
    );
}

function TournamentContainer(props) {

    const theme = useTheme();
    const [searchParam,setSearchParams] = useSearchParams();
    const [currentRound, setcurrentRound] = useState(0);
    const [displayLeft, setDisplayLeft] = useState(false);
    const [displayRight, setDisplayRight] = useState(false);
    const [rounds, setRounds] = useState(undefined);
    const [roundNames, setRoundNames] = useState([". Runde", ". Runde", ". Runde", "Achtelfinale", "Viertelfinale", "Halbfinale", "Finale"]);
    const [displayRounds, setDisplayRounds] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false)
    const { id } = useParams();
    const [matches, setMatches] = useState([]);
    const [tournamentMatches, setTournamentMatches] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState();
    const [bracketAvailable, setBracketAvailable] = useState(false);
    const [event, setEvent] = useState();
    const [scrollView, setScrollView] = useState(false);
    const [tabValue, setTabValue] = useState(searchParam.get('tab') || "bracket");
    const [seeds, setSeeds] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const isAdmin = useSelector((state)=>state.isAdmin);

    const ref = useRef();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }


    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    }

    const handleView = (event, newView) => {
        setScrollView(newView);
    }


    const nextRound = () => {
        if (currentRound <= tournamentMatches.length - 1) {
            setcurrentRound(currentRound + 1)
        }
    }

    const previousRound = () => {
        if (currentRound != 0) {
            setcurrentRound(currentRound - 1)
        }
    }

    const getEvent = () => {
        var promise = getEvents_api("?id=" + id);
        promise.then((result) => { setEvent(result.data[0]) })
            .catch();
    }

    const getSeeds = () => {
        var promise = getTournamentSeeds_api(id);
        promise.then((result) => setSeeds(result.data))
            .catch();
    }

    const getMatches = () => {
        var promise = getBracketMatches_api(id);
        promise.then((result) => {
            getEvent();

            setMatches(result.data);;
            var max_rounds = result.data[result.data.length - 1].round;
            var tournamentMatches_tmp = new Array();
            for (var i = 0; i < max_rounds; i++) {
                var roundArr = new Array();
                for (var j = 0; j < result.data.length; j++) {

                    if (result.data[j].round == i + 1) {
                        roundArr = [...roundArr, result.data[j]];
                    }
                }
                tournamentMatches_tmp = [...tournamentMatches_tmp, roundArr];
            }
            setTournamentMatches(tournamentMatches_tmp);
            setRounds(tournamentMatches_tmp.length);
            setBracketAvailable(true);
        })
            .catch((error) => { setBracketAvailable(false) });
    }

    const openMatchDialog = (match) => {
        setSelectedMatch(match);
        setOpen(true);
    }

    useEffect(() => {
        if (currentRound == 0) {
            setDisplayRight(true);
            setDisplayLeft(false);
        }
        else if (currentRound > 0 && currentRound != tournamentMatches.length - displayRounds - 1) {
            setDisplayLeft(true);
            setDisplayRight(true);
        }
        else if (currentRound == tournamentMatches.length - displayRounds - 1) {
            setDisplayRight(false);
            setDisplayLeft(true);
        }
        return () => {
        }
    }, [currentRound])

    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        return () => { }
    }, [])

    useEffect(() => {
        var matches =
            [window.matchMedia("(max-width: " + theme.breakpoints.values['sm'] + "px)").matches,
            window.matchMedia("(max-width: " + theme.breakpoints.values['lg'] + "px)").matches,
            window.matchMedia("(max-width: " + theme.breakpoints.values['xl'] + "px)").matches];
        if (matches[0]) {
            setDisplayRounds(0);
        }
        else if (matches[1]) {
            setDisplayRounds(1);
        }
        else if (matches[2]) {
            setDisplayRounds(2);
        }
        else {
            setDisplayRounds(2);
        }
        return () => {
        }
    }, [screenWidth])

    useEffect(() => {
        getMatches();
        getSeeds();
    }, [])
    
    useEffect(() => {
        setScrollView(!scrollView);
    }, [tabValue])


    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard title={event ? event.name : "Turniername"} subheader={"Turnierbaum"} icon={<EmojiEvents sx={{ width: 40, height: 40 }}></EmojiEvents>}></TitleCard>
                </Grid>

            </Grid>
            <MainContentContainer>
                <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>

                        <Grid container direction="row" rowSpacing={1} columnSpacing={2} justifyContent="flex-start">
                            <Grid item xs={12}>
                                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}> */}
                                <Tabs centered value={tabValue} onChange={handleTabChange}>
                                    <MyTab label="Gruppen" value="groups" />
                                    <MyTab label="Baum" value="bracket" />
                                    <MyTab label="Setzliste" value="seeds" />
                                    {/* <MyTab label="Nebenrunde" value="sidedraw" /> */}
                                </Tabs>
                                <Divider sx={{ pt: 0, mt: 0 }}></Divider>
                                {/* </Box> */}
                            </Grid>
                            {tabValue == "groups"?
                            <GroupsContainer tournamentId={id}></GroupsContainer>
                            :
                            <></>}
                            {tabValue == "bracket" ?
                                <Grid item container justifyContent={"space-between"}>
                                    <Grid item >
                                        <Stack direction="row">
                                            <ToggleButtonGroup color="primary" value={scrollView} exclusive onChange={handleView} size="small">
                                                <ToggleButton value={false}> <Fullscreen /></ToggleButton>
                                                <ToggleButton value={true}> <Code /></ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>
                                    </Grid>
                                    {editMode ?
                                        <Grid item xs>
                                            <PrimaryButton fullWidth text="Editierung beenden" startIcon={<Edit></Edit>} onClick={() => setEditMode(false)} color="warning"></PrimaryButton>
                                        </Grid>
                                        :
                                        <></>

                                    }
                                    {isAdmin ?

                                        <Grid item >
                                            <BracketMenu setEditMode={setEditMode} editMode={editMode}></BracketMenu>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    

                                </Grid>

                                :
                                <></>
                            }
                        </Grid>
                    </Grid>

                    {bracketAvailable && tabValue == "bracket" ?
                        <>
                            {!scrollView ?
                                <>
                                    <Grid item container direction="row">
                                        {rounds && roundNames && roundNames.slice(roundNames.length - rounds, roundNames.length).map((roundName, i) => (
                                            currentRound <= i && i <= currentRound + displayRounds ?
                                                <Grid item container justifyContent={"center"} xs={12} sm={6} lg={4} key={i}>
                                                    <Chip color={"primary"} variant={"outlined"} label={<Typography>{rounds > 4 && rounds - i > 4 ? i + 1 + roundName : roundName}</Typography>}></Chip>
                                                </Grid>
                                                :
                                                <Grid item container justifyContent={"center"} xs={12} sm={6} lg={4} display={"none"} key={i}>
                                                    <Chip color={"primary"} label={<Typography>{roundName}</Typography>}></Chip>
                                                </Grid>
                                        ))}

                                    </Grid>
                                    {tournamentMatches.map((round, i) => (
                                        currentRound <= i && i <= currentRound + displayRounds ?
                                            <Grid item container direction={"column"} justifyContent="space-around" xs={12} sm={6} lg={4} spacing={2} key={i} ref={ref}>
                                                {tournamentMatches[i].map((match, j) => (
                                                    <Grid item key={j}>
                                                        <BracketMatchCard key={j} getMatches={getMatches} editMode={editMode} match={match} open={open} setOpen={setOpen} openMatchDialog={openMatchDialog} grandSlamMode={event ? event.grandSlamMode : false}></BracketMatchCard>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            :
                                            <Grid item container direction={"column"} justifyContent="space-around" xs={12} sm={6} lg={4} spacing={1} key={i} display={"none"}>
                                                {tournamentMatches[i].map((match, j) => (
                                                    <Grid item key={j}>
                                                        <BracketMatchCard key={j} getMatches={getMatches} editMode={editMode} match={match} open={open} setOpen={setOpen} openMatchDialog={openMatchDialog} grandSlamMode={event ? event.grandSlamMode : false}></BracketMatchCard>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                    ))}
                                </>
                                :
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", overflow: "auto", pt: 4, pl: 2, columnGap: 1, pb: 2 }}>
                                        {tournamentMatches.map((round, i) => (
                                            i == 0 ?
                                                <>
                                                    <Stack spacing={2} alignItems={"center"} key={i}>
                                                        <Chip color={"primary"} variant={"outlined"} label={<Typography align='center'>{rounds > 4 && rounds - i > 4 ? i + 1 + roundNames[roundNames.length - rounds + i] : roundNames[roundNames.length - rounds + i]}</Typography>} sx={{ width: "fit-content" }} />

                                                        <Box flexDirection={"column"} sx={{ display: "flex", justifyContent: "space-around", rowGap: 2, }} ref={ref}>
                                                            {tournamentMatches[i].map((match, j) => (
                                                                <BracketMatchCard getMatches={getMatches} editMode={editMode} key={j} match={match} open={open} setOpen={setOpen} openMatchDialog={openMatchDialog} grandSlamMode={event ? event.grandSlamMode : false}></BracketMatchCard>
                                                            ))}
                                                        </Box>
                                                    </Stack>
                                                </>
                                                :
                                                <>
                                                    <Stack spacing={2} alignItems={"center"} key={i}>
                                                        <Chip color={"primary"} variant={"outlined"} label={<Typography align='center'>{rounds > 4 && rounds - i > 4 ? i + 1 + roundNames[roundNames.length - rounds + i] : roundNames[roundNames.length - rounds + i]} </Typography>} sx={{ width: "fit-content" }} />

                                                        <Box flexDirection={"column"} sx={{ display: "flex", justifyContent: "space-around", rowGap: 2, height: ref.current && ref.current.clientHeight }} >
                                                            {tournamentMatches[i].map((match, j) => (
                                                                <BracketMatchCard getMatches={getMatches} editMode={editMode} key={j} match={match} open={open} setOpen={setOpen} openMatchDialog={openMatchDialog} grandSlamMode={event ? event.grandSlamMode : false}></BracketMatchCard>
                                                            ))}
                                                        </Box>
                                                    </Stack>
                                                </>
                                        ))
                                        }
                                    </Box>
                                </>
                            }
                        </>
                        : tabValue == "bracket" ?
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled"><Typography>Es gibt aktuell keinen Turnierbaum</Typography></Alert>
                            </Grid>
                            :
                            <></>
                    }
                </Grid>
                {bracketAvailable && !scrollView && tabValue == "bracket" ?
                    <>
                        {displayRight &&
                            <Fab size={"small"} color="primary" aria-label="add" sx={{ position: "fixed", top: "275px", right: "40px", zIndex: 100 }} onClick={() => { nextRound() }}>
                                <ChevronRight></ChevronRight>
                            </Fab>
                        }
                        {displayLeft &&
                            <Fab size={"small"} color="primary" aria-label="add" sx={{ position: "fixed", top: "275px", left: "40px", zIndex: 100 }} onClick={() => { previousRound() }}>
                                <ChevronLeft></ChevronLeft>
                            </Fab>
                        }
                    </>
                    :
                    <></>
                }
                {tabValue == "seeds" &&
                    <Container maxWidth={"sm"} sx={{ py: 2 }}>
                        {seeds && seeds.length > 0 ?
                            <Card sx={{ borderRadius: 2, mt: 3 }}>
                                <CardContent>

                                    <List dense>
                                        {seeds && seeds.map((seed, i) => (
                                            <ListItem key={i}>
                                                <ListItemAvatar><Avatar sx={{ backgroundColor: "secondary.main" }}>{seed.seed}</Avatar></ListItemAvatar>
                                                <ListItemText primary={seed.name} secondary={"LK: " + seed.lk} primaryTypographyProps={{ fontWeight: "bold" }} secondaryTypographyProps={{ color: "secondary" }}></ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>

                                </CardContent>
                            </Card>
                            :
                            <Alert severity='info'><Typography>Es gibt keine Setzliste</Typography></Alert>
                        }
                    </Container>
                }
            </MainContentContainer>
            <MatchDialog setOpen={setOpen} open={open} selectedMatch={selectedMatch} getMatches={getMatches} grandSlamMode={event ? event.grandSlamMode : false} />
            <Footer />
        </>
    )
}


export default TournamentContainer