import { Alert, AlertTitle, DialogActions, DialogContent, Divider, Grid, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DialogComponent from './DialogComponent'
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { createGroups_Api } from '../api/Events/events';
import { useSnackbar } from './SnackbarContext';

function GroupsDialog({ open, onClose, event, participants, getEvents, ...props }) {

    const [groupSize, setGroupSize] = useState();
    const [groups, setGroups] = useState(0);
    const [remainder, setRemainder] = useState(0);
    const [maximizeGroups, setmMaximizeGroups] = useState(false);
    const [error, setError] = useState(false);
    const { showSnackbar } = useSnackbar();

    const createGroups = () => {
        var promise = createGroups_Api(event.id, groupSize, maximizeGroups);
        promise.then((result) => { getEvents(); showSnackbar("Gruppen erstellt", "success"); onClose(); })
            .catch((error) => showSnackbar("Fehler beim Erstellen der Gruppen", error.response.status < 500 ? "warning" : "error"));
    }

    useEffect(() => {
        if (groupSize <= participants?.length && groupSize > 0 && participants) {
            var groups = Math.floor(participants.length / groupSize);

            if (participants.length % groupSize != 0 && !maximizeGroups) {
                groups += 1;
                setGroups(groups);
                setRemainder(participants.length % groupSize);
            }
            else {
                setGroups(groups);
            }

        }
        else if (groupSize == 0) {
            setGroups(false);
        }
        else {
            setGroups(1);
            setRemainder(0);
        }

    }, [groupSize, maximizeGroups]);

    useEffect(() => {
        if (groupSize > participants?.length || groupSize <= 1 || !groupSize || groupSize == participants?.length - 1) {
            setError(true);
        }
        else {
            setError(false);
        }

    }, [groupSize]);

    return (
        <DialogComponent open={open} onClose={onClose} dialogTitle={"Gruppen erstellen"}>
            <DialogContent>
                <Grid container spacing={2} sx={{ py: 2 }}>

                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Typography fontWeight={500}>Gruppengröße</Typography>
                        <TextField fullWidth size={"medium"}label="Anzahl Spieler" helperText={"Es haben sich " + (participants && participants.length) + " Spieler angemeldet"} onChange={(event) => { setGroupSize(event.target.value) }}></TextField>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                            <Stack>
                                <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>Keine unvollständigen Gruppen</Typography>
                                {/* <Typography variant="body2" fontWeight={"Regular"} color="text.secondary">Sofern die Teilnehmerzahl nicht exakt teilbar durch die Gruppengröße ist, werden verbleibende Spieler gleichmäßig auf bestehende Gruppen aufgeteilt (Gruppengröße wird überschritten). Ist diese Option deaktiviert, werden die verbleibenden Spieler in eine eigene Gruppe zugewiesen.</Typography> */}
                                <Typography variant="body2" fontWeight={"Regular"} color="text.secondary">Keine unvollständigen Gruppen erzeugen. Überschüssige Personen sollen zufällig auf alle vollen Gruppen verteilt werden.</Typography>
                            </Stack>
                            <Switch name="button_price_enabled" checked={maximizeGroups} onChange={() => setmMaximizeGroups(!maximizeGroups)}></Switch>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        {groups && groupSize && !error ?
                            <>
                                <Alert severity='info'>
                                    <AlertTitle>Gruppenaufteilung</AlertTitle>
                                    {maximizeGroups ?
                                        <>
                                            <Typography variant="body2">{"Die Gruppengröße erzeugt insgesamt " + groups + " Gruppen."} </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant="body2" >{"Die Gruppengröße erzeugt insgesamt " + groups + " Gruppen."} </Typography>
                                        </>
                                    }
                                </Alert>

                            </>
                            :
                            <></>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SecondaryButton text="Abbrechen" onClick={() => onClose()}></SecondaryButton>
                <PrimaryButton disabled={error} text="Gruppen erstellen" onClick={() => createGroups()}></PrimaryButton>
            </DialogActions>
        </DialogComponent >
    )
}

export default GroupsDialog