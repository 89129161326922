import { Button } from '@mui/material'
import React from 'react'

function TertiaryButton(props) {
    const {id,onClick,text,sxprops}=props;
  return (
    <Button id={id} variant="text" onClick={(e)=>{e.stopPropagation();onClick()}} sx={{textTransform: "none",...sxprops}}  {...props}>{text}</Button>
  )
}

export default TertiaryButton