import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react'
import { updateLiveScore_api } from '../api/Teammatches/Teammatches';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

function UpdateLiveMatchModal(props) {

    const { open, onClose, match, getLiveMatches } = props;

    const [teamScore, setTeamScore] = useState( 0);
    const [opponentScore, setOpponentScore] = useState(0);
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        if(match)
        {
            setTeamScore(match.liveScoreTeam);
            setOpponentScore(match.liveScoreOpponent);
        }
        return () => {
            
        };
    }, [open]);

    const updateLiveScore = () => {
        if (teamScore < 0 || teamScore > 6 || opponentScore < 0 || opponentScore > 6 || parseInt(teamScore) + parseInt(opponentScore) > 6) {
            setWarning(true);
            return;
        }
        else {
            setWarning(false);
        }

        var promise = updateLiveScore_api(match.id, teamScore, opponentScore);
        promise.then((result) => { getLiveMatches(); onClose() })
            .catch((error) => { })
    }

    return (
        <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>Live Ergebnis</DialogTitle>
            <DialogContent >
                <Grid container spacing={2} alignItems="center" sx={{pt: 2}}>
                    <Grid item xs={12} sm={6}>
                        <Typography>{match && match.teamName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField type={"number"} fullWidth label="Score" size={"small"} value={teamScore} onChange={(e) => { setTeamScore(e.target.value) }}></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography>{match && match.opponent}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField type={"number"} fullWidth label="Score" size={"small"} value={opponentScore} onChange={(e) => { setOpponentScore(e.target.value) }}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    {warning &&
                        <Grid item xs={12}>
                            <Alert severity='warning'><Typography>Bitte überprüfe deine Eingaben</Typography></Alert>
                        </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <SecondaryButton text="Abbrechen" onClick={onClose} />
                <PrimaryButton text="Bestätigen" onClick={() => { updateLiveScore() }} />
            </DialogActions>
        </Dialog>
    )
}

export default UpdateLiveMatchModal