import { ExpandMore, FilterAltOff, Groups } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import BracketMatchCard from './BracketMatchCard'
import TertiaryButton from './TertiaryButton';
import DynamicExpandIcon from './DynamicExpandIcon';
import PrimaryButton from './PrimaryButton';

function TournamentGroup({ groupName, players, matches, getGroups, ...props }) {
    const [expanded, setExpanded] = useState(true);
    const [filterActivated, setFilterActivated] = useState(false);
    const [playerId, setPlayerId] = useState(0);
    const [expandMatches, setExpandMatches] = useState(false);

    return (
        <Accordion expanded={expanded} >
            <AccordionSummary expandIcon={<ExpandMore />} onClick={() => setExpanded(!expanded)}>
                <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"} sx={{ width: "100%" }}>
                    <Groups color="primary"></Groups>
                    <Typography fontWeight={500}>{groupName}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ borderTop: 1, borderColor: "divider" }}>
                <Stack spacing={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead sx={{ backgroundColor: "#ddd" }}>
                                <TableRow>
                                    <TableCell align='center'>Rang</TableCell>
                                    <TableCell>Spieler</TableCell>
                                    <TableCell align='center'>Match W/L</TableCell>
                                    <TableCell align='center'>Satz W/L</TableCell>
                                    <TableCell align='center'>Spiel W/L</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {players?.map((player, i) => (
                                    <TableRow key={i} onClick={() => { setPlayerId(player.user_id); setExpandMatches(true); setFilterActivated(true) }} sx={{ backgroundColor: playerId == player.user_id ? "#eee" : "#fff" }}>
                                        <TableCell align='center'>{(i + 1)}</TableCell>
                                        <TableCell sx={{ fontWeight: 500 }}>{player.player_name}</TableCell>
                                        <TableCell align='center'>{player.matches_won + " - " + player.matches_loss}</TableCell>
                                        <TableCell align='center'>{player.sets_won + " - " + player.sets_loss}</TableCell>
                                        <TableCell align='center'>{player.games_won + " - " + player.games_loss}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            </AccordionDetails>
            <AccordionDetails sx={{ borderTop: 1, borderColor: "divider" }}>
                <Stack spacing={1}>
                <TertiaryButton startIcon={<DynamicExpandIcon color="primary" expanded={expandMatches} onClick={() => setExpandMatches(!expandMatches)} />} color="primary" text="Gruppen-Matches anzeigen" onClick={() => { setExpandMatches(!expandMatches) }} />
                <Collapse in={expandMatches}>

                    <Stack spacing={1}>
                        {filterActivated ?
                            <PrimaryButton text={"Alle Matches anzeigen"} startIcon={<FilterAltOff />} color={filterActivated ? "primary" : "secondary"} onClick={() => { filterActivated && setFilterActivated(false); setPlayerId(0) }} sx={{ width: "max-content", textTransform: "none" }} />
                            :
                            <></>

                        }
                       
                        {matches?.filter((match) => (match.player2 == playerId || match.player1 == playerId && filterActivated) || !filterActivated).map((match, i) => (
                            <BracketMatchCard match={match} grandSlamMode={false} editMode={false} getMatches={() => getGroups()} key={i}></BracketMatchCard>
                        ))
                        }
                       
                    
                    </Stack>
                </Collapse>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default TournamentGroup