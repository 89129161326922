import { Alert, Box,Container, Grid, Table, TableHead, TablePagination, TableRow, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import { EventAvailable } from '@mui/icons-material';
import { getMyGuestReservations_api, getMyPaidReservations_api, getMyReservations } from '../../api/Reservations/reservations';
import format from 'date-fns/format';
import TitleCard from '../../components/TitleCard';
import Footer from '../../components/Footer';
import BookingCard from '../../components/BookingCardv2';
import MyTab from '../../components/MyTab';
import { getSettings_api } from '../../api/Settings/settings';
import MainContentContainer from '../../components/MainContentContainer';

function MyBoookingsContainer() {
  const [bookings, setBookings] = useState([]);
  const [settings,setSettings] = useState(null);



  //pagintaion
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getClubSettings = () => {
    var promise=getSettings_api();
    promise.then((result) => setSettings(result.data[0]))
    .catch();
  }

  //tab group for filtering
  const [operator, setOperator] = useState("gt");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setOperator("gt");
        break;
      case 1:
        setOperator("lt");
        break;
      default:
        break;
    }
  }

  const displayBookings = () => {
    if (value < 2) {
      const reservationPromise = getMyReservations(format(Date.now(), "yyyy-MM-dd"), operator);
      reservationPromise.then((result) => { setBookings(result.data); });
    }
    else {
      // if(settings && (settings["guest_booking_price"] != 0  && settings["allow_guest_bookings"]==1) || settings["member_booking_price"] != 0 )
      // {
        const reservationPromise = getMyPaidReservations_api();
        reservationPromise.then((result) => { setBookings(result.data); });
      // }
      // else
      // {
      //   setBookings([]);
      //}
    }
  }


  useEffect(() => {
    displayBookings();

  }, [value]);

  useEffect(() => {
    getClubSettings();
  }, []);


  return (
    <>
      <Header />
      <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <TitleCard id="crd-title-mybookings" title={"Deine Buchungen"} subheader={"Verwalte deine anstehenden Reservierungen"} icon={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></TitleCard>
        </Grid>
      </Grid>
      <MainContentContainer>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center"}}  >
          <Tabs onChange={handleChange} value={value} variant='scrollable'
            aria-label="Tabs where each tab needs to be selected manually">
              <MyTab label="Bevorstehend" value={0}/>
              <MyTab label="Vergangen" value={1}/>
              <MyTab label="Kostenpflichtig" value={2}/>
          </Tabs>
        </Box>

        <Grid item container xs={12} md={12} direction="row" alignItems="center" justifyContent={"flex-end"} sx={{ pt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  count={bookings.length || 0}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={"Anzahl"}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ border: 0 }}
                />
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent={{xs: "center", md: "flex-start"}} spacing={2}>
          {bookings.length > 0 ? bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking, i) => (
            <Grid item xs={12} md={6} key={i}>
              <BookingCard booking={booking} opeprator={operator} getBookings={displayBookings} clubSettings={settings}></BookingCard>
            </Grid>
          ))
            :
            <Grid item xs={12}>
              <Alert severity="info" variant="filled"><Typography>Keine Buchungen vorhanden</Typography></Alert>
            </Grid>

          }
        </Grid>
      </MainContentContainer>
      <Footer />
    </>
  );
}

export default MyBoookingsContainer;
