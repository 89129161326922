import { Feedback } from '@mui/icons-material'
import { Alert, Card, CardContent, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { sendFeedback_api } from '../../api/Feedback/feedback'
import Footer from '../../components/Footer'
import Header from '../../components/Header/Header'
import PrimaryButton from '../../components/PrimaryButton'
import TitleCard from '../../components/TitleCard'
import { Box } from '@mui/system'
import { createSupportCase_api } from '../../api/Support/support'
import InternalLink from '../../components/InternalLink'
import { SUPPORT } from '../../navigation/CONSTANTS'
import { useSnackbar } from '../../components/SnackbarContext'
import { useSearchParams } from 'react-router-dom'


function FeedbackContainer() {
    const [searchParam, setSearchParam] = useSearchParams();
    const [message, setFormMessage] = useState("");
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState(null);
    const [category, setCategory] = useState(searchParam.get('category')||"support");
    const [error, setError] = useState(false);
    const {showSnackbar} = useSnackbar();



    const handleChange = (event) => {
        switch (event.target.name) {
            case 'Message':
                setFormMessage(event.target.value);
                setStatus(false);
                break;
            default:
                break;
        }
    }
    const clearData = () => {
        setTitle("");
        setFormMessage("");
        setError(false);
    }

    const sendFeedback = () => {
        if (message == "") {
            setError(true);
            return false;
        }
        var promise = sendFeedback_api(message);
        promise.then((result) => { setStatus(true); showSnackbar("Feedback erfolgreich verschickt - vielen Dank!","success"); clearData(); })
            .catch();
    }

    const sendSupport = () => {
        if (message == "" || title == "") {
            setError(true);
            return false;
        }
        var promise = createSupportCase_api(message, title);
        promise.then((result) => { setStatus(true); showSnackbar("Suppportcase erfolgreich verschickt - sie werden umgehend kontaktiert!","success"); clearData(); })
            .catch();
    }


    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard title={"Support & Feedback"} subheader={"Teile uns deine Anmerkungen, Verbesserungsvorschläge oder Probleme mit!"} icon={<Feedback sx={{ width: 40, height: 40 }}></Feedback>}></TitleCard>
                </Grid>
            </Grid>
            <Container sx={{ maxWidth: "xl", py: 5 }}>
                <Grid container item justifyContent={"center"}>
                    <Grid item xs={12} md={12}>
                        <Card raised sx={{ boderRadius: 1, }}>
                            <CardContent>
                                <Grid direction="row" alignItems="center" container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Box component="img" src={"https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg"} sx={{ width: "90%" }}></Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid direction="row" container alignItems={"center"} justifyContent="flex-end" spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth id="category_select">
                                                    <InputLabel id="category_select">Kategorie</InputLabel>
                                                    <Select label="Kategorie" labelid="Kategorie" value={category} fullWidth onChange={(event) => { setCategory(event.target.value); }}>
                                                        <MenuItem value={"support"}>Support</MenuItem>
                                                        <MenuItem value={"feedback"}>Feedback</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack>
                                                <TextField label="Titel" name="Title" value={title} onChange={(event) => setTitle(event.target.value)} fullWidth multiline minRows={1} inputProps={{ maxLength: 30}} InputProps={{endAdornment: <Typography color="primary" variant="body2">{title.length + "/30"}</Typography>}}/>
                                                
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField label="Nachricht" name="Message" value={message} onChange={handleChange} fullWidth multiline minRows={6} />
                                            </Grid>
                                            {error &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning"><Typography>Bitte füllen Sie Titel und Nachricht aus!</Typography></Alert>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <PrimaryButton text={"Abschicken"} onClick={() => { category == "Feedback" ? sendFeedback() : sendSupport() }} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InternalLink route={SUPPORT} linkname = "Meine Support-Tickets"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

export default FeedbackContainer