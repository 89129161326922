import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getCourts_api = async (query = "") => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/courts" + query,
    { headers: sessionData }
  );
};


export const getClosedCourts_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/courts/close",
    { headers: sessionData }
  );
};

export const getCourtsCounts_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/courts/counts",
    { headers: sessionData }
  );
};

export const closeCourts_api = async (targetCourts, enddate, startdate, courtType) => {
  const sessionData = getSessionData();

  var data = {
    targetCourts: targetCourts,
    enddate: enddate,
    startdate: startdate,
    courtType: courtType
  };
  //fetch reservation filtered by day data
  return await axios.post(
    process.env.REACT_APP_API_URL + "/courts/close", data,
    { headers: sessionData }
  );
};

export const resetCourts_api = async () => {
  const sessionData = getSessionData();

  var data = {};
  //fetch reservation filtered by day data
  return await axios.put(
    process.env.REACT_APP_API_URL + "/courts/open", data,
    { headers: sessionData }
  );
};

export const resetCourt_api = async (id) => {
  const sessionData = getSessionData();

  var data = {};

  //fetch reservation filtered by day data
  return await axios.post(
    process.env.REACT_APP_API_URL + "/courts/open/" + id, data,
    { headers: sessionData }
  );
};

export const createCourt_api = async (name, timeInAdvance, type) => {
  const sessionData = getSessionData();

  var data = {
    name: name,
    timeInAdvance: timeInAdvance,
    type: type
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/courts/", data, { headers: sessionData },
  );

}

export const deleteCourt_api = async (id) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/courts/" + id, { headers: sessionData },
  );

}

export const editCourt_api = async (id, name, timeInAdvance, type) => {
  const sessionData = getSessionData();

  var data = {
    name: name,
    timeInAdvance: timeInAdvance,
    type: type
  }
  return await axios.put(
    process.env.REACT_APP_API_URL + "/courts/" + id, data, { headers: sessionData },
  );
}

export const deleteCourtLock_api = async (id) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/courts/close/" + id, { headers: sessionData },
  );

}




