import { AcUnit, LightMode } from '@mui/icons-material'
import { Divider, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'

function CourtStatsStack({ indoorToottip, outdoorTooltip, indoorValue, outdoorValue,headlineIcon,headlineText }) {
    return (
        <Stack spacing={0.5}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                {headlineIcon}
                <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>{headlineText}</Typography>
            </Stack>
            <Divider></Divider>
            <Stack direction="row" alignItems={"center"} spacing={2} justifyContent={"center"}>
                <Stack sx={{ width: "100%" }}>

                    <Tooltip title={outdoorTooltip}>
                        <Stack direction="row" spacing={1} justifyContent={"space-between"}>
                            <LightMode color="warning"></LightMode>
                            <Typography color="text.secondary" sx={{ fontWeight: "medium" }} >{outdoorValue}</Typography>
                        </Stack>
                    </Tooltip>


                    <Tooltip title={indoorToottip}>
                        <Stack direction="row" spacing={1} justifyContent={"space-between"}>
                            <AcUnit color="primary"></AcUnit>
                            <Typography color="text.secondary" sx={{ fontWeight: "medium" }} > {indoorValue}</Typography>
                        </Stack>
                    </Tooltip>


                </Stack>
            </Stack>
        </Stack>

    )
}

export default CourtStatsStack