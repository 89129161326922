import React from 'react'
import BookingViewReservation from '../../components/BookingViewReservation';

function BookingButton({ shortText, data, ...props }) {
    const types = {
        "teammatch": "Punktspiel",
        "training": "Training",
        "other": "Sonstige"
    }
    return (
        <>
            {data.spieler1 != null && data.spieler2 != null ?
                // userbookings
                <BookingViewReservation shortText={shortText} color="primary.main" secondaryBold={true} data={data} primary={data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name} secondary={data.spieler2_firstname.charAt(0) + ". " + data.spieler2_name} />
                //guest booking
                : data.spieler1 != null && data.guestname != null ?
                    <BookingViewReservation shortText={shortText} color="yellow.dark" secondaryBold={true} data={data} primary={data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name} secondary={data.guestname == "Ballmaschine" ? "Ballmaschine" : "Gast"} />
                    //user Freetext
                    : data.spieler1 != null && data.spieler2 == null && data.guestname == null ?
                        <BookingViewReservation shortText={shortText} color="warning.main" data={data} secondary={data.names} />
                        //freetext event
                        : data.serieId != null && data.eventId != null && data.spieler1 == null && data.spieler2 == null && data.guestname == null ?
                            <BookingViewReservation shortText={shortText} color="success.main" data={data} primary={data.names} secondary={types[data.type]} />
                            :
                            //Abo
                            <BookingViewReservation shortText={shortText} color="secondary.main" data={data} primary={data.names} secondary={types[data.type]} />

            }
        </>
    )
}

export default BookingButton