import React from 'react'
import Footer from '../../components/Footer'
import { Card, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material'
import TitleCard from '../../components/TitleCard'
import Header from '../../components/Header/Header'
import { NewReleases } from '@mui/icons-material'

function ReleaseNotesContainer() {
    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard title={"Release Notes"} subheader={"Hier findest du die neuesten Erneuerungen"} icon={<NewReleases sx={{ width: 40, height: 40 }}></NewReleases>}></TitleCard>
                </Grid>
            </Grid>
            <Container maxWidth="xl" sx={{ py: 5, px: 1 }}>
                <Card>
                    <CardHeader title={"Änderungsübersicht"}></CardHeader>
                    <CardContent>
                        <Typography sx={{ typography: { xs: "body2", md: "body1" } }}>
                            <p><strong>Version 5.4.3:</strong></p>
                            <ul>
                                <li><strong>Verbesserte Buchungsansicht: </strong>In der Tagesvorschau werden auf gro&szlig;en Bildschirmen alle Monatstage und auf kleineren Bildschirmen alle Wochentage angezeigt. Ein durchg&auml;ngiges Weiterklicken erm&ouml;glicht eine einfache Navigation. Zudem wird die aktuelle Zeit durch Hervorhebung der entsprechenden Tabellenzeile deutlich gekennzeichnet.</li>
                            </ul>
                            <p><strong>Version 5.4.2:</strong></p>
                            <ul>
                                <li><strong>Verbesserte Platzsperrungen: </strong>Es ist jetzt m&ouml;glich, mehrere Platzsperrungen f&uuml;r denselben Platz zu definieren. Dadurch wird verhindert, dass bestehende Sperrungen &uuml;berschrieben werden, und eine flexible Verwaltung von Platzsperrzeiten wird erm&ouml;glicht.</li>
                                <li><strong>Verbesserte Serienbuchung</strong>: Der Zeitraum und der Platz einer Reservierung k&ouml;nnen jetzt direkt in der Buchungs&uuml;bersicht ausgew&auml;hlt oder abgew&auml;hlt werden.</li>
                                <li><strong>Links zu Nuliga im Dashboard</strong>: Eine neue Dashboard-Karte bietet ab sofort schnellen Zugriff auf alle relevanten Vereinslinks zu NuLiga.</li>
                            </ul>
                            <p><strong>Version 5.4.0:</strong></p>
                            <ul>
                                <li><strong>Gruppenmodus f&uuml;r Turniere: </strong>Turniere k&ouml;nnen ab sofort im Gruppenmodus ausgetragen werden, wodurch eine neue Struktur f&uuml;r die Organisation und Durchf&uuml;hrung von Turnieren verf&uuml;gbar wird.</li>
                            </ul>
                            <p><strong>Version 5.3.2:</strong></p>
                            <ul>
                                <li><strong>Punktspielplaner Verbesserung & Dashboard: </strong>Ab sofort steht ein Admin-Dashboard zur Verf&uuml;gung, das eine &Uuml;bersicht &uuml;ber erforderliche Aktionen im Zusammenhang mit Platzreservierungen f&uuml;r Punktspiele bietet. Eine Aktion wird erforderlich, wenn eine Platzreservierung fehlt oder ein Spiel verlegt wurde. So k&ouml;nnen offene Aufgaben schnell erkannt und effizient bearbeitet werden.</li>
                            </ul>
                            <p><strong>Version 5.3.1:</strong></p>
                            <ul>
                                <li><strong>Release-Highlights auf der Landingpage: </strong>Ab sofort werden kurze und pr&auml;gnante Beschreibungen der Highlights vergangener Releases auf der Landingpage angezeigt.</li>
                            </ul>
                            <ul>
                                <li><strong>Hervorhebung von Preisen &amp; L&ouml;schfristen:</strong> Preise und L&ouml;schfristen werden jetzt in den Buchungsregeln, im Buchungsdialog und auf dem Buchungsbutton deutlich hervorgehoben. In den Vereinseinstellungen kann die Anzeige im Buchungsbutton bei Bedarf aktiviert oder deaktiviert werden.</li>
                            </ul>
                            <p><strong>Version 5.3.0:</strong></p>
                            <ul>
                                <li><strong>E-Mail-Vorlagen: </strong>Administratoren haben jetzt die M&ouml;glichkeit, E-Mail-Vorlagen zu erstellen und zu verwalten. Diese Vorlagen k&ouml;nnen im E-Mail-Dialog ausgew&auml;hlt und f&uuml;r den Versand von E-Mails verwendet werden.</li>
                            </ul>
                            <p><strong>Version 5.2.0:</strong></p>
                            <ul>
                                <li><strong>Vereinfachtes Wechseln der Saisons:</strong> Bisher konnten Saisons nur einzeln f&uuml;r jede Mannschaft &uuml;ber das Kontextmen&uuml; ge&auml;ndert werden. Ab sofort k&ouml;nnen alle verf&uuml;gbaren Saisonwechsel zentral &uuml;ber das Men&uuml; im Bereich Mannschaften in der Verweinsverwaltung angezeigt und f&uuml;r alle Mannschaften durchgef&uuml;hrt werden.</li>
                            </ul>
                            <p><strong>Version 5.1.0:</strong></p>
                            <ul>
                                <li><strong>Exportfunktion f&uuml;r Einnahmen:</strong>&nbsp;Die Kosten&uuml;bersicht pro Mitglied kann nun als CSV-Datei exportiert werden.</li>
                                <li><strong>Exportfunktion f&uuml;r kostenpflichtige Buchungen:</strong> Die &Uuml;bersicht der kostenpflichtigen Buchungen f&uuml;r ein einzelnes oder alle Mitglieder kann ebenfalls in eine CSV-Datei exportiert werden.</li>
                            </ul>
                            <p><strong>Version 5.0.0:</strong></p>
                            <ul>
                                <li><strong>Einf&uuml;hrung von Hallenpl&auml;tzen:</strong> Ab sofort k&ouml;nnen Hallenpl&auml;tze angelegt, individuell konfiguriert und f&uuml;r Buchungen freigegeben werden.</li>
                                <li><strong>Verbesserte Mannschaftserstellung (NuLiga):</strong> Die Benutzerf&uuml;hrung bei der Erstellung von Mannschaften wurde optimiert. Bestehende Mannschaften werden nun ausgeblendet, und es wurde eine Filterfunktion nach Mannschaftstyp und Saison hinzugef&uuml;gt, um den Prozess zu vereinfachen.</li>
                            </ul>
                            <p><strong>Version 4.11.0:</strong></p>
                            <ul>
                                <li><strong>Redesign anstehende Ereignisse:</strong> Die Dashboard-Ansicht der anstehenden Ereignisse wurde verbessert.</li>
                                <li><strong>Verbesserte Event-Zeitstrahldarstellung:</strong> Die Zeitstrahldarstellung zeigt zus&auml;tzliche Informationen an. Das Eventmen&uuml; sowie die Teilnehmeransicht k&ouml;nnen &uuml;ber Iconbuttons ge&ouml;ffnet werden.</li>
                                <li><strong>Verbesserte Serienbuchung:</strong> Es kann die Buchungsansicht f&uuml;r den gew&auml;hlten Tag der Serienbuchung angezeigt werden, um ggf. Kollisionen zu vermeiden.</li>
                            </ul>
                            <p>Version <strong>4.10.0:</strong></p>
                            <ul>
                                <li><strong>Redesign Trainingcard</strong>: Die Trainingskarte wurde im Design leicht verbessert.</li>
                            </ul>
                            <p>Version <strong>4.9.0:</strong></p>
                            <ul>
                                <li><strong>Hilfecenter</strong>: Ein Hilfecenter, das Anleitgungsartikel f&uuml;r relevante Themen des Portals bereitstellt, is ab sofort &uuml;ber das Fragezeichen-Icon in der Appbar erreichbar.&nbsp;</li>
                                <li><strong>Hervorhebung aktueller Men&uuml;seiten</strong>: Die aktuelle&nbsp; Men&uuml;seite ist im Hauptmen&uuml; hervorgehoben.</li>
                            </ul>
                            <p>Version <strong>4.8.0:</strong></p>
                            <ul>
                                <li><strong>Verbesserte Punktspiel-Zeitstrahldarstellung</strong>: Das UI wurde um Teilnehmer und Umfragen erweitert. Grunds&auml;tzlich wurde ein neues Design-Muster "Drawer" f&uuml;r die Interaktion eingebunden. Die Kachelansicht wurde aus der Zeitstrahlansicht entfernt.</li>
                                <li><strong>Anzeige verlinkter Objekte in Umfragen</strong>: Verlinkte Umfragen zeigen nun die verlinkten Objekte (Punktspiele) an, sodass auch aus der "Meine Umfragen"-Ansicht erkennbar wird, mit welchem Objekt die Umfrage verlinkt ist.&nbsp;</li>
                            </ul>
                            <p>Version <strong>4.7.0:</strong></p>
                            <ul>
                                <li><strong>Verkn&uuml;pfte Umfragen (Punktspiele)</strong>: Umfragen k&ouml;nnen mit Punktspielen verkn&uuml;pft werden. Verkn&uuml;pfte Umfragen k&ouml;nnen in der Punktspielkarte direkt eingesehen werden.</li>
                                <li><strong>Export der Mitgliederinformationen</strong>: Mitgliederinformationen k&ouml;nnen als csv Datei exportiert werden.</li>
                            </ul>
                            <p>Version <strong>4.6.0:</strong></p>
                            <ul>
                                <li><strong>Verbessertes Design &amp; Men&uuml;f&uuml;hrung Buchungen</strong>: Der Buchungsdialog beginnt mit einem Men&uuml; f&uuml;r die Buchungskategorie. Buchungen werden kompakter in der Buchungs&uuml;bersicht dargestellt.</li>
                                <li><strong>Vorschl&auml;ge f&uuml;r Buchungspartner</strong>: Bei der Auswahl des Partners werden h&auml;ufig verwendete Partner vorgeschlagen.</li>
                                <li><strong>&Uuml;berarbeitung Blogdesign</strong>: Anordnung und Darstellung der Beitr&auml;ge wurde leicht angepasst. Das Design des Blogdialogs wurde verbessert.</li>
                            </ul>
                            <p>Version <strong>4.5.0:</strong></p>
                            <ul>
                                <li><strong>Kategorien f&uuml;r Serienbuchungen</strong>: Serien k&ouml;nnen als Punktspiel, Training oder Sonstige kategorisert und gefiltert werden.</li>
                                <li><strong>Benutzerdefinierte Turnierb&auml;ume</strong>: Turnierb&auml;ume k&ouml;nnen grunds&auml;tzliche in ihrer Gr&ouml;&szlig;e und Auslosung angepasst definiert werden. Bestehende Turnierb&auml;ume k&ouml;nnen manuell von einem Admin bearbeitet werden (Paarungen).</li>
                                <li><strong>Komprimierte Buchungs&uuml;bersicht</strong>: Die Buchungsansicht kann in einer Abk&uuml;rzungsschreibwweise dargestellt werden, wodurch gro&szlig;e und viele Buchungen generisch daf&uuml;r aber &uuml;bersichtlich dargestellt werden.</li>
                            </ul>
                            <p>Version <strong>4.4.0:</strong></p>
                            <ul>
                                <li><strong>Punktspielplaner</strong>: Der Punktspielplaner erm&ouml;glicht eine &uuml;berischtliche Darstellung vorhandener und fehlender Platzbelgungen f&uuml;r die Heimpunktspiele.</li>
                                <li><strong>Verkn&uuml;pfungen von Serienbuchungen</strong>: Serienbuchungen k&ouml;nnen &uuml;ber den Punktspielplaner mit Punktspielen verkn&uuml;pft werden, wodurch diese Serienbuchungen in der jeweiligen Mannschafts&uuml;bersicht erscheinen.</li>
                                <li><strong>Verbesserung der Serienansicht: </strong>Serienkarten verf&uuml;gen &uuml;ber mehr Informationen in der unausgeklappten Ansicht.</li>
                            </ul>
                            <p>Version <strong>4.3.0</strong>:</p>
                            <ul>
                                <li><strong>Verbesserung Teammanagement: </strong>Teams k&ouml;nnen jetzt mit einem Klick aus NuLiga angelegt werden und in einer neuen Listenansicht dargestellt werden. Ein aggregregierter Status f&uuml;r alle Teams wird angezeigt. Es wurde eine Legende f&uuml;r Status hinzugef&uuml;gt. Punktspiele k&ouml;nnen von der Teamsseite synchonisiert werden.&nbsp;</li>
                                <li><strong>Zeitstrahl Punktspiele:</strong> Punktspiele k&ouml;nnen jetzt &uuml;bersichtlich in einem Zeitstrahl angezeigt werden.</li>
                                <li><strong>Registrierungsbutton</strong>: Es wurde ein Registrierungsbutton auf der Landingpage hinzugef&uuml;gt, sodass neue Nutzer intuitiver die Registrierungsseite finden.</li>
                            </ul>
                            <p>Version <strong>4.2.2</strong>:</p>
                            <ul>
                                <li><strong>Buchungen - Vorname</strong>: Die Buchungsplaketten zeigen den ersten Buchstaben des Vornamen an.</li>
                                <li><strong>Verf&uuml;gbare Buchungen</strong>: Feitextbuchungen werden nicht mehr in die t&auml;glich verf&uuml;gbaren Buchunugen eingerechnet.</li>
                            </ul>
                            <p>Version <strong>4.2.1</strong>:</p>
                            <ul>
                                <li><strong>Zeistrahlansicht Datum: </strong>Das Jahr wird korrekt angezeigt.</li>
                            </ul>
                            <p>Version <strong>4.2.0</strong>:</p>
                            <ul>
                                <li><strong>Anzeige verf. Buchungen</strong>: Im Dashboard werden die verf&uuml;gbaren Buchungseinheiten angezeigt.</li>
                                <li><strong>Zeitstrahlansicht Events</strong>: Turniere &amp; Veranstaltungen k&ouml;nnen jetzt in einem Zeitstrahl angezeigt werden.Diese neue Darstellungsoption erm&ouml;glicht es den Nutzern, Events &uuml;bersichtlich nach ihrem zeitlichen Verlauf zu visualisieren</li>
                            </ul>
                            <p>Version <strong>4.1.0</strong>:</p>
                            <ul>
                                <li><strong>Freitextbuchungen</strong>: Mitglieder k&ouml;nnen jetzt Freitextbuchungen, unter Angabe des Zwecks&nbsp; &amp; ohne Angabe eines Spielpartners &amp; au&szlig;erhalb des verf. Buchungskontingent, t&auml;tigen.</li>
                                <li><strong>Einstellungen Freitextbuchung:</strong> Freitextbuchungen k&ouml;nnen f&uuml;r Mitglieder in den Club-Einstellungen aktiviert und deaktiviert werden.</li>
                                <li><strong>Mehrfachbuchungen</strong>: Mitglieder k&ouml;nnen Buchungen verl&auml;ngern und somit eine Mehrfachbuchung durchf&uuml;hren, die mehrere aufeinanderfolgende Zeitslots umfasst. Jeder Zeitslot entspricht einer verf. Tagesbuchung.&nbsp;</li>
                            </ul>
                            <p>Version <strong>4.0.2</strong>:</p>
                            <ul>
                                <li><strong>Behebung von Bugs</strong>: Ein Fehler beim Erstellen von Punkstpielen, wenn keine Mannschaft vorhanden ist, wurde behoben.</li>
                                <li><strong>Platzsperrungen 30 Minuten</strong>: Platzsperrungen werden nun korrekt in der Buchungsansicht f&uuml;r 30-Minuten-Slots angezeigt.</li>
                                <li><strong>Verbeserte Abonnement- &amp; Lizenzansicht</strong>:Die Ansicht und Bedienung f&uuml;r Abonnements wurde im Admin-Dashboard optimiert, um die &Auml;nderungen am Abonnement nun klarer darzustellen.</li>
                            </ul>
                            <p>Version <strong>4.0.1</strong>:</p>
                            <ul>
                                <li><strong>Verbesserung der Setzlisten</strong>: Setzlisten und Turnierb&auml;umen k&ouml;nnen jetzt auch f&uuml;r Turniere ohne Anmeldung verwendet werden.</li>
                                <li><strong>&Uuml;berarbeitung der Event-Icons</strong>: Die Icons in der Eventcard wurden &uuml;berarbeitet und mit Tooltips versehen.</li>
                            </ul>
                            <p>Version <strong>4.0.0</strong>:</p>
                            <ul>
                                <li><strong>Setzliste f&uuml;r Turniere</strong>: Spieler k&ouml;nnen f&uuml;r Turniere gesetzt werden. Turnierb&auml;ume werden gem&auml;&szlig; der Setzungen erstellt.</li>
                                <li><strong>Anpassbare Buchungszeiten</strong>: In den Club-Einstellungen k&ouml;nnen nun Buchungszeiten und -dauer definiert werden, um den individuellen Bed&uuml;rfnissen der Vereine besser gerecht zu werden.</li>
                                <li><strong>Benutzerdefinierte Punktspiele</strong>: Die Erstellung benutzerdefinierter Punktspiele wurde optimiert und vereinfacht, vor allem die Auswahl der Saison.</li>
                                <li><strong>&Auml;nderungs&uuml;bersicht</strong>: Benutzer k&ouml;nnen s&auml;mtliche &Auml;nderungen und Verbesserungen aller Versionen einsehen.</li>
                            </ul>
                            <p>Version <strong>3.0.1</strong>:</p>
                            <ul>
                                <li><strong>Verbesserte Administration von Events</strong>: Administratoren verf&uuml;gen nun &uuml;ber die Befugnis, s&auml;mtliche Events einzusehen, ohne auf spezifische Einladungen angewiesen zu sein. Des Weiteren besteht die M&ouml;glichkeit f&uuml;r Administratoren, eingeladene Nutzer direkt als Teilnehmer eines Events einzutragen.</li>
                                <li><strong>Leere Kommentare: </strong>Sobald ein Kommentar f&uuml;r einen Nutzer gel&ouml;scht wird, wird das zugeh&ouml;rige Hinweissymbol in der Mitgliederverwaltung automatisch entfernt.</li>
                            </ul>
                            <p>Version <strong>3.0.0</strong>:</p>
                            <ul>
                                <li><strong>Mehere Teams pro Spieler</strong>: Mitglieder k&ouml;nnen nun mehreren Teams beitreten, was eine flexiblere Nutzung der Plattform erm&ouml;glicht.</li>
                                <li><strong>Buchungsbest&auml;tigungen</strong>: Es wurden eine neue Einstellungen eingef&uuml;hrt, die es erm&ouml;glicht, Buchungsbest&auml;tigungen platformweit zu aktivieren oder deaktivieren.</li>
                                <li><strong>Verbesserte Mitgliederverwaltung</strong>: Die Mitgliederverwaltung wurde um die M&ouml;glichkeit erweitert, Kommentare f&uuml;r Mitglieder zu erstellen, sowie um die Option, Einzugserm&auml;chtigungen f&uuml;r Mitglieder anzugeben.</li>
                            </ul>
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
            <Footer />
        </>
    )
}

export default ReleaseNotesContainer