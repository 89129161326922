import React from "react";
import Typography from '@mui/material/Typography';
//import {LinkRoute} from "components/linkRoute"

function NotFound(){

    return(
        <>
       <Typography variant="h4">404: page not found!</Typography>
       </>
    );
};
export default NotFound

