import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'
import Person from '@mui/icons-material/Person'
import { Chip } from '@mui/material'
import React from 'react'

function RoleChip({ role, ...props }) {
    return (
        role === "superuser" ? <Chip size="small" label={role} icon={<AdminPanelSettings color="action.main" />} sx={{ bgcolor: 'action.trans', color: 'action.main', fontWeight: 'bold' }} variant="primary" />
            : role === "admin" ? <Chip size="small" label={role} icon={<AdminPanelSettings color="warning.main" />} sx={{ bgcolor: 'warning.trans', color: 'warning.main', fontWeight: 'bold' }} variant="primary" />
                : <Chip size="small" label={role} icon={<Person color="success.main" />} sx={{ bgcolor: 'success.trans', color: 'success.main', fontWeight: 'bold' }} variant="primary" />
    )
}

export default RoleChip