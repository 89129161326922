import { Alert, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createCourt_api, editCourt_api } from '../../../api/Courts/courts';
import PrimaryButton from '../../../components/PrimaryButton';
import { useSnackbar } from '../../../components/SnackbarContext';

function AddEditCourtDialog(props) {
    const { open, onClose, getCourts, court, isEdit, setSelectedCourt } = props;
    const [courtName, setCourtName] = useState("");
    const [courtTime, setCourtTime] = useState("");
    const [courtType,setCourtType] = useState("indoor");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const { showSnackbar } = useSnackbar();


    const ceateCourt = () => {
        var promise = createCourt_api(courtName, courtTime,courtType);
        promise.then((result) => { getCourts(); showSnackbar("Platz erfolgreich angelegt", "success"); onClose(); })
            .catch((error) => { });
    }

    const editCourt = () => {
        var promise = editCourt_api(court.id, courtName, courtTime,courtType);
        promise.then((result) => { getCourts(); showSnackbar("Platz erfolgreich bearbeitet", "success"); onClose(); })
            .catch((error) => { });
    }

    useEffect(() => {
        if (open) {
            if (court) {
                console.log(court);
                setCourtName(court["name"]);
                setCourtTime(court["time_in_advance"]);
                setCourtType(court["type"]);
            }
        }
        else {
            setSelectedCourt(undefined);
            setCourtName("");
            setCourtTime("");
            setCourtType("indoor");
        }
        return () => {
        };
    }, [open]);

    useEffect(() => {
        if (!courtName) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
    }, [courtName, courtTime]);

    return (
        <Dialog id="dlg-courtdialog"open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>{isEdit ? "Platz bearbeiten" : "Platz erstellen"}</DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField id="txf-courtdialog-name" label="Name" value={courtName} onChange={(event) => { setCourtName(event.target.value) }} fullWidth></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth id="booking_duration">
                            <InputLabel>Platztyp</InputLabel>
                            <Select id="sel-courtdialog-type" label="Buchungsdauer" labelid="Buchungsdauer" name="booking_duration" value={courtType} onChange={(event)=>{setCourtType(event.target.value)}} fullWidth >
                                <MenuItem id="btn-courtdialog-courttype-outdoor" value={"outdoor"}>Außenplatz</MenuItem>
                                <MenuItem id="btn-courtdialog-courttype-indoor"value={"indoor"}>Hallenplatz</MenuItem>
                            </Select>
                            {/* <FormHelperText></FormHelperText> */}
                        </FormControl>


                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity='info'>
                            <Typography variant="body2"> Definiere eine Zeit, wie lange der Platz im Vorraus buchbar ist. Keine Eingabe für keine Beschränkung!</Typography>
                        </Alert>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={2} justifyContent={"center"} mt={1}>
                            <Chip id="chp-courtdialog-timeinadvance-1h" variant="outlined" size="small" label={"1 Stunde"} onClick={() => setCourtTime(60)} />
                            <Chip id="chp-courtdialog-timeinadvance-1d" variant="outlined" size="small" label={"1 Tag"} onClick={() => setCourtTime(60 * 24)} />
                            <Chip id="chp-courtdialog-timeinadvance-1w" variant="outlined" size="small" label={"1 Woche"} onClick={() => setCourtTime(60 * 24 * 7)} />
                            <Chip id="chp-courtdialog-timeinadvance-unlimited" variant="outlined" size="small" label={"unbeschränkt"} onClick={() => setCourtTime("")} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="txf-courtdialog-timeinadvance" label="Zeit in Minuten" value={courtTime} onChange={(event) => { setCourtTime(event.target.value) }} fullWidth></TextField>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <Divider></Divider>
            <DialogActions>
                <PrimaryButton id="btn-courtdialog-submit" text={isEdit ? "Bestätigen" : "Erstellen"} disabled={submitDisabled} onClick={() => { isEdit ? editCourt() : ceateCourt() }}></PrimaryButton>
            </DialogActions>
        </Dialog >
    )
}

export default AddEditCourtDialog