import React, { useState, useEffect } from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Grid, Typography, Avatar, TablePagination, Menu, MenuItem, ListItemIcon,Divider,Alert, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Check, Delete, Feedback, InfoSharp, Mail, RemoveCircleOutlineSharp } from '@mui/icons-material';
import { Box } from "@mui/system";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from "@emotion/react";
import CommentDialog from "./CommentDialog";
import { updateComment_api } from "../../api/Users/users";
import RoleChip from "../../components/RoleChip";
import { useSnackbar } from "../../components/SnackbarContext";
import DeleteDialog from "../../components/DeleteDialog";


function UsersActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="usermenu"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => { handleClick(event) }}
                color="inherit"
                sx={{ p: 0 }}
            >
                <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'usermenu',
                }}
            >
                <MenuItem onClick={() => { setAnchorEl(null); props.detailsHandler(props.selectedUser.id) }}>
                    <ListItemIcon>
                        <InfoSharp fontSize="small" color="primary" />
                    </ListItemIcon>
                    Informationen
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.openComment() }}>
                    <ListItemIcon>
                        <Feedback fontSize="small" color="primary" />
                    </ListItemIcon>
                    Kommentar anpassen
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.setUserIdMail(props.selectedUser.id); props.setUser(props.selectedUser); props.setOpenMailDialog(true); }}>
                    <ListItemIcon>
                        <Mail fontSize="small" color="primary" />
                    </ListItemIcon>
                    Nachricht senden
                </MenuItem>
                {props.selectedUser.verified == 0 &&
                    <MenuItem onClick={() => { setAnchorEl(null); props.verifyUser(props.selectedUser.id) }}>
                        <ListItemIcon>
                            <Check fontSize="small" color="success" />
                        </ListItemIcon>
                        Verifizieren
                    </MenuItem>
                }
                <Divider></Divider>
                <MenuItem onClick={() => { setAnchorEl(null); props.openDelete(); }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Löschen
                </MenuItem>
            </Menu>
        </div>
    );
}

function UsersTable(props) {

    const { users, refreshHandler, deleteHandler } = props;

    const theme = useTheme();
    const useStyles = makeStyles({
        tableHeader: {
            // backgroundColor: theme.palette.primary.main,
            //  backgroundColor: "#F5F6FA",
            backgroundColor: "#E5E6EA",
            color: theme.palette.text.secondary,
            fontWeight: 600,
        }
    });
    const {showSnackbar} = useSnackbar();

    //declare state vars GRID
    const [state, setState] = useState({});
    const [filter, setFilter] = useState("");
    const [openComment, setOpenComment] = useState(false);
    const [targetUser, setTargetUser] = useState(null);


    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Mitglied löschen", warningText: "Gelöschte Accounts können nicht wiederhergestellt werden. Beim Löschen des Accounts werden alle zugehörigen Informationen gelöscht", openDeleteDialog: false, deleteObjectName: "", deleteHandler: () => {} });


    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: true,deleteObjectName: ("den Account von " + targetUser.firstname +" "+targetUser.surname), deleteHandler: () => deleteHandler(targetUser.id) })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateComment = (comment) => {
        var promise = updateComment_api(targetUser.id, comment)
        promise.then(() => { setOpenComment(false); refreshHandler(); })
            .catch(()=>showSnackbar("Leider ist etwas schief gelaufen","error"));
    }

    const classes = useStyles();

    //fetch users data and populate state
    useEffect(() => {
        refreshHandler();
    }, [filter]);

    return (
        <>
            <Grid container direction="row" alignItems="center" columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12}>
                    {users.length > 0 ?
                        <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[50, 100, 500]}
                                            count={users.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            labelRowsPerPage={"Anzahl"}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            sx={{ border: 0 }}
                                        />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader} align="center">Akt</TableCell>
                                        <TableCell className={classes.tableHeader}>Mitglied</TableCell>
                                        <TableCell className={classes.tableHeader} align="center">LK</TableCell>
                                        <TableCell className={classes.tableHeader} align="center">Rolle</TableCell>
                                        <Tooltip title="Einzugsermächtigung vorhanden?"><TableCell className={classes.tableHeader} align="center">Sepa</TableCell></Tooltip>
                                        <TableCell className={classes.tableHeader} align="center">Kommentar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, i) => (
                                        <TableRow hover key={i} onClick={() => { setTargetUser(user) }}>
                                            <TableCell className={classes.tableCell} >
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                                    <UsersActionMenu selectedUser={user} openComment={() => setOpenComment(true)} openDelete={() =>openDeleteDialogAction()} {...props}></UsersActionMenu>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'left',
                                                }} >
                                                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                                                        {user.firstname.charAt(0)}{user.surname.charAt(0)}
                                                    </Avatar>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'left',
                                                        ml: 1
                                                    }} >
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{user.firstname + " " + user.surname}</Typography>
                                                        <Typography variant="body2" >{user.email}</Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }} >
                                                    <Typography variant="body2">{user.nuliga_lk || ""} </Typography> 
                                                   
                                                </Box>
                                                </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }} >
                                                    <RoleChip role={user.role}/>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    ml: 1
                                                }} >
                                                    {user && user.sepa_available ? <Check color="success" /> : <RemoveCircleOutlineSharp color="warning" />}
                                                </Box>
                                            </TableCell>
                                            <TableCell className={classes.tableCell} onClick={() => { setOpenComment(true) }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }} >
                                                    {user && user.comment ?
                                                        // <Typography noWrap>{user.comment}</Typography>
                                                        <Feedback color="primary"></Feedback>
                                                        :
                                                        <></>
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Alert severity="info" variant="filled"><Typography> Keine Registrierungen vorhanden</Typography></Alert>
                    }
                </Grid>
            </Grid>
            <CommentDialog open={openComment} onClose={() => setOpenComment(false)} handleSubmit={updateComment} userComment={targetUser && targetUser.comment}></CommentDialog>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />

        </>
    );
}
export default UsersTable;
