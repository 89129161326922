import { Clear, PinDrop } from '@mui/icons-material';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getCourts_api } from '../api/Courts/courts';
import CustomAutocomplete from './CustomAutocomplete';
import PrimaryIconButton from './PrimaryIconButton';
import TertiaryButton from './TertiaryButton';
import MyTab from './MyTab';

function CourtSelect(props) {

    const { targetCourts, setTargetCourts, forceResetCourtsOnCourtTypeSwitch} = props;
    const [courtType, setCourtType] = useState("outdoor");

    //const [targetCourts, setTargetCourts] = useState([]);
    const [courtsAC, setCourtsAC] = useState();
    const [courts, setCourts] = useState();

    const addTargetCourt = (targetCourt) => {
        if (targetCourt) {
            setTargetCourts(targetCourts => {
                return [...targetCourts, targetCourt];
            })
        }
    }

    const setAllCourts = () => {
        setTargetCourts(courts);
    }

    const unsetCourts = () => {
        setTargetCourts([]);
    }

    const getCourtsList = () => {
        var promise = getCourts_api("?type=" + courtType);
        promise.then((result) => {
            setCourts(result.data)
            var courts_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                courts_autocomplete.push({ label: result.data[i].name, id: result.data[i].id });
            }
            setCourtsAC(courts_autocomplete);
        })
            .catch((error) => { });
    }

    const removeTargetCourt = (index) => {
        setTargetCourts([
            ...targetCourts.slice(0, index),
            ...targetCourts.slice(index + 1)
        ]);
    }

    useEffect(() => {
        getCourtsList();
        if (forceResetCourtsOnCourtTypeSwitch) {
            unsetCourts();
        }
    }, [courtType]);

    useEffect(() => {
        if (props.courtType != courtType) {
            setCourtType(props.courtType)
            unsetCourts();
        }
    }, [props.courtType]);

    return (
        <Grid container spacing={0}>
            {setTargetCourts &&
                <>

                    {!props.courtType ?
                        <Grid item xs>
                            <Tabs value={courtType} centered onChange={(event, newValue) => { setCourtType(newValue); }}>
                                <MyTab id="tab-courtselect-ooutdoor" value="outdoor" label="Außenplätze" ></MyTab>
                                <MyTab id="tab-courtselect-indoor"value="indoor" label="Hallenplätze" ></MyTab>
                            </Tabs>
                        </Grid>
                        :
                        <></>
                    }
                    <Grid item xs={12}>
                        {courtsAC && (courtType== "indoor" || courtType == "outdoor" )&&
                            <Stack alignItems={"flex-start"}>
                                <CustomAutocomplete id="atc-courts" options={courtsAC} label="Plätze" setTarget={addTargetCourt} multipleSelect={true} />
                                <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
                                    <TertiaryButton id="atc-courts-selectall" text={"Alle Plätze"} onClick={setAllCourts}></TertiaryButton>
                                    <TertiaryButton id="atc-courts-removeall" text={"Leeren"} onClick={unsetCourts}></TertiaryButton>
                                </Stack>

                            </Stack>
                        }
                    </Grid>
                </>
            }
            {targetCourts && targetCourts.length > 0 &&
                <Grid item xs={12}>
                    <List dense>
                        {targetCourts.map((court, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar><Avatar sx={{ bgcolor: 'primary.main' }}><PinDrop color="white"></PinDrop></Avatar></ListItemAvatar>
                                <ListItemText primary={court.name} primaryTypographyProps={{ variant: "body1" }}>
                                </ListItemText>
                                {setTargetCourts &&
                                    <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTargetCourt(i); }} sx={{ ml: 2 }} />
                                }
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            }

        </Grid>
    )
}

export default CourtSelect