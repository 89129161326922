import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import RouterConfig from "./navigation/RouterConfig";
import { useSelector } from "react-redux"; // Updated: useSelector for Redux state access
import { LocalizationProvider } from "@mui/x-date-pickers"; // Updated import for MUI date pickers
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // Updated import for MUI date adapter
import { de } from "date-fns/locale";
import { SnackbarProvider } from "./components/SnackbarContext";
// import { HelpCenterProvider } from "./components/HelpCenter/HelpMenu";

function App() {
  const isAuthenticated = useSelector((state) => state.isAuthenticated); // Updated to use hooks

  useEffect(() => {
    const handleMessage = (event) => {
      const originRegex = /^https:\/\/([a-zA-Z0-9-]+\.)?myclubportal\.de$/;

      // Ensure the message is from a trusted origin
      if (!originRegex.test(event.origin)) {
        console.warn("Message from unauthorized origin:", event.origin);
        return;
      }

      // Handle the received message
      if (event.data.type === "setAuthToken" && event.data.token && event.data.id) {
        localStorage.setItem("token", event.data.token);
        localStorage.setItem("id", event.data.id);
        window.location.reload();
      } else {
        console.warn("Invalid message format");
      }
    };

    // Add event listener for messages
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}> {/* Updated: adapterLocale */}
            <BrowserRouter>
              <RouterConfig />
            </BrowserRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
