import { AccountCircle, AccountCircleSharp, AddBox, Check, Delete, Person } from '@mui/icons-material'
import { Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getEventParticipants_api, setMatchPlayers_api } from '../api/Events/events';
import SecondaryButton from './SecondaryButton';
import MatchDialog from '../pages/Tournament/MatchDialog';
import { useTheme } from '@emotion/react';
function BracketEditParticipantDialog({ open, onClose, match, player, setMatchPlayers }) {
    const [participants, setParticipants] = useState();

    const getParticipants = () => {
        var promise = getEventParticipants_api(match.tournament_id);
        promise.then((result) => { setParticipants(result.data) })
            .catch();
    }

    const addPlayer = (id) => {
        if (player == 1) {
            setMatchPlayers(id, match.player2)
        }
        else {
            setMatchPlayers(match.player1, id)
        }
    }

    useEffect(() => {
        if (open) {
            getParticipants();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Teilnehmer</DialogTitle>
            <DialogContent>
                <List dense sx={{ width: "100%" }}>
                    <ListItem >
                        <ListItemIcon><Person color="primary"></Person></ListItemIcon>
                        <ListItemText primary={"Freilos"} secondary={"bye"}></ListItemText>
                        <IconButton onClick={(e) => { e.stopPropagation(); addPlayer(-2); onClose(); }}><AddBox color="primary" o /></IconButton>
                    </ListItem>
                    <ListSubheader><Divider>Teilnehmer</Divider></ListSubheader>
                    {participants && participants.map((participant, i) => (
                        <ListItem key={i}>
                            <ListItemIcon><Person color={participant.isAssigned ? "secondary" : "primary"}></Person></ListItemIcon>
                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + participant.nuliga_lk + " | " + (participant.isAssigned ? "zugewiesen" : "nicht zugewiesen")}></ListItemText>
                            <IconButton onClick={(e) => { e.stopPropagation(); addPlayer(participant.id); onClose() }}><AddBox color="primary"  /></IconButton>
                        </ListItem>
                    ))
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <SecondaryButton text="Fertig" onClick={(e) => { e.stopPropagation(); onClose() }}></SecondaryButton>
            </DialogActions>
        </Dialog>
    );
}


function BracketMatchCard({match, grandSlamMode, editMode, getMatches, ...props}) {
    const [borderColor, setborderColor] = useState("#e6e6e6");
    const [elevation, setElevation] = useState(1);
    const [openParticipant, setOpenParticipant] = useState(false);
    const [player, setPlayer] = useState();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const borderRadius=5;


    const openEditParticipantDialog = (player) => {
        setPlayer(player);
        setOpenParticipant(true);
    }

    const closeMatchDialog =() => {
        setOpen(false);
        //console.log("closed");
    }

    const setMatchPlayers = (player1, player2) => {
        var promise = setMatchPlayers_api(match.tournament_id, match.id, player1, player2);
        promise.then((result) => { getMatches(); })
            .catch();
    }
    return (
        <Stack onMouseEnter={() => {!isSmallScreen && setborderColor("primary.main"); setElevation(24) }} onMouseLeave={() => {!isSmallScreen && setborderColor("#e6e6e6"); setElevation(1) }} >
            <Card sx={{ border: 1, borderRadius: 0, borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius, borderColor: borderColor, borderBottomColor: "#e6e6e6" }} elevation={elevation} onClick={() => { setOpen(true)}}>
                <CardContent sx={{ py: 1, "&:last-child": { paddingBottom: 1 } }}>
                    <Grid container direction="row" justifyContent={"space-between"} wrap='nowrap'>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                            <AccountCircle color="primary" />
                            <Stack>
                                {match && match.player1_seed ?
                                    <Typography noWrap sx={{ minWidth: "250px", fontWeight: match.player1 == -2 || !match.player1 ? "regular" : 500 }}>{match.player1 == -2 ? "bye" : match.player1_name ? "(" + match.player1_seed + ") " + match.player1_name : "tbd"}</Typography>
                                    :
                                    <Typography noWrap sx={{ minWidth: "250px", fontWeight: match.player1 == -2 || !match.player1 ? "regular" : 500 }}>{match.player1 == -2 ? "bye" : match.player1_name ? match.player1_name : "tbd"}</Typography>
                                }
                                <Typography variant="body2" color="text.secondary">{match.player1_lk ? "LK: " + match.player1_lk : "LK:"}</Typography>
                            </Stack>
                        </Stack>
                        {!editMode ?
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                {match.winner == match.player1 &&
                                    <Check color="success"></Check>
                                }
                                <Stack direction="row" spacing={1}>
                                    {(match.set1_player1 == 6 && match.set1_player2 != 7) || match.set1_player1 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set1_player1}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set1_player1}</Typography>
                                    }
                                    {(match.set2_player1 == 6 && match.set2_player2 != 7) || match.set2_player1 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set2_player1}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set2_player1}</Typography>
                                    }
                                    {(match.set3_player1 == 6 && match.set3_player2 != 7) || match.set3_player1 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set3_player1}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set3_player1}</Typography>
                                    }
                                    {grandSlamMode ?
                                        <>
                                            {(match.set4_player1 == 6 && match.set4_player2 != 7) || match.set4_player1 == 7 ?
                                                <Typography sx={{ fontWeight: 600 }}>{match.set4_player1}</Typography>
                                                :
                                                <Typography sx={{ fontWeight: 400 }}>{match.set4_player1}</Typography>
                                            }
                                            {(match.set5_player1 == 6 && match.set5_player2 != 7) || match.set5_player1 == 7 ?
                                                <Typography sx={{ fontWeight: 600 }}>{match.set5_player1}</Typography>
                                                :
                                                <Typography sx={{ fontWeight: 400 }}>{match.set5_player1}</Typography>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </Stack>
                            </Stack>
                            :
                            <Stack direction="row" spacing={1}>
                                <IconButton onClick={(e) => { e.stopPropagation(); openEditParticipantDialog(1); }}><AddBox color="primary" /></IconButton>
                                <IconButton onClick={(e) => { e.stopPropagation(); setMatchPlayers(null, match.player2) }}><Delete /></IconButton>
                            </Stack>
                        }

                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ border: 1, borderRadius: 0, borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius, borderColor: borderColor, borderTopColor: "#e6e6e6" }} elevation={elevation} onClick={() => { setOpen(true)}}>
                <CardContent sx={{ py: 1, "&:last-child": { paddingBottom: 1 } }}>
                    <Grid container direction="row" justifyContent={"space-between"}>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                            <AccountCircleSharp color="primary" />
                            <Stack>
                                {match.player2_seed ?
                                    <Typography sx={{ fontWeight: match.player2 == -2 || !match.player2 ? "regular" : 500 }}>{match.player2 == -2 ? "bye" : match.player2_name ? "(" + match.player2_seed + ") " + match.player2_name : "tbd"}</Typography>
                                    :
                                    <Typography sx={{ fontWeight: match.player2 == -2 || !match.player2 ? "regular" : 500 }}>{match.player2 == -2 ? "bye" : match.player2_name ? match.player2_name : "tbd"}</Typography>

                                }
                                <Typography variant="body2" color="text.secondary">{match.player2_lk ? "LK: " + match.player2_lk : "LK:"}</Typography>
                            </Stack>
                        </Stack>
                        {!editMode ?
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                {match.winner == match.player2 &&
                                    <Check color="success"></Check>
                                }
                                <Stack direction="row" spacing={1}>
                                    {(match.set1_player2 == 6 && match.set1_player1 != 7) || match.set1_player2 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set1_player2}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set1_player2}</Typography>
                                    }
                                    {(match.set2_player2 == 6 && match.set2_player1 != 7) || match.set2_player2 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set2_player2}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set2_player2}</Typography>
                                    }
                                    {(match.set3_player2 == 6 && match.set3_player1 != 7) || match.set3_player2 == 7 ?
                                        <Typography sx={{ fontWeight: 600 }}>{match.set3_player2}</Typography>
                                        :
                                        <Typography sx={{ fontWeight: 400 }}>{match.set3_player2}</Typography>
                                    }
                                    {grandSlamMode ?
                                        <>
                                            {(match.set4_player2 == 6 && match.set4_player1 != 7) || match.set4_player2 == 7 ?
                                                <Typography sx={{ fontWeight: 600 }}>{match.set4_player2}</Typography>
                                                :
                                                <Typography sx={{ fontWeight: 400 }}>{match.set4_player2}</Typography>
                                            }
                                            {(match.set5_player2 == 6 && match.set5_player1 != 7) || match.set5_player2 == 7 ?
                                                <Typography sx={{ fontWeight: 600 }}>{match.set5_player2}</Typography>
                                                :
                                                <Typography sx={{ fontWeight: 400 }}>{match.set5_player2}</Typography>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </Stack>
                            </Stack>
                            :
                            <Stack direction="row" spacing={1}>
                                <IconButton onClick={(e) => { e.stopPropagation(); openEditParticipantDialog(2); }}><AddBox color="primary" /></IconButton>
                                <IconButton onClick={(e) => { e.stopPropagation(); setMatchPlayers(match.player1, null) }}><Delete></Delete></IconButton>
                            </Stack>
                        }
                    </Grid>
                </CardContent>
            </Card>
            <BracketEditParticipantDialog open={openParticipant} onClose={() => setOpenParticipant(false)} eventId={match.tournament_id} match={match} player={player} setMatchPlayers={setMatchPlayers}></BracketEditParticipantDialog>
            <MatchDialog onClose={closeMatchDialog} open={open} selectedMatch={match} getMatches={getMatches} grandSlamMode={false} />
        </Stack >

    )
}

export default BracketMatchCard