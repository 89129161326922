import { Container, Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer'

function LicenseAgreementContainer() {
    return (
        <>
            <Header></Header>
            <Container maxWidth="lg" px={{ px: 1 }}>
                <Typography variant="h6"><p ><span ><strong>Nutzungsbedingungen</strong></span></p></Typography>
                <Typography variant="body1">
                    <p><strong>Allgemeine Gesch&auml;ftsbedingungen (AGB) f&uuml;r den Abschluss einer Lizenzvereinbarung mit automatischer monatlicher Verl&auml;ngerung:</strong></p>
                    <ol>
                        <li>
                            <p><strong>Vertragsgegenstand</strong> Durch das Akzeptieren dieser AGB schlie&szlig;t der Kunde automatisch ein Abonnement ab, das monatlich verl&auml;ngert wird, bis der Kunde das Abonnement k&uuml;ndigt. Das Abonnement berechtigt den Kunden zur Nutzung der MyClubPortal Plattform.</p>
                            <p>Die Plattform wird auf monatlicher Basis lizenziert. Der Kunde erwirbt das Recht zur Nutzung der Plattform durch den Abschluss einer Lizenzvereinbarung. Die Lizenzgeb&uuml;hren werden monatlich in Rechnung gestellt und sind zahlbar innerhalb von 14 Tagen nach Erhalt der Rechnung. Das Abonnement verl&auml;ngert sich automatisch am Ende jedes Abrechnungszeitraums, es sei denn, der Kunde k&uuml;ndigt das Abonnement vor Ablauf des aktuellen Abrechnungszeitraums. Die Zahlung der Lizenzgeb&uuml;hren erfolgt per &Uuml;berweisung oder Online-Zahlungsdienst, wie in der Rechnung angegeben.</p>
                        </li>
                        <li>
                            <p><strong>Nutzungsumfang</strong> Die Plattform steht dem Kunden im Umfang gem&auml;&szlig; der gew&auml;hlten Lizenz oder des Angebots zur Verf&uuml;gung. Der Kunde erh&auml;lt Zugang zu den Funktionen und Dienstleistungen der Plattform gem&auml;&szlig; den Spezifikationen, die in der Lizenzvereinbarung festgelegt sind. Der Kunde ist berechtigt, die Plattform ausschlie&szlig;lich f&uuml;r interne gesch&auml;ftliche Zwecke zu nutzen.</p>
                        </li>
                        <li>
                            <p><strong>Verantwortlichkeiten des Kunden</strong> Der Kunde ist verantwortlich f&uuml;r die Sicherheit seiner Zugangsdaten zur Plattform. Der Kunde verpflichtet sich, die Plattform nur gem&auml;&szlig; den Bestimmungen dieses Vertrags und geltenden Gesetzen zu nutzen.</p>
                        </li>
                        <li>
                            <p><strong>Haftungsausschluss</strong> Die Plattform &uuml;bernimmt keine Haftung f&uuml;r Sch&auml;den oder Verluste, die durch die Nutzung der Plattform entstehen, es sei denn, sie beruhen auf grober Fahrl&auml;ssigkeit oder Vorsatz seitens der Plattform.</p>
                        </li>
                        <li>
                            <p><strong>Datenschutz</strong> Die Plattform verpflichtet sich, die Datenschutzbestimmungen einzuhalten und die pers&ouml;nlichen Daten des Kunden vertraulich zu behandeln. Der Kunde stimmt der Erhebung, Verarbeitung und Nutzung seiner Daten gem&auml;&szlig; den Datenschutzbestimmungen zu.</p>
                        </li>
                        <li>
                            <p><strong>K&uuml;ndigung des Abonnements</strong> Der Kunde kann das Abonnement jederzeit vor Ablauf des aktuellen Abrechnungszeitraums k&uuml;ndigen. Die K&uuml;ndigung kann per E-Mail oder &uuml;ber die Plattform selbst erfolgen.</p>
                        </li>
                        <li>
                            <p><strong>&Auml;nderungen der AGB</strong> Die Plattform beh&auml;lt sich das Recht vor, diese AGB jederzeit zu &auml;ndern oder zu aktualisieren. Der Kunde wird &uuml;ber &Auml;nderungen der AGB informiert und hat das Recht, diesen &Auml;nderungen zu widersprechen. Bei fortgesetzter Nutzung der Plattform gelten die ge&auml;nderten AGB als akzeptiert.</p>
                        </li>
                        <li>
                            <p><strong>Salvatorische Klausel</strong> Sollten einzelne Bestimmungen dieses Vertrags unwirksam oder undurchf&uuml;hrbar sein, ber&uuml;hrt dies nicht die Wirksamkeit der &uuml;brigen Bestimmungen. Anstelle der unwirksamen oder undurchf&uuml;hrbaren Bestimmungen gelten die gesetzlichen Regelungen.</p>
                        </li>
                    </ol>
                    <p>Stand: 18.03.2024</p>
                    <p>Mit dem Abschluss der Lizenzvereinbarung erkl&auml;rt der Kunde sein Einverst&auml;ndnis mit diesen AGB und dem automatischen monatlichen Verl&auml;ngerungsmechanismus des Abonnements sowie den Zahlungsmodalit&auml;ten.</p>
                    <p>MyClubPortal Team</p>
                </Typography>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default LicenseAgreementContainer