import { Clear } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { deleteNotification_api, setNotificationRead_api } from '../../api/Notifications/notifications';

import NotificationIconSwitch from './NotificationIconSwitch';

function Notification(props) {

    const { content, type, reg_date, action, seen, id, target } = props.notification;
    const [route, setRoute] = React.useState();
    const [bckgColor, setBackgColor] = React.useState();

    const setNotificationRead = (path) => {
        var promise = setNotificationRead_api(id);
        promise.then(() => { props.handleRefresh(); navigate(path) })
            .catch();
    }

    const navigate = useNavigate();
    const handleMenuItemClick = (path) => {
        if (seen == 0) {
            setNotificationRead(path);
        }
        else {
            navigate(path)
        }
    }

    const deleteNotification = () => {
        var promise = deleteNotification_api(id);
        promise.then(() => { props.handleRefresh() })
            .catch();
    }

    React.useEffect(() => {
        if (seen && seen == "1") {
            setBackgColor("#f1f1f1")
        }

        return () => {

        };
    }, [seen]);

    return (
        <MenuItem onClick={(e) => { handleMenuItemClick(route); e.stopPropagation(); }} sx={{ backgroundColor: bckgColor }} divider>
            <ListItemIcon>
                <NotificationIconSwitch type={type} seen={seen} target={target} setRoute={setRoute}></NotificationIconSwitch>
            </ListItemIcon>
            <ListItemText primary={content} secondary={action + " - " + formatDistance(new Date(reg_date.split(" ")[0] + "T" + reg_date.split(" ")[1]), new Date(), { locale: de, addSuffix: true })} primaryTypographyProps={{ noWrap: true }} secondaryTypographyProps={{ color: seen == "1" ? "disabled.main" : "success.main" }}></ListItemText>
            <IconButton sx={{ ml: 3 }} size="small" onClick={(e) => { deleteNotification(); e.stopPropagation(); }}><Clear /></IconButton>
        </MenuItem>
    )
}

export default Notification