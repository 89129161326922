import { Dialog, DialogTitle } from '@mui/material'
import React from 'react'

function DialogComponent({ children,open, onClose,maxWidth, dialogTitle, ...props }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={maxWidth || "sm"} fullWidth>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>{dialogTitle}</DialogTitle>
            {children}
        </Dialog>
    )
}

export default DialogComponent