import { Avatar, Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import PrimaryButton from '../../components/PrimaryButton'

import { resetPassword_api } from '../../api/Users/users';
import { useNavigate } from 'react-router-dom';
import TertiaryButton from '../../components/TertiaryButton';
import { LockReset } from '@mui/icons-material';
import { useSnackbar } from '../../components/SnackbarContext';


function ResetPasswordContainer(props) {
    const [email, setEmail] = useState("");
    const {showSnackbar} = useSnackbar();
    const navigate = useNavigate();

    


    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    const resetPassword = () => {
        var promise = resetPassword_api(email);
        promise.then((result) => { showSnackbar("Password erfolgreich zurückgesetzt. Ein neues Passwort ehalten Sie per Mail!","success");})
            .catch((error) => { showSnackbar("Fehler","error"); });
    }

    return (
        <>
            <Card raised sx={{ mt: 20 }}>
                <CardContent>
                    <Grid container spacing={1} justifyContent={"center"}>
                        <Grid item>
                            <Typography></Typography>
                        </Grid>
                        <Grid item>
                            <Avatar sx={{ color: '#1E88E5', bgcolor: "#E3F2FD", width: "50px", height: "50px" }}><LockReset sx={{ width: "30px", height: "30px" }}></LockReset></Avatar>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography textAlign={"center"} variant="h5" color="text.secondary">{process.env.REACT_APP_CLUB_NAME}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant='middle'><Typography color="text.secondary">Passwort zurücksetzen</Typography></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="email" name="email" required onChange={handleChange} label="E-Mail" autoFocus fullWidth variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton fullWidth text={"Passwort zurücksetzen"} onClick={() => { resetPassword(); }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TertiaryButton text={"Zurück zum Login"} onClick={() => { props.setForgetPw(false); }} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>

    )
}

export default ResetPasswordContainer