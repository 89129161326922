import { EmojiEvents, Error, EventAvailable, EventBusy, Groups, Launch, RunningWithErrors, Sync, SyncProblem } from '@mui/icons-material'
import { Alert, Card, CardContent, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { ADM_COURTS, ADM_TEAMMATCHES } from '../../../navigation/CONSTANTS'
import { useNavigate } from 'react-router-dom'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import { format } from 'date-fns'
import TertiaryButton from '../../../components/TertiaryButton'

function AdmTeammatchesCard({ matches, stats }) {

    const navigate = useNavigate();

    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                {/* <CardHeader title={"Platz Status"} action={<PrimaryButton text="Status ändern" />} /> */}
                <CardContent>
                    <Grid container>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item xs align="left">
                                <Typography variant="h6" >{"Punktspielplaner - Platzbelegung"}</Typography>
                            </Grid>
                            <Grid item align="right">
                                <PrimaryIconButton icon={<Launch color="primary"></Launch>} onClickAction={() => navigate(ADM_TEAMMATCHES + "?tab=planning")} tooltipTitle="Öffne Punktspielplaner"></PrimaryIconButton>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: -1 }}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item container xs={12} justifyContent="space-evenly">
                                <Grid item>
                                    <Stack alignItems={"center"}>
                                        <EmojiEvents color="primary" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Heimspiele</Typography>
                                        <Typography>{stats && stats[0].teammatchCount}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack alignItems={"center"}>
                                        <EventBusy color="warning" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Fehlende Reservierung</Typography>
                                        <Typography>{stats && stats[0].missingReservation}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack alignItems={"center"}>
                                        <RunningWithErrors color="primary" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Verlegt</Typography>
                                        <Typography>{stats && stats[0].dateChanged}</Typography>
                                    </Stack>
                                </Grid>

                            </Grid>

                            <Grid item xs={12}>
                                <Divider><Typography color="text.secondary">{(matches && matches.length >0 ? matches.length : "")+" Handlung/en notwendig"}</Typography></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                {matches && matches.length > 0 ?
                                    <List dense sx={{mt: -2}}>
                                        {matches.map((match, i) => (
                                            i < 2 ?
                                                <ListItem key={i} >
                                                    <ListItemIcon>
                                                        {match.hasReservation && match.hasChanged != 1 ?
                                                            <Tooltip title="Reservierung vorhanden">
                                                                <EventAvailable color={"success"} />
                                                            </Tooltip>
                                                            : match.hasReservation && match.hasChanged ?
                                                                <Tooltip title="Reservierung vorhanden - Spiel verlegt ">
                                                                    <RunningWithErrors color="primary" />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="Keine Reservierung vorhanden">
                                                                    <EventBusy color={"warning"} />
                                                                </Tooltip>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Stack>
                                                        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>{match.teamName}</Typography>
                                                        <Typography color="text.secondary"variant="body2">{match.opponent}</Typography>
                                                    </Stack>} secondary={match.date && format(new Date(match.date), "dd.MM. HH:mm")}>
                                                    </ListItemText>
                                                </ListItem>
                                                :
                                                <></>
                                        ))
                                        }
                                    </List>
                                    :
                                    <Alert severity="info"><Typography>Keine Handlungen notwendig</Typography></Alert>
                                }
                            </Grid>
                            {matches && matches.length > 2 ?
                                <Grid item xs={12} align="center" sx={{mt: -4}}>
                                    <TertiaryButton text={matches.length - 2 + " weitere Handlungen notwendig"} onClick={()=>{navigate(ADM_TEAMMATCHES+"?tab=planning")}}/>
                                </Grid>
                                :
                                <></>

                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </>
    )
}

export default AdmTeammatchesCard