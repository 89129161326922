import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { createMyTraininggroup, createTraininggroup, updateTraininggroup } from '../api/Traininggroups/traininggroups';
import { getUsersNames } from '../api/Users/users';
import CustomAutocomplete from './CustomAutocomplete';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { useSnackbar } from './SnackbarContext';

function AddEditTraininggroupDialog(props) {
    const { open, onClose, getTraininggroups, adminMode, isEdit, traininggroup } = props;

    const [state, setState] = useState(null);
    const [users, setUsers] = useState([{}]);
    const [initialInputValue, setInitialInputValue] = useState("");
    const [targetUserID, setTargetUserID] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const {showSnackbar} = useSnackbar();



    const [name, setName] = useState("");

    const submitHandler = () => {
        var promise = null;
        if (isEdit) {
            promise = updateTraininggroup(name, targetUserID, traininggroup.id);
            promise.then((result) => { onClose(); getTraininggroups(); showSnackbar("Tainingsgruppe editiert","success");  })
                .catch((error) => { showSnackbar(error.response.data.error,"error"); });
        }
        else {
            if (adminMode) {
                promise = createTraininggroup(name, targetUserID);
                promise.then((result) => { onClose(); getTraininggroups(); showSnackbar("Tainingsgruppe angelegt","success");})
                    .catch((error) => { showSnackbar(error.response.data.error,"error"); });
            }
            else {
                promise = createMyTraininggroup(name);
                promise.then((result) => { onClose(); getTraininggroups(); showSnackbar("Tainingsgruppe angelegt","success"); })
                    .catch((error) => { showSnackbar(error.response.data.error,"error");});
            }

        }

    }

    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
            setInitialInputValue(users_autocomplete.filter(function (element) { return element.id === traininggroup.owner })[0]);
        })
            .catch(error => { });
    }

    const changeHandler = (event) => {
        setName(event.target.value);
    }

    useEffect(() => {
        if (traininggroup) {
            setName(traininggroup.name)
            setTargetUserID(traininggroup.owner);
        }
        if (open) {
            getUsersList();
        }
        return () => {
            setState(null)
        };
    }, [open]);

    useEffect(() => {
        if (!name) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
    }, [name]);

    return (
        <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>
                {isEdit ? "Traininggruppe bearbeiten" : "Traininggruppe erstellen"}
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Grid container direction="row" rowSpacing={2}>
                    <Grid item xs={12}>
                        <TextField id="txf-traininggroupdialog-name" required label="Name der Trainingsgruppe" fullWidth sx={{ mt: 1 }} value={name} onChange={changeHandler}></TextField>
                    </Grid>
                    {adminMode || (traininggroup && traininggroup.isOwner) ?
                        <Grid item xs={12}>
                            <CustomAutocomplete id="atc-traininggroupdialog-owner" options={users} label={"Besitzer"} setTarget={setTargetUserID} initialInputValue={initialInputValue}></CustomAutocomplete>
                        </Grid>
                        : <></>}
                </Grid>
            </DialogContent>
            <Divider></Divider>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <SecondaryButton onClick={() => { onClose(); }} text={"Abbrechen"} />
                <PrimaryButton id="btn-traininggroupdialog-submit" disabled={submitDisabled} onClick={() => { submitHandler(); }} text={"Bestätigen"} />
            </DialogActions>
        </Dialog>
    )
}

export default AddEditTraininggroupDialog