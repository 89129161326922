import { Alert, AlertTitle, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import { isValid, setMinutes, setSeconds } from 'date-fns';
import React, {useEffect, useState} from 'react'
import { createTraining} from '../api/Trainings/training';
import DateTimeField from './DateTimeField';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { useSnackbar } from './SnackbarContext';

function AddTrainingDialog(props) {
    const {open, onClose, getTrainings, traininggroup} = props;
    const [date,setDate] = useState(setSeconds(setMinutes(new Date(),0),0));
    const [submitDisabled,setSubmitDisabled] = useState(false);
    const {showSnackbar} = useSnackbar();

    const submitHandler =() =>{
        var promise=createTraining(traininggroup.id,setSeconds(date,0));
        promise.then((result)=>{getTrainings(); onClose();showSnackbar("Training erfolgreich erstellt","success")})
        .catch(error => {showSnackbar("Fehler beim Erstellen des Trainings","eror")});
    }

    useEffect(() => {
        if(!isValid(date))
        {
            setSubmitDisabled(true);
        }
        else
        {
            setSubmitDisabled(false);
        }
        return () => {
            
        };
    }, [date]);

  return (
    <>
    <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{backgroundColor: "#eee"}}>
                    {"Training erstellen"}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity='info'>
                                <AlertTitle>Hinweis</AlertTitle>
                                <Typography variant="body2">Trainings wiederholen sich wöchentlich. Das Datum dient nur zur Bestimmung des Wochentags.</Typography>
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimeField label={"Trainingszeit"} date={date} setDate={setDate} textReadOnly minutesStep={30}></DateTimeField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                    <PrimaryButton id="btn-trainingdialog-submit" text={"Bestätigen"} onClick={() => { submitHandler() }} disabled={submitDisabled}></PrimaryButton>
                </DialogActions>
            </Dialog>
    </>
  )
}

export default AddTrainingDialog