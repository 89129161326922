import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

function PrimaryIconButton( {onClickAction=()=>{}, icon, disabled,tooltipTitle,id,...props}) {
  // const { onClickAction, icon, disabled,tooltipTitle,...otherProps } = props;
  return (
    tooltipTitle ?
    <Tooltip title={tooltipTitle} disableTouchListener>
      <IconButton id={id} disabled={disabled} onClick={(e) => { e.stopPropagation(); onClickAction() }} {...props}>{icon}</IconButton>
    </Tooltip>
    :
    <IconButton id={id} disabled={disabled} onClick={(e) => { e.stopPropagation(); onClickAction() }} {...props}>{icon}</IconButton>
  )
}

export default PrimaryIconButton