import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getMailTemplates_api = async () => {
  const sessionData = getSessionData();
  
  return await axios.get(
    process.env.REACT_APP_API_URL + "/templates",
    { headers: sessionData }
  );
};

export const createMailTemplate_api = async (name,content) => {
    const sessionData = getSessionData();
    var data ={
        name: name,
        content: content
    }
    return await axios.post(
      process.env.REACT_APP_API_URL + "/templates",data,
      { headers: sessionData }
    );
  }; 

  export const updateMailTemplate_api = async (id,name,content) => {
    const sessionData = getSessionData();
    var data ={
        name: name,
        content: content
    }
    return await axios.put(
      process.env.REACT_APP_API_URL + "/templates/"+id,data,
      { headers: sessionData }
    );
  }; 

  export const deleteMailTemplate_api = async (id) => {
    const sessionData = getSessionData();
   
    return await axios.delete(
      process.env.REACT_APP_API_URL + "/templates/"+id,
      { headers: sessionData }
    );
  }; 
  

//   export const getMailTemplates_api = async () => {
//     const sessionData = getSessionData();
    
//     return await axios.get(
//       process.env.REACT_APP_API_URL + "/templates",
//       { headers: sessionData }
//     );
//   };

