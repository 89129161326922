import { Event, Group, Launch, Lock } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Divider, Alert, Stack, CardMedia, Tooltip, Chip, Link } from '@mui/material'
import { Box } from '@mui/system'
import { format, formatDistanceToNow, isFuture } from 'date-fns';
import React from 'react'
import InternalLink from '../../components/InternalLink';
import { MYBOOKINGS, MYEVENTS, MYTEAMMATCHES } from '../../navigation/CONSTANTS';
import BorderedIcon from '../../components/BorderedIcon';
import Trophy_Image from '../../assets/trophy.jpg';
import Event_Image from '../../assets/claycourt.jpg';
import Next_Reservation_Image from '../../assets/nextReservation.jpg';
import IndoorCourt from '../../assets/indoorCourt.jpg';
import Next_Match_Image from '../../assets/tennisStadium.jpg';
import { de } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';


function DashboardNextEvents(props) {
    const { nextMatch, nextEvent, nextBooking, settings } = props;
    const navigate = useNavigate();


    return (
        <React.Fragment>
            <Card raised sx={{ borderRadius: 2 }}>
                <CardContent >
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={12} align="left">
                            <Stack direction="row" spacing={3}>
                                <BorderedIcon icon={<Event color="primary"></Event>} />
                                <Typography variant="h6" >{"Anstehende Ereignisse"}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} >
                            <Divider ></Divider>
                        </Grid>

                        <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"} spacing={1} >
                            <Grid item container xs={12} justifyContent={"space-between"}>
                                <Grid item><Typography variant="subtitle2">Buchung</Typography></Grid>
                                {/* <InternalLink linkname="Mehr" route={MYBOOKINGS} /> */}
                                <Launch id="btn-reservationdashboard-link" color="primary" onClick={() => navigate(MYBOOKINGS)}></Launch>
                            </Grid>
                            {/* Next reservation */}
                            {nextBooking && nextBooking.length > 0 ?
                                <Grid item xs={12}>
                                    <Card id="crd-dashboard-reservation" raised sx={{ borderRadius: 2 }}>
                                    
                                        <Box sx={{ position: "relative", width: "100%" }}>
                                            {nextBooking[0].court_type == "indoor" ?
                                                <CardMedia image={IndoorCourt} sx={{ height: "140px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>
                                                :
                                                <CardMedia image={Next_Reservation_Image} sx={{ height: "140px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>
                                            }

                                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                                                <Grid container direction="row" alignItems={"center"} justifyContent={"center"} sx={{ px: 1 }} spacing={2} >
                                                    <Grid item xs>
                                                        <Typography >{nextBooking[0].timestamp && (format(new Date(nextBooking[0].timestamp), "d. MMM", { locale: de }))}</Typography>
                                                        <Typography>{nextBooking[0].timestamp && (format(new Date(nextBooking[0].timestamp), "kk 'Uhr'", { locale: de }))}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography textAlign={"center"} sx={{ fontWeight: "medium" }}>{nextBooking[0].spieler1_name}</Typography>
                                                        {nextBooking[0].spieler2_name ?
                                                            <Typography textAlign={"center"} sx={{ fontWeight: "medium" }}>{nextBooking[0].spieler2_name}</Typography>
                                                            : nextBooking[0].guestname ?
                                                                <Typography textAlign={"center"} sx={{ fontWeight: "regular" }} noWrap>{nextBooking[0].guestname + " (Gast)"}</Typography>
                                                                :
                                                                <Typography textAlign={"center"} sx={{ fontWeight: "regular" }} noWrap>{nextBooking[0].name}</Typography>

                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography textAlign={"right"} sx={{ fontWeight: "regular" }}>{nextBooking[0].court_name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {settings && settings.booking_confirmation == 1 ?
                                                <Box sx={{ position: "absolute", color: "#fff", top: "5px", left: "50%", transform: "translate(-50%)", width: "auto", backgroundColor: nextBooking[0].confirmed ? "rgba(27, 94, 32, .7)" : "rgba(198, 40, 40, .7)", py: 0.25, px: 1, borderRadius: 1.5 }}>
                                                    <Stack direction="row" justifyContent={"center"}>
                                                        <Stack direction="row" spacing={1}><Typography variant="body2" color="white.main" noWrap>{nextBooking[0].confirmed ? "bestätigt" : "unbestätigt"}</Typography> </Stack>
                                                    </Stack>
                                                </Box>
                                                :
                                                <></>
                                            }
                                        </Box>

                                    </Card>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Alert severity="info" id="alt-dashboard-reservation"><Typography> Keine anstehende Reservierung</Typography></Alert>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            <Divider ></Divider>
                        </Grid>
                        <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"} spacing={1} >

                            <Grid item container xs={12} justifyContent={"space-between"}>
                                <Grid item><Typography variant="subtitle2">Punktspiel</Typography></Grid>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* {!newEvent &&
                                        <Skeleton sx={{ backgroundColor: "success.main" }} animation="pulse" variant="circular" width="10px" height="10px"></Skeleton>
                                    } */}
                                    {/* <InternalLink linkname="Mehr" route={MYTEAMMATCHES} /> */}
                                    <Launch color="primary" onClick={() => navigate(MYTEAMMATCHES)}></Launch>
                                </Stack>

                            </Grid>
                            {/* Next Matches */}
                            {nextMatch && nextMatch.length > 0 ?
                                <Grid item xs={12}>
                                    <Card sx={{ borderRadius: 2 }}>
                                        <Box sx={{ position: "relative", width: "100%" }}>
                                            <CardMedia image={Next_Match_Image} sx={{ height: "140px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>
                                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                                                <Grid container direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{ px: 1 }} spacing={2} >
                                                    <Grid item>
                                                        {isFuture(new Date(nextMatch[0].date)) ?
                                                            <Stack>
                                                                <Typography>{nextMatch[0].date && format(new Date(nextMatch[0].date), "dd. MMM yy", { locale: de })}</Typography>
                                                                <Typography>{nextMatch[0].date && format(new Date(nextMatch[0].date), "HH 'Uhr'", { locale: de })}</Typography>
                                                            </Stack>
                                                            :
                                                            <>
                                                                <Typography sx={{ fontWeight: "600" }} color="#fff" variant="h5">{nextMatch[0].liveScoreTeam}</Typography>
                                                                <Typography sx={{ fontWeight: "600" }} color="#fff" variant="h5"> {nextMatch[0].liveScoreOpponent}</Typography>
                                                            </>
                                                        }
                                                    </Grid>

                                                    <Grid item xs zeroMinWidth>
                                                        <Typography textAlign={"center"} noWrap sx={{ fontWeight: "medium" }}>{nextMatch[0].teamName}</Typography>
                                                        <Typography textAlign={"center"} noWrap sx={{ fontWeight: "medium" }}>{nextMatch[0].opponent}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography>{nextMatch[0].teamRanking}. Platz</Typography>
                                                        <Typography>{nextMatch[0].ranking || "-"}. Platz</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box sx={{ position: "absolute", color: "#fff", top: "5px", left: "50%", transform: "translate(-50%)", width: "auto", backgroundColor: nextMatch[0].isParticipant == 1 ? "rgba(27, 94, 32, .7)" : nextMatch[0].isParticipant == 0 ? "rgba(198, 40, 40, .7)" : "rgba(230,81,0,.7)", py: 0.25, px: 1, borderRadius: 1.5 }}>
                                                <Stack direction="row" justifyContent={"center"}>
                                                    <Stack direction="row" spacing={1}><Typography variant="body2" color="white.main" noWrap>{nextMatch[0].date && formatDistanceToNow(new Date(nextMatch[0].date), { locale: de })}</Typography><Typography variant="body2" color="white.main">|</Typography><Typography variant="body2" color="white.main">{nextMatch[0].location && nextMatch[0].location == "(H)" ? "Heim" : "Auswärts"}</Typography><Typography variant="body2" color="white.main"> | </Typography><Typography variant="body2" color="white.main">{nextMatch[0].isParticipant == 1 ? " Angemeldet " : nextMatch[0].isParticipant == 0 ? " Abgemeldet " : " Ausstehend "}</Typography> <Typography variant="body2" color="white.main"> | </Typography><Group fontSize='24px'></Group><Typography variant="body2" color="white.main">{nextMatch[0].totalParticipants}</Typography></Stack>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>

                                :
                                <Grid item xs={12}>
                                    <Alert severity="info" ><Typography> Kein anstehendes Punktspiel</Typography></Alert>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} >
                            <Divider ></Divider>
                        </Grid>
                        <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"} spacing={1} >
                            <Grid item container xs={12} justifyContent={"space-between"}>
                                <Grid item><Typography variant="subtitle2">Event</Typography></Grid>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* {!newEvent &&
                                        <Skeleton sx={{ backgroundColor: "success.main" }} animation="pulse" variant="circular" width="10px" height="10px"></Skeleton>
                                    } */}
                                    {/* <InternalLink linkname="Mehr" route={MYEVENTS} /> */}
                                    <Launch color="primary" onClick={() => navigate(MYEVENTS)}></Launch>
                                </Stack>
                            </Grid>
                            {/* Next Events */}
                            {nextEvent && nextEvent.length > 0 ?
                                <Grid item xs={12}>
                                    <Card raised sx={{ borderRadius: 2 }}>

                                        <Box sx={{ position: "relative", width: "100%" }}>
                                            <CardMedia image={nextEvent[0].type != "Turnier" ? Event_Image : Trophy_Image} sx={{ height: "140px", filter: "grayscale(0.4)" }}></CardMedia>

                                            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                                                <Grid container direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{ px: 1 }} spacing={2} >
                                                    <Grid item>
                                                        <Typography>{nextEvent[0].ende && (format(new Date(nextEvent[0].start), "d. MMM yy", { locale: de }))}</Typography>
                                                        <Typography>{nextEvent[0].ende && (format(new Date(nextEvent[0].start), "kk 'Uhr'", { locale: de }))}</Typography>
                                                    </Grid>
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography textAlign={"center"} sx={{ fontWeight: "medium" }}>{nextEvent[0].name}</Typography>

                                                    </Grid>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={1} >
                                                            <Tooltip title={(nextEvent[0].global ? "Vereinsweites Event" : "Privates Event") + (nextEvent[0].withParticipants === 1 ? " | Teilnehmer" : "")}>
                                                                {nextEvent[0].withParticipants === 1 ?
                                                                    <Group />
                                                                    :
                                                                    <Lock />
                                                                }
                                                            </Tooltip>

                                                            {nextEvent[0].withParticipants === 1 ?
                                                                nextEvent[0].maxRegistrations != 0 && nextEvent[0].maxRegistrations != null && nextEvent[0].maxRegistrations != "" ?
                                                                    <Typography >{nextEvent[0].totalParticipants + "/" + nextEvent[0].maxRegistrations}</Typography>
                                                                    :
                                                                    <Typography >{nextEvent[0].totalParticipants}</Typography>
                                                                :
                                                                <></>
                                                            }

                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box sx={{ position: "absolute", color: "#fff", top: "5px", left: "50%", transform: "translate(-50%)", backgroundColor: nextEvent[0].isParticipant == 1 ? "rgba(27, 94, 32, .7)" : nextEvent[0].isParticipant == 0 ? "rgba(198, 40, 40, .7)" : "rgba(230,81,0,.7)", width: "auto", px: 1, py: 0.25, borderRadius: 1.5 }}>
                                                <Stack direction="row" justifyContent={"center"}>
                                                    {nextEvent[0].withParticipants ?
                                                        // <Chip size="small" color={nextEvent[0].isParticipant == 1 ? "success" : nextEvent[0].isParticipant == 0 ? "error" : "warning"} sx={{ borderRadius: 1 }} label={<Stack direction="row" spacing={1}><Typography variant="body2" color="white.main">{nextEvent[0].start && formatDistanceToNow(new Date(nextEvent[0].start), { locale: de })}</Typography><Typography variant="body2" color="white.main"> | </Typography><Typography variant="body2" color="white.main">{nextEvent[0].isParticipant == 1 ? " Angemeldet " : nextEvent[0].isParticipant == 0 ? " Abgemeldet " : " austehend "}</Typography><Typography variant='body2' color="white.main"> | </Typography><Typography variant='body2' color="white.main">{nextEvent[0].global ? "Vereinsweit" : "Privat"}</Typography></Stack>} />
                                                        <Stack direction="row" spacing={1}><Typography variant="body2" color="white.main" noWrap>{nextEvent[0].start && formatDistanceToNow(new Date(nextEvent[0].start), { locale: de })}</Typography><Typography variant="body2" color="white.main"> | </Typography><Typography variant="body2" color="white.main">{nextEvent[0].isParticipant == 1 ? " Angemeldet " : nextEvent[0].isParticipant == 0 ? " Abgemeldet " : " Ausstehend "}</Typography><Typography variant='body2' color="white.main"> | </Typography><Typography variant='body2' color="white.main">{nextEvent[0].global ? "Vereinsweit" : "Privat"}</Typography></Stack>

                                                        :
                                                        <Stack direction="row" spacing={1}><Typography variant="body2" color="white.main" noWrap>{nextEvent[0].start && formatDistanceToNow(new Date(nextEvent[0].start), { locale: de })}</Typography><Typography variant="body2" color="white.main"> | </Typography><Typography variant="body2" color="white.main" noWrap>{"Ohne Anmeldung"}</Typography><Typography variant='body2' color="white.main"> | </Typography><Typography variant='body2' color="white.main">{nextEvent[0].global ? "Vereinsweit" : "Privat"}</Typography></Stack>
                                                    }

                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Alert severity="info" ><Typography> Kein anstehendes Event</Typography></Alert>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >
    )
}

export default DashboardNextEvents