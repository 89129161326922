import axios from "axios";
import getSessionData from "../../utils/getSession";

export const updateAnalytics_api = async (pathname) => {
  const sessionData = getSessionData();

  var data = {
      pathname: pathname
  }
  
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/analytics",data,
    { headers: sessionData }
  );
};

export const getAnalytics_routes_api = async () => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/routes",
      { headers: sessionData }
    );
  };

  export const getAnalytics_actions_api = async () => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/actions",
      { headers: sessionData }
    );
  };

  export const getAnalytics_objects_api = async () => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/objects",
      { headers: sessionData }
    );
  };

  export const getAnalytics_guests_api = async (year) => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/guests"+"?year="+year,
      { headers: sessionData }
    );
  };

  export const getAnalytics_paid_api = async (year) => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/paidreservations?year="+year,
      { headers: sessionData }
    );
  };

  export const getAnalytics_devices_api = async () => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/devices",
      { headers: sessionData }
    );
  };

  export const exportAnalytics_paid_api = async (year) => {
    const sessionData = getSessionData();
  
    return await axios.get(
      process.env.REACT_APP_API_URL + "/analytics/paidreservations?year="+year+"&format=csv",
      { headers: sessionData }
    );
  };


