import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BorderedIcon from './BorderedIcon';

function FeatureCard({ icon, name, route, ...props }) {
    const navigate = useNavigate();
    return (
        <Card onClick={() => { navigate(route) }} sx={{ borderLeft: 4, borderColor: "primary.main", }}>
            <CardContent sx={{p: 1.5, "&:last-child": {paddingBottom: 1.5}}}>
                <Stack direction="row" sx={{width: "100%"}} justifyContent={"flex-start"} alignItems={"center"} spacing={3}>
                    {/* <Avatar variant="square" sx={{ borderRadius: 2, color: '#1E88E5', bgcolor: "#E3F2FD", width: 56, height: 56 }}>{icon}</Avatar> */}
                    <BorderedIcon icon={icon}/>
                    <Typography  color="text.secondary"  sx={{typography: {xs: "body1", sm: "h6"}, fontWeight: {xs: "medium", sm: "medium"}}}>{name || "Feature"}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default FeatureCard