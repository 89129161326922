import { Stack, Typography } from '@mui/material'
import React from 'react'
import HelpAccordion from './HelpAccordion'

function ArticlesStack({ stackName, articles }) {
    return (
        <Stack>
            <Typography color="secondary" variant="h6" sx={{ py: 2 }}>{stackName}</Typography>
            {articles && articles.length > 0 && articles.map((article, i) => (
                <HelpAccordion summary={article.summary} innerHTML={article.description}></HelpAccordion>
            ))
            }
        </Stack>
    )
}

export default ArticlesStack