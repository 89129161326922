import { EventBusy, EventRepeat, PlayArrow, Stop, Subscriptions, UpdateSharp } from '@mui/icons-material';
import { Alert, AlertTitle, Button, CardContent, Checkbox, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemIcon, ListItemText, Stack, Switch, TextField, Typography } from '@mui/material';
import { addMonths, compareAsc, format, isFuture, isToday } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createSubscription_api, setSubscriptionPause_api, stopSubscription_api } from '../api/Subscription/subscription';
import DynamicExpandIcon from './DynamicExpandIcon';
import { LICENSEAGREEMENT } from '../navigation/CONSTANTS';
import { Link } from 'react-router-dom';

function SubscriptionDialog({ open, onClose, subscription, ...props }) {

    const [duration, setDuration] = useState(-1);
    const [activationDate, setActivationDate] = useState(new Date());
    const [sub, setSub] = useState(subscription && subscription.subscription || "free");
    const [reactivationDate, setReactivationDate] = useState(new Date())
    const [disabled, setDisabled] = useState(false);
    const [expandPause, setExpandPause] = useState(false);
    const [expandCancel, setExpandCancel] = useState(false);
    const [consent, setConsent] = useState(false);
    const [expandInfo, setExpandInfo] = useState(true);


    const handleChangeSubscription = (event) => {
        setSub(event.target.value);
    }

    const handleSubmit = () => {
        var promise;
        if (expandPause) {
            if (duration == 0 && !isFuture(new Date(reactivationDate))) {
                promise = createSubscription_api();
            }
            else {
                promise = setSubscriptionPause_api(reactivationDate);
            }
        }
        else {
            promise = stopSubscription_api();
        }

        promise.then((result) => { onClose(); })
            .catch((error) => { });
    }

    const createNewReactivationDate = (expiryDate, months) => {
        if (expiryDate) {
            setReactivationDate(addMonths(new Date(expiryDate), months));
        }
        else {
            setReactivationDate(addMonths(new Date(), months));
        }
    }

    useEffect(() => {
        if (subscription) {
            setActivationDate(subscription.subscription_expiry_date);
            setReactivationDate(subscription.subscription_reactivation_date);
            setSub(subscription.subscription)
        }
    }, [subscription]);

    useEffect(() => {
        if (!expandCancel && !expandPause) {
            setDisabled(true);
        }
        else if (expandCancel && consent) {
            setDisabled(false);
        }
        else if (sub == "free" || (expandPause && (!isFuture(new Date(reactivationDate)) && !isToday(new Date(reactivationDate))) || compareAsc(new Date(activationDate), new Date(reactivationDate)) > 0) || consent == false) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }

    }, [sub, reactivationDate, expandCancel, expandPause, consent]);

    // useEffect(() => {
    //     if (isToday(new Date(activationDate))) {
    //     }
    //     else {
    //     }

    //     createNewExpiryDate(reactivationDate, duration);
    //     return () => {
    //     };
    // }, [activationDate]);


    useEffect(() => {
        if (expandPause && isFuture(new Date(activationDate))) {
            setReactivationDate(new Date(activationDate));
            setDuration(0);
        }
        else {
            setReactivationDate(addMonths(new Date(activationDate), 1));
            setDuration(1);
        }
        return () => {

        };
    }, [expandPause]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle sx={{ backgroundColor: "#eee" }} >
                Abonnement ändern
            </DialogTitle>
            <Divider></Divider>
            {subscription ?
                <Alert severity="info" action={<DynamicExpandIcon expanded={expandInfo} onClick={() => setExpandInfo(!expandInfo)} />} sx={{ pr: 3 }}>
                    <AlertTitle>Aktuelles Abonnement</AlertTitle>
                    <Collapse in={expandInfo}>
                        <ListItem sx={{ p: 0 }}>
                            <ListItemIcon><Subscriptions color="primary" /></ListItemIcon>
                            <ListItemText primary={subscription && subscription.subscription} secondary={"Lizenz"} />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                            <ListItemIcon><UpdateSharp color="primary" /></ListItemIcon>
                            <ListItemText primary={subscription && subscription.subscription_plan == "active" ? "Fortlaufend" : subscription.subscription_plan == "paused" ? "Pausiert" : "Gekündigt"} secondary={"Abonnement-Status"} />
                        </ListItem>
                        <ListItem sx={{ p: 0 }}>
                            <ListItemIcon>{subscription && subscription.subscription_plan == "active" ? <EventRepeat color="primary" /> : <EventBusy color="primary" />}</ListItemIcon>
                            <ListItemText primary={subscription && format(new Date(subscription.subscription_expiry_date), "dd.MM.yy")} secondary={"Auslaufdatum"} />
                        </ListItem>
                        {subscription.subscription_reactivation_date &&
                            <ListItem sx={{ p: 0 }}>
                                <ListItemIcon>{subscription && subscription.subscription_plan == "active" ? <EventRepeat color="primary" /> : <EventBusy color="primary" />}</ListItemIcon>
                                <ListItemText primary={subscription && format(new Date(subscription.subscription_reactivation_date), "dd.MM.yy")} secondary={"Reaktivierungsdatum"} />
                            </ListItem>
                        }
                    </Collapse>
                </Alert>
                :
                <></>
            }
            <DialogContent sx={{ p: 2 }}>

                <Grid container direction="row" spacing={2}>
                    {!expandCancel ?
                        <>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent="space-between" alignItems={"center"} sx={{ width: "100%" }} onClick={() => { setExpandPause(!expandPause); setExpandCancel(false); }}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        <PlayArrow color="primary"></PlayArrow>
                                        <Stack>
                                            <Typography variant="body1" fontWeight={"Medium"}>{subscription && subscription.subscription_plan == "active" ? "Abonnement pausieren" : "Abonnement fortführen"}</Typography>
                                            <Typography variant="body2" fontWeight={"Regular"}>Soll das Abonnement wieder aktiviert oder pausiert werden?</Typography>
                                        </Stack>
                                    </Stack>
                                    <Switch checked={expandPause} value={expandPause} onChange={(event) => { setExpandPause(event.target.checked); setExpandCancel(false); }}></Switch>
                                </Stack>
                            </Grid>


                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>

                            <Collapse in={expandPause} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Stack spacing={2}>
                                                <DatePicker size="small" label="Reaktivierungsdatum" value={new Date(reactivationDate)} inputFormat="dd/MM/yyyy" disablePast onChange={(newValue) => { setReactivationDate(newValue); setDuration(-1) }} renderInput={(params) => <TextField {...params} />}></DatePicker>
                                                <Stack direction="row" spacing={1}>
                                                    {isFuture(new Date(activationDate)) ? <Chip label="Keine Pause" color={duration == 0 ? "primary" : "default"} onClick={() => { setReactivationDate(new Date(activationDate)); setDuration(0) }} sx={{ width: "fit-content" }}></Chip> :
                                                        <Chip label="Heute fortführen" color={duration == 0 ? "primary" : "default"} onClick={() => { setReactivationDate(new Date()); setDuration(0) }} sx={{ width: "fit-content" }}></Chip>}
                                                    <Chip label="1 Monat" color={duration == 1 ? "primary" : "default"} onClick={() => { createNewReactivationDate(activationDate, 1); setDuration(1) }}></Chip>
                                                    <Chip label="6 Monat" color={duration == 6 ? "primary" : "default"} onClick={() => { createNewReactivationDate(activationDate, 6); setDuration(6) }}></Chip>
                                                    <Chip label="1 Jahr" color={duration == 12 ? "primary" : "default"} onClick={() => { createNewReactivationDate(activationDate, 12); setDuration(12) }}></Chip>
                                                </Stack>
                                            </Stack>
                                        </Grid>

                                        {duration != -1 && expandPause &&
                                            <Grid item xs={12}>
                                                <Alert severity={compareAsc(new Date(activationDate), new Date(reactivationDate)) < 0 && duration != 0 ? "warning" : "success"}>
                                                    <AlertTitle>{compareAsc(new Date(activationDate), new Date(reactivationDate)) < 0 && duration != 0 ? "Abonnement pausieren" : "Abonnement fortführen"}</AlertTitle>
                                                    <Typography>{compareAsc(new Date(activationDate), new Date(reactivationDate)) < 0 && duration != 0 ? "Das Abonnement wird pausiert! Zum Reaktivierungdsatum wird automatisch eine kostenpflichtige Lizenz (1 Monat) erworben" : "Das Abonnement wird fortgeführt. Zum Auslaufdatum wird automatisch eine neue kostenpflichtige Lizenz (1 Monat) erworben."}</Typography>
                                                </Alert>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardContent>
                            </Collapse>
                        </>
                        :
                        <></>
                    }
                    {
                        subscription && (subscription.subscription_plan == "active" || subscription.subscription_plan == "paused") && !expandPause ?
                            <>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between" alignItems={"center"} sx={{ width: "100%" }}>
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <Stop color="primary"></Stop>
                                            <Stack>
                                                <Typography variant="body1" fontWeight={"Medium"}>Kündigen</Typography>
                                                <Typography variant="body2" fontWeight={"Regular"}>Soll das Abonnement gekündigt werden?</Typography>
                                            </Stack>
                                        </Stack>
                                        <Switch checked={expandCancel} value={expandCancel} onChange={(event) => { setExpandCancel(event.target.checked); setExpandPause(false); }}></Switch>
                                    </Stack>

                                    <Collapse in={expandCancel}>
                                        <Grid item xs={12}>
                                            <CardContent>
                                                <Alert severity={"error"}>
                                                    <AlertTitle>Abonnement kündigen</AlertTitle>
                                                    <Typography variant="body2">{"Das Abonnement wird gekündigt. Das Portal ist ab dem Auslaufdatum nicht mehr nutzbar. Es werden keine Lizenzen automatisch erworben!"}</Typography>
                                                </Alert>
                                            </CardContent>
                                        </Grid>
                                    </Collapse>
                                </Grid>
                            </>
                            : <></>
                    }

                    {(expandCancel || expandPause) &&
                        <>
                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">AGB</Typography></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2} sx={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography variant="body2">Ich akzeptiere die <Link to={LICENSEAGREEMENT} target="_blank">allgemeinen Geschäftsbedingungen (AGB)</Link></Typography>
                                    <Checkbox checked={consent} onChange={(event) => setConsent(event.target.checked)}></Checkbox>
                                </Stack>
                            </Grid>
                        </>
                    }
                </Grid>

            </DialogContent>
            <Divider></Divider>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button variant="outlined" onClick={onClose} sx={{ textTransform: "none" }}>Abbrechen</Button>
                <Button variant="contained" disabled={disabled} sx={{ textTransform: "none" }} onClick={() => { handleSubmit(); }}>{expandCancel ? "Abonnement kündigen" : "kostenpflichtiges Abonnement abschließen"}</Button>
            </DialogActions>
        </Dialog >
    )
}

export default SubscriptionDialog