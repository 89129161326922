import { Add, Event, GridView, ViewListSharp } from '@mui/icons-material'
import { Container, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getPosts_api } from '../../../api/Posts/post'
import AddEditPostDialog from '../../../components/AddEditPostDialog'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Header'
import PostCardAdm from '../../../components/PostCardAdm'
import PrimaryButton from '../../../components/PrimaryButton'
import TitleCard from '../../../components/TitleCard'
import LightPostCard from '../../../components/LightPostCard'
import MainContentContainer from '../../../components/MainContentContainer'

function AdmPostContainer() {
    const [openAddEditPost, setOpenAddEditPost] = useState(false);

    const [posts, setPosts] = useState();
    const [selectedPost, setSelectedPost] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [view, setView] = useState(false);


    const handleView = (event, newView) => {
        setView(newView);
    }


    const getPosts = () => {

        var promise = getPosts_api();
        promise.then((result) => { setPosts(result.data); })
            .catch((error) => { });
    }

    const openEditPost = (post) => {
        setSelectedPost(post);
        setIsEdit(true);
        setOpenAddEditPost(true);
    }


    useEffect(() => {
        getPosts();
        return () => {
        };
    }, []);

    useEffect(() => {

        return () => {

        };
    }, [openAddEditPost]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-blogmanagement" title={"Blog Beiträge"} subheader={"Verwalte Stories, Ankündigungen und vieles mehr!"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" columnSpacing={2} justifyContent="space-between">
                            <Grid item>
                                <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small">
                                    <ToggleButton value={false}> <GridView /></ToggleButton>
                                    <ToggleButton value={true}> <ViewListSharp /></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <PrimaryButton text="Neuer Beitrag" startIcon={<Add/>} onClick={() => { setOpenAddEditPost(true); }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"flex-start"} spacing={1}>
                        {posts && posts.map((post, i) => (
                            view ?
                                <Grid item xs={12} key={i}>
                                    < PostCardAdm post={post} getPosts={() => { getPosts() }} openEditPost={openEditPost} />
                                </Grid>
                                :
                                <Grid item xs={12} md={6} key={i}>
                                    <LightPostCard post={post} getPosts={() => { getPosts() }} openEditPost={openEditPost} />
                                </Grid>
                        ))
                        }
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer />
            <AddEditPostDialog open={openAddEditPost} onClose={() => { setOpenAddEditPost(false); setIsEdit(false); }} isEdit={isEdit} post={selectedPost} getPosts={getPosts} />
        </>
    )
}

export default AdmPostContainer