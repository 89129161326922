import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getSurveys_api = async (query = "") => {
  const sessionData = getSessionData();

  return await axios.get(process.env.REACT_APP_API_URL + "/surveys?text__like=" + query, {
    headers: sessionData,
  });
};

export const getMySurveys_api = async (query = "") => {
  const sessionData = getSessionData();

  return await axios.get(process.env.REACT_APP_API_URL + "/mysurveys", {
    headers: sessionData,
  });
};

export const createSurvey_api = async (survey) => {
  const sessionData = getSessionData();

  var data = {
    survey: survey,
  };
  return await axios.post(process.env.REACT_APP_API_URL + "/surveys", data, {
    headers: sessionData,
  });
};

export const createMySurvey_api = async (survey) => {
  const sessionData = getSessionData();

  var data = {
    survey: survey,
  };
  return await axios.post(process.env.REACT_APP_API_URL + "/mysurveys", data, {
    headers: sessionData,
  });
};

//remove users vote from survey
export const removeVote_api = async (surveyID) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/surveys/" + surveyID + "/votes",
    { headers: sessionData }
  );
};

export const removeAllVotes_api = async (surveyID) => {
  const sessionData = getSessionData();

  var data= {};
  return await axios.put(
    process.env.REACT_APP_API_URL + "/surveys/" + surveyID + "/votes",data,
    { headers: sessionData }
  );
};

//set users vote to survey
export const submitVotes_api = async (surveyID, choiceIDs) => {
  const sessionData = getSessionData();

  var data = {
    choices: choiceIDs,
  };
  return await axios.post(
    process.env.REACT_APP_API_URL + "/surveys/" + surveyID + "/votes",
    data,
    { headers: sessionData }
  );
};

export const deleteSurvey_api = async (surveyID) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/surveys/" + surveyID ,
    { headers: sessionData }
  );
};

export const getSurveyParticipants_api = async (surveyID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/surveys/" + surveyID +"/participants" ,
    { headers: sessionData }
  );
};

export const updateSurvey_api = async (survey) => {
  const sessionData = getSessionData();

  var data = {
    survey: survey
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/surveys/"+survey.survey_id,data,
    { headers: sessionData }
  );
};

export const updateMySurvey_api = async (survey) => {
  const sessionData = getSessionData();

  var data = {
    survey: survey
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/mysurveys/"+survey.survey_id,data,
    { headers: sessionData }
  );
};