import { Container, Toolbar } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { getAnalytics_devices_api } from '../../../api/Analytics/analytics';
import { getClosedCourts_api, getCourts_api, getCourtsCounts_api } from '../../../api/Courts/courts';
import { getHealth_api } from '../../../api/Health/health';
import { getLogsFiltered } from '../../../api/Log/log';
import { getActiveUsers_api, getCount_api } from '../../../api/Users/users';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header/Header'
import AdmActiveUserCard from './AdmActiveUserCard';
import AdmClientDeviceCard from './AdmClientDeviceCard';
import AdmCourtStatus from './AdmCourtStatus';
import AdmHealthCard from './AdmHealthCard';
import AdmLastLogsCard from './AdmLastLogsCard';
import Masonry from '@mui/lab/Masonry';
import AdmSubscriptionCard from './AdmSubscriptionCard';
import { getSubscriptionData_api } from '../../../api/Subscription/subscription';
import AdmTeammatchesCard from './AdmTeammatchesCard';
import { getTeammachtesHomeGrouped_api, getTeammatchActionRequired_api, getTeammatchReservationStatus_api } from '../../../api/Teammatches/Teammatches';
import AdmTeammsCard from './AdmTeamsCard';
import { getClubInfo_api } from '../../../api/Club/club';
import AdmNuligaInterfaceCard from './AdmNuligaInterfaceCard';
import MainContentContainer from '../../../components/MainContentContainer';




function AdmDashboardContainer() {

  const [activeUsers, setActiveUsers] = useState([]);
  const [courts, setCourts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [health, setHealth] = useState();
  const [devices, setDevices] = useState();
  const [subscription, setSubscription] = useState();
  const [userCounts, setUserCounts] = useState(null);
  const [courtCounts, setCourtCounts] = useState(null);
  const [nextHomematches, setNextHomematches] = useState();
  const [teammacthReservationStats, setTeammatchReservationsStats] = useState();
  const [clubInfo,setClubInfo] = useState();

  const getUserCounts = () => {
    var promise = getCount_api();
    promise.then((result) => { setUserCounts(result.data[0]) })
      .catch((error) => { });
  }

  const getActiveUsers = () => {
    var promise = getActiveUsers_api();
    promise.then((result) => { setActiveUsers(result.data) })
      .catch((error) => { });
  }

  const getClosedCourts = () => {
    var promise = getClosedCourts_api();
    promise.then((result) => { setCourts(result.data) })
      .catch((error) => { });
  }

  const getCourts = () => {
    var promise = getCourts_api();
    promise.then((result) => { setCourts(result.data) })
      .catch((error) => { });
  }

  const getCourtCounts = () => {
    var promise = getCourtsCounts_api();
    promise.then((result) => { setCourtCounts(result.data[0]) })
      .catch((error) => { });
  }

  const getLastLogs = (date) => {
    var promise = getLogsFiltered("reg_date__gte=" + date);
    promise.then((result) => { setLogs(result.data) })
      .catch((error) => { });
  }

  const getHealth = () => {
    var promise = getHealth_api();
    promise.then((result) => { setHealth(result.data) })
      .catch();
  }

  const getDevices = () => {
    var promise = getAnalytics_devices_api();
    promise.then((result) => { setDevices(result.data) })
      .catch();
  }

  const getSubscription = () => {
    var promise = getSubscriptionData_api();
    promise.then((result) => { setSubscription(result.data[0]); })
      .catch();
  }

  const getTeammatchesHomeGrouped = () => {
    var promise = getTeammachtesHomeGrouped_api("limited");
    promise.then((result) => { setNextHomematches(result.data); })
      .catch(() => { });
  }

  const getTeammatchesActionRequired= () => {
    var promise = getTeammatchActionRequired_api();
    promise.then((result) => { setNextHomematches(result.data); })
      .catch(() => { });
  }
  const getTeammatchesReservationStats = () => {
    var promise=getTeammatchReservationStatus_api()
    promise.then((result) => setTeammatchReservationsStats(result.data))
    .catch();
  }

  const getClubInfo = () => {
    var promise = getClubInfo_api();
    promise.then((result)=>setClubInfo(result.data))
    .catch();
  }

  useEffect(() => {
    getCourtCounts();
    getUserCounts();
    getActiveUsers();
    getCourts();
    getLastLogs(date);
    //getHealth();
    getDevices();
    getSubscription();
    // getTeammatchesHomeGrouped();
    getTeammatchesReservationStats();
    getTeammatchesActionRequired();
    getClubInfo();
    return () => {
    };
  }, []);
  return (
    <>
      <Header></Header>
      <Toolbar></Toolbar>
      <MainContentContainer px={0} py={0}>
        <Masonry columns={{ xs: 1, sm: 2, xl: 2 }} spacing={3} sx={{ width: "auto", m: 0 }}>
          <AdmSubscriptionCard subscription={subscription && subscription} getSubscription={getSubscription} />
          <AdmActiveUserCard users={activeUsers} counts={userCounts} />
          <AdmLastLogsCard logs={logs} date={date} />
          <AdmCourtStatus courts={courts} counts={courtCounts} />
          {/* <AdmHealthCard healthdata={health} /> */}
          <AdmTeammatchesCard matches={nextHomematches} stats={teammacthReservationStats}></AdmTeammatchesCard>
          {/* <AdmTeammsCard></AdmTeammsCard> */}
          {/* <AdmClientDeviceCard devices={devices}></AdmClientDeviceCard> */}
          <AdmNuligaInterfaceCard club={clubInfo}></AdmNuligaInterfaceCard>
        </Masonry>
        </MainContentContainer>
      <Footer />
    </>
  )
}

export default AdmDashboardContainer