import { Card, CardMedia, Chip, Grid, Link, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { format, formatDistanceToNow, isFuture } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react'
import PrimaryIconButton from './PrimaryIconButton';
import { Upgrade } from '@mui/icons-material';


function LiveMatchCard(props) {

    const { match, setOpen, setTargetMatch } = props;
    return (
        <Card sx={{ borderRadius: 2 }}>
            <Box sx={{ position: "relative", width: "100%" }}>
                <CardMedia image={"https://media.myclubportal.de/tennis-court-render-3d-illustrationV2.jpg"} sx={{ height: "150px", filter: "brightness(130%) grayscale(0.4)" }}></CardMedia>
                <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                    <Grid  container direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{px:1}} spacing={2} >
                        <Grid item>
                            <Typography>{match.teamRanking}. Platz</Typography>
                            <Typography>{match.ranking || "-"}. Platz</Typography>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography textAlign={"center"} noWrap sx={{ fontWeight: "600" }}>{match.teamName}</Typography>
                            <Typography textAlign={"center"} noWrap>{match.opponent}</Typography>
                        </Grid>
                        <Grid item>
                            {isFuture(new Date(match.date)) ?
                                <Stack>
                                    <Typography>{match.date && format(new Date(match.date), "dd. MMM yy", { locale: de })}</Typography>
                                    <Typography>{match.date && format(new Date(match.date), "HH 'Uhr'", { locale: de })}</Typography>
                                </Stack>
                                :
                                <>
                                    <Typography sx={{ fontWeight: "600" }} color="#fff" variant="h5">{match.liveScoreTeam}</Typography>
                                    <Typography sx={{ fontWeight: "600" }} color="#fff" variant="h5"> {match.liveScoreOpponent}</Typography>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ position: "absolute", color: "#fff", top: "5px", left: "10px", width: "calc(100% - 8px)" }}>
                    <Stack direction="row" justifyContent={"center"}>
                        {isFuture(new Date(match.date)) ?
                            <Chip size="small" color="info" sx={{ borderRadius: 1 }} label={<Stack direction="row" spacing={1}><Typography variant="body2">{match.date && formatDistanceToNow(new Date(match.date), { locale: de })}</Typography><Typography variant="body2"> | </Typography><Link href={match.leagueLink + " "} target="_blank" rel="noreferrer" underline="none"><Typography variant="body2" color="#fff" sx={{ textDecoration: "underline", textDecorationColor: "#fff" }}>{match.league}</Typography></Link> <Typography variant="body2">|</Typography><Typography variant="body2">{match.location && match.location == "(H)" ? "Heim" : "Auswärts"}</Typography> </Stack>} />
                            :
                            <Chip size="small" sx={{ animation: "pulse", borderRadius: 1 }} color="success" label={<Stack direction="row" spacing={1}><Typography variant="body2" color="#fff">Live</Typography><Typography variant="body2" color="#fff"> | </Typography><Link href={match.leagueLink} target="_blank" rel="noreferrer"><Typography variant="body2" color="#fff" sx={{ textDecoration: "underline", textDecorationColor: "#fff" }}>{match.league}</Typography></Link> </Stack>} />
                        }
                    </Stack>
                </Box>
                {isFuture(new Date(match.date)) ?
                    <></>
                    :
                    <PrimaryIconButton size="small" sx={{ position: "absolute", top: "5px", right: "10px", backgroundColor: "primary.main" }} icon={<Upgrade color="white"></Upgrade>} onClickAction={() => { setTargetMatch(match); setOpen(true) }}></PrimaryIconButton>
                }
            </Box>
        </Card >
    )
}

export default LiveMatchCard