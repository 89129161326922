import { Card, CardContent, Divider, Grid, Link, List, Typography, Alert, Chip } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import SimpleListItem from '../../../components/SimpleListItem';
import { Launch } from '@mui/icons-material';
import PrimaryIconButton from '../../../components/PrimaryIconButton';
import { LOG } from '../../../navigation/CONSTANTS';

function AdmLastLogsCard(props) {
    const { logs, date } = props;

    const navigate = useNavigate();
    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                {/* <CardHeader title={"Platz Status"} action={<PrimaryButton text="Status ändern" />} /> */}
                <CardContent>
                    <Grid container>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item xs align="left">
                                <Typography variant="h6" >{"Aktivitäten - Heute (" + logs.length + ")"}</Typography>
                            </Grid>
                            <Grid item align="right">
                                <PrimaryIconButton icon={<Launch color="primary"></Launch>} onClickAction={() => navigate(LOG + "?reg_date__gte=" + date)} tooltipTitle="Öffne Logs"></PrimaryIconButton>
                            </Grid>
                            <Grid item xs={12} sx={{mt: -1}}>
                                <Divider></Divider >
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    {logs && logs.length > 0 ?
                                        logs.slice(0, 5).map((log, i) => (
                                            <SimpleListItem key={i} divider object={log.action} valueIcon={log.https_status == "200" ? <Chip label={log.https_status} color="success" variant="primary" sx={{ color: "#fff" }} /> : <Chip label={log.https_status} color="error" variant="primary" sx={{ color: "#fff" }} />} icon={<Typography color="primary" sx={{ fontWeight: "bold" }}>{log.userid}</Typography>}></SimpleListItem>
                                        ))
                                        :
                                        <Alert severity="info"><Typography>Keine Aktivitäten vorhanden</Typography></Alert>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default AdmLastLogsCard