import { Dialog, DialogActions } from '@mui/material';
import React from 'react'
import TertiaryButton from './TertiaryButton';
import { ChevronLeft } from '@mui/icons-material';
import BookingContainer from '../pages/booking/BookingContainer';
import PrimaryButton from './PrimaryButton';

function ReservationDialog({open=false,onClose,dateProp,originName,courtTypeProp,setStartdate, endtime,targetCourts,setTargetCourts,setEnddate,adminMode,serieId}) {
  return (
  
    <Dialog open={open} onClose={onClose} fullScreen>
       <PrimaryButton size="large" fullWidth color="warning" text={originName} onClick={onClose}/>
      <DialogActions sx={{justifyContent: "start"}}>
       
      </DialogActions>
      <BookingContainer  serieId={serieId} setTargetCourts={setTargetCourts} adminMode={adminMode} dateProp={dateProp} endtime={endtime} setEnddate={setEnddate} targetCourts={targetCourts} dialogView courtTypeProp={courtTypeProp} isShortText setStartdate={setStartdate}></BookingContainer>
    </Dialog>
  );
 
}

export default ReservationDialog