import { Alert, Box, Collapse, Container, Grid, Snackbar, Tabs, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getMyTraininggroups } from '../../api/Traininggroups/traininggroups'
import Header from '../../components/Header/Header'
import TitleCard from '../../components/TitleCard'
import TraininggroupCard from '../../components/TraininggroupCard'
import PrimaryButton from '../../components/PrimaryButton'
import AddTraininggroupDialog from '../../components/AddEditTraininggroupDialog'
import { getTrainingsFromUser_api } from '../../api/Trainings/training'
import TrainingCard from '../../components/TrainingCard'
import Footer from '../../components/Footer'
import MyTab from '../../components/MyTab'
import MainContentContainer from '../../components/MainContentContainer'

function MyTraininggroupsContainer() {
    const [traininggroups, setTraininggroups] = useState(null);
    const [trainings, setTrainings] = useState(null);
    const [info, setInfo] = useState(true);

    //tabs
    const [tabValue, setTabValue] = useState("training");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }


    //dialog
    const [openAddTraininggroupDialog, setOpenAddTraininggroupDialog] = useState(false);
    const getTraininggroups = () => {
        var promise = getMyTraininggroups();
        promise.then((result) => { setTraininggroups(result.data); })
            .catch((error) => { });
    }

    const getTrainings = () => {
        var promise = getTrainingsFromUser_api();
        promise.then((result) => { setTrainings(result.data) })
            .catch((error) => { });
    }

    useEffect(() => {
        getTraininggroups()
        getTrainings();
        return () => {
        };
    }, []);
    return (
        <>
            <Header></Header>
            <Grid container direction="row" justifyContent="center" rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} >
                    <TitleCard id="crd-title-mytraininggroups" title="Deine Trainingsgruppen" subheader="Erstelle Gruppen und Trainings!"></TitleCard>
                </Grid>
            </Grid>
           <MainContentContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                    <Tabs value={tabValue} onChange={handleTabChange}>
                                        <MyTab label="Training" value="training" />
                                        <MyTab label="Gruppen" value="groups" />
                                    </Tabs>
                                </Box>
                            </Grid>
                            {tabValue === "groups" && <Grid item xs={12}><PrimaryButton text={"Erstellen"} onClick={() => { setOpenAddTraininggroupDialog(true); }} /></Grid>}
                        </Grid>
                    </Grid>
                    {/* {tabValue === "training" &&
                        <Grid item xs={12} md={12}>
                            <Snackbar open={info} autoHideDuration={5000} anchorOrigin={{vertical:'top',horizontal: 'center' }} onClose={() => setInfo(false)}>
                                <Alert severity='info' variant="standard" onClose={() => { setInfo(false) }} ><Typography>Trainings können vom Besitzer einer Trainingsgruppe erstellt werden!</Typography></Alert>
                            </Snackbar>
                        </Grid>
                    } */}
                    {tabValue === "groups" ?
                        traininggroups && traininggroups.length > 0 ? traininggroups.map((traininggroup, i) => (
                            <Grid item xs={12} md={6} key={i}>
                                <TraininggroupCard traininggroup={traininggroup} getTraininggroups={getTraininggroups} adminMode={false} />
                            </Grid>
                        ))
                            :
                            <Grid item xs={12}>
                                <Alert severity='info' variant="filled"><Typography>Du bist in keiner Traininggruppe! Erstelle eine Traininggruppe und verwalte die Anwesenheit bei Trainings</Typography></Alert>
                            </Grid>
                        :
                        trainings && trainings.length > 0 ? trainings.map((training, i) => (
                            <Grid item xs={12} md={6} key={i}>
                                <TrainingCard getTrainings={getTrainings} training={training} adminMode={false}></TrainingCard>
                            </Grid>
                        ))
                            :
                            <Grid item xs={12}>
                                <Alert severity='info' variant="filled"><Typography>Es gibt keine Trainings</Typography></Alert>
                            </Grid>
                    }
                </Grid>
                </MainContentContainer>
            <Footer />
            <AddTraininggroupDialog open={openAddTraininggroupDialog} onClose={() => { setOpenAddTraininggroupDialog(false); }} getTraininggroups={getTraininggroups} ></AddTraininggroupDialog>
        </>
    )
}

export default MyTraininggroupsContainer