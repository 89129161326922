import { Ballot, EmojiEvents, Feed, GroupAddSharp, LiveHelp, LocationOn, NotificationAdd, PersonAdd, SportsTennis } from '@mui/icons-material';
import React from 'react'
import { BLOG, MYBOOKINGS, MYEVENTS, MYREQUESTS, MYSURVEYS, MYTEAM, MYTEAMCONFIRMATION, MYTEAMMATCHES, USERS } from '../../navigation/CONSTANTS';

function NotificationIconSwitch(props) {

    const [state, setState] = React.useState();

    React.useEffect(() => {
        switch (props.type) {
            case "Blog":
                props.setRoute(BLOG);
                break;

            case "Reservation":
                props.setRoute(MYBOOKINGS);
                break;

            case "Event":
                props.setRoute(MYEVENTS);
                break;

            case "Registration":
                props.setRoute(USERS + "?tab=registrations");
                break;

            case "Teammatch":
                props.setRoute(MYTEAMMATCHES);
                break;
            case "TeammatchRequest":
                props.setRoute(MYREQUESTS);
                break;
            case "Survey":
                props.setRoute(MYSURVEYS);
                break;
            case "TeamInvitation":
                if (props.target) {
                    props.setRoute(MYTEAM + "/invitation/" + props.target);
                }
                else {
                    props.setRoute(MYTEAMCONFIRMATION);
                }
                break;
            default:
                props.setRoute("/");
                break;
        }
        return () => {
        };
    }, [props.type]);

    switch (props.type) {
        case "Blog":
            return (<Feed color={props.seen ? "#bbbbbb" : "warning"}></Feed>);

        case "Reservation":
            return (<LocationOn color={props.seen ? "#bbbbbb" : "primary"}></LocationOn>);

        case "Event":
            return (<EmojiEvents color={props.seen ? "#bbbbbb" : "secondary"}></EmojiEvents>);

        case "Registration":
            return (<PersonAdd color={props.seen ? "#bbbbbb" : "primary"}></PersonAdd>);

        case "Teammatch":
            return (<SportsTennis color={props.seen ? "#bbbbbb" : "primary"}></SportsTennis>);

        case "TeammatchRequest":
            return (<LiveHelp color={props.seen ? "#bbbbbb" : "primary"}></LiveHelp>);

        case "Survey":
            return (<Ballot color={props.seen ? "#bbbbbb" : "success"} />);

        case "TeamInvitation":
            return (<GroupAddSharp color={props.seen ? "#bbbbbb" : "success"} />);

        default:
            return (<NotificationAdd ></NotificationAdd>);

    }
}

export default NotificationIconSwitch