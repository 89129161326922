import { Clear } from '@mui/icons-material';
import { Alert, Card, CardContent, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import AvatarInitials from './AvatarInitials';


function TeamMemberCard(props) {
    const { team, teammates, deleteHandler } = props;
    const isAdmin = useSelector((state)=>state.isAdmin);

    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                <CardContent>
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item xs={12} align="left">
                            <Typography variant="h6">Mitglieder</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Divider></Divider>
                        </Grid>
                    </Grid>
                    <List dense>
                        {teammates && teammates.length > 0 ? teammates.map((teammate, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar><AvatarInitials firstname={teammate.firstname} lastname={teammate.surname}></AvatarInitials></ListItemAvatar>
                                {/* <ListItemAvatar><Person color="primary"/></ListItemAvatar> */}
                                <ListItemText primary={teammate.firstname + " " + teammate.surname} secondary={"LK: "+teammate.nuliga_lk} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                </ListItemText>
                                {team.isCaptain === 1 || isAdmin === true ? <IconButton onClick={(e) => { e.stopPropagation(); deleteHandler(teammate.user_id); }} sx={{ ml: 2 }}><Clear></Clear> </IconButton> : <></>}
                            </ListItem>
                        ))
                            :
                            <Alert severity="info"><Typography>Es gibt keine Teammitglieder</Typography></Alert>
                        }
                    </List>
                </CardContent>
            </Card>
        </>
    )
}




export default TeamMemberCard