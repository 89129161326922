import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Step, StepContent, StepLabel, Stepper, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Clear, Delete, DragIndicator, Groups } from '@mui/icons-material';
import React, { useState, useEffect } from 'react'
import PrimaryIconButton from './PrimaryIconButton';
import TertiaryButton from './TertiaryButton';
import { createMySurvey_api, createSurvey_api, getSurveyParticipants_api, updateMySurvey_api, updateSurvey_api } from '../api/Surveys/surveys';
import PrimaryButton from './PrimaryButton';
import CustomAutocomplete from './CustomAutocomplete';
import AvatarInitials from './AvatarInitials';
import { getUsersNames } from '../api/Users/users';
import TeamSelect from './TeamSelect';
import { useSelector } from 'react-redux';
import TeammatchAssignments from './TeammatchAssignments';
import { useSnackbar } from './SnackbarContext';
import SecondaryButton from './SecondaryButton';

function AddEditSurveyDialog(props) {
    const { open, onClose, isEdit, getSurveys, selectedSurvey, targetTeamsArgs = [], assignedTeammatchArgs, adminMode } = props;
    const [surveyQuestion, setSurveyQuestion] = useState("");
    const [multiple, setMultiple] = useState(false);
    const [choices, setChoices] = useState([{ "text": "" }]);
    const [global, setGlobal] = useState(false);
    const [anonymous, setAnonymous] = useState(false);

    const [targetUsers, setTargetUsers] = useState([]);
    const [users, setUsers] = useState([{}]);

    const [activeStep, setActiveStep] = useState(0);
    const [activeTab, setActiveTab] = useState("users");
    const [teamid, setTeamid] = useState("");
    const [targetTeams, setTargetTeams] = useState(null);
    const [step1IsError, setStep1IsError] = useState(false);
    const [step2IsError, setStep2IsError] = useState(false);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [deletedTeams, setDeletedTeams] = useState([]);
    const [deletedChoices, setDeletedChoices] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    //TODO: filter already assigned from api 
    const [assignments, setAssignments] = useState(null);

    const isAdmin = useSelector((state) => state.isAdmin);

    const { showSnackbar } = useSnackbar();


    //autocomplete
    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, firstname: result.data[i].firstname, lastname: result.data[i].surname, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }

    const addTargetUser = (targetUser) => {
        if (targetUser && !targetUsers.includes(targetUser)) {

            setTargetUsers(targetUsers => {
                return [...targetUsers, targetUser];
            })
        }
    }

    const removeTargetUser = (index) => {
        //add user to deleted users
        setDeletedUsers(deletedUsers => {
            return [...deletedUsers, targetUsers[index]];
        })
        //remove user
        setTargetUsers([
            ...targetUsers.slice(0, index),
            ...targetUsers.slice(index + 1)
        ]);
    }

    const addTeam = (targetTeam) => {
        setTeamid(targetTeam.id);
        if (!targetTeams.includes(targetTeam)) {
            setTargetTeams(teams => {
                return [...teams, targetTeam];
            })
        }
    }

    const removeTeam = (index) => {
        //add team to deleted teams
        setDeletedTeams(deletedTeams => {
            return [...deletedTeams, targetTeams[index]];
        })
        //remove team
        setTargetTeams([
            ...targetTeams.slice(0, index),
            ...targetTeams.slice(index + 1)
        ]);
    }


    const addChoice = () => {
        var choice = { "text": "" };
        setChoices(choices => {
            return [...choices, choice]
        });
    }

    const removeChoice = (index) => {
        //add choice to deleted choices
        setDeletedChoices(deletedChoices => {
            return [...deletedChoices, choices[index]];
        })

        setChoices(choices => {
            return [
                ...choices.slice(0, index),
                ...choices.slice(index + 1)
            ]
        })
    }

    const changeHandler = (event) => {
        switch (event.target.name) {
            case "survey":
                setSurveyQuestion(event.target.value);
                break;
            default:
                break;
        }
    }

    const changeHandlerChoices = (event, i) => {
        setChoices(() => {
            return [
                ...choices.slice(0, i),
                { ...choices[i], "text": event.target.value },
                ...choices.slice(i + 1)
            ]
        });
    }

    const createSurvey = () => {
        var survey = {}
        var promise = null;
        if (surveyQuestion == "" || surveyQuestion == null || choices.length <= 1) {
            setStep1IsError(true);
        }
        else if (!global && targetTeams.length == 0 && targetUsers.length == 0) {
            setStep2IsError(true);
        }
        else {
            setStep1IsError(false);
            setStep2IsError(false);
            if (isAdmin) {
                survey = { surveyQuestion, multiple, choices, global, anonymous, targetUsers, targetTeams, assignments }
                promise = createSurvey_api(survey);
                promise.then((result) => { getSurveys(); onClose(); showSnackbar("Umfrage erfolgreich erstellt", "success"); })
                    .catch((error) => { showSnackbar("Fehler beim Erstellen der Umfrage", "error") });
            }
            else {
                survey = { surveyQuestion, multiple, choices, anonymous, targetUsers, targetTeams, assignments }
                promise = createMySurvey_api(survey);
                promise.then((result) => { getSurveys(); onClose(); showSnackbar("Umfrage erfolgreich erstellt", "success"); })
                    .catch((error) => { showSnackbar("Fehler beim Erstellen der Umfrage", "error") });
            }

        }
    }

    const editSurvey = () => {
        var survey_id = selectedSurvey.id;
        var survey = {};
        var promise = null;
        if (isAdmin) {
            survey = { surveyQuestion, multiple, choices, global, anonymous, targetUsers, targetTeams, survey_id, deletedTeams, deletedUsers, deletedChoices, assignments }
            if (surveyQuestion == "" || surveyQuestion == null || choices.length <= 1) {
                setStep1IsError(true);
            }
            else if (!global && targetTeams.length == 0 && targetUsers.length == 0) {
                setStep2IsError(true);
            }
            else {
                setStep1IsError(false);
                setStep2IsError(false);
                promise = updateSurvey_api(survey);
                promise.then((result) => { getSurveys(); onClose(); showSnackbar("Umfrage erfolgreich geändert", "success"); })
                    .catch((error) => { showSnackbar("Fehler beim Ändern der Umfrage", "error") });
            }
        }
        else {
            survey = { surveyQuestion, multiple, choices, anonymous, targetUsers, targetTeams, survey_id, deletedTeams, deletedUsers, deletedChoices, assignments }
            if (surveyQuestion == "" || surveyQuestion == null || choices.length <= 1) {
                setStep1IsError(true);
            }
            else {
                setStep1IsError(false);
                promise = updateMySurvey_api(survey);
                promise.then((result) => { getSurveys(); onClose(); showSnackbar("Umfrage erfolgreich geändert", "success"); })
                    .catch((error) => { showSnackbar("Fehler beim Ändern der Umfrage", "error") });
            }
        }
    }

    const nextStep = () => {
        if (activeStep + 1 <= 2) {
            setActiveStep(activeStep + 1);
        }
    }

    const backStep = () => {
        if (activeStep - 1 >= 0) {
            setActiveStep(activeStep - 1);
        }
    }

    const handleChange = (event, newTab) => {
        setActiveTab(newTab);
    }

    const getParticipants = () => {
        var promise = getSurveyParticipants_api(selectedSurvey.id);
        promise.then((result) => { setTargetUsers(result.data.users); setTargetTeams(result.data.teams); })
            .catch();
    }

    const initializeSurvey = (survey) => {
        setSurveyQuestion(survey.text);
        setMultiple(survey.is_multiple_votes);
        setGlobal(survey.is_global);
        setAnonymous(survey.is_anonymous);
        setChoices(survey.choices);
        setTargetUsers(survey.users);
        setTargetTeams(survey.teams);
        setAssignments(survey.assignmentData);
        getParticipants();
    }

    useEffect(() => {
        if (!open) {
            setSurveyQuestion("");
            setMultiple(false);
            setChoices([{ "text": "" }]);
            setActiveStep(0);
            setTargetUsers([]);
            setTargetTeams(null);
            setDeletedUsers([]);
            setDeletedTeams([]);
            setDeletedChoices([]);
            setGlobal(false);
            setStep1IsError(false);
            setAnonymous(false);
            setAssignments(null);


        }
        else {
            if (isEdit && selectedSurvey) {
                initializeSurvey(props.selectedSurvey);
                setAssignments(selectedSurvey.assignmentData)
            }
            else {
                setTargetTeams(targetTeamsArgs);
                setAssignments(assignedTeammatchArgs);
            }
            // console.log(assignedTeammatchArgs)
            getUsersList();
        }
        return () => {

        };
    }, [open]);

    useEffect(() => {
        if (activeStep == 1 && (surveyQuestion == "" || surveyQuestion == null || choices.length <= 1)) {
            setStep1IsError(true);
        }
        else {
            setStep1IsError(false);
        }
        if (activeStep == 1 && !global && targetTeams.length == 0 && targetUsers.length == 0) {
        }
        else {
            setStep2IsError(false);
        }
    }, [activeStep, global, targetTeams, targetUsers]);

    useEffect(() => {
        if (step1IsError || step2IsError) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
    }, [step1IsError, step2IsError]);


    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {!isEdit ? "Umfrage erstellen" : "Umfrage bearbeiten"}
                </DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} orientation='vertical' sx={{ mt: 1 }}>
                        <Step onClick={() => { setActiveStep(0) }}>
                            <StepLabel error={step1IsError}> <Typography variant="body1" sx={{ fontWeight: 500 }}>Umfrage definieren </Typography></StepLabel>
                            <StepContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth required value={surveyQuestion} onChange={changeHandler} name="survey" label="Umfrage Text"></TextField>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                            <Stack>
                                                <Typography sx={{ fontWeight: 700 }} variant="body1">Multiple Antworten</Typography>
                                                <Typography variant="body2">Dürfen mehrere Optionen gewählt werden?</Typography>
                                            </Stack>

                                            <Switch checked={Boolean(multiple)} onChange={() => { setMultiple(!multiple); }}></Switch>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                            <Stack>
                                                <Typography sx={{ fontWeight: 700 }} variant="body1">Anonyme Abstimmung</Typography>
                                                <Typography variant="body2">Sollen die Namen der Stimmen angezeigt werden?</Typography>
                                            </Stack>
                                            <Switch checked={anonymous} onChange={() => { setAnonymous(!anonymous); }}></Switch>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    {choices && choices.map((choice, i) => (
                                        <Grid item container direction="row" key={i}>
                                            <Grid item>
                                                <PrimaryIconButton icon={<DragIndicator />} onClickAction={() => { }}></PrimaryIconButton>
                                            </Grid>
                                            <Grid item xs >
                                                <TextField fullWidth size="small" required value={choices[i].text} onChange={(event) => { changeHandlerChoices(event, i); }} name={"choice" + i} label={"Option " + (i + 1) + " Text"}></TextField>
                                            </Grid>
                                            <Grid item>
                                                <PrimaryIconButton icon={<Delete />} onClickAction={() => { removeChoice(i) }}></PrimaryIconButton>
                                            </Grid>
                                        </Grid>
                                    ))

                                    }
                                    <Grid item xs={12}>
                                        <TertiaryButton text="+ Option hinzufügen" onClick={() => { addChoice(); }}></TertiaryButton>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>

                        <Step>
                            <StepLabel onClick={() => { setActiveStep(1) }} optional={<Typography variant="body2">optional</Typography>}>
                                <Typography variant="body1" fontWeight={500}>Verknüpfungen</Typography>
                            </StepLabel>
                            <StepContent>
                                <TeammatchAssignments assignments={assignments} setAssignments={setAssignments} teammatchQuery={""} adminMode={adminMode} />
                            </StepContent>
                        </Step>

                        <Step onClick={() => { setActiveStep(2) }}>
                            <StepLabel error={step2IsError}><Typography variant="body1" sx={{ fontWeight: 500 }}>Teilnehmer auswählen</Typography></StepLabel>
                            <StepContent >
                                <Grid container spacing={2}>
                                    {isAdmin == 1 ?

                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                                        <Stack>
                                                            <Typography sx={{ fontWeight: 700 }} variant="body1">Vereinsweite Umfrage</Typography>
                                                            <Typography variant="body2">Soll der ganze Verein oder nur bestimmte Personen/Mannschaften abstimmen?</Typography>
                                                        </Stack>
                                                        <Switch checked={global} onChange={() => { setGlobal(!global); }}></Switch>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        : <></>
                                    }
                                    {!global ?
                                        <>
                                            <Grid item xs={12}>
                                                <ToggleButtonGroup color="primary" value={activeTab} onChange={handleChange} exclusive>
                                                    <ToggleButton value="users" sx={{ textTransform: "none" }}>{"Mitglieder " + (targetUsers && "(" + targetUsers.length + ")")}</ToggleButton>
                                                    <ToggleButton value="teams" sx={{ textTransform: "none" }}>{"Teams " + (targetTeams && "(" + targetTeams.length + ")")}</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>

                                            {activeTab == "users" &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <CustomAutocomplete options={users} label="Mitglied" setTarget={addTargetUser} multipleSelect={true} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider></Divider>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {targetUsers && targetUsers.length > 0 ?
                                                            <List>
                                                                {targetUsers.map((user, i) => (
                                                                    <ListItem key={i}>
                                                                        <ListItemAvatar><AvatarInitials firstname={user.firstname} lastname={user.lastname} /></ListItemAvatar>
                                                                        <ListItemText primary={user.firstname + " " + user.lastname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                                                        </ListItemText>
                                                                        <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTargetUser(i); console.log(i) }} sx={{ ml: 2 }} />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                            :
                                                            <></>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            {activeTab == "teams" &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <TeamSelect teamid={teamid} addTeam={addTeam} multipleSelect={true} myTeams={isAdmin == 1 ? false : true}></TeamSelect>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider></Divider>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {targetTeams && targetTeams.length > 0 ?
                                                            <List>
                                                                {targetTeams.map((team, i) => (
                                                                    <ListItem key={i}>
                                                                        <ListItemAvatar><Groups /></ListItemAvatar>
                                                                        <ListItemText primary={team.team}>
                                                                        </ListItemText>
                                                                        <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTeam(i); console.log(i) }} sx={{ ml: 2 }} />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                            :
                                                            <></>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            {targetUsers && targetTeams && targetUsers.length == 0 && targetTeams.length == 0 ?
                                                <Grid item xs={12} sx={{ pt: 0 }}>
                                                    <Alert severity='warning'><Typography>Bitte wähle mindestens ein Mitglied oder eine Mannschaft aus</Typography></Alert>
                                                </Grid>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <Grid item xs={12}>
                                            <Alert severity="info">
                                                <Typography>Alle Mitglieder des Vereins sehen die Umfrage und können abstimmen</Typography>
                                            </Alert>
                                        </Grid>
                                    }
                                </Grid>
                            </StepContent>
                        </Step>
                    </Stepper >
                </DialogContent >
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    {/* {activeStep > 1 ?
                        <>
                            <SecondaryButton text="Abbrechen" onClick={() => { onClose(); }} />
                            <Stack direction="row" spacing={1}>
                                <SecondaryButton text="Zurück" onClick={() => { backStep(); }} />
                                <PrimaryButton text="Bestätigen" disabled={submitDisabled} onClick={() => { if (isEdit) { editSurvey(); } else { createSurvey(); } }} />
                            </Stack>
                        </>
                        :
                        <>
                            <SecondaryButton text="Abbrechen" onClick={() => { onClose(); }} />

                            <Stack direction="row" spacing={1}>
                                {activeStep != 0 &&
                                    <SecondaryButton text="Zurück" onClick={() => { backStep(); }} />
                                }

                                <PrimaryButton text="Weiter" onClick={() => { nextStep() }} />
                            </Stack>
                        </>
                    } */}
                    
                    <SecondaryButton text="Abbrechen" onClick={() => { onClose(); }} />
                    <Stack direction="row" spacing={1}>
                        {activeStep != 0 &&
                            <SecondaryButton text="Zurück" onClick={() => { backStep(); }} />
                        }
                        {activeStep != 2 ?
                            <PrimaryButton text="Weiter" onClick={() => { nextStep() }} />
                            :
                            <PrimaryButton text="Bestätigen" disabled={submitDisabled} onClick={() => { if (isEdit) { editSurvey(); } else { createSurvey(); } }} />
                        }

                    </Stack>

                </DialogActions>
            </Dialog >
        </>
    )
}

export default AddEditSurveyDialog