import { useTheme } from '@emotion/react';
import { Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import PrimaryIconButton from './PrimaryIconButton';
import { Visibility } from '@mui/icons-material';
import SecondaryButton from './SecondaryButton';
import ResponsiveDrawer from './ResponsiveDrawer';

function ViewDrawerMenu({ children, open, setOpen, drawerTitle, closeDrawerOnClick, ...props }) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {isSmallScreen ?
                <PrimaryIconButton color="primary" icon={<Visibility />} tooltipTitle={"Ansicht konfigurieren"} onClick={() => setOpen(!open)} />
                :
                <SecondaryButton startIcon={<Visibility />} text={<Typography variant="body2" fontWeight={500} sx={{ textTransform: "none" }}>Ansicht</Typography>} onClick={() => setOpen(!open)} />
            }
            <ResponsiveDrawer drawerTitle={drawerTitle} drawerSubtitle={"Filterkonfiguration"} openDrawer={open} setOpenDrawer={setOpen} closeDrawerOnClick={closeDrawerOnClick}>
                {children}
            </ResponsiveDrawer>

        </>
    )
}

export default ViewDrawerMenu