import { Alert, AlertTitle, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getNuligaAccounts_api } from '../api/Users/users';
import { AddLink, Person } from '@mui/icons-material';
import DynamicExpandIcon from './DynamicExpandIcon';
import SecondaryButton from './SecondaryButton';
import PrimaryIconButton from './PrimaryIconButton';

function NuligaAccount({ account, selectAction}) {

    return (
        <ListItem secondaryAction={<PrimaryIconButton icon={<AddLink button color="primary" />} onClick={() => { selectAction(); }}></PrimaryIconButton>}>
            <ListItemAvatar><Person color="primary"/></ListItemAvatar>
            <ListItemText primary={account.nuliga_name} secondary={"ID: " + account.id_number + " | LK: " + account.lk} primaryTypographyProps={{ fontWeight: 500 }}></ListItemText>
        </ListItem>
    )
}

function ConnectUserToNuligaDialog({ open, onClose, userData, setNuligaData, submitHandler }) {
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading0, setIsLoading0] = useState(true);
    const [nuligaAccountsWomen, setNuligaAccountsWomen] = useState(null);
    const [nuligaAccountsMen, setNuligaAccountsMen] = useState(null);
    const [expanded1, setExpanded1] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [expandInfo, setExpandInfo] = useState(false);


    const NuligaAccountsWomen = () => {
        var promise = getNuligaAccounts_api("?gender=1");
        promise.then((result) => { setNuligaAccountsWomen(result.data); setIsLoading1(false) })
            .catch(() => { setIsLoading1(false) })
    }
    const NuligaAccountsMen = () => {
        var promise = getNuligaAccounts_api("?gender=0");
        promise.then((result) => { setNuligaAccountsMen(result.data); setIsLoading0(false) })
            .catch(() => { setIsLoading0(false) })
    }

    useEffect(() => {
        if (open) {
            NuligaAccountsWomen();
            NuligaAccountsMen();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>Account mit Nuliga verbinden</DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <Alert severity='info'><AlertTitle onClick={() => { setExpandInfo(!expandInfo) }}><Stack direction="row" alignItems={"flex-start"}>Kein Vorschlag vorhanden?<DynamicExpandIcon expanded={expandInfo} sx={{ pt: 0 }} /></Stack></AlertTitle>
                    <Collapse in={expandInfo}>
                        <Typography variant="body2">Sollten keine Vorschläge erscheinen, kannst du alle gefunden Accounts manuell durchsuchen. Sollte der passende Account auch dort nicht erscheinen, ist dein Account höchstwahrscheinlich nicht bei NuLiga gelistet.</Typography>
                    </Collapse>
                </Alert>
                {isLoading0 || isLoading1 ?
                    <Stack spacing={1} justifyContent={"center"} alignItems={"center"} sx={{ mt: 2 }}>
                        <CircularProgress></CircularProgress>
                        <Typography>Suche Accounts in Nuliga</Typography>
                    </Stack>
                    :
                    <></>
                }
                {nuligaAccountsWomen && nuligaAccountsMen ?
                    <List dense>
                        <ListSubheader>Vorschlag</ListSubheader>
                        {nuligaAccountsWomen && nuligaAccountsWomen.map((account, i) => (
                            account.nuliga_name.includes(userData.lastname) && account.nuliga_name.includes(userData.firstname) &&
                            <NuligaAccount key={i} account={account}  selectAction={() => { submitHandler(account.id_number, account.lk.substring(2), account.nuliga_name); }}></NuligaAccount>
                        ))
                        }
                        {nuligaAccountsMen && nuligaAccountsMen.map((account, i) => (
                            account.nuliga_name.includes(userData.lastname) && account.nuliga_name.includes(userData.firstname) &&
                            <NuligaAccount key={i} account={account} selectAction={() => { submitHandler(account.id_number, account.lk.substring(2), account.nuliga_name);  }}></NuligaAccount>
                        ))
                        }
                        <Stack direction="row" spacing={1}  onClick={() => setExpanded1(!expanded1)}><DynamicExpandIcon expanded={expanded1} /><ListSubheader>{"Alle Accounts Damen (" + nuligaAccountsMen.length + ")"}</ListSubheader></Stack>
                        <Collapse in={expanded1}>
                            {nuligaAccountsMen && nuligaAccountsMen.map((account, i) => (
                                <NuligaAccount key={i} account={account}  selectAction={() => { submitHandler(account.id_number, account.lk.substring(2), account.nuliga_name);  }}></NuligaAccount>
                            ))
                            }
                        </Collapse>
                        <Stack direction="row" spacing={1} onClick={() => setExpanded(!expanded)}><DynamicExpandIcon expanded={expanded}  /><ListSubheader>{"Alle Accounts Herren (" + nuligaAccountsWomen.length + ")"}</ListSubheader></Stack>
                        <Collapse in={expanded}>
                            {nuligaAccountsWomen && nuligaAccountsWomen.map((account, i) => (
                                <NuligaAccount key={i} account={account}  selectAction={() => { submitHandler(account.id_number, account.lk.substring(2), account.nuliga_name); }}></NuligaAccount>
                            ))
                            }
                        </Collapse>
                    </List>
                    : isLoading0 || isLoading1 ?
                        <></>
                        :
                        <Alert severity='warning'><Typography>Keine NuLiga-Accounts gefunden</Typography></Alert>
                }

            </DialogContent>
            <Divider></Divider>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <SecondaryButton text="Abbrechen" onClick={() => { onClose() }}></SecondaryButton>
                {/* <PrimaryButton startIcon={<Link></Link>} text="Verbinden" onClick={() => { submitHandler(); onClose(); }} /> */}
            </DialogActions>
        </Dialog>
    )
}

export default ConnectUserToNuligaDialog