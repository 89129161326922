import { Avatar, Link, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import InternalLink from './InternalLink';


function SimpleListLink(props) {
    const { icon, linkURL, linkname, value, divider, disablePadding, avatarWidth } = props;
    const navigate=useNavigate();
    return (
        <ListItem divider={divider} disableGutters disablePadding={disablePadding}>
            <ListItemAvatar sx={{ minWidth: avatarWidth || '56px' }}><Avatar size="small" sx={{ backgroundColor: "#E3F2FD" }}>{icon}</Avatar></ListItemAvatar>
            <ListItemText sx={{ pl: 0 }}> <Stack direction="row" justifyContent="space-between"><InternalLink linkname={linkname} route={linkURL}></InternalLink><Typography sx={{ typography: { xs: 'body2', md: 'body1' } }}>{value}</Typography></Stack></ListItemText>
        </ListItem>
    );
}

export default SimpleListLink