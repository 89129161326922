import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { addMinutes, setMinutes } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { closeCourts_api } from '../../../api/Courts/courts';
import CourtSelect from '../../../components/CourtSelect';
import DateTimeField from '../../../components/DateTimeField';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import { useSnackbar } from '../../../components/SnackbarContext';


function CourtsLockDialog(props) {
    const { open, onClose, getCourts,court,courtType } = props;

    const [targetCourts, setTargetCourts] = useState([]);
    const [startdate, setStartdate] = useState(new Date());
    const [enddate, setEnddate] = useState(new Date());
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const {showSnackbar} = useSnackbar();

    const submitHandler = () => {
        var promise = closeCourts_api(targetCourts, enddate, startdate,courtType);
        promise.then((result) => { getCourts(); onClose(); showSnackbar("Plätze gesperrt", "success") })
            .catch();
    }

    useEffect(() => {
        if (open) {
            if (court) {
                setTargetCourts([court]);
            }
            else {
                setTargetCourts([]);
            }

            setStartdate(setMinutes(new Date(), 0));
            setEnddate(setMinutes(new Date(), 30));
        }
        return () => {

        };
    }, [open]);

    useEffect(() => {
        if(targetCourts.length ==0) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false);
        }
    }, [targetCourts]);
    
    return (
        <>
            <Dialog id="dlg-courtlocksdialog" open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>{"Plätze sperren"}</DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Grid container spacing={2}>
                      
                        <Grid item xs={12} md={6}>
                            <DateTimeField id="dtp-courtlocksdialog-start" label={"Startdatum"} date={new Date(startdate)} setDate={setStartdate} ></DateTimeField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimeField id="dtp-courtlocksdialog-end"  label={"Enddatum"} date={enddate} setDate={setEnddate}  timeSteps={{minutes: 30}} minDateTime={addMinutes(startdate,28)} ></DateTimeField>
                        </Grid>
                        <Grid item xs={12}>
                            <CourtSelect targetCourts={targetCourts} setTargetCourts={setTargetCourts} courtType={courtType}></CourtSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                    <PrimaryButton id="btn-courtlocksdialog-submit" text={"Bestätigen"} onClick={() => { submitHandler(); }} disabled={submitDisabled}></PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CourtsLockDialog