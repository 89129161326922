import { Button, Container, Dialog, DialogActions, DialogContent, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react'
import Post from '../pages/Blog/Post'
import SecondaryButton from './SecondaryButton'

function BlogPreviewDialog({ open, onClose, post }) {
    const [size, setSize] = useState("xs");

    const handleChangeSize = (event, value) => {
        setSize(value);
    }
    return (
        <Dialog open={open} onClose={() => onClose()} fullScreen >
            <DialogContent sx={{backgroundColor: "#eee"}}>
            <SecondaryButton text={"Preview schließen"} onClick={() => onClose()}></SecondaryButton>
                {/* <Stack direction="row" spacing={1}>
                   
                    <ToggleButtonGroup color="primary" value={size} exclusive onChange={handleChangeSize} size="small">
                        <ToggleButton value={"xs"}>xs</ToggleButton>
                        <ToggleButton value={"sm"} >sm</ToggleButton>
                        <ToggleButton value={"lg"} >md</ToggleButton>
                    </ToggleButtonGroup>
                </Stack> */}
                <Container maxWidth={"lg"} sx={{ py: 2 }}>
                    <Post title={post && post.title} src={post && post.src} author={post && post.author} content={post && post.content} post={post} commentsCount={0} />
                </Container>
            </DialogContent>

        </Dialog>
    )
}

export default BlogPreviewDialog