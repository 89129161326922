import React from 'react'
import ActionMenu from '../../../components/ActionMenu';
import { ListItemIcon, MenuItem } from '@mui/material';
import { GroupAdd, KeyboardDoubleArrowRight, Refresh } from '@mui/icons-material';

function TeamsMenu({setOpenCreate,updateTeammatches,...props}) {
    return (
        <ActionMenu buttonColor="primary" button buttonName='Menü'>
            {/* {props.isAdmin && props.adminMode ? */}
            <MenuItem onClick={() => { setOpenCreate(true); }}>
                <ListItemIcon>
                    <GroupAdd fontSize="small" />
                </ListItemIcon>
                Mannschaft erstellen
            </MenuItem>
            <MenuItem onClick={props.setOpenChangeSeason}>
                <ListItemIcon>
                    <KeyboardDoubleArrowRight fontSize="small" />
                </ListItemIcon>
             Saisons wechseln
            </MenuItem>
            <MenuItem onClick={() => { updateTeammatches(); }}>
                <ListItemIcon>
                    <Refresh fontSize="small" />
                </ListItemIcon>
             Sync. Mannschaften/Punktspiele
            </MenuItem>
        </ActionMenu>
    )
}

export default TeamsMenu