import { Card, CardContent,Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function FeatureAnnouncementCard({ title, description, link="", imageUrl, imageProps,release }) {
    const [elevation, setElevation] = useState(0);
    const navigate = useNavigate();
    return (
        <Card elevation={elevation} sx={{ backgroundColor: "rgb(25, 103, 210,0.1)", borderRadius: "20px",p: 2 }} onMouseOver={() => setElevation(24)} onMouseLeave={() => setElevation(0)}>
            <CardContent >
                <Stack spacing={1} justifyContent={"flex-start"}>
                    <Typography textAlign="center"variant="h6" fontWeight={500} color="secondary">{title}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{overflow: "hidden",textOverflow: "ellipsis",display: "-webkit-box",WebkitLineClamp: "4", WebkitBoxOrient: "vertical", p: 0, minHeight: "80.06px"}}>{description}</Typography>
                    <Typography textAlign={"right"} variant="body2" fontWeight={500} color="primary" noWrap>{"Release "+release}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default FeatureAnnouncementCard