import { Container, Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer'
import LicenseAgreementText from './LicenseAgreementText'

function LicenseAgreementContainer() {
    return (
        <>
            <Header></Header>
            <Container maxWidth="lg" sx={{ px: 1, py: 5 }}>
                <LicenseAgreementText></LicenseAgreementText>
            </Container>
            <Footer></Footer>
        </>
    )
}

export default LicenseAgreementContainer