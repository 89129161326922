import { AddBox, Delete, InfoSharp } from '@mui/icons-material';
import { Alert, AlertTitle, Collapse, Grid, IconButton, List, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import TertiaryButton from './TertiaryButton';
import TeammatchListItem from './TeammatchListItem';
import { getMyTeammatches, getTeammatches_api } from '../api/Teammatches/Teammatches';

function TeammatchAssignments({ assignments, setAssignments, teammatchQuery = "location=(H)", adminMode, ...props }) {

    const [showTeammatchesSelect, setShowTeammatchesSelect] = useState(false)
    const [info, setInfo] = useState();
    const [teammatches, setTeammatches] = useState();

    const addAssignment = (teammatch) => {
        //add participanzt to seeds if it is not already assigned
        if (!assignments) {
            setAssignments(assignments => {
                return [teammatch];
            })
        }
        else if (assignments.length >= 0 && assignments.indexOf(teammatch) === -1) {
            setAssignments(assignments => {
                return [...assignments, teammatch];
            })
        }

    }

    const getTeammatches = () => {
        var promise = null;
        if (adminMode) {
            promise = getTeammatches_api(teammatchQuery);
        }
        else {
            promise = getMyTeammatches(teammatchQuery);
        }

        promise.then((result) => setTeammatches(result.data))
            .catch((error) => { });
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Collapse in={info}>
                    <Alert severity='info' variant="outlined" onClose={() => setInfo(false)}>
                        <AlertTitle>Info</AlertTitle>
                        <Typography variant="body2">Verknüpfte Serienbuchungen erscheinen in der Übersicht der zugehörigen Mannschaft und heben die Warnung der fehlenden Platzbuchung im Punktspielplaner auf. Vordefinerte Verknüpfungen können  aus dem Punktspielplaner heraus initiert werden. </Typography>
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
                {assignments && assignments.length > 0 ?
                    <>
                        <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                            <Typography variant="body2" color="secondary" fontWeight={500}>{"Verknüpfungen (" + assignments.length + ")"}</Typography>
                            {!info &&
                                <TertiaryButton startIcon={<InfoSharp color="primary" />} text={"Info"} onClick={() => setInfo(true)}></TertiaryButton>
                            }
                        </Stack>

                        <List dense sx={{ maxHeight: "300px", overflowY: "auto" }}>
                            {assignments.map((assignment, i) => (
                                <TeammatchListItem key={i} teammatch={assignment} iconColor="secondary" secondaryAction={<IconButton onClick={() => { setAssignments([...assignments.slice(0, i), ...assignments.slice(i + 1)]) }} ><Delete /></IconButton>} />
                            ))}
                        </List>
                    </>
                    :
                    <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant="body2" color="secondary" fontWeight={500}>{"Verknüpfungen (0)"}</Typography>
                        {!info &&
                            <TertiaryButton startIcon={<InfoSharp color="primary" />} text={"Info"} onClick={() => setInfo(true)}></TertiaryButton>
                        }
                    </Stack>
                }
                <Stack direction={"row"} justifyContent={"center"}>
                    <TertiaryButton text={showTeammatchesSelect ? "Fertig" : "+ Punktspiele verknüpfen"} onClick={() => { if (teammatches) { setShowTeammatchesSelect(!showTeammatchesSelect) } else { getTeammatches(); setShowTeammatchesSelect(!showTeammatchesSelect) } }} />
                </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
                <Collapse in={showTeammatchesSelect}>
                    {teammatches ?
                        <>
                            <Typography variant="body2" color="text.secondary" fontWeight={500}>{"Heim-Punktspiele (" + teammatches.length + ")"}</Typography>
                            <List dense sx={{ maxHeight: "300px", overflowY: "auto" }}>
                                {/* <ListSubheader disableSticky>{"Punktspiele (" + teammatches.length + ")"}</ListSubheader> */}
                                {teammatches.map((teammatch, i) => (
                                    !assignments ?
                                        <TeammatchListItem key={i} teammatch={teammatch} iconColor="primary" secondaryAction={<IconButton onClick={() => addAssignment(teammatch)}><AddBox color="primary" /></IconButton>} />
                                        :
                                        assignments.indexOf(teammatch) === -1 ?
                                            <TeammatchListItem key={i} teammatch={teammatch} iconColor="primary" secondaryAction={<IconButton onClick={() => addAssignment(teammatch)}><AddBox color="primary" /></IconButton>} />
                                            :
                                            <></>
                                ))}
                            </List>
                        </>
                        :
                        <></>
                    }
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default TeammatchAssignments