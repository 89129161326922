import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


function LoginButton(props) {

    const isAuthenticated = useSelector((state)=>state.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [signInButton_name, setSignInButton_name] = useState("Anmelden");
    // const [auth, setAuth] = useState(isAuthenticated);
    const [variant, setVariant] = useState("outlined");


    useEffect(() => {
        if (isAuthenticated) {
            //change label if authenticated
            setSignInButton_name("Abmelden");
        }
        else {
            //change label if not authenticated
            setSignInButton_name("Anmelden");
        }

    }, [isAuthenticated])


    const Logout = () => {

        //set redux authentiocated if logout
        if (isAuthenticated) {
            dispatch({ type: "SIGNIN", payload: { isAuthenticated: false, firstname: undefined, lastname: undefined } });
            localStorage.clear();
            navigate("/");
        }
        else {
            navigate("/login");
        }
    }
    return (
        <Button id="btn-logout" size="small" variant={variant} color={props.color} onClick={() => Logout()}>{signInButton_name}</Button>
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.isAuthenticated,
    firstname: state.firstname,
    lastname: state.lastname
});

export default LoginButton;