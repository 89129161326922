import { Alert, CardContent, Checkbox, Chip, Collapse, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react'
import AvatarInitials from './AvatarInitials';
import DynamicExpandIcon from './DynamicExpandIcon';


function SurveyChoice(props) {

    const { choiceText, choiceVotesAbs, choiceVotesRel, survey, choiceID, addVote, removeVote, votes, participants } = props;
    const [checked, setChecked] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const checkAction = () => {
        if (!checked) {
            //setVote();
            setChecked(true);
            addVote(choiceID);
        }
        else {
            setChecked(false);
            removeVote(choiceID);
        }
    }

    useEffect(() => {
        if (votes.includes(choiceID)) {
            setChecked(true);
        }
        else {
            setChecked(false);
        }
        return () => {
        };
    }, [votes]);

    return (
        <Grid container direction="row" spacing={1} justifyContent={"space-between"} alignItems={"center"}>

            {!survey.voted && survey.is_active == 1 ?
                <Grid item>
                    <Checkbox color="primary" checked={checked} onClick={() => { checkAction() }}></Checkbox>
                </Grid>
                :
                <></>
            }
            {survey.voted || props.isAdmin ?
                <Grid item>
                    <DynamicExpandIcon onClick={() => { setExpanded(!expanded) }} expanded={expanded} />
                </Grid>
                :
                <></>
            }

            <Grid item xs>
                <Stack direction="column" justifyContent="center" spacing={1}>
                    <Typography variant="body1">{choiceText}</Typography>
                    {
                        //distinguish betwwen votes and zero --> display at least a small bar
                        choiceVotesRel != 0 && choiceVotesAbs != 0 ?
                            <Box sx={{ width: choiceVotesRel + "%", height: "10px", backgroundColor: "primary.main", borderRadius: 3 }}></Box>
                            :
                            <Box sx={{ width: "0.5%", height: "10px", backgroundColor: "primary.main", borderRadius: 3 }}></Box>
                    }
                </Stack>
            </Grid>
            <Grid item>
                {/* <Typography variant="h6">{choiceVotesAbs}</Typography> */}
                <Chip label={<Typography variant="h6">{choiceVotesAbs}</Typography>}></Chip>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent onClick={(e) => { e.stopPropagation(); }}>
                        {participants && participants.length > 0 ?
                            <List>
                                {participants.map((participant, i) => (
                                    <ListItem key={i}>
                                        <ListItemAvatar><AvatarInitials firstname={participant.firstname} lastname={participant.lastname} /></ListItemAvatar>
                                        <ListItemText primary={participant.firstname + " " + participant.lastname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                            :
                            <Alert severity="info"><Typography>Keine Stimmen vorhanden</Typography></Alert>
                        }
                    </CardContent>
                </Collapse>
            </Grid>
        </Grid>


    )
}

export default SurveyChoice