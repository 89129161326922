import { Add, EmojiEvents, GridView,ListAlt, Polyline, Refresh } from '@mui/icons-material'
import {  Alert, ButtonGroup, CircularProgress, Container, Divider, Grid, List, ListItemIcon, MenuItem, Stack, Switch, Table, TableHead, TablePagination, TableRow, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getTeammachtesHomeGrouped_api, getTeammatches, getTeammatchReservationStatus_api, updateTeammatches_api } from '../../../api/Teammatches/Teammatches'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard'
import TeamSelect from '../../../components/TeamSelect'
import AddEditTeammatchDialog from '../../../components/AddEditTeammatchDialog'
import Footer from '../../../components/Footer'
import { Box } from '@mui/system'
import { useLocation, useSearchParams } from 'react-router-dom'
import MyTab from '../../../components/MyTab'
import Timeline from '@mui/lab/Timeline'
import TeammatchCardv2 from '../../../components/TeammatchCardv2'
import ActionMenu from '../../../components/ActionMenu'
import { useSnackbar } from '../../../components/SnackbarContext'
import TeammatchPlannerTab from './TeammatchPlannerTab'

import FilterDrawerMenu from '../../../components/FilterDrawerMenu'
import ViewDrawerMenu from '../../../components/ViewDrawerMenu'
import MainContentContainer from '../../../components/MainContentContainer'


function TeammatchMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);


  return (
    <ActionMenu button buttonName='Menu'>
      <MenuItem onClick={() => { setAnchorEl(null); props.setOpenAddTeammatchDialog(true); }}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        Spiel erstellen
      </MenuItem>
      <MenuItem onClick={() => { setAnchorEl(null); props.updateTeammatches(); }}>
        <ListItemIcon>
          <Refresh fontSize="small" />
        </ListItemIcon>
        Spiele aktualisieren
      </MenuItem>

    </ActionMenu>

  );
}

function AdmTeammatchesContainer() {
  const [searchParam, setSearchParam] = useSearchParams();
  const [teammatches, setTeammatches] = useState([{}]);
  const [homematches, setHomematches] = useState();
  const [teamid, setTeamid] = useState(searchParam.get("team") || "");
  const [tabValue, setTabValue] = useState(searchParam.get("tab") || "matches");
  const [openAddTeammatchDialog, setOpenAddTeammatchDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("timeline");
  const [isNext, setIsNext] = useState(false);
  const [teammatchReservationsStats, setTeammatchReservationsStats] = useState(null);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [openViewMenu, setOpenViewMenu] = useState(false);
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  //pagintaion
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleView = (event, newView) => {
    setView(newView);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const assignSeriesToMatchDay = (object) => {

  //   var tmpAssignments = [];
  //   var tmpNames = "Punktspiel: ";
  //   for (var i = 0; i < object.teammatches.length; i++) {

  //     tmpAssignments.push(object.teammatches[i]);
  //     if (i === object.teammatches.length - 1) {
  //       tmpNames += object.teammatches[i].teamName;
  //     } else {
  //       tmpNames += object.teammatches[i].teamName + ", ";
  //     }

  //   }
  //   setStartArgs({ ...startArgs, date: object.teammatches[0].date, name: tmpNames, assignments: tmpAssignments }); setOpenSeriesDialog(true)
  // }


  const getAllTeammatches = () => {
    setIsLoading(true);
    var promise = null;
    var upcomingFilter = "";
    if (isNext) {
      upcomingFilter = "next";
    }
    promise = getTeammatches(teamid, upcomingFilter);

    promise.then((result) => { setTeammatches(result.data); setTimeout(() => { setIsLoading(false) }, 350) })
      .catch();
  }
  const updateTeammatches = () => {
    setIsLoading(true);
    var promise = updateTeammatches_api();
    promise.then((result) => { setIsLoading(false); getAllTeammatches(); showSnackbar("Punktspiele erfolgreich geupdated", "success"); })
      .catch((error) => { setIsLoading(false); showSnackbar("Fehler beim updaten: " + error, "success"); });
  }



  const getTeammatchesHomeGrouped = () => {
    setIsLoading(true);
    var promise = getTeammachtesHomeGrouped_api();
    promise.then((result) => { getTeammatchesReservationStats(); setHomematches(result.data); setTimeout(() => { setIsLoading(false) }, 350); })
      .catch(() => { setIsLoading(false); });
  }

  const getTeammatchesReservationStats = () => {
    var promise = getTeammatchReservationStatus_api()
    promise.then((result) => setTeammatchReservationsStats(result.data))
      .catch();
  }


  useEffect(() => {

    if (tabValue === "planning") {
      getTeammatchesHomeGrouped();

    }
    else {
      getAllTeammatches();
      setSearchParam({ tab: tabValue, team: teamid });
    }
  }, [teamid, tabValue, isNext]);

  useEffect(() => {
    searchParam.set("tab", tabValue)
    setSearchParam(searchParam);
  }, [tabValue]);

  // useEffect(() => {
  //   console.log("searchParam Changes")
  //   if (searchParam.get("tab") == "planning" || searchParam.get("tab") == "matches") {
  //     if (tabValue != searchParam.get("tab")) {
  //       setTabValue(searchParam.get("tab"));

  //     }
  //   }
  // }, [searchParam]);
  useEffect(() => {
    if (searchParam.get("tab") == "planning" || searchParam.get("tab") == "matches") {
      setTabValue(searchParam.get("tab"));
    }
  }, [location]);

  return (
    <>
      <Header></Header>
      <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <TitleCard id="crd-title-teammatches" title={"Punktspiele"} subheader={"Verwalte die Punktspiele vom " + process.env.REACT_APP_CLUB_NAME} icon={<EmojiEvents sx={{ width: 40, height: 40 }}></EmojiEvents>}></TitleCard>
        </Grid>
      </Grid>
      <MainContentContainer>
        <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
              <Tabs value={tabValue} onChange={handleTabChange}>
                <MyTab label="Punktspiele" value="matches" />
                <MyTab label="Punktspielplanung" value="planning" />
              </Tabs>
            </Box>

            {tabValue !== "planning" &&
              <>
                <Grid container direction="row" columnSpacing={1} rowSpacing={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent={"space-between"}>
                      <ButtonGroup variant="outlined">
                        <ViewDrawerMenu open={openViewMenu} setOpen={setOpenViewMenu} drawerTitle={"Punkstpiele"}>
                          <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12}>
                              <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small" fullWidth>
                                <ToggleButton value={"list"}> <Stack direction="row" spacing={1}><ListAlt /><Typography sx={{ textTransform: "none" }}>Liste</Typography></Stack></ToggleButton>
                                <ToggleButton value={"grid"}> <Stack direction="row" spacing={1}><GridView /><Typography sx={{ textTransform: "none" }}>Kachel</Typography></Stack></ToggleButton>
                                <ToggleButton value={"timeline"}> <Stack direction="row" spacing={1}><Polyline /><Typography sx={{ textTransform: "none" }}>Zeitstrahl</Typography></Stack></ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>
                          </Grid>
                        </ViewDrawerMenu> 
                        <FilterDrawerMenu open={openFilterMenu} setOpen={setOpenFilterMenu} drawerTitle={"Punktspiele"}>
                          <Grid container spacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12}>
                              <TeamSelect teamid={teamid} setTeamid={setTeamid} showAllOption={true} size="medium"></TeamSelect>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} sx={{ px: 1 }} spacing={3}>
                                <Stack>
                                  <Typography variant='body2' fontWeight={500}>Bevorstehende Spiele</Typography>
                                  <Typography variant='body2' color="text.secondary">Es werden nur Spiele in der Zukunft angezeigt</Typography>
                                </Stack>
                                <Switch checked={isNext} onChange={(event, newValue) => { setIsNext(newValue) }}></Switch>
                              </Stack>
                            </Grid>
                          </Grid>
                        </FilterDrawerMenu>
                      </ButtonGroup>
                      <TeammatchMenu updateTeammatches={updateTeammatches} setOpenAddTeammatchDialog={setOpenAddTeammatchDialog}></TeammatchMenu>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                </Grid>

                <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 50, 100]}
                          count={teammatches.length || 0}
                          rowsPerPage={rowsPerPage}
                          labelRowsPerPage={"Anzahl"}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sx={{ border: 0 }}
                        />
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>

                <Grid container spacing={1} justifyContent={"center"}>
                  {isLoading ?
                    <Grid item>
                      <Stack alignItems={"center"} spacing={1}>
                        <CircularProgress></CircularProgress>
                        <Typography>Lade Punktspiele</Typography>
                      </Stack>
                    </Grid>
                    :
                    teammatches.length > 0 ?
                      <>
                        {view === "timeline" ?
                          <Grid item container xs={12} md={12} >
                            <Timeline sx={{ px: "0px" }}>
                              {teammatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((teammatch, i) => (
                                i == rowsPerPage - 1 || (rowsPerPage > teammatches.length && i === teammatches.length - 1) ?
                                  <TeammatchCardv2 isTimeLine isGuest={false} showTeam getTeammatches={getAllTeammatches} teammatch={teammatch} adminMode={true} key={i} hideConnector />
                                  :
                                  <TeammatchCardv2 isTimeLine isGuest={false} showTeam getTeammatches={getAllTeammatches} teammatch={teammatch} adminMode={true} key={i} />
                              ))}
                            </Timeline>
                          </Grid>
                          : view === "grid" ?
                            <Grid item container xs={12} md={12} spacing={4}>
                              {teammatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((teammatch, i) => (

                                <Grid key={i} item xs={12} md={6} >
                                  <TeammatchCardv2 isGuest={false} getTeammatches={getAllTeammatches} teammatch={teammatch} adminMode={true}></TeammatchCardv2>
                                </Grid>
                              ))}
                            </Grid>
                            : view === "list" ?
                              <Grid item xs={12}>
                                <List dense>
                                  {teammatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((teammatch, i) => (
                                    <TeammatchCardv2 isList isGuest={false} getTeammatches={getAllTeammatches} teammatch={teammatch} adminMode={true} key={i} />
                                  ))}
                                </List>
                              </Grid>
                              :
                              <></>
                        }
                      </>
                      :
                      <Grid item xs>
                        <Alert severity="info"><Typography>Keine Punktspiele vorhanden</Typography></Alert>
                      </Grid>
                  }
                </Grid>
              </>
            }
            {tabValue === "planning" &&
              <TeammatchPlannerTab matchdays={homematches} getTeammatchesHomeGrouped={getTeammatchesHomeGrouped} isLoading={isLoading} stats={teammatchReservationsStats} />
            }
          </Grid>

        </Grid>
      </MainContentContainer>
      <Footer />
      <AddEditTeammatchDialog open={openAddTeammatchDialog} getTeammatches={getAllTeammatches} onClose={() => { setOpenAddTeammatchDialog(false); }} isEdit={false} adminMode={true}></AddEditTeammatchDialog>
    </>
  )
}

export default AdmTeammatchesContainer