import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getNotifications_api = async (userID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/notifications/"+sessionData.id,
    { headers: sessionData }
  );
};

export const setNotificationRead_api = async (notificationID ) => {
  const sessionData = getSessionData();

  var data= {};
  return await axios.put(
    process.env.REACT_APP_API_URL + "/notifications/"+notificationID,data,
    { headers: sessionData }
  );
};

export const deleteNotification_api = async (notificationID) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/notifications/"+notificationID,
    { headers: sessionData }
  );
};

export const deleteAllNotificationOfUser_api = async (userid) => {
  const sessionData = getSessionData();

  var data= {
    userid: sessionData.id
  };
  return await axios.put(
    process.env.REACT_APP_API_URL + "/notifications/deleteall",data,
    { headers: sessionData }
  );
};

export const readAllNotificationOfUser_api = async (userid) => {
  const sessionData = getSessionData();

  var data= {
    userid: userid
  };
  return await axios.put(
    process.env.REACT_APP_API_URL + "/notifications/readall",data,
    { headers: sessionData }
  );
};

