import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Alert,  CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { deleteReservation, deleteReservations } from '../../api/Reservations/reservations.js';
import { myStyles } from '../../css/Sytles.jsx';
import AdminBookingDialog from './AdmBookingDialog.jsx';
import AdminBookingButton from './AdmBookingButton.jsx';
import MailDialog from '../../components/MailDialog.jsx';
import InternalLink from '../../components/InternalLink.jsx';
import CourtsLockDialog from '../Admin/Courts/CourtsLockDialog.jsx';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../components/SnackbarContext.jsx';
import BookingDateView from '../../components/BookingDateView.jsx';
import { Delete, } from '@mui/icons-material';
import PrimaryButton from '../../components/PrimaryButton.jsx';
import { addMinutes, addSeconds, differenceInMinutes,isValid, isWithinInterval } from 'date-fns';
import CheckoutSlider from './CheckoutSlider.jsx';
import BookingTypeLegend from './BookingTypeLegend.jsx';
import { ADM_COURTS } from '../../navigation/CONSTANTS.js';


function AdminBookingTable(props) {

    //Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [newBookingData, setNewBookingData] = useState({ time: null, court: null, date: null })
    const [targetCourts, setTargetCourts] = useState([]);
    const [enddate, setEnddate] = useState();
    const [startdate, setStartdate] = useState();
    const [numBookingUnits, setNumBookingUnits] = useState(0)



    //delete Dialog
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isMassDelete, setIsMassDelete] = useState(false);
    const [openLockDialog, setOpenLockDialog] = useState(false);
    const navigate = useNavigate();

    const [shortText, setShortText] = useState(false);
    const [classes, setClasses] = useState(myStyles());
    const { showSnackbar } = useSnackbar();

    //add or remove selected reservation
    const handleSelectedIDs = (id) => {
        setSelectedIDs(prevSelectedIDs => {
            // Check if the ID is already in the list
            if (prevSelectedIDs.includes(id)) {
                // Remove the ID by filtering it out
                return prevSelectedIDs.filter(item => item !== id);
            } else {
                // Add the ID to the list
                return [...prevSelectedIDs, id];
            }
        });
        setTargetCourts([]);
    };

    const handleOpen = (event) => {
        setModalOpen(true);
    }
    const handleClose = () => {
        setModalOpen(false);
        setTargetCourts([]);
        setEnddate();
        setStartdate();
    }

    const handleMassDelete = () => {
        if (selectedIDs.length > 0) { setDeleteDialogOpen(true); setIsMassDelete(true); }
        else {
            showSnackbar("Bitte wähle mindestens eine Buchung aus!", "warning"); setDeleteDialogOpen(false); setIsMassDelete(false);
        }
    }
    const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

    const handleDelete = (deleteMessage) => {
        var reservationPromise = null;
        //distinguish between mass and single delete
        if (isMassDelete) {
            reservationPromise = deleteReservations(selectedIDs, deleteMessage);
        }
        else {
            reservationPromise = deleteReservation(selectedBookingId, deleteMessage);
        }
        reservationPromise
            .then(result => { props.populateTable(); showSnackbar("Reservierung/en gelöscht", "success"); setDeleteDialogOpen(false); setSelectedIDs(new Array()) })
            .catch(error => { showSnackbar("Beim Löschen ist etwas schief gelaufen!", "error"); setDeleteDialogOpen(false); });
    }

    const isHighlighted = (time) => {

        var reservationStart = new Date(props.dayFilter).setHours(time.split(":")[0], time.split(":")[1]);
        var reservationEnd = addMinutes(reservationStart, props.settings?.booking_duration)

        var isSelected = isWithinInterval(new Date(), { start: reservationStart, end: addMinutes(reservationEnd, -1) })
        if (isSelected) {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        if (isValid(new Date(startdate)) && isValid(new Date(enddate))) {
            var diffInMinutes = differenceInMinutes(enddate, startdate);
            setNumBookingUnits((diffInMinutes / props.settings?.booking_duration) * targetCourts.length)
        }
    }, [startdate, enddate, targetCourts]);

    useEffect(() => {
        try {
            if (props.reservations?.some(reservation => targetCourts.some(courts => courts.id == reservation.platz) && isWithinInterval(addSeconds(new Date(reservation.timestamp), 1), { start: startdate, end: enddate }))) {
                showSnackbar("Buchungszeitraum kollidiert mit einer bestehenden Buchung. Auswahl wird zurückgesetzt", "warning");
                setTargetCourts([]);
                setStartdate();
                setEnddate();

            }
        } catch (error) {

        }

    }, [startdate, enddate, targetCourts]);

    return (
        <>
            <Grid container direction="row" alignItems="center" rowSpacing={1} columnSpacing={1} mb={1}>
                <Grid item xs={12}>
                    <BookingDateView date={props.dayFilter} setDate={props.setDayFilter} shortText={shortText} setShortText={setShortText} courts={props.courts} settings={props.settings}></BookingDateView>
                </Grid>
                <Grid item container justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <BookingTypeLegend></BookingTypeLegend>
                    </Grid>
                    {/* <Grid item >
                        <ActionMenu button buttonName={"Aktionen"}>
                            <MenuList dense>
                                <MenuItem onClick={() => navigate(ADM_COURTS)}><ListItemIcon><Web /></ListItemIcon>Platzverwaltung</MenuItem>
                                <MenuItem onClick={() => setOpenLockDialog(true)}><ListItemIcon><Lock /></ListItemIcon>Plätze sperren</MenuItem>
                                <MenuItem onClick={() => navigate(ADM_COURTLOCKS)}><ListItemIcon><Block /></ListItemIcon>Platzsperrungen</MenuItem>
                            </MenuList>
                        </ActionMenu>
                    </Grid> */}
                </Grid>
                {!props.courts || props.courts.length < 1 ?
                    <Grid item xs={12}>
                        <Alert severity='info'><Typography variant="body1">Es sind keine Tennisplätze vorhanden</Typography><InternalLink linkname="Platzverwaltung" route={ADM_COURTS}></InternalLink></Alert>
                    </Grid>
                    :
                    <Grid item xs={12} align="center">
                        {!props.isLoading ?
                            <TableContainer sx={{ borderRadius: 5, left: 1, zIndex: 2, backgroundColor: "#eee" }}>
                                <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '4px 4px' }}>
                                    <TableHead >
                                        <TableRow sx={{ borderRadius: 1 }}>
                                            <TableCell className={classes.tableHeader} sx={{ position: 'sticky', left: 0, zIndex: 1, width: { xs: "45px", md: "70px" } }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: 500, }}></Typography></TableCell>
                                            {props.courts && props.courts.map((court, i) => (
                                                <TableCell key={i} className={classes.tableHeader} align="center"><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: { xs: 500, md: 500 }  }}>{court.name}</Typography></TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.rows.map((row, i) => (
                                            <TableRow hover={true} key={i} sx={{ borderRadius: 1, m: 10 }}>
                                                <TableCell sx={{ position: 'sticky', background: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", left: 0, zIndex: 2, pl: 1, pr: 1, width: { xs: "45px", md: "70px"} }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: { xs: 500, md: 500 } }}>{row[0]}</Typography></TableCell>
                                                {props.courts && props.courts.map((court, i) => (
                                                    <TableCell key={i} align="center" sx={{ border: 0, p: 0, background: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", borderRadius: "4px", maxWidth: { xs: "200px", md:"250px"}}}>
                                                        <AdminBookingButton shortText={shortText} setSelectedIDs={handleSelectedIDs} setNewBookingData={setNewBookingData} zeit={row[0]} data={row[court.id]} handleOpen={handleOpen} day={props.dayFilter} courts={props.courts} court={court} enddate={enddate} startdate={startdate} setStartdate={setStartdate} setEnddate={setEnddate} targetCourts={targetCourts} setTargetCourts={setTargetCourts} settings={props.settings} />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <CircularProgress></CircularProgress>
                        }
                    </Grid>
                }
            </Grid >
            <CheckoutSlider targetCourts={targetCourts} startdate={startdate} enddate={enddate} checkoutHandler={setModalOpen} />
            {selectedIDs?.length > 0 ?
                <PrimaryButton id="btn-admbooking-deleteselection" variant="contained" fullWidth startIcon={<Delete />} text="Auswahl löschen" onClick={() => { handleMassDelete() }} color="warning" sx={{ position: "fixed", width: "100%", height: "75px", pb: 3, bottom: "0px", left: "50%", transform: "translate(-50%)", zIndex: 1000 }} />
                : <></>
            }
            <AdminBookingDialog open={modalOpen} numBookingUnits={numBookingUnits} startdate={startdate} enddate={enddate} targetCourts={targetCourts} dayFilter={props.dayFilter} onClose={handleClose} newBookingData={newBookingData} populateTable={props.populateTable} settings={props.settings} courtType={props.courtType}></AdminBookingDialog>
            <MailDialog open={deleteDialogOpen} title={"Buchung stornieren.."} onClose={() => { handleCloseDeleteDialog() }} handleSubmit={handleDelete} submitName={"Löschen"} isHidden={false} isMailActivated={false}></MailDialog>
            <CourtsLockDialog open={openLockDialog} onClose={() => setOpenLockDialog(false)} getCourts={props.getCourts}></CourtsLockDialog>
        </>
    );
}

export default AdminBookingTable;
