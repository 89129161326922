import { Layers } from '@mui/icons-material';
import { Box, Checkbox, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'

function BookingViewReservation({ color, checkState, setSelectedIDs, setCheckState, data, primary, secondary, secondaryBold, shortText }) {

    const [tooltipOpen,setTootltipOpen] =useState(false);

    const handleTooltipClick = () => {
        setTootltipOpen(true);
        // Close the tooltip after 3 seconds
        setTimeout(() => setTootltipOpen(false), 3000);
      };

    return (
        <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: shortText ? 'opx' : '4px', pr: 1, borderLeft: 4, borderColor: color }} justifyContent="center">
            <Grid container direction="row" spacing={0.5} alignItems={"center"} wrap='nowrap' >
                {setCheckState &&
                    <Grid item>
                        <Stack direction={"row"}>
                            <Checkbox id="chb-reservation-delete" size="small" checked={checkState} onClick={() => { setSelectedIDs(data.id); setCheckState(!checkState) }} />
                            <Divider flexItem orientation='vertical' ></Divider>
                        </Stack>

                    </Grid>
                }
                <Grid item xs zeroMinWidth>
                    {shortText ?
                        <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{"belegt"}</Typography>
                        :
                        <>
                            {!primary ?
                                <Stack sx={{ minHeight: { xs: "34px", md: "44px" } }} justifyContent={"center"}>
                                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{secondary}</Typography>
                                </Stack>
                                :
                                <>
                                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{primary}</Typography>
                                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap color={secondaryBold ? "text.primary" : "text.secondary"}>{secondary}</Typography>
                                </>
                            }
                        </>
                    }
                </Grid>
                {data?.collision >0 ?
                    <Grid item>
                        <Tooltip open={tooltipOpen} title={`Diese Buchung kollidiert mit ${data?.collision} anderen Buchung/en`} >
                            <Layers color="warning" onClick={()=>handleTooltipClick()}/>
                        </Tooltip>
                    </Grid>
                    :
                    <></>
                }
            </Grid>
        </Box>
    )
}

export default BookingViewReservation