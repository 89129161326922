import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer'
import { Alert, AlertTitle, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material'
import TitleCard from '../../components/TitleCard'
import { EventAvailable, LocationOn, Person, Schedule } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { confirmReservationByCode_api } from '../../api/Reservations/reservations'

function MyBookingConfimationContainer() {

    const [searchParam, setSearchParam] = useSearchParams();
    const [code, setCode] = useState(searchParam.get("code") || false);
    const [confirmationStatus, setConfirmationStatus] = useState(0);
    const [messageData, setMessageData] = useState("");
    const [responseCode, setResponseCode] = useState("");

    const confirmCourtByCode = () => {
        var promise = confirmReservationByCode_api(code);
        promise.then((result) => { setConfirmationStatus(1); setMessageData(result.data);  setResponseCode(result.status) })
            .catch(error => { setMessageData(error.response.data); setConfirmationStatus(2); setResponseCode(error.response.status) });
    }

    useEffect(() => {
        confirmCourtByCode();
        return () => {
        };
    }, [code]);

    return (
        <>
            <Header />
            <TitleCard title={"Buchungsbestätigung"} subheader={"Bestätige deine anstehende Buchung!"} icon={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></TitleCard>

            <Container maxWidth={"md"} sx={{ mt: 4 }}>
                <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                    {code ?
                        <>
                            {confirmationStatus == 1 ?
                                <>
                                    <Grid item xs={12}>
                                        <Alert severity='info' variant="outlined" >
                                            <AlertTitle>Zusmmenfassung der Reservierung</AlertTitle>
                                            <Stack direction={"column"} spacing={2} sx={{ pt: 2 }}>
                                                <Stack direction={"row"} spacing={1}>
                                                    <Person />
                                                    <Typography>{messageData['name']}</Typography>
                                                </Stack>
                                                {/* <Stack direction={{ xs: "column", sm: "column" }} spacing={1}> */}
                                                <Stack direction={"row"} spacing={1}>
                                                    <Schedule />
                                                    <Typography>{messageData['time']}</Typography>
                                                </Stack>
                                                <Stack direction={"row"} spacing={1}>
                                                    <LocationOn />
                                                    <Typography>{messageData['court_name'] && messageData['court_name']}</Typography>
                                                </Stack>
                                                {/* </Stack> */}
                                            </Stack>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert severity={'success'} variant="filled" sx={{ color: "#fff" }}>
                                            <Typography>{messageData['message']}</Typography>
                                        </Alert>
                                    </Grid>
                                </>
                                : confirmationStatus == 2 ?
                                    <>
                                        <Grid item xs={12}>
                                            <Alert severity='warning' variant="filled" ><Typography>{(messageData['message'] + " " + responseCode) || messageData['error']}</Typography></Alert>
                                        </Grid>
                                    </>
                                    :
                                    <Stack direction={"row"} justifyContent={"center"} sx={{ width: "100%" }}>
                                        <CircularProgress></CircularProgress>
                                    </Stack>

                            }
                        </>
                        :
                        <></>
                    }
                </Grid>
            </Container>
            <Footer />
        </>
    )
}

export default MyBookingConfimationContainer