import { Box } from '@mui/material'
import React from 'react'

function BorderedIcon({ icon }) {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ boxShadow: "0px 1px 6px 0px rgba(194, 224, 255, 1),0px 2px 30px 0px rgba(234, 237, 241, 0.3) inset", border: 1, borderRadius: 1, borderColor: "rgba(194, 224, 255, 1)", p: 0.5, backgroundColor: "#F0F7FF" }}>
            {icon}
        </Box> 
    )
}

export default BorderedIcon