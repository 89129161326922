import { Card, CardContent, Divider, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import { AccountBalance, Launch } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import BorderedIcon from '../../../components/BorderedIcon'

function AdmNuligaInterfaceCard({ club, title = "Nuliga Schnittstelle", iconTitle = false }) {

    const navigate = useNavigate();
    return (
        <>
            <Card raised>
                <CardContent>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        {iconTitle ?
                            <Grid item xs={"auto"} align="left">
                                <Stack direction="row" spacing={3}>
                                    <BorderedIcon icon={<AccountBalance color="primary" />} />
                                    <Typography variant="h6">{title}</Typography>
                                </Stack>
                            </Grid>
                            :
                            <Grid item xs align="left">
                                <Typography variant="h6" >{title}</Typography>
                            </Grid>
                        }
                        <Grid item align="right">
                            <PrimaryIconButton icon={<Launch color="primary"></Launch>} onClickAction={() => window.open(club?.nuliga_fedaration_link, '_blank').focus()} tooltipTitle="Zu Nuliga"></PrimaryIconButton>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: -1 }}>
                            <Divider></Divider >
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                <Typography textAlign={"center"} fontWeight={"medium"} variant="h6">{club?.club_name}</Typography>
                                <Typography textAlign={"center"} color="text.secondary" variant="body2">{"VNr: " + club?.club_number + " | ID: " + club?.club_id}</Typography>
                            </Stack>
                            <List dense>
                                <ListItem secondaryAction={<PrimaryIconButton onClickAction={() => { window.open(club?.club_info_link, '_blank').focus() }} icon={<Launch color="primary" />} />}>
                                    <ListItemText primary={"Vereinsinformationen"} secondary={"Übersicht der Vereinsinformationen"} slotProps={{ primary: { fontWeight: "medium" } }} />
                                </ListItem>
                                <ListItem secondaryAction={<PrimaryIconButton onClickAction={() => { window.open(club?.club_meetings_link, '_blank').focus() }} icon={<Launch color="primary" />} />}>
                                    <ListItemText primary={"Begegnungen"} secondary={"Auflistung aller Begegnungen"} slotProps={{ primary: { fontWeight: "medium" } }} />
                                </ListItem>
                                <ListItem secondaryAction={<PrimaryIconButton onClickAction={() => { window.open(club?.club_pools_link, '_blank').focus() }} icon={<Launch color="primary" />} />}>
                                    <ListItemText primary={"Namentliche Mannschaftsmeldung"} secondary={"Auflistung namentlicher Meldungen pro Altersgruppe"} slotProps={{ primary: { fontWeight: "medium" } }} />
                                </ListItem>
                                <ListItem secondaryAction={<PrimaryIconButton onClickAction={() => { window.open(club?.club_teams_link, '_blank').focus() }} icon={<Launch color="primary" />} />}>
                                    <ListItemText primary={"Mannschaften"} secondary={"Auflistung aller gemeldeten Mannschaften"} slotProps={{ primary: { fontWeight: "medium" } }} />
                                </ListItem>
                                <ListItem secondaryAction={<PrimaryIconButton onClickAction={() => { window.open(club?.club_lk_link, '_blank').focus() }} icon={<Launch color="primary" />} />}>
                                    <ListItemText primary={"Leinstungsklassen"} secondary={"Auflistung aller Leistungsklassen"} slotProps={{ primary: { fontWeight: "medium" } }} />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default AdmNuligaInterfaceCard