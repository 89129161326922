import { Delete, Edit, Lock, LockOpen, } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import React from 'react'

import ActionMenu from '../../../components/ActionMenu';

function CourtActionMenu(props) {
    return (
        <ActionMenu id="btn-courtcard-menu">
            {/* {props.isAdmin && props.adminMode ? */}
            {/* <MenuItem onClick={() => { props.resetCourt(); }}>
                <ListItemIcon>
                    <LockOpen fontSize="small" />
                </ListItemIcon>
                Öffnen / Reset
            </MenuItem> */}
            <MenuItem onClick={() => { props.lockDialog(true); }}>
                <ListItemIcon>
                    <Lock fontSize="small" />
                </ListItemIcon>
                Platz sperren
            </MenuItem>
            <MenuItem onClick={() => { props.editAction(); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                Bearbeiten
            </MenuItem>
            <MenuItem id="btn-courtcard-menu-delete" onClick={() => { props.deleteCourt(); }}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                Löschen
            </MenuItem>
        </ActionMenu>
    );
}

export default CourtActionMenu