import { ChevronRight, Sports } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

function HelpMenuListItem({ primary, secondary, clickHandler,icon }) {
  return (
    <ListItem disablePadding>
            <ListItemButton onClick={() => { clickHandler() }}>
              <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} secondary={secondary} primaryTypographyProps={{ variant: "body1", fontWeight: 500 }} />
                <ChevronRight/>
            </ListItemButton>
        </ListItem>
  )
}

export default HelpMenuListItem