import { Alert, AlertTitle, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getUsersNamesWithFrequentPartners, validateUsersBookingRequest_api } from '../../api/Users/users';
import { addMinutes,format, isValid } from 'date-fns';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { Box } from '@mui/system';
import SecondaryButton from '../../components/SecondaryButton';
import PrimaryButton from '../../components/PrimaryButton';
import { AccessTime, ChevronLeft, ChevronRight, DeleteForever, EuroSymbolSharp, Event, Group, LocationOn, PersonAdd, TextFields } from '@mui/icons-material';
import { de } from 'date-fns/locale';
import { setReservation } from '../../api/Reservations/reservations';
import { connect } from 'react-redux';
import { getSettings_api, getSettingsIndoor_api, getSettingsOutdoor_api } from '../../api/Settings/settings';
import TimeField from '../../components/TimeField';
import TertiaryButton from '../../components/TertiaryButton';
import { useSnackbar } from '../../components/SnackbarContext';
import { convertMinutesToRemainingTime} from '../../utils/dateMechanics';


function BookingDialog(props) {
  const { newBookingData } = props
  const [users, setUsers] = useState([{}]);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [settings, setSettings] = useState(null);
  const [bookingDateTime, setBookingDateTime] = useState(null);
  const [bookingEnd, setBookingEnd] = useState(null);
  const [expansionMinutes, setExpansionMinutes] = useState(null);
  const [expansionIndex, setExpansionIndex] = useState(0);
  const [freetext, setFreetext] = useState("");
  const [mode, setMode] = useState("menu");
  const [unitPrice, setUnitPrice] = useState(0);

  //mate or Guest
  const [mate, setMate] = useState();
  const [guest, setGuest] = useState("");
  const [isBulk, setIsBulk] = useState(false);

  const { showSnackbar } = useSnackbar();

  const getClubSettings = () => {
    var promise = null;
    if (props.courtType == "outdoor") {
      promise = getSettingsOutdoor_api();
    }
    else if (props.courtType == "indoor") {
      promise = getSettingsIndoor_api();
    }
    else {
      promise = getSettings_api();
    }
    promise.then((result) => {
      setSettings(result.data[0]);
      if (newBookingData.time != null) {
        var tempExpansionMinutes = [];
        //calculate max number of extension options depending  remaining hours untill end of booking date and remaining open bookings 
        var extensionLimit = Math.min(result.data[0].allowed_bookings_per_day, (result.data[0].booking_endtime - parseInt(newBookingData.time.substring(0, 2))));
        //console.log(extensionLimit, result.data[0].allowed_bookings_per_day, result.data[0].booking_endtime, parseInt(newBookingData.time.substring(0, 2)));

        if (result.data[0].allowed_bookings_per_day > 1) {
          for (var i = 1; i <= extensionLimit; i++) {
            console.log(extensionLimit)
            tempExpansionMinutes[i - 1] = i * result.data[0].booking_duration;
          }
          setExpansionMinutes(tempExpansionMinutes);
        }
        else {
          tempExpansionMinutes = [result.data[0].booking_duration]
          setExpansionMinutes(tempExpansionMinutes);
        }
        if (!result.data[0].allow_guest_bookings && !result.data[0].allow_freetext_bookings) {
          setMode("memberbooking");
        }
      }
    })
      .catch();

  }

  const fetchUsers = () => {
    const userPromise = getUsersNamesWithFrequentPartners();
    userPromise.then((result) => {

      var users_autocomplete = [];
      // users_autocomplete.push({ label: "Hans Peter", id: 13, type: "Häufig verwendet" });
      for (var i = 0; i < result.data.length; i++) {
        var user_id = result.data[i].id;
        var user_label = result.data[i].firstname + " " + result.data[i].surname + " | " + user_id;
        if (i <= 2) {
          users_autocomplete.push({ label: user_label, id: user_id, type: "Häufig verwendet" });
        }
        else {
          users_autocomplete.push({ label: user_label, id: user_id, type: "Mitglieder" });
        }


      }

      setUsers(users_autocomplete)
    });
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case "guestname":
        setGuest(event.target.value);
        break;
      case "freetext":
        setFreetext(event.target.value);
        break;
      case "isBulk":
        setIsBulk(event.target.checked);
        break;
      default:
        break;
    }
  }

  const validateBookingRequest = (day, id) => {
    if (props.courtType == "outdoor") {
      var promise = validateUsersBookingRequest_api(day, props.courtType, id);
      promise.then((result) => {
        if (result.data.validation_result == "false") {
          showSnackbar("Dein Partner hat bereits die maximale Anzahl an offenen Buchungen!", "warning");
          setSubmitDisabled(true);
          return false;
        }
        else if (result.data.validation_result == "true") {
          setSubmitDisabled(false);
        }
        else {
        }
      });
    }
    else {
      setSubmitDisabled(false);
    }
  }

  const handleBook = () => {
    setSubmitDisabled(true);
    var promise = null;
    var data = null;

    if (mode === "guestbooking" && (!guest || guest.trim() === "")) {
      showSnackbar("Bitte geben Sie einen Gastnamen an", "warning");
      setSubmitDisabled(true);
      return;
    }
    if (mode === "memberbooking" && (!mate || mate == props.userid)) {
      showSnackbar("Reservierung fehlgeschlagen! Bitte wählen Sie einen Partner aus", "warning");
      setSubmitDisabled(true);
      return;
    }

    if (mode === "freetextbooking" && (!freetext || freetext.trim() === "")) {
      showSnackbar("Bitte geben Sie einen Freitext an", "warning");
      setSubmitDisabled(true);
      return;
    }

    data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), mate_id: mate, guestname: guest, endtime: bookingEnd, starttime: bookingDateTime, name: freetext, isFreetext: mode === "freetextbooking" ? true : false };
    promise = setReservation(data);
    promise.then(() => { props.onClose(); props.populateTable(); showSnackbar("Reservierung erfolgreich", "success"); setSubmitDisabled(false); })
      .catch(error => { showSnackbar("Fehler: " + error.response.data.error, "error"); setSubmitDisabled(false); });
  }

  const calculateEndBookingTime = (minutes) => {
    var tempdate = addMinutes(bookingDateTime, minutes);
    setBookingEnd(tempdate);
  }

  const calculatePrice = () => {
    if (mode == "memberbooking") {
      setUnitPrice(settings.member_booking_price);
    }
    else {
      setUnitPrice(settings.guest_booking_price);
    }
  }

  useEffect(() => {
    if (mate) {
      validateBookingRequest(format(new Date(props.dayFilter), "yyyy-MM-dd"), mate);
      setFreetext("");
      setGuest("");
    }
    else {
      setSubmitDisabled(true);
    }
    return () => {

    };
  }, [mate]);

  useEffect(() => {
    if (mode === "guestbooking" && guest.trim() != "" && guest) {
      setMate();
      setFreetext("");
      setSubmitDisabled(false);
    }
    else {
      setSubmitDisabled(true);
    }
  }, [guest]);

  useEffect(() => {
    if (mode === "freetextbooking" && freetext.trim() != "" && freetext) {
      setGuest("");
      setMate();
      setSubmitDisabled(false);
    }
    else {
      setSubmitDisabled(true);
    }
  }, [freetext]);

  useEffect(() => {
    if (!isBulk && settings) {
      var endtime = addMinutes(new Date(format(props.dayFilter, "yyyy-MM-dd") + "T" + newBookingData.time + ":00"), settings.booking_duration)
      setBookingEnd(endtime);
      setExpansionIndex(0);
    }
  }, [isBulk]);

  useEffect(() => {
    if (expansionMinutes) {
      calculateEndBookingTime(expansionMinutes[0])
      setExpansionIndex(0)
      setIsBulk(false);
    }
    if (settings) {
      calculatePrice();
    }
  }, [mode]);


  useEffect(() => {
    fetchUsers();
    setGuest("");
    setSubmitDisabled(true);
    getClubSettings();
    setExpansionIndex(0);
    setIsBulk(false);
    setMate();
    setFreetext("");
    setMode("menu");

  }, [props.open]);

  useEffect(() => {
    if (props.open && settings) {
      var datetime = new Date(format(props.dayFilter, "yyyy-MM-dd") + "T" + newBookingData.time + ":00");
      setBookingDateTime(datetime);
      var endtime = addMinutes(new Date(format(props.dayFilter, "yyyy-MM-dd") + "T" + newBookingData.time + ":00"), settings.booking_duration)
      setBookingEnd(endtime);
    }

    // if(settings && settings['booking_deletetime'])
    //   {console.log(formatDistance(new Date(),addMinutes(new Date(),2880),{locale:de}))
    //     setFormatedDeleteTime(formatDistance(0,settings['booking_deletetime'],{locale:de}));
    //   }
  }, [props.open, settings]);



  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} elevation={10} fullWidth maxWidth={"sm"}>
        <DialogTitle sx={{ backgroundColor: "#eee" }}>Platzbuchung</DialogTitle>

        <Divider></Divider>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info"><AlertTitle>Buchungsinformationen</AlertTitle>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Event color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Typography variant="body1" fontWeight={500}>Datum</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>{format(new Date(props.dayFilter.toString()), "EEEE dd. MMMM ", { locale: de })}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <AccessTime color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Typography variant="body1" fontWeight={500}>Uhrzeit</Typography>
                    < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(bookingDateTime) && format(bookingDateTime, 'HH:mm') + " - " + format(bookingEnd, "HH:mm")}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <LocationOn color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Typography variant="body1" fontWeight={500}>Platz</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 400 }}>{newBookingData.name}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <DeleteForever color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{"Löschfrist"}</Typography>
                    {settings && settings['booking_deletetime'] > 0 ?
                      <Typography variant="body2" sx={{ fontWeight: 400 }}>{"bis " + convertMinutesToRemainingTime(settings['booking_deletetime']) + " vor Spielbeginn"}</Typography>
                      : settings && settings['booking_deletetime'] == 0 ?
                        <Typography variant="body2" sx={{ fontWeight: 400 }}>{"bis zum Spielbeginn"}</Typography>
                        :
                        <></>
                    }
                    <Typography variant="body2" sx={{ fontWeight: 400 }}>{"innerhalb 30 Minuten nach Buchungserstellung"}</Typography>
                  </Stack>
                </Stack>
                {/* <Typography variant="body1" sx={{ fontWeight: 500 }}>{+newBookingData.time + " Uhr auf " + newBookingData.name}</Typography> */}
                {settings && (settings.member_booking_price != 0 || mode === "guestbooking" && settings.guest_booking_price != 0) ?
                  <>
                    <Divider></Divider>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <EuroSymbolSharp color="primary" sx={{ width: "20px", height: "20px" }} />
                      <Stack>
                        {mode === "guestbooking" || mode === "memberbooking" ?
                          <Typography variant="body1" fontWeight={"bold"}>{settings && unitPrice != 0 && expansionMinutes != null ? "Diese Buchung kostet " + (unitPrice * (expansionMinutes[expansionIndex] / settings.booking_duration)).toFixed(2) + "€" : "kostenlos"}</Typography>
                          :
                          <Typography variant="body1">{"Kosten"}</Typography>
                        }
                        <Typography variant="body2">{"Die Kosten werden dem Buchenden in Rechnung gestellt"}</Typography>

                      </Stack>
                    </Stack>
                  </>
                  : <></>
                }
              </Stack>
            </Alert>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            {mode === "menu" ?
              <Grid item xs={12}>
                <List sx={{ width: '100%' }}>
                  <ListItemButton id={"btn-bookingdialog-menu-memberbooking"} onClick={() => { setMode("memberbooking"); }}>
                  <ListItemIcon><Group color="primary"/></ListItemIcon>
                    {props.settings &&
                      <ListItemText primary={"Mitgliederbuchung | " + (props.settings['member_booking_price'] != 0 ? props.settings['member_booking_price'] + "€" : "kostenlos")} secondary={"Eine Buchung mit einem Vereinsmitglied"} primaryTypographyProps={{ fontWeight: 500 }} />
                    }
                    <ChevronRight></ChevronRight>
                  </ListItemButton>
                  {props.settings && props.settings['allow_guest_bookings'] ?
                    <ListItemButton id={"btn-bookingdialog-menu-guestbooking"} onClick={() => setMode("guestbooking")} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                       <ListItemIcon><PersonAdd color="primary"/></ListItemIcon>
                      <ListItemText primary={"Gastbuchung | " + (props.settings['guest_booking_price'] != 0 ? props.settings['guest_booking_price'] + "€" : "kostenlos")} secondary={"Eine Buchung mit einem Gastspieler (nicht Vereinsmitglied)"} primaryTypographyProps={{ fontWeight: 500 }} />
                      <ChevronRight color="text.secondary" />
                    </ListItemButton>
                    :
                    <></>
                  }
                  {props.settings && props.settings['allow_freetext_bookings'] ?
                    <ListItemButton id={"btn-bookingdialog-menu-freetextbooking"} onClick={() => setMode("freetextbooking")} sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      <ListItemIcon><TextFields color="primary"/></ListItemIcon>
                      <ListItemText primary={"Freitextbuchung"} secondary={"Eine Buchung außerhalb des verf. Buchungskontingents unter Angabe des Zwecks"} primaryTypographyProps={{ fontWeight: 500 }} />
                      <ChevronRight></ChevronRight>
                    </ListItemButton>
                    :
                    <></>
                  }
                </List>
              </Grid>
              :
              <></>
            }
            {mode !== "menu" && (props.settings && (props.settings['allow_freetext_bookings'] || props.settings['allow_guest_bookings'])) ?
              <TertiaryButton text="zurück zur Auswahl" startIcon={<ChevronLeft />} onClick={() => setMode("menu")} />
              :
              <></>
            }
            {mode === "memberbooking" ?

              <Grid item xs={12}>

                <Stack>
                  <Typography fontWeight={500} >Mitgliederbuchung</Typography>
                  <Typography variant="body2" color="text.secondary">Bitte wähle deinen Spielpartner aus</Typography>
                  <Collapse in={mode === "memberbooking"}>
                    <CustomAutocomplete id="ac-bookingdialog-member" size="small" options={users} label="Spielpartner (Mitglied)" setTarget={setMate} sx={{ mt: 1 }} />
                  </Collapse>
                </Stack>
              </Grid>

              :
              <></>
            }
            {props.settings && props.settings['allow_guest_bookings'] && mode === "guestbooking" ?

              <Grid item xs={12}>
                <Stack>
                  <Typography fontWeight={500}>Gastbuchung</Typography>
                  <Typography variant="body2" color="text.secondary">Bitte gib Vor- und Nachnamen des Gasts an.</Typography>
                  <Collapse in={mode === "guestbooking"}>
                    <TextField id="tf-bookingdialog-guest" fullWidth name="guestname" size="small" value={guest} onChange={handleChange} label="Vor- & Nachname des Gasts" sx={{ mt: 1 }} />
                  </Collapse>
                </Stack>
              </Grid>
              :
              <></>
            }
            {props.settings && props.settings['allow_freetext_bookings'] && mode === "freetextbooking" ?
              <Grid item xs={12}>
                <Stack>
                  <Typography fontWeight={500}>Freitextbuchung</Typography>
                  <Typography variant="body2" color="text.secondary">Bitte gib den Buchungszweck an. Dieser Name erscheint in der Buchung.</Typography>
                  <Collapse in={mode === "freetextbooking"}>
                    <TextField fullWidth name="freetext" size="small" value={freetext} onChange={handleChange} label="Buchungszweck" sx={{ mt: 1 }} />
                  </Collapse>
                </Stack>
              </Grid>
              :
              <></>
            }
            {settings && settings.allowed_bookings_per_day > 1 && mode !== "menu" ?
              <>
                <Grid item xs={12}><Divider></Divider></Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Buchung verlängern?</Typography>
                      <Typography variant="body2">{"Möchtest du mehr als " + settings.booking_duration + " Minuten reservieren?"}</Typography>
                    </Box>
                    <Switch size="medium" name="isBulk" id="isBulk" checked={isBulk} onChange={handleChange}></Switch>
                  </Stack>

                  <Collapse in={isBulk}>
                    <Grid item xs={12}>
                      {mode !== "freetextbooking" ?
                        <List dense>
                          {expansionMinutes && expansionMinutes.map((minutes, i) => (
                            <ListItem key={i} sx={{ px: 0 }}>
                              <SecondaryButton variant={expansionIndex === i ? "contained" : "outlined"} text={convertMinutesToRemainingTime(expansionMinutes[i])} onClick={() => { calculateEndBookingTime(minutes); setExpansionIndex(i) }} fullWidth></SecondaryButton>
                            </ListItem>
                          ))
                          }
                        </List>
                        :
                        <TimeField name="endtime" fullWidth label={"Buchung bis"} date={bookingEnd} setDate={setBookingEnd} views={settings.booking_duration == 60 ? ['hours'] : ['hours', 'minutes']} minutesStep={settings.booking_duration} textReadOnly sx={{ mt: 2 }}></TimeField>
                      }
                    </Grid>
                  </Collapse>
                </Grid>
              </>
              :
              <></>
            }
          </Grid>
        </DialogContent>
        <Divider></Divider>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <SecondaryButton text="Abbrechen" onClick={props.onClose} />
          <PrimaryButton id="btn-bookingdialog-submit"text="Bestätigen" disabled={submitDisabled} onClick={handleBook} />
        </DialogActions>
      </Dialog >
    </>
  );
}

export default BookingDialog;
