import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header.jsx'
import { format, isValid } from 'date-fns';
import { getReservationsByDay_api } from '../../api/Reservations/reservations.js';
import populateReservations from '../../utils/populateReservations.js';
import { AcUnit, EventAvailable, LightMode } from '@mui/icons-material';
import AdminBookingTable from './AdmBookingTable.jsx';
import { Box, Grid, Tabs } from '@mui/material';
import TitleCard from '../../components/TitleCard.jsx';
import Footer from '../../components/Footer.jsx';
import { getCourts_api } from '../../api/Courts/courts.js';
import { getSettings_api, getSettingsIndoor_api, getSettingsOutdoor_api } from '../../api/Settings/settings.js';
import MyTab from '../../components/MyTab.jsx';
import MainContentContainer from '../../components/MainContentContainer.jsx';

export default function AdminBookingContainer() {
    const [dayFilter, setDayFilter] = useState(new Date());
    const [rows, setRows] = useState([]);
    const [courts, setCourts] = useState();
    const [settings, setSettings] = useState();
    const [courtType, setCourtType] = useState("outdoor");
    const [isLoading, setIsLoading] = useState(false);
    const [reservations,setReservations] = useState([setCourtType]);

    const populateTable = () => {
        setIsLoading(true);
        const reservationPromise = getReservationsByDay_api(format(new Date(dayFilter.toString()), "yyyy-MM-dd"), null, courtType);
        reservationPromise.then((result) => { setReservations(result.data);setRows(populateReservations(result.data, courts, settings)); setTimeout(() => { setIsLoading(false) }, 350); });
    }

    const getCourts = () => {
        var promise = getCourts_api("?type=" + courtType);
        promise.then((result) => { setCourts(result.data); })
            .catch((error) => { });
    }

    const getSettings = (newCourtType = null) => {
        var promise = null;
        if (newCourtType == "outdoor") {
            promise = getSettingsOutdoor_api();
        }
        else if (newCourtType == "indoor") {
            promise = getSettingsIndoor_api();
        }
        else {
            promise = getSettings_api();
        }
        promise.then((result) => { setSettings(result.data[0]) })
            .catch((error) => { });
    }


    useEffect(() => {
        setIsLoading(true);
        getSettings(courtType);
    }, [courtType])

    useEffect(() => {
        if (settings) {
            getCourts();
        }
    }, [settings]);

    useEffect(() => {
        if (courts && courts.length > 0 && settings && isValid(dayFilter)) {
            populateTable();
        }
    }, [dayFilter, courts]);

    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-admbooking" title={"Admin Platzverwaltung"} subheader={"Verwalte alle Buchungen!"} icon={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer px={0} py={0} pb={8}>
                <Box
                    sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
                    <Tabs onChange={(event, newValue) => { setCourtType(newValue); }} value={courtType} variant='scrollable' aria-label="Tabs where each tab needs to be selected manually">
                        <MyTab value="outdoor" label="Außenplätze" icon={<LightMode color="warning" />} iconPosition="start" sx={{ pb: 0 }} disabled={isLoading} ></MyTab>
                        <MyTab value="indoor" label="Hallenplätze" icon={<AcUnit color="primary" />} iconPosition="start" sx={{ pb: 0 }} disabled={isLoading}></MyTab>
                    </Tabs>
                </Box>
                
                <AdminBookingTable reservations={reservations} courts={courts} rows={rows} dayFilter={dayFilter} setDayFilter={setDayFilter} populateTable={populateTable} getCourts={getCourts} settings={settings} courtType={courtType} isLoading={isLoading}></AdminBookingTable>
            </MainContentContainer>
            <Footer />
        </>
    );
}
