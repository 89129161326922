import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material'
import React, {useEffect, useState} from 'react'

function WeekDayCheckboxes(props) {
    const { days, setDays, disabled } = props;
    const [state,setState]= useState(null);

    const handleChangeDays = (event) => {
        setDays({
            ...days,
            [event.target.name]: event.target.checked,
        });
    };

    useEffect(() => {
        
        return () => {
            setState(null);
        };
    }, []);
    return (
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">Tage</FormLabel>
            <FormGroup >
                <FormControlLabel
                    control={
                        <Checkbox checked={days.monday || false} onChange={handleChangeDays} name="monday" disabled={disabled}/>
                    }
                    label="Montag"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.tuesday || false} onChange={handleChangeDays} name="tuesday" disabled={disabled}/>
                    }
                    label="Dienstag"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.wednesday || false} onChange={handleChangeDays} name="wednesday" disabled={disabled}/>
                    }
                    label="Mittwoch"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.thursday || false} onChange={handleChangeDays} name="thursday" disabled={disabled}/>
                    }
                    label="Donnerstag"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.friday || false} onChange={handleChangeDays} name="friday" disabled={disabled}/>
                    }
                    label="Freitag"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.saturday || false} onChange={handleChangeDays} name="saturday" disabled={disabled}/>
                    }
                    label="Samstag"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={days.sunday || false} onChange={handleChangeDays} name="sunday" disabled={disabled}/>
                    }
                    label="Sonntag"
                />
            </FormGroup>
        </FormControl>
    )
}

export default WeekDayCheckboxes