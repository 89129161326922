import { Box,Container, Divider, Fab, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tabs } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import TitleCard from "../../components/TitleCard";
import GroupIcon from '@mui/icons-material/Group';
import UsersTable from "./UsersTable";
import TableFilter from "../../components/TableFilter";
import { createRegistration_api, deleteUser_api,downloadUsersExport_api, getCount_api, getRegistrations_api, getUsers_api, sendAllUsersMail_api, sendUserMail_api, updateNuligaAccounts_api, verifyUser_api } from "../../api/Users/users";
import { AccessTime, Article, Dashboard, FileDownload, FilterAlt, Mail, PersonAdd, Search, UpdateSharp } from "@mui/icons-material";
import UserCard from "./UserCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryIconButton from "../../components/PrimaryIconButton";
import MailDialog from "../../components/MailDialog";
import Footer from "../../components/Footer";
import AddMemberDialog from "./AddMemberDialog";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getActiveUsers_api } from "../../api/Users/users";
import FilterChip from "../../components/FilterChip";
import MyTab from "../../components/MyTab";
import { format } from "date-fns";
import { useSnackbar } from "../../components/SnackbarContext";
import { exportDataToCSV } from "../../utils/export";
import MainContentContainer from "../../components/MainContentContainer";

function UserFilterMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const addSearchParam = (mewKey, newValue) => {
        if (props.searchParams.has(mewKey)) {
            props.searchParams.set(mewKey, newValue)
        }
        else {
            props.searchParams.append(mewKey, newValue)
        }

        props.setSearchParams(props.searchParams);
    }

    return (
        <div>
            <IconButton
                id="usermenu"
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => { handleClick(event) }}
                color="primary"
            >
                <FilterAlt></FilterAlt>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'usermenu',
                }}
            >
                {/* <MenuItem onClick={() => { setAnchorEl(null); addSearchParam("role","admin");}}>
                    <ListItemIcon>
                        <AdminPanelSettingsSharp fontSize="small" />
                    </ListItemIcon>
                    Admin
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); addSearchParam("role","user");}}>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    User
                </MenuItem> */}
                <MenuItem onClick={() => { setAnchorEl(null); addSearchParam("last_activity", "today"); }}>
                    <ListItemIcon>
                        <AccessTime fontSize="small" />
                    </ListItemIcon>
                    Heute Online
                </MenuItem>
            </Menu>
        </div>
    );
}

function UserContainerMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Fab
                id="usermenu"
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => { handleClick(event) }}
                color="primary"
            >
                <MoreVertIcon></MoreVertIcon>
            </Fab>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'usermenu',
                }}
            >
                <MenuItem onClick={() => { setAnchorEl(null); props.setOpenRegisrtationDialog(true) }}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Mitglied erstellen
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.setOpenMailDialog(true) }}>
                    <ListItemIcon>
                        <Mail fontSize="small" />
                    </ListItemIcon>
                    Club-Nachricht
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.handleDonwloadExport() }}>
                    <ListItemIcon>
                        <FileDownload fontSize="small" />
                    </ListItemIcon>
                    Export Mitglieder
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.updateNuligaAccounts() }}>
                    <ListItemIcon>
                        <UpdateSharp fontSize="small"/>
                    </ListItemIcon>
                    Update LKs
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { setAnchorEl(null); props.setIsTableView(false) }}>
                    <ListItemIcon>
                        <Dashboard fontSize="small" />
                    </ListItemIcon>
                    CardView
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.setIsTableView(true) }}>
                    <ListItemIcon>
                        <Article fontSize="small" />
                    </ListItemIcon>
                    TableView
                </MenuItem>
            </Menu>
        </div>
    );
}

function UsersContainer() {

    const [searchParam, setSearchParam] = useSearchParams();
    const [filter, setFilter] = useState("");
    const [users, setUsers] = useState({});
    const [user, setUser] = useState(null);
    //const [showUsers, setShowUsers] = useState(searchParam.get("tab")||"users");
    const [counts, setCounts] = useState();
    const [isTableView, setIsTableView] = useState(true);
    const [search, setSearch] = useState(false);
    const navigate = useNavigate();

    const [openMailDialog, setOpenMailDialog] = useState(false);
    const [userIdMail, setUserIdMail] = useState(null);
    const [isSending, setIsSending] = useState();

    const [openRegisrtationDialog, setOpenRegisrtationDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [tabValue, setTabValue] = useState(searchParam.get("tab") || "users");
    const {showSnackbar} = useSnackbar();

    //tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    const getUsers = () => {
        if (!searchParam.get("last_activity")) {
            const data = getUsers_api(filter);
            data.then(result => { setUsers(result.data); showSnackbar("Daten aktuallisert","success"); })
                .catch(error => { showSnackbar("Fehler: " + error,"error"); });
        }
        else {
            const data = getActiveUsers_api();
            data.then(result => { setUsers(result.data); showSnackbar("Daten aktuallisert","success"); })
                .catch(error => { showSnackbar("Fehler: " + error,"error"); });
        }

    }

    const getRegistrations = () => {
        var promise = getRegistrations_api(filter);
        promise.then((result) => { setUsers(result.data); })
            .catch();
    }

    const getCounts = () => {
        var promise = getCount_api();
        promise.then((result) => { setCounts(result.data[0]) })
            .catch();
    }

    const refreshHandler = () => {
        getCounts();
        if (tabValue === "users") {
            getUsers();
        }
        else {
            getRegistrations();
        }

    }

    const updateNuligaAccounts = () => {
        var promise = updateNuligaAccounts_api();
        promise.then((result)=>{})
        .catch(()=>{})
    }

    //handlers
    const deleteHandler = (id) => {
        if (!id) {
            showSnackbar("Bitte wählen Sie zuerst einen User aus","warning"); 
        }
        else {
            const deleteEvent = deleteUser_api(id);
            deleteEvent.then(() => { refreshHandler(); showSnackbar("User erfolgreich gelöscht","success"); })
                .catch(error => { showSnackbar("Fehler:" + error,"error");});
        }
    }

    const detailsHandler = (id) => {
        if (!id) {
            showSnackbar("Bitte wählen Sie zuerst einen User aus","warning");
        }
        else {
            navigate("/users/" + id);
        }
    }

    const handleMailSubmit = (message, subject) => {
        setIsSending(true);
        //single Mail or mass clubmail
        if (userIdMail) {
            sendUserMail(message, subject, userIdMail);
        }
        else {
            sendClubMail(message, subject);
        }
    }

    const sendClubMail = (message, subject) => {
        var promise = sendAllUsersMail_api(message, subject);
        promise.then((result) => { setIsSending(false); showSnackbar(result.data,"success"); setOpenMailDialog(false); })
            .catch((error) => { setIsSending(false); });
    }

    const sendUserMail = (message, subject, id) => {
        var promise = sendUserMail_api(message, subject, id);
        promise.then((result) => { setIsSending(false); showSnackbar(result.data,"success"); setOpenMailDialog(false); })
            .catch((error) => { setIsSending(false); showSnackbar("Fehler beim Versenden der Mail","error"); });
    }

    const handleCreateUserSubmit = (firstname, lastname, email, role) => {
        var promise = createRegistration_api(firstname, lastname, email, role);
        promise.then((result) => { setOpenRegisrtationDialog(false); showSnackbar("Registrierung erfolgreich","success"); refreshHandler(); })
            .catch((error) => { showSnackbar(error.response.data['error'],"error"); });
    }

    const verifyUser = (id) => {
        var promise = verifyUser_api(id);
        promise.then((result) => { refreshHandler() })
            .catch();
    }

    const handleDonwloadExport = async()=>{
            
        var promise = downloadUsersExport_api();
        promise.then((result) => {
            exportDataToCSV(result.data,"Mitglieder");
        })
        .catch();           
    }

    useEffect(() => {
        refreshHandler();
        setSearchParam(searchParam);
        return () => {
        };
    }, [filter, tabValue]);

    useEffect(() => {
        if (!openMailDialog) {
            setUserIdMail(null);
        }
        return () => {

        };
    }, [openMailDialog]);

    useEffect(() => {
        refreshHandler()
        return () => {
        };
    }, [searchParam]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} justifyContent={"center"} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-users"title={"Mitglieder"} subheader={"Verwalte die Mitglieder im System!"} icon={<GroupIcon sx={{ width: 40, height: 40 }}></GroupIcon>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer maxWidth="xl">
                <Grid container item direction="row" justifyContent="space-between" alignItems="center" spacing={1} >
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <MyTab label="Users" value="users" />
                                <MyTab label="Registrierungen" value="registrations" />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Stack direction="row" spacing={1}>
                            <UserFilterMenu searchParams={searchParam} setSearchParams={setSearchParam}></UserFilterMenu>
                            <PrimaryIconButton icon={<Search></Search>} onClickAction={() => { setSearch(!search) }}></PrimaryIconButton>
                        </Stack>

                    </Grid>
                    <Grid item xs>
                        <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                            {tabValue !== "registrations" && searchParam && Array.from(searchParam.entries()).map(([key, value], i) =>
                                key !== "tab" ?
                                    <FilterChip key={i} keyName={key} value={value} searchParams={searchParam} setSearchParam={setSearchParam}></FilterChip>
                                    :
                                    <></>
                            )
                            }
                        </Stack>
                    </Grid>
                    <Grid item>
                        <UserContainerMenu handleDonwloadExport={handleDonwloadExport} updateNuligaAccounts={updateNuligaAccounts} setOpenRegisrtationDialog={setOpenRegisrtationDialog} setOpenMailDialog={setOpenMailDialog} setIsTableView={setIsTableView} />
                    </Grid>
                    {search &&
                        <Grid item xs={12}>
                            <TableFilter filter={filter} setFilter={setFilter} ></TableFilter>
                        </Grid>
                    }

                    {isTableView ?
                        <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={12}>
                                <UsersTable verifyUser={verifyUser} refreshHandler={refreshHandler} detailsHandler={detailsHandler} deleteHandler={deleteHandler} users={users} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} setOpenMailDialog={setOpenMailDialog} setUserIdMail={setUserIdMail} setUser={setUser} user={user}></UsersTable>
                            </Grid>
                        </Grid>
                        :
                        <Grid item container xs={12}>
                            <UserCard verifyUser={verifyUser} users={users} detailsHandler={detailsHandler} deleteHandler={deleteHandler}></UserCard>
                        </Grid>
                    }
                </Grid>
            </MainContentContainer>
            <Footer />
            <AddMemberDialog open={openRegisrtationDialog} submit={handleCreateUserSubmit} onClose={() => { setOpenRegisrtationDialog(false) }}></AddMemberDialog>
            <MailDialog open={openMailDialog} isSending={isSending} onClose={() => { setOpenMailDialog(false); }} title={userIdMail ? ("Nachricht an " + user.firstname + " " + user.surname) : "Nachricht an alle User"} submitName="Abschicken" handleSubmit={handleMailSubmit} isHidden={true} setUser={setUser} user={user} />
        </>
    );
}

export default UsersContainer;
