import { SportsTennis } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { format, isValid } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react'

function TeammatchListItem({ teammatch, secondaryAction, iconColor, ...props }) {
    return (
        <ListItem secondaryAction={secondaryAction}>
            <ListItemIcon><SportsTennis color={iconColor} /></ListItemIcon>
            <ListItemText sx={{ pr: 5 }} disableTypography primary={<Typography variant='body2' >{teammatch.teamName}</Typography>} secondary={<Stack><Typography variant='body2' color="text.secondary">{teammatch.opponent}</Typography><Typography variant="body2" color="text.secondary">{isValid(new Date(teammatch.date)) && format(new Date(teammatch.date), "dd.MM.yy. HH:mm", { locale: de })}</Typography></Stack>} />
        </ListItem>
    );
}

export default TeammatchListItem