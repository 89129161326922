
import { Card, CardContent, Divider, Grid, TextField, Typography, Box, Select, MenuItem, InputLabel, FormControl, CardHeader, Avatar, Switch, Toolbar, Alert, AlertTitle, Collapse, IconButton, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import { connectNuligaAccount_api, deleteUser_api, disconnectNuligaAccount_api, getUser, updateUser } from '../../../api/Users/users';
import { USERS } from '../../../navigation/CONSTANTS';
import Footer from '../../../components/Footer';
import SecondaryButton from '../../../components/SecondaryButton';
import PrimaryButton from '../../../components/PrimaryButton';
import TertiaryButton from '../../../components/TertiaryButton';
import ConnectUserToNuligaDialog from '../../../components/ConnectUserToNuligaDialog';
import { ChevronLeft, Close, HelpOutlined, Link, LinkOff } from '@mui/icons-material';
import { sanitize } from 'dompurify';
import { useSnackbar } from '../../../components/SnackbarContext';
import DeleteDialog from '../../../components/DeleteDialog';

function UsersDetails(props) {

    const [state, setState] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    //form states
    const [userData, setUserData] = useState({});
    const [firstname, setFirstname] = useState("na");
    const [lastname, setLastName] = useState("na");
    const [mail, setMail] = useState("na");
    const [expires, setExpires] = useState("na");
    const [role, setRole] = useState(1);
    const [nuligaLK, setNuligaLK] = useState("na");
    const [nuligaID, setNuligaID] = useState("na");
    const [nuligaName, setNuligaName] = useState("na");
    const [notifications, setNotifications] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [openNuliga, setOpenNuliga] = useState(false);
    const [openAlert, setOpenAlert] = useState(false)
    const [expandInfo1, setExpandInfo1] = useState(false);
    const [expandInfo2, setExpandInfo2] = useState(false);
    const [sepa, setSepa] = useState("");
    const [comment, setComment] = useState("");
    const {showSnackbar} = useSnackbar();
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Mitglied löschen", warningText: "Gelöschte Accounts können nicht wiederhergestellt werden. Beim Löschen des Accounts werden alle zugehörigen Informationen gelöscht", openDeleteDialog: false, deleteObjectName: (firstname +" "+lastname), deleteHandler: () => {} });

    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: true,deleteObjectName: ("den Account von " + firstname +" "+lastname), deleteHandler: () => deleteHandler(id) })
    }

    // TextField hanndler
    const handleChange = (event) => {

        switch (event.target.name) {
            case "firstName":
                setFirstname(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "email":
                setMail(event.target.value);
                break;
            case "expirationdate":
                setExpires(event.target.value);
                break;
            case "nuliga_lk":
                setNuligaLK(event.target.value);
                break;
            case "nuliga_id":
                setNuligaID(event.target.value);
                break;
            case "nuliga_name":
                setNuligaName(event.target.value);
                break;
            case "role":
                setRole(event.target.value);
                break;
            case "notifications":
                setNotifications(event.target.checked);
                break;
            case "sepa":
                setSepa(event.target.checked);
                break;
            default:
                break;

        }

    };

    const populateUsersCard = () => {
        const userDataHandler = getUser(id);
        userDataHandler.then(result => {
            setUserData({ notifications: result.data[0].receiveMails, firstname: result.data[0].firstname, lastname: result.data[0].surname, nuliga_lk: result.data[0].nuliga_lk, nuliga_id: result.data[0].nuliga_id, nuliga_name: result.data[0].nuliga_name,sepa_available: result.data[0].sepa_available  });
            setFirstname(result.data[0].firstname);
            setLastName(result.data[0].surname);
            setMail(result.data[0].email);
            setExpires(result.data[0].expiration_date || "na");
            setNotifications(result.data[0].receiveMails);
            setNuligaLK(result.data[0].nuliga_lk || "");
            setNuligaID(result.data[0].nuliga_id || "");
            setNuligaName(result.data[0].nuliga_name || "");
            if (result.data[0].role === "user") {
                setRole(1);
            }
            else {
                setRole(2);
            }
            setComment(result.data[0].comment);
            setSepa(result.data[0].sepa_available);

        }).catch(error => { showSnackbar("Fehler:" + error,"error");});
    }

    const deleteHandler = (id) => {
        if (!id) {
            showSnackbar("Bitte wählen Sie zuerst einen User aus","warning");
        }
        else {
            const deleteEvent = deleteUser_api(id);
            deleteEvent.then(() => { navigate(USERS) })
                .catch(error => { showSnackbar("Fehler:" + error,"error");});
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const submitPromise = updateUser(data, id, notifications,sepa,comment);
        //handle success and error
        submitPromise.then(result => { populateUsersCard(id); showSnackbar("Daten aktualisiert","success"); })
            .catch(error => {showSnackbar("Fehler:" + error,"error");});
    }

    const disconnectNuligaAccount = () => {
        var promise = disconnectNuligaAccount_api(id);
        promise.then((result) => { populateUsersCard(); })
            .catch(() => { populateUsersCard(); });
    }

    const connectnuligaAccount = (nuligaId, lk, name) => {
        var promise = connectNuligaAccount_api(id, nuligaId, lk, name);
        promise.then((result) => { populateUsersCard(); setOpenNuliga(false); showSnackbar("Nuliga-Account erfolgreich verbunden!","success"); })
            .catch(() => { showSnackbar("Verbindung mit dem Nuliga-Account fehlgeschlagen", "error"); setOpenNuliga(false) });
    }


    useEffect(() => {
        populateUsersCard(id);
    }, [])

    useEffect(() => {
        if (!firstname || !lastname || !mail) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false);
        }
    }, [firstname, lastname, mail]);
    return (
        <>
            <Header />
            <Toolbar></Toolbar>
            <Grid container direction="row" justifyContent="center" alignItems={"center"} spacing={2}>
                <Grid item xs={12} md={8}>
                    <TertiaryButton text="Mitglieder" startIcon={<ChevronLeft/>} onClick={()=>{navigate(USERS)}}></TertiaryButton>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card raised>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: "primary.main" }}>
                                    {firstname && firstname.charAt(0)}{lastname && lastname.charAt(0)}
                                </Avatar>
                            }
                            titleTypographyProps={{ variant: "h6" }}
                            title={firstname && firstname + " " + lastname}
                            subheader={id && "MyclubPortal ID: " + id + " | " + "Nuliga LK: " + nuligaLK}
                            sx={{ pr: 0, pb: 0 }}
                        />
                        <CardContent>
                            <Divider><Typography color="text.secondary">Konto</Typography></Divider>
                            <Box component="form" noValidate onSubmit={submitHandler} sx={{ mt: 3 }}>
                                <Grid container item direction="row" alignItems="center" spacing={3} >
                                    <Grid item xs={12} md={6}>
                                        <TextField name="firstName" id="firstName" required fullWidth label="Vorname" value={firstname} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField autoComplete="given-name" id="lastName" name="lastName" fullWidth label="Nachname" value={lastname} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField autoComplete="given-mail" id="email" name="email" fullWidth label="E-Mail" value={mail} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth id="role_select">
                                            <InputLabel id="role-label">Rolle</InputLabel>
                                            <Select labelId='role-label' id="role" name="role" value={role} label="Rolle" onChange={handleChange} >
                                                <MenuItem value={1}>User</MenuItem>
                                                <MenuItem value={2}>Admin</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider><Typography color="text.secondary">NuLiga</Typography></Divider>
                                    </Grid>
                                    {openAlert ?
                                        <Grid item xs={12}>
                                            <Alert severity='info' action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }}><Close fontSize="inherit" /></IconButton>} >
                                                <AlertTitle onClick={() => setExpandInfo1(!expandInfo1)}>Warum mit NuLiga verbinden?</AlertTitle>
                                                <Collapse in={expandInfo1}>
                                                    <Typography variant="body2">Durch die Verbinung mit einem Nuliga Account wird deine LK automatisch synchronisiert.Die Synchronisierung findet in einem täglichem Nachtdurchlauf statt.</Typography>
                                                </Collapse>
                                                <AlertTitle sx={{ mt: 1 }} onClick={() => setExpandInfo2(!expandInfo2)}>Wie verbinde ich mein Profil mit NuLiga?</AlertTitle>
                                                <Collapse in={expandInfo2}>
                                                    <Typography variant="body2">Klicke auf mit Nuliga verbinden und wähle anschließend den enstprechenden Account aus. Bestätige deine Profiländerung - fertig! Sollte die Verbindung nicht klappen, kannst du im Notfall deine LK auch manuell pflegen. </Typography>
                                                </Collapse>
                                                {/* <AlertTitle>Nuliga Account Verbindung</AlertTitle>
                                                    <Typography variant="body2">Durch die Verbinung mit einem Nuliga Account wird deine LK automatisch synchronisiert.Die Synchronisierung findet in einem täglichem Nachtdurchlauf statt.</Typography> */}
                                            </Alert>
                                        </Grid>
                                        :
                                        <></>
                                    }
                                    {nuligaName && nuligaID ?
                                        <Grid item xs={12}>
                                            <Alert severity="success"><AlertTitle>Nuliga-Account verbunden</AlertTitle><Typography variant="body2">Dein Profil ist mit Nuliga verbunden - die LK wird automatisch synchronisiert.</Typography></Alert>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <Alert severity="warning"><AlertTitle>NuLiga-Account nicht verbunden</AlertTitle><Typography variant="body2">Dein Profil ist nicht mit einem NuLiga Account verbunden. Du kannst/musst die LK manuell pflegen.</Typography></Alert>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={"space-between"}>
                                            {nuligaName && nuligaID ?
                                                <TertiaryButton startIcon={<LinkOff />} text="Nuliga-Account trennen" onClick={() => { disconnectNuligaAccount(); }} sx={{ width: "auto", textTransform: "none" }} />
                                                :
                                                <TertiaryButton startIcon={<Link />} text="Nuliga-Account verbinden" onClick={() => { setOpenNuliga(true) }} sx={{ width: "auto", textTransform: "none" }} />
                                            }
                                            <TertiaryButton text="Hilfe" endIcon={<HelpOutlined></HelpOutlined>} onClick={() => setOpenAlert(!openAlert)} />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="nuliga_name" name="nuliga_name" fullWidth label="Nuliga Name" value={nuligaName} onChange={handleChange} helperText="automatisch ermittelt" inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="nuliga_id" name="nuliga_id" fullWidth label="Nuliga ID Nummer" value={nuligaID} onChange={handleChange} helperText="automatisch ermittelt" inputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField id="nuliga_lk" name="nuliga_lk" fullWidth label="NuLiga Leistungsklasse" value={nuligaLK} onChange={handleChange} helperText="automatisch / ggf. manuell" inputProps={{ readOnly: nuligaName && nuligaID ? true : false }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider><Typography color="text.secondary">Benachrichtigungen</Typography></Divider>
                                    </Grid>
                                    <Grid container item xs={12} direction="row" justifyContent={"space-between"}>
                                        <Box>
                                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>E-Mail Benachrichtigung</Typography>
                                            <Typography variant="body2">Benachrichtigungen für Events wie z.B. Platzbuchungen</Typography>
                                        </Box>
                                        <Switch size="medium" name="notifications" id="notifications" checked={Boolean(notifications)} onChange={handleChange}></Switch>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider><Typography color="text.secondary">Zahlungsinformation</Typography></Divider>
                                    </Grid>
                                    <Grid container item xs={12} direction="row" justifyContent={"space-between"}>
                                        <Box>
                                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>SEPA-Lastschriftmandat</Typography>
                                            <Typography variant="body2">Ist eine Sepa Einzugsermächtigung vorhanden?</Typography>
                                        </Box>
                                        <Switch size="medium" name="sepa" id="sepa" checked={Boolean(sepa)} onChange={handleChange}></Switch>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid container item direction="row" justifyContent="space-between">
                                        <Grid item align="left">
                                            <SecondaryButton text="Account löschen" onClick={() => {openDeleteDialogAction() }} fullWidth />
                                        </Grid>
                                        <Grid item align="right">
                                            <PrimaryButton text={"Bestätigen"} type={"submit"} disabled={submitDisabled}></PrimaryButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card raised>
                        <CardHeader title={"Kommentar"} />
                        <CardContent>
                            <Typography dangerouslySetInnerHTML={{ __html: sanitize(comment) }} ></Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
            <ConnectUserToNuligaDialog open={openNuliga} onClose={() => setOpenNuliga(false)} submitHandler={connectnuligaAccount} userData={userData}></ConnectUserToNuligaDialog>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />


        </>
    );
}
export default UsersDetails;
