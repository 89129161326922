import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Avatar, Card, CardContent, CssBaseline, Divider, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { SIGNUP } from '../../navigation/CONSTANTS';
import { Container } from '@mui/material';
import { Typography } from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton';
import TertiaryButton from '../../components/TertiaryButton';
import ResetPasswordContainer from './ResetPasswordContainer';


function LoginView(props) {

   const submitHandler = (event) => {
      if (event.key === "Enter") {
         props.login();
      }
   }

   useEffect(() => {
      if (process.env.REACT_APP_DEMO_PASSWORD) {
         document.getElementById('password').value = process.env.REACT_APP_DEMO_PASSWORD;
      }
      if(process.env.REACT_APP_DEMO_USERNAME)
      {
         document.getElementById('email').value=process.env.REACT_APP_DEMO_USERNAME;
      }

   }, []);

   return (
      <>
         <Container component="main" maxWidth="xs" sx={{
            display: 'flex',
            flexDirection: 'row',
         }}>
            {!props.forgetPw ?
               <Card raised sx={{ mt: 20 }} id='crd-login'>
                  <CardContent>
                     <CssBaseline />

                     <Grid container direction="row" justifyContent={"center"} spacing={1} sx={{ mt: 1, mb: 3 }}>
                        {/* <Grid item xs={12}>
                           <Typography textAlign={"center"} variant="h5" >Login</Typography>
                        </Grid> */}
                        <Grid item>
                           <Avatar sx={{ color: '#1E88E5', bgcolor: "#E3F2FD" }}></Avatar>
                        </Grid>

                        <Grid item xs={12}>
                           <Typography textAlign={"center"} variant="h5" color="text.secondary">{process.env.REACT_APP_CLUB_NAME}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Divider variant="middle"> <Typography textAlign={"center"} variant="body1" color="text.secondary" >Login</Typography></Divider>
                        </Grid>
                     </Grid>

                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <TextField id="email" name="email" required label="E-Mail" autoFocus fullWidth variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                           <TextField id="password" name="password" required label="Password" fullWidth variant="outlined" type="password" onKeyUp={submitHandler} />
                        </Grid>
                        <Grid item xs={12}>
                           <TertiaryButton text={"Password vergessen?"} onClick={() => { props.setForgetPw(true); }} />
                        </Grid>
                        <Grid item xs={12}>
                           <PrimaryButton id="btn-login" fullWidth text={"login"} onClick={props.login} />
                        </Grid>
                        <Grid item xs={12}>
                           <Divider></Divider>
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                           <Grid item>
                              <Link to={SIGNUP}>
                                 <Typography variant="body1">Noch kein Accout ? Sign Up</Typography>
                              </Link>
                           </Grid>
                        </Grid>
                     </Grid>

                  </CardContent>
               </Card>
               :
               <ResetPasswordContainer setForgetPw={props.setForgetPw} />
            }
         </Container >
      </>
   );
};

export default LoginView;