import { Button } from '@mui/material'
import React from 'react'

function SecondaryButton(props) {
    const {text, onClick, startIcon, endIcon}=props;
  return (
    <Button variant="outlined" onClick={onClick} sx={{textTransform: "none"}} startIcon={startIcon} endIcon={endIcon} {...props}>{text}</Button>
  )
}

export default SecondaryButton