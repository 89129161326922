import { Avatar, Card, CardHeader, Typography } from '@mui/material'
import React, {useState,useEffect} from 'react'

function TitleCard({title, subheader, icon,id }) {
   
    const [innerWidth,setInnerWidth] = useState();

    useEffect(() => {
        setInnerWidth(document.documentElement.clientWidth);
        return () => {
        };
    }, [document.documentElement.clientWidth]);
    return (
        <Card id={id || "crd-title"} sx={{ borderRadius: 0, flex:1, pt: "40px" }}>
            <CardHeader  disableTypography title={<Typography variant="h6">{title} </Typography>}  subheader={<Typography>{subheader}</Typography>} avatar={<Avatar variant="square" sx={{ borderRadius: 2, color: '#1E88E5', bgcolor: "#E3F2FD", width: 56, height: 56 }}>{icon}</Avatar>} />
        </Card>
    )
}

export default TitleCard