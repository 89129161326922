import { addMinutes, differenceInDays, differenceInHours, differenceInMinutes } from "date-fns";

export function convertIntToDayName(number) {
    var dayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"]
    return dayNames[number];
}


export function convertMinutesToRemainingTime(totalMinutes) {
    // Start from a base date (e.g., beginning of the epoch)
    const startDate = new Date(0);
    // Add the specified minutes to the start date
    const endDate = addMinutes(startDate, totalMinutes);

    // Calculate days, hours, and minutes
    const days = differenceInDays(endDate, startDate);
    const hours = differenceInHours(endDate, startDate) - days * 24;
    const minutes = differenceInMinutes(endDate, startDate) - days * 1440 - hours * 60;
    // Example usage:
    const dayStrting = `${days} Tage `;
    const hourStrting = `${hours} Stunden `;
    const minuteStrting = `${minutes} Minuten `;
    var resultString = ""
    if (days != 0) {
        resultString += dayStrting;
    }
    if (hours != 0) {
        resultString += hourStrting;
    }
    if (minutes != 0) {
        resultString += minuteStrting;
    }
    // return `${days} Tage ${hours} Stunden ${minutes} Minuten`;
    return resultString;
}

