import { AccessTime, CheckCircleOutline, Description, EmojiEvents, EventNote, FormatListNumbered, Grid3x3Sharp, GridOn, Group, HelpOutline, HighlightOffOutlined, HistorySharp, Lock, LockClockSharp, LockPersonSharp, Login, Logout, Public } from '@mui/icons-material'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Stack, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { de } from 'date-fns/locale'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import PrimaryIconButton from './PrimaryIconButton'



// function EventTimelineItem({ event, getEvents=()=>{}, setSnackBar=()=>{}, adminMode = false, hideConnector = false, eventMenu, expanded, participants, getParticipants = () => { }, setParticipant = () => { }, invitees, getInvitees = () => { }, ...props }) {
function EventTimelineItem({ event, getEvents = () => { }, setSnackBar = () => { }, adminMode = false, hideConnector = false, eventMenu, setOpenDescription = () => { }, setOpenParticipants = () => { }, ...props }) {

    const [showEvent, setShowEvent] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate();

    return (

        <TimelineItem id="tli-event" sx={{
            [`& .${timelineOppositeContentClasses.root}`]: { flex: { xs: 0.4, lg: 1, }, },
        }} >
            <TimelineOppositeContent textAlign="center" variant={'body1'} color={"primary"} sx={{ py: '18px', px: 2 }} onClick={() => { setShowEvent(!showEvent) }}>{event && event.start && format(new Date(event.start), "dd. MMM yy", { locale: de })}</TimelineOppositeContent>

            <TimelineSeparator onClick={() => { setShowEvent(!showEvent) }}>
                <TimelineDot color={event.type == "Turnier" ? "primary" : "secondary"} variant="outlined">
                    {event.type == "Turnier" ?
                        <EmojiEvents color="primary" />
                        :
                        <EventNote color="secondary" />
                    }</TimelineDot>
                {hideConnector ? <></> : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent component={'span'} sx={{ py: '12px', px: 2 }}>
                <Stack onClick={() => { setShowEvent(!showEvent) }}>
                    <Typography variant={'body2'} color={event.type == "Turnier" ? "primary" : "secondary"} sx={{ textTransform: "uppercase" }}>{event.type}</Typography>
                    <Typography variant={'h6'} color="text.secondary">{event.name}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ pt: 0.5 }}>
                    {eventMenu}
                    <PrimaryIconButton icon={<Tooltip title="Beschreibung anzeigen"><Description color="grey" fontSize='small'/></Tooltip>} onClickAction={() => { setOpenDescription(true); }}></PrimaryIconButton>
                    <Stack direction="row" spacing={1} onClick={() => { setOpenParticipants(true); }} alignItems={"center"} sx={{}}>
                        {event.withParticipants === 1 ?
                            <>
                                <Tooltip title={(event.global ? "Vereinsweites Event" : "Privates Event") + (event.withParticipants === 1 ? " | Teilnehmer" : "")}>
                                    <Group color="grey" fontSize='small' />
                                </Tooltip>

                                {event.maxRegistrations != 0 && event.maxRegistrations != null && event.maxRegistrations != "" ?
                                    <Typography sx={{ color: "grey" }}>{event.totalParticipants + "/" + event.maxRegistrations}</Typography>
                                    :
                                    <Typography sx={{ color: "grey" }}>{event.totalParticipants}</Typography>
                                }
                            </>
                            :
                            <Tooltip title="Anmeldung nicht notwendig">
                                <Lock color="grey" fontSize='small'></Lock>
                            </Tooltip>
                        }
                        {!adminMode && event.withParticipants ?
                            event.isParticipant == 1 ?
                                <PrimaryIconButton size="small" icon={<CheckCircleOutline color="grey" fontSize='small'/>} tooltipTitle={"Du bist angemeldet"} />
                                : event.isParticipant == 0 ?
                                    <PrimaryIconButton size="small" icon={<HighlightOffOutlined color="grey"fontSize='small' />} tooltipTitle={"Du bist abgemeldet"} />
                                    :
                                    <PrimaryIconButton size="small" icon={<HelpOutline color="grey" fontSize='small'/>} tooltipTitle={"Anmeldung austehend"} />
                            :
                            <></>
                        }
                        {event.hasBracket && event.hasBracket > 0 ?
                            <PrimaryIconButton icon={<Tooltip title="Turnierbaum öffnen"><FormatListNumbered color="grey" fontSize='small' /></Tooltip>} onClickAction={() => navigate("/tournament/" + event.id+"?tab=bracket")}></PrimaryIconButton>
                            :
                            <></>
                        }
                        {event.hasGroups && event.hasGroups > 0 ?
                            <PrimaryIconButton icon={<Tooltip title="Turngruppen anzeigen"><GridOn color="grey" fontSize='small' /></Tooltip>} onClickAction={() => navigate("/tournament/" + event.id+"?tab=groups")}></PrimaryIconButton>
                            :
                            <></>
                        }
                    </Stack>
                </Stack>
            </TimelineContent>
        </TimelineItem>

    )
}

export default EventTimelineItem