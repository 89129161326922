function getSessionData()
{
     //get token from localStorage (reqiures avctive Session)
     const access_token=localStorage.getItem('token');
     const user_id=localStorage.getItem('id')

     if(!access_token || !user_id)
     {
         return false;
     }
     else
     {
         return {token: access_token, 'id': user_id};
     }

}
export default getSessionData;