import { Chip, Stack } from "@mui/material";
import { isAfter, isBefore } from "date-fns";

import React from 'react'
import { useNavigate } from "react-router-dom";
import { ADM_COURTLOCKS } from "../navigation/CONSTANTS";

function CourtStatusChip({court,...props}) {

    const navigate = useNavigate();

    if (isAfter(new Date(), new Date(court.locks?.[0]?.startdate)) && isBefore(new Date(), new Date(court.locks?.[0]?.enddate))) {
        return (
            <Stack>
                {/* <Typography>{format(new Date(court.reset_time),"dd.MM. HH 'Uhr'")}</Typography> */}

                <Chip id="chp-courtstatus-locked" size="small" color="error" label="Gesperrt" onClick={()=>navigate(ADM_COURTLOCKS)}/>
            </Stack>
        )
    }
    else  if (isBefore(new Date(), new Date(court.locks?.[0]?.startdate)) && isBefore(new Date(), new Date(court.locks?.[0]?.enddate)))
    {
        return(
        <Chip id="chp-courtstatus-upcominglock" size="small" color="warning" label="gepl. Sperrung" onClick={()=>navigate(ADM_COURTLOCKS)}/>
        )
    }
    else {
        return (
            <Chip id="chp-courtstatus-open" size="small" color="success" label="Offen" sx={{ color: "#fff" }} />
        )
    }
}

export default CourtStatusChip
