import { Button, Card, CardContent, Divider, Grid, TextField, Typography, Box, CardHeader, Avatar, Switch, CardActions, Alert, Tabs, Stack, IconButton, AlertTitle, Collapse } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import { changePassword_api, connectNuligaAccount_api, disconnectNuligaAccount_api, fetchMe, updateMe } from '../../api/Users/users';
import initializeUser from '../../utils/initializeUser';
import PrimaryButton from '../../components/PrimaryButton';
import Footer from '../../components/Footer';
import LogTable from '../Log/LogTable';
import { getUserLogs_api } from '../../api/Log/log';
import TitleCard from '../../components/TitleCard';
import { Close, HelpOutlined, Link, LinkOff, VerifiedUser } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import MyTab from '../../components/MyTab';
import TertiaryButton from '../../components/TertiaryButton';
import ConnectUserToNuligaDialog from '../../components/ConnectUserToNuligaDialog';
import DynamicExpandIcon from '../../components/DynamicExpandIcon';
import { useSnackbar } from '../../components/SnackbarContext';
import MainContentContainer from '../../components/MainContentContainer';

function ProfileContainer(props) {

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const dispatch = useDispatch();

    //form states
    const [userData, setUserData] = useState({});
    const [firstname, setFirstname] = useState("na");
    const [lastname, setLastName] = useState("na");
    const [mail, setMail] = useState("na");
    const [nuligaLK, setNuligaLK] = useState("na");
    const [nuligaID, setNuligaID] = useState("na");
    const [nuligaName, setNuligaName] = useState("na");
    const [notifications, setNotifications] = useState(false);
    const [pwOld, setPwOld] = useState("");
    const [pwNew, setPwNew] = useState("");
    const [pwNewRepeat, setPwNewRepeat] = useState("");
    const [differentPassword, setDifferentPassword] = useState(false);
    const [logs, setLogs] = useState(null);
    const [searchParam, setSearchParam] = useSearchParams();
    const [openNuliga, setOpenNuliga] = useState(false);
    const [openAlert, setOpenAlert] = useState(false)
    const [expandInfo1, setExpandInfo1] = useState(false);
    const [expandInfo2, setExpandInfo2] = useState(false);
    const { showSnackbar } = useSnackbar();

    //tabs
    const [tabValue, setTabValue] = useState(searchParam.get('tab') || "personal");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    // TextField hanndler
    const handleChange = (event) => {

        switch (event.target.name) {
            case "firstName":
                setFirstname(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "email":
                setMail(event.target.value);
                break;
            case "notifications":
                setNotifications(event.target.checked);
                break;
            case "nuliga_lk":
                setNuligaLK(event.target.value);
                break;
            case "nuliga_id":
                setNuligaID(event.target.value);
                break;
            case "nuliga_name":
                setNuligaName(event.target.value);
                break;
            case "pw_old":
                setPwOld(event.target.value);
                break;
            case "pw_new":
                setPwNew(event.target.value);
                break;
            case "pw_new_repeat":
                setPwNewRepeat(event.target.value);
                break;
            default:
                break;
        }

    };

    const populateUsersCard = () => {
        const userDataHandler = fetchMe();
        userDataHandler.then(result => {
            setUserData({ notifications: result.data[0].receiveMails, id: result.data[0].id, firstname: result.data[0].firstname, lastname: result.data[0].surname, nuliga_lk: result.data[0].nuliga_lk, nuliga_id: result.data[0].nuliga_id, nuliga_name: result.data[0].nuliga_name });
            setFirstname(result.data[0].firstname);
            setLastName(result.data[0].surname);
            setMail(result.data[0].email);
            setNotifications(result.data[0].receiveMails);
            setNuligaLK(result.data[0].nuliga_lk || "");
            setNuligaID(result.data[0].nuliga_id || "");
            setNuligaName(result.data[0].nuliga_name || "");
        }).catch(error => { showSnackbar("Fehler:" + error, "error"); });


    }

    const submitHandler = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const submitPromise = updateMe(data, notifications);
        //handle success and error
        submitPromise.then(result => { initializeUser(dispatch, setUserData); showSnackbar("Persönliche Daten aktualisiert", "success"); })
            .catch((error) => { showSnackbar("Fehler: " + error.response.data.error, "error"); });
    }

    const changePassword = () => {

        if (pwNew === pwNewRepeat) {
            setDifferentPassword(false);
            var promise = changePassword_api(pwOld, pwNew);
            promise.then((result) => { showSnackbar("Passwort erfolgreich geändert!", "success"); })
                .catch(error => { showSnackbar(error.response.data.error, "error"); });
        }
        else {
            setDifferentPassword(true);
        }
    }
    const getUserLogs = () => {
        var promise = getUserLogs_api();
        promise.then((result) => { setLogs(result.data) })
            .catch();

    }

    const setNuligaData = (id, lk, name) => {
        setNuligaID(id);
        setNuligaLK(lk);
        setNuligaName(name)
    }

    const disconnectNuligaAccount = () => {
        var promise = disconnectNuligaAccount_api(userData.id);
        promise.then((result) => { populateUsersCard(); })
            .catch(() => { populateUsersCard(); });
    }

    const connectnuligaAccount = (nuligaId, lk, name) => {
        var promise = connectNuligaAccount_api(userData.id, nuligaId, lk, name);
        promise.then((result) => { populateUsersCard(); setOpenNuliga(false); showSnackbar("Nuliga-Account erfolgreich verbunden!", "success"); })
            .catch(() => { showSnackbar("Verbindung mit dem Nuliga-Account fehlgeschlagen", "error"); setOpenNuliga(false) });
    }

    useEffect(() => {
        populateUsersCard();
        getUserLogs();
    }, []);

    useEffect(() => {
        if (!firstname || !lastname || !mail) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false);
        }
    }, [firstname, lastname, mail]);
    return (
        <>
            <Header />
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-profile" title="Dein Profil" icon={<VerifiedUser sx={{ width: 40, height: 40 }}></VerifiedUser>} subheader="Verwalte deine Angaben und Aktivitäten"></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                            <Tabs value={tabValue} onChange={handleTabChange} centered>
                                <MyTab label="Persönliches" value="personal" />
                                <MyTab label="Log" value="logs" />
                                {/* <MyTab label="Support" value="support" /> */}
                            </Tabs>
                        </Box>
                    </Grid>
                    {tabValue === "personal" &&
                        <>
                            <Grid item xs={12} md={8}>
                                <Card raised>
                                    <CardHeader
                                        avatar={
                                            <Avatar sx={{ bgcolor: "primary.main" }}>
                                                {userData.firstname && userData.firstname.charAt(0)}{userData.lastname && userData.lastname.charAt(0)}
                                            </Avatar>
                                        }
                                        titleTypographyProps={{ variant: "h6" }}
                                        title={userData.firstname && userData.firstname + " " + userData.lastname}
                                        subheader={userData.id && "MyclubPortal ID: " + userData.id + " | " + "Nuliga LK: " + userData.nuliga_lk}
                                        sx={{ pr: 0, pb: 0 }}
                                    />

                                    <CardContent>
                                        <Divider><Typography color="text.secondary">Konto</Typography></Divider>
                                        <Box component="form" noValidate onSubmit={submitHandler} sx={{ mt: 3 }}>
                                            <Grid container item direction="row" xs={12} alignItems="center" spacing={3} >
                                                <Grid item xs={12} md={6}>
                                                    <TextField name="firstName" id="firstName" required fullWidth label="Vorname" value={firstname} onChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField autoComplete="given-name" id="lastName" name="lastName" fullWidth label="Nachname" value={lastname} onChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField type={"email"} id="email" name="email" fullWidth label="E-Mail" value={mail} onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider><Typography color="text.secondary">NuLiga</Typography></Divider>
                                                </Grid>
                                                {openAlert ?
                                                    <Grid item xs={12}>
                                                        <Alert severity='info' action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }}><Close fontSize="inherit" /></IconButton>} >
                                                            <AlertTitle onClick={() => setExpandInfo1(!expandInfo1)}><Stack direction={"row"} alignItems={"flex-start"}>Warum mit NuLiga verbinden?<DynamicExpandIcon sx={{ pt: 0 }} expanded={expandInfo1} /></Stack></AlertTitle>
                                                            <Collapse in={expandInfo1}>
                                                                <Typography variant="body2">Durch die Verbinung mit einem Nuliga Account wird deine LK automatisch synchronisiert.Die Synchronisierung findet in einem täglichem Nachtdurchlauf statt.</Typography>
                                                            </Collapse>
                                                            <AlertTitle sx={{ mt: 1 }} onClick={() => setExpandInfo2(!expandInfo2)}> <Stack direction={"row"} alignItems={"flex-start"}>Wie verbinde ich mein Profil mit NuLiga?<DynamicExpandIcon sx={{ pt: 0 }} expanded={expandInfo2} /></Stack></AlertTitle>
                                                            <Collapse in={expandInfo2}>
                                                                <Typography variant="body2">Klicke auf <span> <TertiaryButton startIcon={<Link />} text="Nuliga-Account verbinden" onClick={() => { setOpenNuliga(true) }} sx={{ width: "auto", textTransform: "none" }} /></span>und wähle anschließend den enstprechenden Account aus. Sollte die Verbindung nicht klappen, kannst du im Notfall deine LK auch manuell pflegen.</Typography>
                                                            </Collapse>
                                                            {/* <AlertTitle>Nuliga Account Verbindung</AlertTitle>
                                                    <Typography variant="body2">Durch die Verbinung mit einem Nuliga Account wird deine LK automatisch synchronisiert.Die Synchronisierung findet in einem täglichem Nachtdurchlauf statt.</Typography> */}
                                                        </Alert>
                                                    </Grid>
                                                    :
                                                    <></>
                                                }

                                                {nuligaName && nuligaID ?
                                                    <Grid item xs={12}>
                                                        <Alert severity="success"><AlertTitle>Nuliga-Account verbunden</AlertTitle><Typography variant="body2">Dein Profil ist mit Nuliga verbunden - die LK wird automatisch synchronisiert.</Typography></Alert>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12}>
                                                        <Alert severity="warning"><AlertTitle>NuLiga-Account nicht verbunden</AlertTitle><Typography variant="body2">Dein Profil ist nicht mit einem NuLiga Account verbunden. Du kannst/musst die LK manuell pflegen.</Typography></Alert>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Stack direction="row" justifyContent={"space-between"}>
                                                        {nuligaName && nuligaID ?
                                                            <TertiaryButton startIcon={<LinkOff />} text="Nuliga-Account trennen" onClick={() => { disconnectNuligaAccount(); }} sx={{ width: "auto", textTransform: "none" }} />
                                                            :
                                                            <TertiaryButton startIcon={<Link />} text="Nuliga-Account verbinden" onClick={() => { setOpenNuliga(true) }} sx={{ width: "auto", textTransform: "none" }} />
                                                        }
                                                        <TertiaryButton text="Hilfe" endIcon={<HelpOutlined></HelpOutlined>} onClick={() => setOpenAlert(!openAlert)} />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField id="nuliga_name" name="nuliga_name" fullWidth label="Nuliga Name" value={nuligaName} onChange={handleChange} helperText="automatisch ermittelt" inputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField id="nuliga_id" name="nuliga_id" fullWidth label="Nuliga ID Nummer" value={nuligaID} onChange={handleChange} helperText="automatisch ermittelt" inputProps={{ readOnly: true }} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField id="nuliga_lk" name="nuliga_lk" fullWidth label="Nuliga Leistungsklasse" value={nuligaLK} onChange={handleChange} helperText={nuligaName && nuligaID ? "automatisch ermittelt " : "manuelle Eingabe"} inputProps={{ readOnly: nuligaName && nuligaID ? true : false }} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Divider><Typography color="text.secondary">Benachrichtigungen</Typography></Divider>
                                                </Grid>
                                                <Grid container item xs={12} direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                                    <Grid item xs zeroMinWidth>
                                                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>E-Mail Benachrichtigung</Typography>
                                                        <Typography variant="body2">Benachrichtigungen für Events wie z.B. Platzbuchungen</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Switch size="medium" name="notifications" id="notifications" checked={Boolean(notifications)} onChange={handleChange}></Switch>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                                <Grid container item direction="row" justifyContent="space-between">
                                                    <Grid item xs={6} md={2}>
                                                        {/* <Button type="submit" variant="outlined" sx={{ textTransform: "none" }}>Account Löschen</Button> */}
                                                    </Grid>
                                                    <Grid item xs={6} md={2} align="right">
                                                        <Button type="submit" variant="contained" disabled={submitDisabled} sx={{ textTransform: "none" }}>Bestätigen</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </CardContent>
                                    {/* <Divider></Divider>
                                <CardActions sx={{ justifyContent: "flex-end" }}>
                                </CardActions> */}
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Card raised>
                                    <CardHeader title={"Passwort Ändern"} />
                                    <CardContent>
                                        <Grid container item direction="row" xs={12} alignItems="center" spacing={3} >
                                            <Grid item xs={12}>
                                                <TextField name="pw_old" id="pw_old" type="password" required fullWidth label="Altes Passwort" value={pwOld} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField name="pw_new" type="password" id="pw_new" required fullWidth label="Neues Passwort" value={pwNew} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField name="pw_new_repeat" id="pw_new_repeat" type="password" required fullWidth label="Neues Passwort" value={pwNewRepeat} onChange={handleChange} />
                                            </Grid>
                                            {differentPassword ?
                                                <Grid item xs={12}>
                                                    <Alert severity="warning"><Typography>Passwörter stimmen nicht überein!</Typography></Alert>
                                                </Grid>
                                                : <></>}
                                            <Grid item xs={12}>
                                                <Divider></Divider>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: "flex-end" }}>
                                        <PrimaryButton text="Passwort ändern" onClick={() => { changePassword(); }}></PrimaryButton>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </>
                    }
                    {tabValue === "logs" &&
                        <Grid item xs={12} md={8}>
                            {logs &&
                                <LogTable rows={logs}></LogTable>
                            }
                        </Grid>
                    }
                </Grid>
            </MainContentContainer>

            <Footer />
            <ConnectUserToNuligaDialog open={openNuliga} onClose={() => setOpenNuliga(false)} submitHandler={connectnuligaAccount} userData={userData}></ConnectUserToNuligaDialog>
        </>
    );
}
export default ProfileContainer;
