import React, { useState, useEffect } from "react";
import { Toolbar, AppBar, Grid, Stack, Button, useScrollTrigger } from "@mui/material";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoginButton from "./LoginButton";
import { Box } from "@mui/system";
import initializeUser from "../../utils/initializeUser";
import DrawerMenu from "./DrawerMenu";
import { Help,  Menu, MenuOpen } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../navigation/CONSTANTS";
import NotificationMenu from "../Notifications/NotificationMenu";
import PrimaryIconButton from "../PrimaryIconButton";
import { useHelpCenter } from "../HelpCenter/HelpMenu";
import { updateAnalytics_api } from "../../api/Analytics/analytics";
import ScrollToTop from "../ScrollToTop";

//import MenuIcon from '@mui/icons-material/Menu';


function Header(props) {

    const [state, setState] = useState({})
    const [userData, setUserData] = useState({ firstname: props.firstname, lastname: props.lastname });
    const [open, setOpen] = useState(false);

    //colors
    const [headerColor, setHeaderColor] = useState("primary.dark");
    const [headerFontColor, setHeaderFontColor] = useState("white");
    
    //title and abbrevation
    const [clubname, setClubname] = useState(process.env.REACT_APP_CLUB_NAME || "Club");
    const [abbreviation, setAbbreviation] = useState(process.env.REACT_APP_CLUB_ABBREVATION || "XXX");

    const {showHelpCenter} = useHelpCenter();

    var currentPath = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // Redux state selectors
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const has_notifications = useSelector((state) => state.has_notifications);


    const clickHandler = (path) => {
        navigate(path);
    }

    const updateAnalytics = () => {
        var promise = updateAnalytics_api("openHelpcenter");
        promise.then().catch((error) => { });
    };

    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    useEffect(() => {
        initializeUser(dispatch, setUserData);
        return () => {
            setState({});
        }
    }, [])

    return (
        <React.Fragment>
            <AppBar size="small" position="fixed" elevation={scrollTrigger ? 6 : 0} sx={{ backgroundColor: headerColor }} >
                <Toolbar variant="dense">
                    <Grid container alignItems={"center"}>
                        <Grid container item xs={4} md={4} direction="row" alignItems="center" justifyContent="flex-start">
                            <Grid item>
                                <LoginButton color={headerFontColor}></LoginButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display={{ xs: "none", md: "block" }}>
                                <Typography variant="h6" align="center" color={headerFontColor} sx={{ fontWeight: 400, cursor: "pointer" }} onClick={() => { clickHandler(DASHBOARD) }}>
                                    {clubname}
                                </Typography>
                            </Box>
                            <Box display={{ xs: "block", md: "none" }}>
                                <Typography variant="h6" align="center" color={headerFontColor} sx={{ fontWeight: 400, cursor: "pointer" }} onClick={() => { clickHandler(DASHBOARD) }}>
                                    {abbreviation}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} justifyContent="flex-end">
                            <Stack direction="row" justifyContent="flex-end" alignItems={"center"} spacing={1}>
                                {isAuthenticated &&
                                    <NotificationMenu/>
                                }
                                <PrimaryIconButton icon={<Help color="white"></Help>} onClickAction={() => {showHelpCenter("menu");updateAnalytics(); }} />
                               <Button id="btn-mainmenu" size="xl" onClick={() => { setOpen(!open); }} color={headerFontColor}>{open ? <MenuOpen sx={{rotate: "180deg"}}/>: <Menu/>}</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <DrawerMenu open={open} setOpen={setOpen} />
            <ScrollToTop/>
        </React.Fragment>
    );
}

export default Header;

