import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getEvents_api = async (querryString) => {
  const sessionData = getSessionData();

  if (!querryString) {
    querryString = "";
  }

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events" + querryString,
    { headers: sessionData }
  );
};

export const getMyEvents_api = async (querryString) => {
  const sessionData = getSessionData();

  if (!querryString) {
    querryString = "";
  }

  return await axios.get(
    process.env.REACT_APP_API_URL + "/myevents" + querryString,
    { headers: sessionData }
  );
};



export const getNextEvent_api = async () => {
  const sessionData = getSessionData();

  return await axios.get(process.env.REACT_APP_API_URL + "/events/next", {
    headers: sessionData,
  });
};

export const getEventParticipants_api = async (eventid) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventid + "/participants",
    { headers: sessionData }
  );
};

export const setEventParticipant_api = async (eventid, userid) => {
  const sessionData = getSessionData();

  var data = {};
  return await axios.post(
    process.env.REACT_APP_API_URL + "/events/" + eventid + "/participants/" + userid,
    data,
    { headers: sessionData }
  );
};

export const deleteEvents_api = async (eventsid) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/events/" + eventsid,
    { headers: sessionData }
  );
};

export const deleteEventParticipant_api = async (eventid, userid) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/events/" + eventid + "/participants/" + userid, { headers: sessionData }
  );
};

export const setEvent_api = async (
  courts,
  name,
  type,
  startdate,
  enddate,
  description,
  withParticipants,
  withBooking,
  deadline,
  global,
  targetUsers,
  targetTeams,
  eventMail,
  maxRegistrations
) => {
  const sessionData = getSessionData();

  var data = {
    courts: courts,
    startdate: startdate,
    enddate: enddate,
    name: name,
    withParticipants: withParticipants,
    withBooking: withBooking,
    deadline: deadline,
    description: description,
    type: type,
    global: global,
    targetUsers: targetUsers,
    targetTeams: targetTeams,
    eventMail: eventMail,
    maxRegistrations: maxRegistrations

  };
  return await axios.post(process.env.REACT_APP_API_URL + "/events", data, {
    headers: sessionData,
  });
};

export const updateEvent_api = async (
  courts,
  name,
  type,
  startdate,
  enddate,
  description,
  withParticipants,
  withBooking,
  deadline,
  eventID,
  global,
  targetUsers,
  targetTeams,
  deletedUsers,
  deletedTeams,
  eventMail,
  maxRegistrations
) => {
  const sessionData = getSessionData();

  var data = {
    courts: courts,
    startdate: startdate,
    enddate: enddate,
    name: name,
    withParticipants: withParticipants,
    withBooking: withBooking,
    deadline: deadline,
    description: description,
    type: type,
    global: global,
    targetUsers: targetUsers,
    targetTeams: targetTeams,
    deletedUsers: deletedUsers,
    deletedTeams: deletedTeams,
    eventMail: eventMail,
    maxRegistrations: maxRegistrations
  };
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/events/" + eventID,
    data,
    { headers: sessionData }
  );
};

export const sendEventMail_api = async (message, subject, eventID) => {
  const sessionData = getSessionData();

  var data = {
    message: message,
    subject: subject
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/mail",
    data, { headers: sessionData }
  );
};

export const getBracketMatches_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/bracket",
    { headers: sessionData }
  );
};

export const createBracket_api = async (eventID, seeds, isAutoGenerated, bracketSize, bracketParticipants=null) => {
  const sessionData = getSessionData();

  var data = {
    seeds: seeds,
    isAutoGenerated: isAutoGenerated,
    bracketSize: bracketSize,
    bracketParticipants: bracketParticipants
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/bracket",
    data, { headers: sessionData }
  );
};

export const setWinner_api = async (eventID, matchID, winner, result) => {
  const sessionData = getSessionData();

  var data = {
    winner: winner,
    s1p1: result.s1p1,
    s1p2: result.s1p2,
    s2p1: result.s2p1,
    s2p2: result.s2p2,
    s3p1: result.s3p1,
    s3p2: result.s3p2,
    s4p1: result.s4p1,
    s4p2: result.s4p2,
    s5p1: result.s5p1,
    s5p2: result.s5p2
  };

  return await axios.patch(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/match/" + matchID,
    data, { headers: sessionData }
  );
};

export const deleteBracket_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/bracket",
    { headers: sessionData }
  );
};

export const getViewPermissions_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/viewpermissions",
    { headers: sessionData }
  );
};

export const getEventsViewPermissionsResolved_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/viewpermissionsresolved",
    { headers: sessionData }
  );
};

export const getTournamentSeeds_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/seeds",
    { headers: sessionData }
  );
};

export const closeRegistration_api = async (eventID) => {
  const sessionData = getSessionData();
  var data = {};

  return await axios.patch(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/closeregistration", data,
    { headers: sessionData }
  );
};

export const setMatchPlayers_api = async (eventId, matchId, player1, player2) => {
  const sessionData = getSessionData();
  var data = {
    player1: player1,
    player2: player2
  };

  return await axios.patch(
    process.env.REACT_APP_API_URL + "/events/" + eventId + "/matchassignment/" + matchId, data,
    { headers: sessionData }
  );
};

export const getTournamentGroups_api = async (eventId) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventId + "/groups/",
    { headers: sessionData }
  );
};

export const createGroups_Api = async (eventId,groupSize,maximizeGroups) => {
  const sessionData = getSessionData();
  var data = {
    grp_size: groupSize,
    maximize_Groups: maximizeGroups
  }

  return await axios.post(
    process.env.REACT_APP_API_URL + "/events/" + eventId + "/groups/",data,
    { headers: sessionData }
  );
};

export const deleteGroups_Api = async (eventId) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/events/" + eventId + "/groups/",
    { headers: sessionData }
  );
};

export const getTournamentGroupPlayers_api = async (eventID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/events/" + eventID + "/grouprankings",
    { headers: sessionData }
  );
};





