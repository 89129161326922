import { Ballot, GridView, Search, ViewListSharp } from '@mui/icons-material'
import { Alert, Container, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { getSurveys_api } from '../../../api/Surveys/surveys'
import AddEditSurveyDialog from '../../../components/AddEditSurveyDialog'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Header'
import PrimaryButton from '../../../components/PrimaryButton'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import Survey from '../../../components/Survey'
import TableFilter from '../../../components/TableFilter'
import TitleCard from '../../../components/TitleCard'
import MainContentContainer from '../../../components/MainContentContainer'

function AdmSurveyContainer() {

    const [surveys, setSurveys] = useState(null);
    const [open, setOpen] = useState(false);
    const [isSearch, setIsSearch] = useState();
    const [filter, setFilter] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [dense, setDense] = useState(false);

    const handleDense = (event, newView) => {
        setDense(newView);
    }

    const getSurveys = () => {
        var promise = getSurveys_api(filter);
        promise.then((response) => { setSurveys(response.data) })
            .catch();
    }

    const openEditDialog = (survey) => {
        setSelectedSurvey(survey);
        setIsEdit(true);
        setOpen(true);
    }

    useEffect(() => {
        getSurveys();
        return () => {
        };
    }, []);

    useEffect(() => {
        getSurveys(filter);
        return () => {

        };
    }, [filter]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-surveys" title={"Umfragen"} subheader={"Verwalte vereinsweite Umfragen"} icon={<Ballot sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} md={10} >
                                <Grid container direction="row" columnSpacing={2} rowSpacing={1} justifyContent="space-between">
                                    <Grid item>
                                        <Stack direction="row">
                                            <ToggleButtonGroup color="primary" value={dense} exclusive onChange={handleDense} size="small">
                                                <ToggleButton value={false}> <GridView /></ToggleButton>
                                                <ToggleButton value={true}> <ViewListSharp /></ToggleButton>
                                            </ToggleButtonGroup>

                                            <PrimaryIconButton icon={<Search />} onClickAction={() => { setIsSearch(!isSearch) }}></PrimaryIconButton>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <PrimaryButton text="Umfrage erstellen" onClick={() => { setOpen(true); }}></PrimaryButton>
                                    </Grid>
                                    {isSearch &&
                                        <Grid item xs={12}>
                                            <TableFilter filter={filter} setFilter={setFilter}></TableFilter>
                                        </Grid>
                                    }

                                </Grid>
                    </Grid>
                    <Grid item container xs={12} md={10} direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                        {surveys && surveys.length > 0 ? surveys.map((survey, i) => (
                            <Grid item xs={12} key={i}>
                                <Survey surveyData={survey} getSurveys={getSurveys} setOpen={setOpen} openEditDialog={openEditDialog} dense={dense}></Survey>
                            </Grid>
                        ))
                            :
                            <Grid item xs={12}>
                                <Alert variant="filled" severity="info"><Typography>Es gibt aktuell keine Umfragen</Typography></Alert>
                            </Grid>
                        }
                    </Grid>
                </Grid>

            </MainContentContainer>
            <AddEditSurveyDialog open={open} onClose={() => { setOpen(false); setIsEdit(false); }} getSurveys={getSurveys} isEdit={isEdit} selectedSurvey={selectedSurvey} adminMode={true}/>
            <Footer />
        </>
    )
}

export default AdmSurveyContainer