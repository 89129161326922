import { Alert, Box, Card, CardContent, CardMedia, Chip, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { BLOG } from '../../navigation/CONSTANTS'
import InternalLink from '../../components/InternalLink'
import { sanitize } from 'dompurify'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import BorderedIcon from '../../components/BorderedIcon'
import { Feed, Launch } from '@mui/icons-material'
import HeadlinePost from '../Blog/HeadlinePost'
import { useNavigate } from 'react-router-dom'

function DashboardBlogCard({ post }) {

    const ref = useRef();
    const navigate = useNavigate();

    const openPost = (id) => {
        navigate("/blog/" + id);
    }
    return (
        <Card raised sx={{ borderRadius: 2 }}>
            <CardContent >
                <Grid container direction="row" alignItems="center" justifyContent={"space-between"} spacing={2}>
                    <Grid item align="left">
                        <Stack direction="row" spacing={3}>
                            <BorderedIcon icon={<Feed color="primary"></Feed>} />
                            <Typography variant="h6" >{"Letzter Beitrag"}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs align="right">
                        {/* <InternalLink linkname="Mehr" route={BLOG} /> */}
                        <Launch color="primary" onClick={()=>navigate(BLOG)}></Launch>
                    </Grid>
                    {!post &&
                        < Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                    }

                </Grid>
            </CardContent>
            {
                post ?
                <HeadlinePost id="crd-dashboard-post" post={post} imageHeight={{ xs: "200px", md: "250px" }} headlineSize={{xs:"h5",md: "h5"}}></HeadlinePost>
                :
                <CardContent>
                    <Alert severity='info'><Typography>Es gibt keine Beiträge</Typography></Alert>
                </CardContent>
            }

        </Card >

    )
}

export default DashboardBlogCard