import { Box, Card, CardMedia, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import TertiaryButton from './TertiaryButton'
import { Launch } from '@mui/icons-material'

function TeamCardLight({ team }) {
    return (
        <Card sx={{ borderRadius: 2 }}>
            <Box sx={{ position: "relative" }}>
                {team.type == "men" ?
                    <CardMedia component="img" image={"https://media.myclubportal.de/teams_men.jpg"} sx={{ height: "125px", objectFit: "cover", objectPosition: "50% 35%", filter: "brightness(200%)contrast(0.7)" }}></CardMedia>
                    :
                    <CardMedia component="img" image={"https://media.myclubportal.de/teams_women.jpg"} sx={{ height: "125px", objectFit: "cover", objectPosition: "50% 50%", filter: "brightness(100%)contrast(1)" }}></CardMedia>
                }
                <Box sx={{ position: "absolute", color: "#fff", top: "0%", right: "0%", p: 0.5 }}>
                    <TertiaryButton endIcon={<Launch color="white"></Launch>} text="NuLiga" sx={{color: "#fff", textTransform: "none"}} onClick={()=>{ window.open(team.grouplink, '_blank');}}></TertiaryButton>
                </Box>

                <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .5)", width: "calc(100% - 8px)", p: 0.5 }}>
                    <Stack direction="row" justifyContent={"space-between"} sx={{ overflowX: 'auto', px: 1 }} spacing={1}>

                        <Stack alignItems={"left"} justifyContent={"center"}>
                            <Typography variant="body1" noWrap textAlign={"left"} sx={{ fontWeight: "medium" }}>{team.team}</Typography>
                            {team.tnbchampionship && team.tnbid ?
                                <Typography variant="subtitle1" noWrap>{team.league || "Nuliga Stats aktualisieren"}</Typography> :
                                <Typography variant="subtitle1">{"Keine NuLiga Synchronisierung"}</Typography>
                            }
                        </Stack>
                        {team.sync_status ?
                            <>
                                <Stack alignItems={"center"}>
                                    <Typography align="center" variant={"body1"} sx={{ fontWeight: "medium" }}>{team.ranking || "na"}</Typography>
                                    <Typography align="center" variant={"subtitle1"}>Rang</Typography>
                                </Stack>
                                <Stack alignItems={"center"}>
                                    <Typography align="center" variant={"body1"} sx={{ fontWeight: "medium" }}>{team.points || "na"}</Typography>
                                    <Typography align="center" variant={"subtitle1"}>Punkte</Typography>
                                </Stack>
                                {

                                    <Stack alignItems={"center"} sx={{ "display": { xs: "none", lg: "block" } }}>
                                        <Typography align="center" variant={"body1"} sx={{ fontWeight: "medium" }}>{team.matchpoints || "na"}</Typography>
                                        <Typography align="center" variant={"subtitle1"}>Matchpunkte</Typography>
                                    </Stack>
                                }
                            </>
                            :
                            <></>
                        }
                    </Stack>

                </Box>
            </Box>
        </Card>
    )
}

export default TeamCardLight