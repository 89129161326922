import { ChevronLeft, EmojiEvents, ExpandLess, GridView, Polyline } from '@mui/icons-material'
import { Alert, Box, ButtonGroup, CircularProgress, Container, Divider, Grid, Skeleton, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getLiveMatches_api, getMyGuestMatches, getMyTeammatches } from '../../api/Teammatches/Teammatches'
import Header from '../../components/Header/Header'
import TitleCard from '../../components/TitleCard'
import PrimaryButton from '../../components/PrimaryButton'
import AddEditTeammatchDialog from '../../components/AddEditTeammatchDialog'
import Footer from '../../components/Footer'
import { useParams, useSearchParams } from 'react-router-dom'
import LiveMatchCard from '../../components/LiveMatchCard'
import SecondaryButton from '../../components/SecondaryButton'
import UpdateLiveMatchModal from '../../components/UpdateLiveMatchModal'
import TeammatchCardv2 from '../../components/TeammatchCardv2'
import MyTab from '../../components/MyTab'
import TeamSelect from '../../components/TeamSelect'
import Timeline from '@mui/lab/Timeline'
import TertiaryButton from '../../components/TertiaryButton'
import { useSnackbar } from '../../components/SnackbarContext'
import ViewDrawerMenu from '../../components/ViewDrawerMenu'
import FilterDrawerMenu from '../../components/FilterDrawerMenu'
import MainContentContainer from '../../components/MainContentContainer'

function MyTeammatchesContainer(props) {
    const [searchParam, setSearchParam] = useSearchParams();
    const [teammatches, setTeammatches] = useState([]);
    const [liveMatches, setLiveMatches] = useState([{}]);
    const [isGuest, setIsGuest] = useState(false);
    const [openAddTeammatchDialog, setOpenAddTeammatchDialog] = useState(false);
    const [tabValue, setTabValue] = useState(searchParam.get("tab") || "mymatches");
    const [isloading, setIsLoading] = useState(false);
    const [openLiveResult, setOpenLiveResult] = useState(false);
    const [targetMatch, setTargetMatch] = useState(null)
    const [view, setView] = useState("timeline");
    const [teamid, setTeamid] = useState("");
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [openViewMenu, setOpenViewMenu] = useState(false);
    const { id } = useParams();
    const [filterAction, setFilterAction] = useState("");
    const { showSnackbar } = useSnackbar();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleView = (event, newView) => {
        setView(newView);
    }

    const getTeammatches = () => {
        const teammatchesPromise = getMyTeammatches(searchParam.toString());
        teammatchesPromise.then((result) => { setTeammatches(result.data) });
        setIsGuest(false);
    }

    const getGuestMatches = () => {
        var promise = getMyGuestMatches();
        promise.then((result) => { setTeammatches(result.data) })
            .catch();
        setIsGuest(true);
    }

    const getLiveMatches = () => {
        setIsLoading(true);
        var promise = getLiveMatches_api();
        promise.then((result) => { setIsLoading(false); setLiveMatches(result.data); showSnackbar("Live Matches aktuallisiert", "success"); })
            .catch(() => { setIsLoading(false) });
    }

    useEffect(() => {
        //setSearchParam({ tab: tabValue });
        switch (tabValue) {
            case "mymatches":
                getTeammatches();
                break;
            case "requests":
                getGuestMatches();
                break;
            case "live":
                getLiveMatches();
                break;
            default:
                getTeammatches();
                break;
        }
        switch (searchParam.get('view')) {
            case "timeline":
                setView("timeline");
                break;
            case "grid":
                setView("grid");
                break;
            default:
                break;
        }
    }, [tabValue, searchParam]);

    useEffect(() => {
        setSearchParam({ teamid: teamid });
    }, [teamid]);

    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-myteammatches"title={"Deine Punktspiele"} subheader={"Trage dich für deine Punktspiele ein"} icon={<EmojiEvents sx={{ width: 40, height: 40 }}></EmojiEvents>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    {!searchParam.get('id') ?
                        <Grid item xs={12} >
                            <Grid container direction="row" rowSpacing={2} columnSpacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                        <Tabs value={tabValue} onChange={handleTabChange}>
                                            <MyTab label="Meine Spiele" value="mymatches" />
                                            <MyTab label="Aushilfe" value="requests" />
                                            <Tab label={<Stack direction={"row"} alignItems={"center"} spacing={1}><Skeleton sx={{ backgroundColor: "success.main" }} animation="pulse" variant="circular" width="10px" height="10px"></Skeleton><Typography sx={{ typography: { xs: "h6", md: "h5" } }}>Live</Typography></Stack>} value="live" sx={{ textTransform: "none" }}></Tab>
                                        </Tabs>
                                    </Box>
                                </Grid>
                                <Grid item container xs={12} justifyContent={"space-between"} spacing={2}>
                                    <Grid item container direction="row" columnSpacing={1} rowSpacing={1} justifyContent="space-between" alignItems="center">
                                        {tabValue !== "live" &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Stack direction="row" justifyContent={"space-between"}>
                                                        <ButtonGroup variant="outlined">
                                                            <ViewDrawerMenu open={openViewMenu} setOpen={setOpenViewMenu} drawerTitle={"Meine Punktspiele"}>
                                                                <Grid container spacing={2} sx={{ p: 2 }}>
                                                                    <Grid item xs={12}>
                                                                        <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small" fullWidth>
                                                                            <ToggleButton value={"grid"}> <Stack direction="row" spacing={1}><GridView /><Typography sx={{ textTransform: "none" }}>Kachel</Typography></Stack></ToggleButton>
                                                                            <ToggleButton value={"timeline"}> <Stack direction="row" spacing={1}><Polyline /><Typography sx={{ textTransform: "none" }}>Zeitstrahl</Typography></Stack></ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </Grid>
                                                                </Grid>
                                                            </ViewDrawerMenu>
                                                            <FilterDrawerMenu open={openFilterMenu} setOpen={setOpenFilterMenu} drawerTitle={"Meine Punktspiele"}>
                                                                <Grid container spacing={2} sx={{ p: 2 }}>
                                                                    <Grid item xs={12}>
                                                                        <TeamSelect teamid={teamid} setTeamid={setTeamid} showAllOption={true} size="medium" myTeams></TeamSelect>
                                                                    </Grid>
                                                                </Grid>

                                                            </FilterDrawerMenu>
                                                        </ButtonGroup>



                                                        {/* <ToggleButtonGroup color="primary" value={filterAction} exclusive onChange={(event, newValue) => setFilterAction(newValue)} size="small" >
                                                            <ToggleButton value="view" ><Stack direction="row" spacing={1} alignItems={"center"} ><Visibility/><Typography variant="body2" fontWeight={500} sx={{textTransform:"None"}}>Ansicht</Typography> </Stack></ToggleButton>
                                                            <ToggleButton value="filter" ><Stack direction="row" spacing={1} alignItems={"center"}><FilterAlt/><Typography variant="body2" fontWeight={500} sx={{textTransform:"None"}}>Filter</Typography> </Stack></ToggleButton>
                                                        </ToggleButtonGroup> */}
                                                        <PrimaryButton text="Spiel erstellen" onClick={() => { setOpenAddTeammatchDialog(true); }} />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>

                                                {
                                                    filterAction == "view" &&
                                                    <Grid item xs={12}>
                                                        <Stack spacing={1} sx={{ pt: 1 }}>

                                                            <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small" fullWidth>
                                                                <ToggleButton value={"grid"}> <Stack direction="row" spacing={1}><GridView /><Typography sx={{ textTransform: "none" }}>Kachel</Typography></Stack></ToggleButton>
                                                                <ToggleButton value={"timeline"}> <Stack direction="row" spacing={1}><Polyline /><Typography sx={{ textTransform: "none" }}>Zeitstrahl</Typography></Stack></ToggleButton>
                                                            </ToggleButtonGroup>
                                                            <Divider><TertiaryButton text="Ansicht schließen" onClick={() => setFilterAction("")} endIcon={<ExpandLess />} sxprops={{ p: 0 }} /></Divider>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {
                                                    filterAction == "filter" &&
                                                    <Grid item xs sm md>
                                                        <Stack spacing={1} sx={{ pt: 1 }}>
                                                            <TeamSelect teamid={teamid} setTeamid={setTeamid} showAllOption={true} size="small" myTeams></TeamSelect>
                                                            <Divider><TertiaryButton text="Filter schließen" onClick={() => setFilterAction("")} endIcon={<ExpandLess fontSize='small' />} sxprops={{ p: 0 }} /></Divider>
                                                        </Stack>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                        {tabValue === "live" &&
                                            <Grid item>
                                                <SecondaryButton text="aktualisieren" onClick={() => { getLiveMatches(); }}></SecondaryButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            {/* <SecondaryButton startIcon={<FilterAltOff></FilterAltOff>} text={"Alle Punktspiele anzeigen"} onClick={()=>{searchParam.set("view", "timeline");searchParam.delete("id");searchParam.set("teamid",teamid);setSearchParam(searchParam)}} fullWidth></SecondaryButton> */}
                            <TertiaryButton startIcon={<ChevronLeft />} text="Punktspiele" onClick={() => { searchParam.set("view", "timeline"); searchParam.delete("id"); searchParam.set("teamid", teamid); setSearchParam(searchParam) }} />
                        </Grid>
                    }


                    <Grid item container direction={"row"} xs={12} spacing={4}>
                        {view === "timeline" && (tabValue === "requests" || tabValue === "mymatches") ?
                            <Grid item xs={12}>
                                <Timeline sx={{ px: "0px" }}>
                                    {teammatches && teammatches.length > 0 ?
                                        teammatches.map((teammatch, i) => (
                                            i == teammatches.length - 1 ?
                                                // <TeammatchTimelineItem key={i} teammatch={teammatch} hideConnector={true} isGuest={isGuest} getGuestMatches={getGuestMatches} getTeammatches={getTeammatches} />
                                                // :
                                                // <TeammatchTimelineItem key={i} teammatch={teammatch} isGuest={isGuest} getGuestMatches={getGuestMatches} getTeammatches={getTeammatches} />
                                                <TeammatchCardv2 key={i} isGuest={isGuest} getGuestMatches={getGuestMatches} getTeammatches={getTeammatches} teammatch={teammatch} isTimeLine={true} hideConnector={true} />
                                                :
                                                <TeammatchCardv2 key={i} isGuest={isGuest} getGuestMatches={getGuestMatches} getTeammatches={getTeammatches} teammatch={teammatch} isTimeLine={true} />
                                        ))
                                        :
                                        <Grid item xs={12} >
                                            <Alert severity="info" variant="filled"><Typography>Es gibt aktuell keine Punktspiele oder Anfragen</Typography></Alert>
                                        </Grid>
                                    }
                                </Timeline>
                            </Grid>
                            :
                            <></>
                        }
                        {view === "grid" && (tabValue === "requests" || tabValue === "mymatches") ?
                            teammatches && teammatches.length > 0 ?
                                teammatches.map((teammatch, i) => (
                                    <Grid key={i} item xs={12} sm={6} lg={4} >
                                        <TeammatchCardv2 key={i} isGuest={isGuest} getGuestMatches={getGuestMatches} getTeammatches={getTeammatches} teammatch={teammatch}></TeammatchCardv2>
                                    </Grid>
                                ))
                                :
                                <Grid item xs={12} >
                                    <Alert severity="info" variant="filled"><Typography>Es gibt aktuell keine Punktspiele/Anfragen</Typography></Alert>
                                </Grid>
                            :
                            <></>
                        }

                        {!isloading ?
                            liveMatches && tabValue === "live" && liveMatches.length > 0 ? liveMatches.map((liveMatch, i) => (
                                <Grid item key={i} xs={12} md={6} >
                                    <LiveMatchCard match={liveMatch} setOpen={setOpenLiveResult} setTargetMatch={setTargetMatch}></LiveMatchCard>
                                </Grid>
                            ))
                                :
                                <></>
                            :
                            <Grid container item xs={12} justifyContent="center">
                                <CircularProgress></CircularProgress>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer />
            <AddEditTeammatchDialog open={openAddTeammatchDialog} getTeammatches={getTeammatches} onClose={() => { setOpenAddTeammatchDialog(false); }} isEdit={false} adminMode={false}></AddEditTeammatchDialog>
            <UpdateLiveMatchModal open={openLiveResult} onClose={() => { setOpenLiveResult(false) }} match={targetMatch} getLiveMatches={getLiveMatches}></UpdateLiveMatchModal>
        </>
    )
}


export default MyTeammatchesContainer