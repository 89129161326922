import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';  // Correct import for DateTimePicker
import React from 'react';
import { setSeconds } from 'date-fns';

function DateTimeField(props) {
  const { id,date, setDate, size, label, textReadOnly, required } = props;

  // Function to handle the onChange event and ensure the date is in the correct format
  const handleChange = (newValue) => {
    if (newValue) {
      // Set seconds to 0 using dayjs.set() method
      setDate(setSeconds(newValue,0));  // Correct way to set seconds in dayjs
    }
  };

  return (
    <DateTimePicker
      {...props}
      label={label}
      value={date}  // Ensure the date is a dayjs object
      disableMaskedInput
      onChange={handleChange}  // Handle onChange for dayjs value
      // renderInput={(params) => 
      //   textReadOnly ? (
      //     <TextField 
      //       required={required} 
      //       size={size} 
      //       fullWidth 
      //       {...params} 
      //       inputProps={{
      //         ...params.inputProps,
      //         readOnly: true  // Make the input read-only if needed
      //       }} 
      //     />
      //   ) : (
      //     <TextField 
      //       size={size} 
      //       fullWidth 
      //       {...params} 
            
      //     />
      //   )
      slotProps={{
        textField: {
            size: size,
            fullWidth: true,
            required: required,
            htmlInput: textReadOnly ? { readOnly: true } : {},
        },
        inputAdornment:
        {
          id: id
        }
    }}
      
    />
  );
}

export default DateTimeField;
