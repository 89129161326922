import { Delete, Edit } from '@mui/icons-material';
import { Card, CardContent, Chip, Collapse, Container, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react'
import { deletePost_api } from '../api/Posts/post';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DynamicExpandIcon from './DynamicExpandIcon';
import Post from '../pages/Blog/Post';

function PostActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        //props.setObject(props.object);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="menu"
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(event) => { handleClick(event) }}
                color="inherit"
            >
                <MoreVertIcon></MoreVertIcon>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu',
                }}
            >
                <MenuItem onClick={() => { setAnchorEl(null); props.editAction(); }}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    Bearbeiten
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.deleteAction(); }}>
                    <ListItemIcon>
                        <Delete fontSize="small" color="error" />
                    </ListItemIcon>
                    Löschen
                </MenuItem>
            </Menu>
        </div>
    );
}


function PostCardAdm(props) {

    const { post, getPosts, openEditPost } = props;

    const [expand, setExpand] = useState(false);

    const deletePost = (post) => {
        var promise = deletePost_api(post.id);
        promise.then((result) => { getPosts(); })
            .catch();
    }

    return (
        <Card raised sx={{ borderRadius: 2, borderBottom: 4, borderColor: "primary.main" }}>
            <CardContent sx={{ p: 1, "&:last-child": { paddingBottom: 1 } }} onClick={() => { setExpand(!expand) }}>
                <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                        <DynamicExpandIcon onClick={() => { setExpand(!expand) }} expanded={expand}></DynamicExpandIcon>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6" sx={{ typography: { xs: 'body1', md: 'h6' } }} noWrap={false}>{post.title}</Typography>
                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                            <Typography noWrap={false} variant="body2">{post.reg_date}</Typography>
                            <Chip variant="filled" color="primary" size="small" label={post.category}></Chip>
                        </Stack>
                    </Grid>
                    <Grid item onClick={(e) => { e.stopPropagation(); }}>
                        <PostActionMenu deleteAction={() => { setExpand(false); deletePost(post) }} editAction={() => openEditPost(post)}></PostActionMenu>
                    </Grid>
                    {/* <Grid item >
                        <PrimaryIconButton icon={<Delete />} onClick={() => { deletePost(post.id) }}></PrimaryIconButton>
                    </Grid>
                    <Grid item >
                        <PrimaryIconButton icon={<Edit />} onClick={() => { openEditPost(post); }}></PrimaryIconButton>
                    </Grid> */}
                </Grid>
            </CardContent>
            <Collapse in={expand} transition="auto">
                <CardContent onClick={(e) => { e.stopPropagation(); }}>
                    <Container maxWidth="md">
                        <Post src={post.img_url} imageHeight="45vh" title={post.title} content={post.content} author={post.author_name} commentsCount={post.comments_count} post={post} />
                    </Container>
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default PostCardAdm