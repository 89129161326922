import { Chip, Stack } from "@mui/material";
import { isAfter, isBefore } from "date-fns";

import React from 'react'

function CourtLockStatusChip({startdate,enddate,...props}) {

    if (isAfter(new Date(), new Date(startdate)) && isBefore(new Date(), new Date(enddate))) {
        return (
            <Stack>
                <Chip size="small" color="error" label="aktive Sperrung" />
            </Stack>
        )
    }
    else  if (isBefore(new Date(), new Date(startdate)) && isBefore(new Date(), new Date(enddate)))
    {
        return(
        <Chip size="small" color="warning" label="anstehende Sperrung" />
        )
    }
    else {
        return (
            <Chip size="small" color="success" label="Offen" sx={{ color: "#fff" }} />
        )
    }
}

export default CourtLockStatusChip
