import { Clear, Delete, Logout, PersonAdd, EventRepeat, Edit, Group,} from '@mui/icons-material';
import { Alert, Avatar, Card,  CardContent, CardHeader, CardMedia, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react'
import { deleteTraininggroup, deleteTraininggroupParticpants, getTraininggroupParticpants, leaveMyTraininggroup, sendTraininggroupInvitation_api, setTraininggroupParticpants } from '../api/Traininggroups/traininggroups';
import { deleteTraining, getTrainingsFromGroup } from '../api/Trainings/training';
import { getUsersNames } from '../api/Users/users';
import AddUserDialog from './AddUserDialog';
import { useSelector } from 'react-redux';
import PrimaryIconButton from './PrimaryIconButton';
import AddEditTraininggroupDialog from './AddEditTraininggroupDialog';
import AddTrainingDialog from './AddTrainingDialog';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import CountWidget from './CountWidget';
import ActionMenu from './ActionMenu';
import DeleteDialog from './DeleteDialog';
import { useSnackbar } from './SnackbarContext';

function TraininggroupMenu({ traininggroup, adminMode, isAdmin, inviteMemberAction, createTrainingAction, editAction, deleteAction, leaveGroupAction, ...props }) {
    return (
        <ActionMenu id="btn-traininggroupcard-menu" color="primary">
            <MenuItem id="btn-traininggroupcardmenu-addmember"onClick={() => { inviteMemberAction(); }}>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                Spieler einladen
            </MenuItem>
            {traininggroup.isOwner !== 1 && !(isAdmin && adminMode) ?
                <MenuItem onClick={() => { leaveGroupAction(); }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Gruppe verlassen
                </MenuItem>
                :
                <div />
            }
            {traininggroup.isOwner === 1 || (isAdmin && adminMode) ?
                <div>
                    <MenuItem id="btn-traininggroupcardmenu-createtraining" onClick={() => { createTrainingAction(); }}>
                        <ListItemIcon>
                            <EventRepeat fontSize="small" />
                        </ListItemIcon>
                        Training erstellen
                    </MenuItem>
                    <MenuItem onClick={() => { editAction(); }}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        Bearbeiten
                    </MenuItem>
                    <Divider />
                    <MenuItem id="btn-traininggroupcardmenu-delete" onClick={() => { deleteAction(); }}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        Löschen
                    </MenuItem>
                </div>
                :
                <div />
            }

        </ActionMenu>
    )
}

function TraininggroupCard(props) {
    const { traininggroup, getTraininggroups, adminMode } = props;
    const [expanded, setExpanded] = useState(false);
    const [traininggroupMembers, setTraininggroupMembers] = useState([{}]);
    const [trainings, setTrainings] = useState([{}]);
    const [state, setState] = useState(null);
    const [openAddEditTraininggroupDialog, setOpenAddEditTraininggroupDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Traininggruppe löschen", warningText: "Es werden alle Trainings und Mitglieder unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: traininggroup.name, deleteHandler: () => { } });

    const isAdmin = useSelector((state)=>state.isAdmin);

    const {showSnackbar} = useSnackbar();

    //adduserDialog
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [users, setUsers] = useState([{}]);

    //add TrainingsDialog
    const [openAddTrainingDialog, setOpenAddTrainingDialog] = useState(false);
    const [date, setDate] = useState(null);

    const getTraininggroupMembers = () => {
        var promise = getTraininggroupParticpants(traininggroup.id)
        promise.then((result) => { setTraininggroupMembers(result.data) })
            .catch();
    }
    //expand card
    const expandHandler = () => {
        setExpanded(!expanded);
        if (!expanded) {
            getTraininggroupMembers();
            getTrainings();
        }
    }
    //delete Participant
    const deleteParticipantHandler = (traininggroupid, id) => {
        var promise = deleteTraininggroupParticpants(traininggroupid, id);
        promise.then((result) => { getTraininggroups(); getTraininggroupMembers(); })
            .catch((error) => { });
    }

    const openDeleteDialogAction = () => {
        setDeleteDialogState({...deleteDialogState,deleteObjectName: traininggroup.name,openDeleteDialog: true, deleteHandler: () => deleteTraininggroupHandler()})
    }

    //delete traininggroup
    const deleteTraininggroupHandler = () => {
        var promise = deleteTraininggroup(traininggroup.id);
        promise.then((result) => { getTraininggroups(); setExpanded(false); })
            .catch((error) => { });
    }

    //leave group
    const leaveHandler = () => {
        var promise = leaveMyTraininggroup(traininggroup.id);
        promise.then((result) => { getTraininggroups(); })
            .catch((error) => { });
    }

    //get trainings from group
    const getTrainings = () => {
        var promise = getTrainingsFromGroup(traininggroup.id)
        promise.then((result) => { setTrainings(result.data) })
            .catch((error) => { });
    }

    //delete training
    const deleteTrainingHandler = (traininggroupid, trainingid) => {
        var promise = deleteTraining(traininggroupid, trainingid);
        promise.then((result) => { getTrainings(); })
            .catch((error) => { });
    }

    //autocomplete
    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, firstname: result.data[i].firstname, lastname: result.data[i].surname, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }
    const addUserHandler = (targets) => {
        var promise
        if (adminMode) {
            promise = setTraininggroupParticpants(traininggroup.id, targets);
            promise.then((result) => { getTraininggroupMembers(); getTraininggroups(); setOpenAddUserDialog(false); showSnackbar("User erfolgreich hinzugefügt","success");})
                .catch((error) => { showSnackbar(error.message,"error");});
        }
        else {
            promise = sendTraininggroupInvitation_api(traininggroup.id, targets);
            promise.then((result) => { showSnackbar("User erfolgreich eingeladen","success");  setOpenAddUserDialog(false); })
                .catch((error) => { showSnackbar(error.message,"error");});
        }

    }

    useEffect(() => {
        if (openAddUserDialog) {
            getUsersList();
        }
        return () => {
            setState(null);
        };
    }, [openAddUserDialog]);

    useEffect(() => {
        getTraininggroups();
    }, [trainings]);

    return (
        <>
            <Card raised sx={{ borderRadius: 1, borderBottom: 4, borderColor: "primary.main" }} onClick={() => { expandHandler() }}>
                <Box sx={{ position: "relative", width: "100%" }}>
                    <CardMedia component={"img"} image={"https://media.myclubportal.de/traininggroup.jpg"} sx={{ height: "150px", objectFit: "cover" }}></CardMedia>
                    <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 0.5 }}>
                        <Grid container direction="row" item xs={12} alignItems={"center"} sx={{ flexWrap: "nowrap", overflowX: "clip" }}>
                            <Grid item>
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 2, alignItems: "left" }}>
                                    <Typography sx={{ fontWeight: 'bold', typography: { xs: "h6", md: "h6" } }}>{traininggroup.name}</Typography>
                                    <Typography sx={{ fontWeight: 'bold', typography: { xs: "body1", md: "body1" } }}>{"Besitzer: " + traininggroup.ownerName}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <CardContent sx={{ p: 1, "&:last-child": { paddingBottom: 1 } }}>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid item container justifyContent={"space-around"} alignItems={"center"} sx={{ pt: 1 }}>
                            <Grid item>
                                <CountWidget id="txt-traininggroupcard-countmembers"icon={<Group color="primary" />} count={traininggroup.totalParticipants} color="primary" onClick={() => expandHandler()}></CountWidget>
                            </Grid>
                            <Grid item>
                                <CountWidget id="txt-traininggroupcard-countrainings" icon={<EventRepeat color="primary" />} count={traininggroup.totalTrainings} color="primary" onClick={() => expandHandler()}></CountWidget>
                            </Grid>
                            <Grid item>
                                <TraininggroupMenu leaveGroupAction={() => leaveHandler()} traininggroup={traininggroup} isAdmin={isAdmin} adminMode={adminMode} inviteMemberAction={() => setOpenAddUserDialog(true)} createTrainingAction={() => setOpenAddTrainingDialog(true)} editAction={() => setOpenAddEditTraininggroupDialog(true)} deleteAction={() => openDeleteDialogAction()} />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ bgcolor: "#eeeeee" }}>
                        <Grid container spacing={2}>
                            <Grid container item justifyContent="center" xs={12} sm={6}>
                                <Card raised sx={{ borderRadius: 2, width: "inherit" }}>
                                    <CardHeader title={"Teilnehmer"}></CardHeader>
                                    <List dense>
                                        {traininggroupMembers.length > 0 && traininggroupMembers.map((participant, i) => (
                                            <ListItem key={i}>
                                                <Divider></Divider>
                                                <ListItemAvatar><Avatar sx={{ color: "white", backgroundColor: "primary.main" }} /></ListItemAvatar>
                                                <ListItemText primary={participant.firstname + " " + participant.lastname} secondary={participant.reg_date && format(new Date(participant.reg_date),"dd.MM.yyyy")} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                                </ListItemText>
                                                {traininggroup.isOwner === 1 || (isAdmin && adminMode) ? <IconButton id={"btn-trainings-deletemember-"+participant.userid} onClick={(e) => { e.stopPropagation(); deleteParticipantHandler(traininggroup.id, participant.id); }} sx={{ ml: 2 }}><Clear></Clear> </IconButton> : <></>}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Card>
                            </Grid>
                            <Grid container item justifyContent="center" xs={12} sm={6}>
                                <Card raised sx={{ borderRadius: 2, width: 'inherit' }}>
                                    <CardHeader title={"Trainings"}></CardHeader>
                                    <List dense>
                                        {trainings.length >= 1 ? trainings.map((training, i) => (
                                            <ListItem key={i}>
                                                <Divider></Divider>
                                                <ListItemAvatar><Avatar sx={{ backgroundColor: "primary.main" }}><EventRepeat color="white" /></Avatar></ListItemAvatar>
                                                <ListItemText primary={training.datetime && format(new Date(training.datetime), "EEEE", { locale: de })} secondary={training.datetime && format(new Date(training.datetime), "kk:mm", { locale: de }) + " Uhr"} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                                </ListItemText>
                                                {traininggroup.isOwner === 1 || (isAdmin && adminMode) ? <IconButton id="btn-trainings-deletetraining" onClick={(e) => { e.stopPropagation(); deleteTrainingHandler(traininggroup.id, training.id); }} sx={{ ml: 2 }}><Clear></Clear> </IconButton> : <></>}
                                            </ListItem>
                                        ))
                                            : <>
                                                <CardContent>
                                                    {traininggroup.isOwner === 1 || isAdmin ?
                                                        <Alert severity="info" action={<PrimaryIconButton icon={<EventRepeat />} onClickAction={() => { setOpenAddTrainingDialog(true); }} />}><Typography>Erstelle ein Training!</Typography></Alert>
                                                        :
                                                        <Alert severity="info"><Typography>Keine Trainings - der Besitzer muss ein Training erstellen</Typography></Alert>}
                                                </CardContent>
                                            </>
                                        }
                                    </List>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Collapse>
            </Card>
            <AddUserDialog openAddUserDialog={openAddUserDialog} multipleSelect={true} onClose={() => { setOpenAddUserDialog(false) }} users={users} setOpenAddUserDialog={setOpenAddUserDialog} title={"Spieler einladen"} addUserDialogSubmit={addUserHandler} ></AddUserDialog>
            <AddEditTraininggroupDialog open={openAddEditTraininggroupDialog} onClose={() => { setOpenAddEditTraininggroupDialog(false); }} traininggroup={traininggroup} getTraininggroups={getTraininggroups} isEdit={true} adminMode={adminMode}></AddEditTraininggroupDialog>
            <AddTrainingDialog open={openAddTrainingDialog} onClose={() => { setOpenAddTrainingDialog(false); }} getTrainings={getTrainings} traininggroup={traininggroup}></AddTrainingDialog>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({...deleteDialogState ,openDeleteDialog: false})} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />

        </>
    )
}

export default TraininggroupCard
