import { Clear, Download, Event, FilterList, FilterListOff } from '@mui/icons-material';
import { Alert, DialogActions, Divider, Grid, InputAdornment, Paper, Slide, Stack, Table, TableHead, TablePagination, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { addDays, format, isToday, isValid, setDate } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { exportReservations_api, getReservationsFiltered_api } from '../../../api/Reservations/reservations';
import { getUsersNames } from '../../../api/Users/users';
import BookingCard from '../../../components/BookingCardv2';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard';
import { getSettings_api } from '../../../api/Settings/settings';
import PrimaryButton from '../../../components/PrimaryButton';
import { exportDataToCSV } from '../../../utils/export';
import MainContentContainer from '../../../components/MainContentContainer';
import ResponsiveDrawer from '../../../components/ResponsiveDrawer';
import PrimaryIconButton from '../../../components/PrimaryIconButton';
import { DatePicker } from '@mui/x-date-pickers';
import SecondaryButton from '../../../components/SecondaryButton';

function AdmReservationsContainer() {

    const [searchParam, setSearchParam] = useSearchParams();
    const [users, setUsers] = useState([{}]);
    const [targetUser, setTargetUser] = useState(searchParam.get("user") || "");
    const [bookings, setBookings] = useState([]);
    const [settings, setSettings] = useState(null);
    const [initialInputValue, setInitialInputValue] = useState("");
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [dateMode, setDateMode] = useState("");



    const handleDateMode = (event, newValue) => {
        
        searchParam.delete("day");
        searchParam.delete("day__gte");
        searchParam.delete("day__lte");
        if (newValue == "") {
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else if (newValue == "today") {
            searchParam.set("day", format(new Date(), "yyyy-MM-dd"));
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else if (newValue == "from") {
            searchParam.set("day__gte", format(new Date(), "yyyy-MM-dd"));
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else if (newValue == "till") {
            searchParam.set("day__lte", format(new Date(), "yyyy-MM-dd"));
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else if (newValue == "custom") {
            searchParam.set("day", format(new Date(), "yyyy-MM-dd"));
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else if (newValue == "range") {
            searchParam.set("day__gte", format(new Date(), "yyyy-MM-dd"));
            searchParam.set("day__lte", format(addDays(new Date(), 1), "yyyy-MM-dd"));
            setSearchParam(searchParam);
            setDateMode(newValue);
        }
        else {
            //setSearchParam(searchParam);
        }
    }

    const getReservations = () => {
        var promise = null;
        promise = getReservationsFiltered_api(searchParam.toString());
        promise.then((result) => { setBookings(result.data) })
            .catch((error) => { });
    }


    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchUsers = () => {
        const userPromise = getUsersNames();
        userPromise.then((result) => {

            var users_autocomplete = [];

            for (var i = 0; i < result.data.length; i++) {
                var user_id = result.data[i].id;
                var user_label = result.data[i].firstname + " " + result.data[i].surname + " | " + user_id;
                users_autocomplete.push({ label: user_label, id: user_id });
                //users_autocomplete.push(result.data[i].firstname + " " + result.data[i].surname)
            }
            setUsers(users_autocomplete);
            setInitialInputValue(users_autocomplete.filter(function (element) { return element.id == searchParam.get("id") })[0]);

        });
    }

    const getClubsettings = () => {
        var promise = getSettings_api();
        promise.then((result) => { setSettings(result.data[0]) })
            .catch()
    }

    const exportReservations = () => {
        var promise = exportReservations_api(searchParam.toString())
        promise.then((result) => exportDataToCSV(result.data, "Buchungen_" + targetUser))
            .catch();
    }

    //populate users autocomplete
    useEffect(() => {
        fetchUsers();
        getClubsettings();
        getReservations();
        if (searchParam.get("day") && isToday(new Date(searchParam.get("day")))) {
            setDateMode("today")
        }
        else if (searchParam.get("day")) {
            setDateMode("custom")
        }
        else if (searchParam.get("day__gte") && !searchParam.get("day__lte")) {
            setDateMode("from")
        }
        else if (searchParam.get("day__gte") && searchParam.get("day__lte")) {
            setDateMode("range")
        }
        else if (!searchParam.get("day__gte") && searchParam.get("day__lte")) {
            setDateMode("till")
        }
        else {
            setDateMode("");
        }

    }, [searchParam]);


    useEffect(() => {
        if (!targetUser || targetUser == "") {
            searchParam.delete("user");
        }
        else {
            searchParam.set("user", targetUser)
        }
        setSearchParam(searchParam);
    }, [targetUser]);

    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-reservations" title={"Reservierungen"} subheader={"Verwalte alle Reservierungen!"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer maxWidth='xl'>
                <Grid container direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    {/* <Grid item xs={12}>
                    <Typography color="primary.main" textAlign={"center"} variant={"h6"}>{bookings?.length + " Reservierungen gefunden"}</Typography>

                    </Grid> */}
                    <Grid item container direction="row" alignItems="center" justifyContent={"space-between"} xs={12}>
                        <Grid item>
                            <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                <PrimaryButton size="medium" startIcon={<FilterList fontSize='large' />} text={"Filter (" + bookings?.length + ")"} onClick={() => setOpenFilterDrawer(true)} ></PrimaryButton>
                                <SecondaryButton color="warning" text="zurücksetzen" endIcon={<FilterListOff></FilterListOff>} onClick={() => { setSearchParam({}); getReservations(); }}></SecondaryButton>
                            </Stack>

                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={1}>
                                <SecondaryButton endIcon={<Download />} text="Export" onClick={() => exportReservations()}></SecondaryButton>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        count={bookings.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        labelRowsPerPage={"Anzahl"}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        sx={{ border: 0 }}
                                    />
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Grid>
                    <Divider></Divider>
                    <Grid container item xs={12} spacing={2}>
                        {bookings && bookings.length > 0 ?
                            bookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking, i) => (
                                <Grid item key={i} xs={12} md={6}>
                                    <BookingCard booking={booking} adminMode={true} getBookings={getReservations} clubSettings={settings} />
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled"><Typography>Keine Buchungen vorhanden</Typography></Alert>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </MainContentContainer>
            <ResponsiveDrawer openDrawer={openFilterDrawer} setOpenDrawer={setOpenFilterDrawer} drawerTitle={"Filterkonfiguration"} drawerSubtitle={`${bookings?.length} Ergebnisse`}>
                <Stack sx={{ p: 1 }} spacing={2} >
                    <PrimaryButton color="warning" text="Filter zurücksetzen" endIcon={<FilterListOff></FilterListOff>} onClick={() => { setSearchParam({}); getReservations(); }}></PrimaryButton>

                    <Stack spacing={0.5} sx={{ overflowX: "auto" }}>
                        <Typography fontWeight={"bold"} color="primary.main"> Buchungstyp:</Typography>
                        <ToggleButtonGroup variant="contained" color="primary" value={searchParam.get("type")} onChange={(event, newValue) => { searchParam.set('type', newValue == null ? "" : newValue); setSearchParam(searchParam) }} exclusive size="small">
                            <ToggleButton value={""} sx={{ textTransform: "none" }}>Alle</ToggleButton>
                            <ToggleButton value={"member"} sx={{ textTransform: "none" }}>Mitglieder</ToggleButton>
                            <ToggleButton value={"guest"} sx={{ textTransform: "none" }}>Gast</ToggleButton>
                            <ToggleButton value={"training"} sx={{ textTransform: "none" }}>Training</ToggleButton>
                            <ToggleButton value={"other"} sx={{ textTransform: "none" }}>Sonstiges</ToggleButton>
                            <ToggleButton value={"teammatch"} sx={{ textTransform: "none" }}>Punktspiel</ToggleButton>
                            <ToggleButton value={"lock"} sx={{ textTransform: "none" }}>Sperrung</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={"bold"} color="primary.main"> Platztyp:</Typography>
                        <ToggleButtonGroup variant="contained" color="primary" value={searchParam.get("courtType")} onChange={(event, newValue) => { searchParam.set('courtType',  newValue == null ? "" : newValue); setSearchParam(searchParam) }} exclusive size="small">
                            <ToggleButton value={""} sx={{ textTransform: "none" }}>Alle</ToggleButton>
                            <ToggleButton value={"outdoor"} sx={{ textTransform: "none" }}>Außenplatz</ToggleButton>
                            <ToggleButton value={"indoor"} sx={{ textTransform: "none" }}>Hallenplatz</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={"bold"} color="primary.main">Buchungsname:</Typography>
                        <TextField size="small" InputProps={{
                            endAdornment: (<InputAdornment position="end"><Clear onClick={() => { searchParam.set("name__like", ""); setSearchParam(searchParam) }} /> </InputAdornment>)
                        }} value={searchParam.get("name__like") || ""} onChange={(event) => { searchParam.set("name__like", event.target.value); setSearchParam(searchParam) }}></TextField>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={"bold"} color="primary.main">Datum:</Typography>
                        <ToggleButtonGroup variant="contained" color="primary" value={dateMode} onChange={handleDateMode} exclusive size="small">
                            <ToggleButton value={""} sx={{ textTransform: "none" }}>Alle</ToggleButton>
                            <ToggleButton value={"today"} sx={{ textTransform: "none" }}>Heute</ToggleButton>
                            <ToggleButton value={"from"} sx={{ textTransform: "none" }}>Ab</ToggleButton>
                            <ToggleButton value={"till"} sx={{ textTransform: "none" }}>Bis</ToggleButton>
                            <ToggleButton value={"custom"} sx={{ textTransform: "none" }}>Datum</ToggleButton>
                            <ToggleButton value={"range"} sx={{ textTransform: "none" }}>Zeitraum</ToggleButton>
                        </ToggleButtonGroup>
                        <Slide in={Boolean(dateMode == "from")} timeout={{ enter: 500, exit: 500 }} direction="right" mountOnEnter unmountOnExit>
                            <Paper elevation={0}>
                                <DatePicker label="Tag" views={["year", "month", "day"]} sx={{ mt: 1 }} value={isValid(new Date(searchParam.get('day__gte'))) ? new Date(searchParam.get('day__gte')) : new Date()} onChange={(newValue) => { if (isValid(new Date(newValue))) { searchParam.set("day__gte", format(newValue, "yyyy-MM-dd")); setSearchParam(searchParam) } }} slots={{ toolbar: () => null, actionBar: () => null }} ></DatePicker>
                            </Paper>
                        </Slide>
                        <Slide in={Boolean(dateMode == "till")} timeout={{ enter: 500, exit: 500 }} direction="right" mountOnEnter unmountOnExit>
                            <Paper elevation={0}>
                                <DatePicker label="Tag" views={["year", "month", "day"]} sx={{ mt: 1 }} value={isValid(new Date(searchParam.get('day__lte'))) ? new Date(searchParam.get('day__lte')) : new Date()} onChange={(newValue) => { if (isValid(new Date(newValue))) { searchParam.set("day__lte", format(new Date(newValue), "yyyy-MM-dd")); setSearchParam(searchParam) } }} slots={{ toolbar: () => null, actionBar: () => null }} ></DatePicker>
                            </Paper>
                        </Slide>
                        <Slide in={Boolean(dateMode == "custom")} timeout={{ enter: 500, exit: 500 }} direction="right" mountOnEnter unmountOnExit>
                            <Paper elevation={0}>
                                <DatePicker label="Tag" views={["year", "month", "day"]} sx={{ mt: 1 }} value={isValid(new Date(searchParam.get('day'))) ? new Date(searchParam.get('day')) : new Date()} onChange={(newValue) => { if (isValid(newValue)) { searchParam.set("day", format(newValue, "yyyy-MM-dd")); setSearchParam(searchParam) } }} slots={{ toolbar: () => null, actionBar: () => null }} ></DatePicker>
                            </Paper>
                        </Slide>
                        <Slide in={Boolean(dateMode == "range")} timeout={{ enter: 500, exit: 500 }} direction="right" mountOnEnter unmountOnExit>
                            <Paper elevation={0}>
                                <DatePicker label="Start" views={["year", "month", "day"]} sx={{ mt: 1 }} value={isValid(new Date(searchParam.get('day__gte'))) ? new Date(searchParam.get('day__gte')) : new Date()} onChange={(newValue) => { if (isValid(new Date(newValue))) { searchParam.set("day__gte", format(newValue, "yyyy-MM-dd")); setSearchParam(searchParam) } }} slots={{ toolbar: () => null, actionBar: () => null }} ></DatePicker>
                                <DatePicker label="Ende" views={["year", "month", "day"]} sx={{ mt: 1 }} value={isValid(new Date(searchParam.get('day__lte'))) ? new Date(searchParam.get('day__lte')) : new Date()} onChange={(newValue) => { if (isValid(new Date(newValue))) { searchParam.set("day__lte", format(newValue, "yyyy-MM-dd")); setSearchParam(searchParam) } }} slots={{ toolbar: () => null, actionBar: () => null }} ></DatePicker>
                            </Paper>
                        </Slide>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={"bold"} color="primary.main">Mitglied:</Typography>
                        <CustomAutocomplete size="small" options={users} initialInputValue={initialInputValue} label="Mitglied" setTarget={setTargetUser}></CustomAutocomplete>
                    </Stack>
                    <Divider></Divider>
                    <Stack spacing={0.5}>
                        <Typography fontWeight={"bold"} color="primary.main"> Buchungskosten:</Typography>
                        <ToggleButtonGroup variant="contained" color="primary" value={searchParam.get("price__neq") == "0.00" ? searchParam.get("price__neq") : ""} onChange={(event, newValue) => { searchParam.set('price__neq', newValue == null ? "" : newValue); setSearchParam(searchParam) }} exclusive size="small">
                            <ToggleButton value={""} sx={{ textTransform: "none" }}>Alle</ToggleButton>
                            <ToggleButton value={"0.00"} sx={{ textTransform: "none" }}>Kostenpflichtig</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>


                </Stack>
            </ResponsiveDrawer>
            <Footer></Footer>
        </>
    )
}

export default AdmReservationsContainer