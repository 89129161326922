import {AllInclusiveSharp, CalendarMonth, Launch,LinkOff, LinkSharp, ManageAccounts, Today } from '@mui/icons-material';
import { Avatar, Card, CardContent, CircularProgress, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InternalLink from '../../components/InternalLink';
import { USERSME } from '../../navigation/CONSTANTS';
import BorderedIcon from '../../components/BorderedIcon';
import RoleChip from '../../components/RoleChip';
import { useNavigate } from 'react-router-dom';
import CourtStatsStack from '../../components/CourtStatsStack';
import { useSelector } from 'react-redux';

function DashboardStatsCard(props) {

    const firstname = useSelector((state)=>state.firstname);
    const lastname = useSelector((state)=>state.lastname);
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Card raised sx={{ borderRadius: 2 }}>
                <CardContent>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>

                        <Grid item xs={6} align="left">
                            <Stack direction="row" spacing={3}>
                                <BorderedIcon icon={<ManageAccounts color="primary"></ManageAccounts>} />
                                <Typography variant="h6">{"Mein Profil"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} align="right">
                            {/* <InternalLink linkname="Mehr" route={USERSME} /> */}
                            <Launch color="primary" onClick={() => navigate(USERSME)}></Launch>
                        </Grid>
                        {props.data && props.counts && props.settings ?
                            <>
                                <Grid item xs={12} >
                                    <Divider>
                                        <RoleChip role={props.data[0].role}></RoleChip>
                                    </Divider>
                                </Grid>
                                <Grid item container xs={12} alignItems="flex-start" direction="row" justifyContent={"space-around"} spacing={2}>
                                    <Grid item xs sx={{ overflowX: "hidden" }} container justifyContent={"center"}>
                                        <Stack direction="row" spacing={2} alignItems={"center"}>
                                            <Avatar variant="circle" sx={{ bgcolor: "primary.main", width: 56, height: 56 }}><Typography variant="h5" sx={{ fontWeight: "regular" }}>{firstname && firstname.charAt(0)}{lastname && lastname.charAt(0)}</Typography></Avatar>
                                            <Stack direction="column" alignItems={"flex-start"}>
                                                <Typography variant="body1" color="secondary">Account</Typography>
                                                <Typography align="center" variant="body1" sx={{ fontWeight: 'bold' }}>{firstname + " " + lastname}</Typography>
                                                <Typography align="center" variant="body2" noWrap>{props.email}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Stack direction="column" spacing={0} alignItems={"center"}>
                                            <Typography variant="body1" color="secondary">Leistungsklasse</Typography>
                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                {
                                                    props.data && props.data[0].nuliga_lk ?
                                                        <Typography variant="h4">{props.data[0].nuliga_lk}</Typography> :
                                                        <InternalLink route={USERSME} linkname="Einstellungen" />
                                                }
                                                {props.data && props.data[0].nuliga_name && props.data[0].nuliga_id ?
                                                    <Tooltip title="Nuliga-Account verbunden">
                                                        <LinkSharp></LinkSharp>
                                                    </Tooltip>
                                                    : props.data && props.data[0].nuliga_lk ?
                                                        <Tooltip title="Kein Nuliga-Account verbunden">
                                                            <LinkOff color="text.secondary"></LinkOff>
                                                        </Tooltip>
                                                        : <></>
                                                }
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Divider><Typography color="text.secondary">{"Verfügbare Buchungen"}</Typography></Divider>
                                </Grid>
                                <Grid item container xs={12} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={"space-around"} spacing={2} >
                                            <CourtStatsStack indoorToottip={"Verfügbare Buchungen heute | Hallenplatz"}
                                                indoorValue={props.settings.allowed_bookings_per_day_indoor >= 999 ? <AllInclusiveSharp/>:(props.settings.allowed_bookings_per_day_indoor - props.counts.dailyBookingCountIndoor) + "/" + props.settings.allowed_bookings_per_day_indoor} outdoorTooltip={"Verfügbare Buchungen heute | Außenplatz"}
                                                outdoorValue={props.settings.allowed_bookings_per_day >= 999 ? <AllInclusiveSharp/>:(props.settings.allowed_bookings_per_day - props.counts.dailyBookingCount) + "/" + props.settings.allowed_bookings_per_day} headlineIcon={<Today color="secondary" />} headlineText={"Heute"} />
                                            <CourtStatsStack indoorToottip={"Verfügbare Buchungen gesamt | Hallenplatz"}
                                                indoorValue={props.settings.allowed_bookings_total_indoor >= 999 ? <AllInclusiveSharp/> :(props.settings.allowed_bookings_total_indoor - props.counts.totalBookingCountIndoor) + "/" + props.settings.allowed_bookings_total_indoor} outdoorTooltip={"Verfügbare Buchungen gesamt | Außenplatz"}
                                                outdoorValue={props.settings.allowed_bookings_total >= 999 ? <AllInclusiveSharp/> : (props.settings.allowed_bookings_total - props.counts.totalBookingCount) + "/" + props.settings.allowed_bookings_total} headlineIcon={<CalendarMonth color="primary" />} headlineText={"Gesamt"} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid item container direction={"row"} xs={12} justifyContent={"center"}>
                                <CircularProgress></CircularProgress>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >
    );
}

export default DashboardStatsCard;
