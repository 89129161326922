import { CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'

function LoadingProgress({ isLoading,loadingLabel, ...props }) {
    return (
        <>
            {isLoading ?
                <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    < CircularProgress color="primary" ></CircularProgress >
                    <Typography color="primary">{loadingLabel}</Typography>
                </Stack >
                :
                <></>
            }
        </>
    )
}


export default LoadingProgress