import React from 'react'
import SecondaryButton from './SecondaryButton'
import { FilterAlt } from '@mui/icons-material'
import { Typography, useMediaQuery } from '@mui/material'
import ResponsiveDrawer from './ResponsiveDrawer'
import { useTheme } from '@emotion/react'
import PrimaryIconButton from './PrimaryIconButton'

function FilterDrawerMenu({ children, open, setOpen, closeDrawerOnClick, drawerTitle, ...props }) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {isSmallScreen ?
                <PrimaryIconButton color="primary" icon={<FilterAlt />} tooltipTitle={"Filter konfigurieren"}  onClick={() => setOpen(!open)}/>
                :
                <SecondaryButton startIcon={<FilterAlt />} text={<Typography variant="body2" fontWeight={500} sx={{ textTransform: "none" }}>Filter</Typography>} onClick={() => setOpen(!open)} />
            }
            <ResponsiveDrawer drawerTitle={drawerTitle} drawerSubtitle={"Filterkonfiguration"} openDrawer={open} setOpenDrawer={setOpen} closeDrawerOnClick={closeDrawerOnClick}>
                {children}
            </ResponsiveDrawer>

        </>
    )
}

export default FilterDrawerMenu