function setSessionData(access_token,user_id)
{
     if(!access_token || !user_id)
     {
        return false;
     }
     else
     {
        localStorage.setItem('token', access_token);
        localStorage.setItem('id', user_id);
        return true;
     }

}
export default setSessionData;