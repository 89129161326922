import { Clear, Login } from '@mui/icons-material';
import { Alert, Card, CardContent, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText,  Tabs, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { deleteEventParticipant_api, setEventParticipant_api } from '../api/Events/events';
import AvatarInitials from './AvatarInitials';

import PrimaryIconButton from './PrimaryIconButton';
import MyTab from './MyTab';
import { useSnackbar } from './SnackbarContext';

function EventMemberCard(props) {
    const { participants, invitees=null, getEvents, getParticipants, getInvitees,event, deadline, isDelete, maxHeight } = props;

    const [expParticipants, setExpParticipants] = useState(false);
    const [expanded, setExpanded] = useState(props.expanded || false);
    const [view, setView] = useState("participants");

    const isAdmin = useSelector((state)=>state.isAdmin);

    const {showSnackbar} = useSnackbar();

    const deleteEventParticipant = (userid) => {
        var promise = deleteEventParticipant_api(event.id, userid);
        promise.then((result) => { getParticipants(); getEvents();if(invitees){ getInvitees();} })
            .catch(() => { });
    }

    const addInviteeToParticipants = (userid) => {
        var promise = setEventParticipant_api(event.id,userid);
        promise.then((result) => { getInvitees();getParticipants(); getEvents(); })
            .catch((error) => {showSnackbar(error.response.data.error,"error");});
    }

    useEffect(() => {
        setExpanded(props.expanded);
        return () => {

        };
    }, [props.expanded]);
    return (
        <Card  elevation={0} sx={{ borderRadius: 2, border:0,overflowY: "auto"}}  >
            {/* <CardHeader title={"Teilnehmer"} subheader={deadline && deadline} titleTypographyProps={{ variant: "h6" }} onClick={(e) => { setExpanded(!expanded); e.stopPropagation(); }} sx={{ cursor: "pointer" }} action={<DynamicExpandIcon expanded={expanded}></DynamicExpandIcon>} /> */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent onClick={(e) => { e.stopPropagation(); }} sx={{ pt: 0}}>
                    {invitees && invitees.length > 0 ?
                        <>
                            <Tabs value={view} centered onChange={(event, newValue) => setView(newValue)}>
                                <MyTab id="tab-eventmember-participants" xsSize={"h6"} mdSize={"h6"} value="participants" label={"Teilnehmer (" + participants.length + ")"}></MyTab>
                                <MyTab id="tab-eventmember-invitees" xsSize={"h6"} mdSize={"h6"}value="invitees" label={"Eingeladen (" + invitees.length + ")"}></MyTab>
                            </Tabs>
                            <Divider></Divider>
                        </>
                        : <></>
                    }
                    {view == "invitees" && invitees ?
                        <List dense sx={{maxHeight: maxHeight, overflowY: "auto"}}>
                           {/* <Stack direction={"row"} justifyContent={"flex-end"}><TertiaryButton text="alle hinzufügen" onClick={()=>{}}></TertiaryButton></Stack>  */}

                            {invitees && invitees.length > 0 ? invitees.map((invitee, i) => (
                                <ListItem key={i}>
                                    <ListItemAvatar><AvatarInitials firstname={invitee.firstname} lastname={invitee.surname} /></ListItemAvatar>
                                    <ListItemText primary={invitee.firstname + " " + invitee.surname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }} secondary={"LK: "+invitee.nuliga_lk}>
                                    </ListItemText>
                                        {!invitee.isParticipant ? <Tooltip title={"zur Teilnehmerliste hinizufügen"} arrow><PrimaryIconButton id={"btn-invitees-add-"+invitee.id} icon={<Login color="primary" />} onClickAction={() => {addInviteeToParticipants(invitee.id)}} />  </Tooltip>: <></>}
                                </ListItem>
                            ))
                                :
                                <>
                                    <Alert severity="info"><Typography>Es gibt noch keine Teilnehmer</Typography></Alert>
                                </>}
                            {/* add collapse button to display all participants */}
                            {/* {invitees && invitees.length > 5 && <ListSubheader inset={true}><TertiaryButton text={expParticipants ? "einklappen" : ("Alle (" + invitees.length + ") Teilnehmer anzeigen")} endIcon={expParticipants ? <ExpandLess /> : <ExpandMore />} onClick={(e) => { setExpParticipants(!expParticipants); e.stopPropagation(); }}></TertiaryButton></ListSubheader>} */}
                        </List>
                        :
                        <></>
                    }
                    {view == "participants" ?
                        <List dense sx={{maxHeight: maxHeight, overflowY: "auto"}}>
                            {/* <Stack direction={"row"} justifyContent={"flex-end"}><TertiaryButton text="alle austragen" onClick={()=>{}}></TertiaryButton></Stack>  */}
                            {participants && participants.length > 0 ? participants.map((participant, i) => (
                                <ListItem key={i}>
                                    <ListItemAvatar><AvatarInitials firstname={participant.firstname} lastname={participant.surname} /></ListItemAvatar>
                                    <ListItemText primary={participant.firstname + " " + participant.surname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }} secondary={"LK: "+participant.nuliga_lk}>
                                    </ListItemText>
                                    {isAdmin === true && isDelete ? <PrimaryIconButton id={"btn-participants-delete-"+participant.id} icon={<Clear />} onClickAction={() => { deleteEventParticipant(participant.id); }} sx={{ ml: 2 }} /> : <></>}
                                </ListItem>
                            ))
                                :
                                <>
                                    <Alert severity="info"><Typography>Es gibt noch keine Teilnehmer</Typography></Alert>
                                </>}
                            {/* add collapse button to display all participants */}
                            {/* {participants && participants.length > 5 && <ListSubheader inset={true}><TertiaryButton text={expParticipants ? "einklappen" : ("Alle (" + participants.length + ") Teilnehmer anzeigen")} endIcon={expParticipants ? <ExpandLess /> : <ExpandMore />} onClick={(e) => { setExpParticipants(!expParticipants); e.stopPropagation(); }}></TertiaryButton></ListSubheader>} */}
                        </List>
                        :
                        <></>
                    }

                </CardContent>
            </Collapse>
        </Card>
    )
}


export default EventMemberCard