import { Download, Insights, Moving, PlaylistAdd } from '@mui/icons-material'
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, Tabs } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { exportAnalytics_paid_api, getAnalytics_actions_api, getAnalytics_guests_api, getAnalytics_objects_api, getAnalytics_paid_api, getAnalytics_routes_api } from '../../../api/Analytics/analytics'
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard'
import AdmStatsCard from '../Dashboard/AdmStatsCard'
import ListCard from '../../../components/ListCard'
import { addYears, format } from 'date-fns'
import MyTab from '../../../components/MyTab'
import PrimaryButton from '../../../components/PrimaryButton'
import { exportDataToCSV } from '../../../utils/export'
import MainContentContainer from '../../../components/MainContentContainer'

function AdmStatisticsContainer() {
    const [view, setView] = useState("objects");
    const [analytics, setAnalytics] = useState([{}]);
    const [objects, setObjects] = useState([{}]);
    const [actions, setActions] = useState([{}]);
    const [guests, setGuests] = useState([{}]);
    const [paidReservations,setPaidReservations] = useState([{}])
    const [value, setValue] = useState("objects");
    const [year, setYear] = useState(format(new Date(), "yyyy"));

    const getAnalytics = () => {
        var promise = getAnalytics_routes_api();
        promise.then((result) => { setAnalytics(result.data) })
            .catch((error) => { });
    }

    const getAnalyticsActions = () => {
        var promise = getAnalytics_actions_api();
        promise.then((result) => { setActions(result.data) })
            .catch((error) => { });
    }

    const getAnalyticsObjects = () => {
        var promise = getAnalytics_objects_api();
        promise.then((result) => { setObjects(result.data) })
            .catch((error) => { });
    }

    const getAnalyticsGuests = () => {
        var promise = getAnalytics_guests_api(year);
        promise.then((result) => { setGuests(result.data) })
            .catch((error) => { });
    }

    const getAnalyticsPaidReservations = () => {
        var promise = getAnalytics_paid_api(year);
        promise.then((result) => { setPaidReservations(result.data) })
            .catch((error) => { });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    }

    const handleView = (event, newView) => {
        setView(newView);
    };

    const downloadExport = () =>
    {
        var promise = exportAnalytics_paid_api(year);
        promise.then((result)=>exportDataToCSV(result.data,"Einnahmen"))
        .catch();
    }

    useEffect(() => {
        getAnalytics();
        getAnalyticsObjects();
        getAnalyticsActions();
        //getAnalyticsGuests();
        //getAnalyticsPaidReservations();
        return () => {

        };
    }, []);

    useEffect(() => {
        //getAnalyticsGuests();
        getAnalyticsPaidReservations();
        return () => {
        };
    }, [year]);
    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-analytics" title={"Statistiken"} subheader={"Aktionen und Nutzung"} icon={<Insights sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                    <Tabs value={value} onChange={handleChange} centered>
                                        <MyTab value="objects" label="Allgemeines" />
                                        <MyTab value="actions" label="Nutzung" />
                                        <MyTab value="income" label="Einnahmen" />
                                    </Tabs>
                                </Box>
                            </Grid>
                            {value === "income" &&
                                <Grid container item direction="row" columnSpacing={2} justifyContent="space-between" xs={12}>
                                    <Grid item>
                                        <FormControl>
                                            <InputLabel id="year-select">Jahr</InputLabel>
                                            <Select size="small" labelId="year-select" label={"Jahr"} value={year} onChange={handleYearChange}>
                                                <MenuItem value={format(new Date(), "yyyy")}>{format(new Date(), "yyyy")}</MenuItem>
                                                <MenuItem value={format(addYears(new Date(), -1), "yyyy")}>{format(addYears(new Date(), -1), "yyyy")}</MenuItem>
                                                <MenuItem value={format(addYears(new Date(), -2), "yyyy")}>{format(addYears(new Date(), -2), "yyyy")}</MenuItem>
                                                <MenuItem value={format(addYears(new Date(), -3), "yyyy")}>{format(addYears(new Date(), -3), "yyyy")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <PrimaryButton startIcon={<Download/>} text="Export" onClick={()=>downloadExport()}></PrimaryButton>
                                        </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {value === "objects" &&
                        <>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <AdmStatsCard items={objects[0]} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ListCard header="Seitenaufrufe" items={analytics} icon={<Moving color="primary" />} />
                                </Grid>
                            </Grid>
                        </>
                    }
                    {value === "actions" &&
                        <Grid item container xs={12}spacing={2}>
                            <Grid item xs={12} md={6}>
                                <ListCard header="Aktionen" items={actions} icon={<PlaylistAdd color="primary" />} />
                            </Grid>
                        </Grid>
                    }
                    {value === "income" &&
                        // <Grid item container xs={12}  spacing={2}>
                        //     <Grid item xs={12} md={6}>
                        //         <ListCard header="Gastbuchungen" items={guests} link={true} year={year} icon={<Moving color="primary" />} />
                        //     </Grid>
                        // </Grid>
                        <Grid item container xs={12}  spacing={2}>

                        <Grid item xs={12} md={6}>
                            <ListCard header="Kostenpflichtige Buchungen" items={paidReservations} link={true} year={year} icon={<Moving color="primary" />} />
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </MainContentContainer>
            <Footer />
        </>
    )
}

export default AdmStatisticsContainer