import { Box, Button, Checkbox, Divider,Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addMinutes, addSeconds, format, isAfter, isBefore, isEqual, isSameDay, isValid, isWithinInterval, } from 'date-fns';
import BookingViewReservation from '../../components/BookingViewReservation';


function AdminBookingButton(props) {
  const { data, zeit, handleOpen, setNewBookingData, setSelectedIDs, day, court, startdate, enddate, setStartdate, setEnddate, setTargetCourts, settings, targetCourts, shortText } = props;

  const [checkState, setCheckState] = useState(false);
  const [bookingDate, setBookingDate] = useState(false);
  const [selected, setSelected] = useState(false);
  const [locked, setLocked] = useState();
  const types = {
    "teammatch": "Punktspiel",
    "training": "Training",
    "other": "Sonstige"
}


  const addCourtToTargetCourts = () => {
    setTargetCourts((prevCourts) => {
      // Check if the court already exists in the target array
      const exists = prevCourts.some((existingCourt) => existingCourt.id === court.id);
      // If it doesn't exist, add it; otherwise, return the previous state
      if (!exists) {
        return [...prevCourts, { id: court.id, name: court.name }];
      }
      return prevCourts;
    });
  }

  const removeCourtFromTargetCourts = () => {
    setTargetCourts((prevCourts) => {
      const index = prevCourts.findIndex((c) => c.id === court.id);
      if (index === -1) return prevCourts; // If the id is not found, return the unchanged array
      return [
        ...prevCourts.slice(0, index),
        ...prevCourts.slice(index + 1),
      ];
    });
  };


  const handleClick = () => {


    var tmp = new Date(format(day, "yyyy-MM-dd") + "T" + zeit + ":00")
    var tmp_end = addMinutes(tmp, settings?.booking_duration);

    //adjust startdate in case date has been changed after first click
    if (!isSameDay(startdate, tmp_end)) {
      tmp = tmp_end;
      tmp = addMinutes(tmp, -(settings?.booking_duration));
      setStartdate(tmp);
    }
    addCourtToTargetCourts();

    //set start and enddate on first click
    if (!isValid(new Date(startdate)) && !isValid(new Date(enddate))) {
      setStartdate(tmp)
      setEnddate(tmp_end);
      // addCourtToTargetCourts();
    }
    // unset all if clicked startdate is before old startdate
    else if (isValid(new Date(startdate)) && isBefore(tmp, startdate)) {
      setStartdate();
      setEnddate();
      setTargetCourts([]);

    }
    else if (targetCourts.length > 0 && !isEqual(tmp, startdate) && !isEqual(tmp_end, enddate)) {
      setEnddate(tmp_end);
    }
    else if (targetCourts.length > 0 && !targetCourts?.some((existingCourt) => existingCourt.id === court.id) && isEqual(tmp_end, enddate)) {

    }
    else if (targetCourts.length > 0 && targetCourts?.some((existingCourt) => existingCourt.id === court.id) && isEqual(tmp_end, enddate)) {
      setEnddate(tmp);
    }
    else {
      setStartdate(tmp)
      setEnddate(tmp_end);
    }
  }

  useEffect(() => {
    var datetime = new Date(format(day, "yyyy-MM-dd") + "T" + zeit + ":00");
    setBookingDate(datetime);
  }, [day]);

  useEffect(() => {
    if (data.type == "lock") {
      setLocked(true);
    }
    else {
      setLocked(false);
    }
    setCheckState(false);
  }, [data]);

  useEffect(() => {
    try {

      if (isValid(new Date(enddate)) && targetCourts && isValid(new Date(startdate)) && isValid(new Date(bookingDate) & isAfter(enddate, startdate))) {
        if (targetCourts?.some(courtItem => courtItem.id === court.id) && isWithinInterval(addSeconds(bookingDate, 1), { start: startdate, end: enddate })) {
          setSelected(true);
        }
        else {
          setSelected(false);
        }
      }
      else {
        setSelected(false);
      }
    } catch (error) {
    }

  }, [bookingDate, enddate, startdate, targetCourts]);


  if (locked) {
    return (<>
      <BookingViewReservation shortText={shortText} color="error.main" checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data}  secondary={"Gesperrt"} />
    </>
    );
  }
  else {

    return (
      <>
        {!data || data.names === "" || data.names === undefined ?
          <Stack alignItems={"stretch"} sx={{ height: "100%" }}>
            {targetCourts?.some((existingCourt) => existingCourt.id === court.id) && (isEqual(bookingDate, startdate)) &&
              <Button  variant="contained" color="warning" onClick={() => removeCourtFromTargetCourts()} sx={{ textWrap: "nowrap", textTransform: "none", fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small">
                Platz abwählen
              </Button>
            }
            <Button id="btn-admbooking-booking" size="small" color={selected ? "secondary" : "primary"} variant={selected ? "contained" : "outlined"} onClick={() => { handleClick() }} sx={{ height: "100%" }}>Buchen</Button>
          </Stack>
          :
          //userbooking
          data.spieler1 != null && data.spieler2 != null ?
            <BookingViewReservation shortText={shortText} color="primary.main" secondaryBold={true} checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data} primary={data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name} secondary={data.spieler2_firstname.charAt(0) + ". " + data.spieler2_name} />
            //guestBooking
            : data.spieler1 != null && data.guestname != null ?
              <BookingViewReservation shortText={shortText} color="yellow.dark" secondaryBold={true} checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data} primary={data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name} secondary={data.guestname == "Ballmaschine" ? "Ballmaschine" : "Gast"} />
              //user freetext
              : data.spieler1 != null && data.spieler2 == null && data.guestname == null ?
                <BookingViewReservation shortText={shortText} color="warning.main" checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data} primary={data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name} secondary={data.names} />
                  //Series Event
                  : data.serieId != null && data.eventId != null && data.spieler1 == null && data.spieler2 == null && data.guestname == null ?
                    <BookingViewReservation shortText={shortText} color="success.main" checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data} primary={data.names} secondary={types[data.type]} />
                    //Abo
                    :
                    <BookingViewReservation shortText={shortText} color="secondary.main" checkState={checkState} setCheckState={setCheckState} setSelectedIDs={setSelectedIDs} data={data} primary={data.names} secondary={types[data.type]} />
        }
      </>
    )
  }
}

export default AdminBookingButton