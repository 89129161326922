import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer'
import TitleCard from '../../../components/TitleCard'
import { Box, Card, CardContent, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Tabs, TextField, Tooltip } from '@mui/material'
import { Add, Delete, Edit, Launch, Mail, Save } from '@mui/icons-material'
import { createMailTemplate_api, deleteMailTemplate_api, getMailTemplates_api, updateMailTemplate_api } from '../../../api/Mail/mail'
import MyTab from '../../../components/MyTab'
import QuillEditor from '../../../components/QuillEditor'
import PrimaryButton from '../../../components/PrimaryButton'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import { useSnackbar } from '../../../components/SnackbarContext'
import DeleteDialog from '../../../components/DeleteDialog'
import { format, isValid } from 'date-fns'
import MainContentContainer from '../../../components/MainContentContainer'

function TemplatesContainer() {

    const [name, setName] = useState();
    const [id, setId] = useState();
    const [content, setContent] = useState();
    const [templates, setTemplates] = useState();
    const [tabValue, setTabValue] = useState("template");
    const [isEdit, setIsEdit] = useState();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Vorlage löschen", warningText: "Die Vorlage wird unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: "", deleteHandler: () => { } });


    const { showSnackbar } = useSnackbar();

    const getTemplates = () => {
        var promise = getMailTemplates_api();
        promise.then((result) => { setTemplates(result.data) })
            .catch((error) => { })
    }

    const saveNewTemplate = () => {
        if (!isEdit) {
            var promise = createMailTemplate_api(name, content);
            promise.then((result) => { getTemplates(); setIsEdit(true); showSnackbar("Neue Vorlage erstellt.", "success"); })
                .catch((error) => { showSnackbar("Fehler beim Erstellen der Vorlage", "error"); })
        }
    }

    const updateTemplate = () => {
        if (isEdit) {
            var promise = updateMailTemplate_api(id, name, content);
            promise.then((result) => { getTemplates(); setIsEdit(true); showSnackbar("Vorlage erfolgreich geändert", "success"); })
                .catch((error) => { showSnackbar("Fehler beim Ändern der Vorlage", "error"); })
        }
    }

    const deleteTemplate = (id) => {
        var promise = deleteMailTemplate_api(id);
        promise.then((result) => { getTemplates(); showSnackbar("Vorlage erfolgreich gelöscht", "success"); })
            .catch((error) => { showSnackbar("Fehler beim Löschen der Vorlage", "error"); })
    }

    const createTemplate = () => {
        setIsEdit(false);
        setName("");
        setContent("");
        setTabValue("editor");

    }

    const openDeleteDialogAction = (id, name) => {
        setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: true, deleteObjectName: "die Vorlage: " + name, deleteHandler: () => deleteTemplate(id) })
    }

    useEffect(() => {
        getTemplates();
    }, []);

    return (
        <>
            <Header />
            <TitleCard id="crd-title-templates" title={"Mail Vorlagen"} subheader={"Erstelle und verwalte Vorlagen für E-Mails"}></TitleCard>
            <MainContentContainer maxWidth='lg'>

                <Card sx={{ width: "100%", mt: 4 }}>

                    <CardContent>
                        <Grid direction={"row"} container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ width: "100%", borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered>
                                        <MyTab label={"Vorlagen"} value="template"></MyTab>
                                        <MyTab label={"Editor"} value="editor"></MyTab>
                                    </Tabs>
                                </Box>
                            </Grid>

                            {tabValue == "template" &&
                                <>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={"flex-end"} sx={{ width: "100%" }}>
                                            <PrimaryButton text="Vorlage erstellen" onClick={() => { createTemplate(); }} ></PrimaryButton>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List sx={{maxHeight: "55vh", overflow: "auto"}}>
                                            {templates && templates.map((template, i) => (
                                                <div key={i}>

                                                    <ListItem key={i} secondaryAction={<Stack direction="row" >
                                                        <PrimaryIconButton tooltipTitle={"Vorlage im Editor editieren"} icon={<Edit color="primary" />} onClick={() => { setId(template.id); setIsEdit(true); setTabValue("editor"); setContent(template.content); setName(template.name); }}></PrimaryIconButton>
                                                        <PrimaryIconButton tooltipTitle={"Vorlage löschen"} icon={<Delete color="primary" />} onClick={() => { setId(template.id); openDeleteDialogAction(template.id, template.name); }}></PrimaryIconButton>
                                                    </Stack>
                                                    }>
                                                        <ListItemIcon><Mail color="primary"></Mail></ListItemIcon>
                                                        <ListItemText primary={template.name} secondary={template.modified_date && isValid(new Date(template.modified_date)) ? format(new Date(template.modified_date), "dd.MM.yyyy HH:mm") : ""} sx={{ pr: 5 }}></ListItemText>
                                                    </ListItem>
                                                    {i < templates.length - 1 &&
                                                        <Divider></Divider>
                                                    }
                                                </div>
                                            ))
                                            }
                                        </List>
                                    </Grid>
                                </>
                            }

                            {tabValue == "editor" &&
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={"flex-end"} sx={{ width: "100%" }}>
                                            {isEdit ?
                                                <PrimaryButton color="warning" text="Speichern" onClick={() => { updateTemplate(); }} ></PrimaryButton>
                                                :
                                                <PrimaryButton text="Vorlage erstellen" onClick={() => { saveNewTemplate(); }} ></PrimaryButton>}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField size="small" name="name" value={name} onChange={(event) => { setName(event.target.value); }} label={"Name der Vorlage"} fullWidth></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <QuillEditor value={content} setValue={setContent}></QuillEditor>
                                    </Grid>
                                </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </MainContentContainer >
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />

            <Footer />
        </>
    )
}

export default TemplatesContainer