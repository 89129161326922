import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getComments_api = async (postID) => {
  const sessionData = getSessionData();

  return await axios.get(
    process.env.REACT_APP_API_URL + "/posts/"+postID+"/comment",
    { headers: sessionData }
  );
};

export const setComment_api = async (message,postID) => {
  const sessionData = getSessionData();

  var data= {
    message: message
  }

  return await axios.post(
    process.env.REACT_APP_API_URL + "/posts/"+postID+"/comment",data, { headers: sessionData }
  );
};

export const deleteComment_api = async (postID,commentID) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/posts/"+postID+"/comment/"+commentID,
    { headers: sessionData }
  );
};
