import { FitnessCenter } from '@mui/icons-material';
import { Box, Container, Grid, Table, TableHead, TablePagination, TableRow, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getTraininggroups } from '../../../api/Traininggroups/traininggroups';
import { getTrainings_api } from '../../../api/Trainings/training';
import AddEditTraininggroupDialog from '../../../components/AddEditTraininggroupDialog';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header/Header'
import PrimaryButton from '../../../components/PrimaryButton';
import TitleCard from '../../../components/TitleCard';
import TrainingCard from '../../../components/TrainingCard';
import TraininggroupCard from '../../../components/TraininggroupCard';
import MyTab from '../../../components/MyTab';
import MainContentContainer from '../../../components/MainContentContainer';

function AdmTraininggroupsContainer() {
    const [state, setState] = useState(null);
    const [traininggroups, setTraininggroups] = useState([]);
    const [trainings, setTrainings] = useState([]);

    //tabs
    const [tabValue, setTabValue] = useState("training");
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [openAddEditTraininggroupDialog, setOpenAddEditTraininggroupDialog] = useState(false);

    const getAllTraininggroups = () => {
        var promise = getTraininggroups();
        promise.then((result) => { setTraininggroups(result.data) })
            .catch();
    }

    const getTrainings = () => {
        var promise = getTrainings_api();
        promise.then((result) => { setTrainings(result.data) })
            .catch((error) => { });
    }

    useEffect(() => {
        if (tabValue == "groups") {
            getAllTraininggroups();
        }
        else {
            getTrainings();
        }

        return () => {
            setState(null);
        };
    }, [tabValue]);
    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-traininggroups" title={"Trainingsgruppen"} subheader={"Verwalte die Trainingsgruppen vom " + process.env.REACT_APP_CLUB_NAME} icon={<FitnessCenter sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" spacing={1} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                    <Tabs value={tabValue} onChange={handleTabChange}>
                                        <MyTab id="tab-traininggroups-groups" label="Gruppen" value="groups"></MyTab>
                                        <MyTab id="tab-traininggroups-trainings" label="Trainings" value="training"></MyTab>
                                    </Tabs>
                                </Box>
                            </Grid>
                            {tabValue == "groups" &&
                                <Grid item>
                                    <PrimaryButton id="btn-traininggroups-create" onClick={() => { setOpenAddEditTraininggroupDialog(true); }} text="Trainingsgruppe erstellen" />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"flex-end"}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        count={traininggroups.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        labelRowsPerPage={"Anzahl"}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        sx={{ border: 0 }}
                                    />
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {tabValue == "groups" ?
                            traininggroups && traininggroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((traininggroup, i) => (
                                <Grid item key={i} xs={12} md={6}>
                                    <TraininggroupCard traininggroup={traininggroup} getTraininggroups={getAllTraininggroups} adminMode={true}></TraininggroupCard>
                                </Grid>
                            ))
                            :
                            trainings && trainings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((training, i) => (
                                <Grid item key={i} xs={12} md={6}>
                                    <TrainingCard training={training} getTrainings={getTrainings} adminMode={true}></TrainingCard>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer />
            <AddEditTraininggroupDialog open={openAddEditTraininggroupDialog} onClose={() => { setOpenAddEditTraininggroupDialog(false); }} getTraininggroups={getAllTraininggroups} adminMode={true} isEdit={false}></AddEditTraininggroupDialog>
        </>
    )
}

export default AdmTraininggroupsContainer