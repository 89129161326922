import { Chip, Typography } from '@mui/material';
import React from 'react'

function StatusChip(props) {
    const { status, trueLabel, falseLabel,defaultLabel } = props;


    if (status === 1) {
        return (
            <Chip color="success"  sx={{backgroundColor: "success.light"}} size="small" label={<Typography variant="body2" color="#fff">{trueLabel ? trueLabel :"angemeldet"}</Typography>}/>
        )
    }
    else if (status === 0) {
        return (

            <Chip color="error"  sx={{backgroundColor: "error.light"}} size="small" label={falseLabel ? falseLabel : "abgemeldet"}/>
        )
    }
    else {
        return (
            <Chip color="warning" sx={{backgroundColor: "warning.light"}} size="small" label={defaultLabel ? defaultLabel: "ausstehend"}/>
        )
    }
}

export default StatusChip