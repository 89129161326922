import React from 'react'
import ActionMenu from '../../../components/ActionMenu'
import { ListItemIcon, MenuItem } from '@mui/material';
import { Add, ListAlt, Lock } from '@mui/icons-material';

function CourtContainerMenu(props) {
  return (
    <ActionMenu id="btn-courts-mainmenu" buttonColor="primary" button buttonName='Menü'>
         {/* {props.isAdmin && props.adminMode ? */}
         <MenuItem id="btn-courts-mainmenu-create" onClick={() => {  props.setOpenCreate(true); }}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    Platz erstellen
                </MenuItem>
                <MenuItem id="btn-courts-mainmenu-lock" onClick={() => {  props.lockDialog(true); }}>
                    <ListItemIcon>
                        <Lock fontSize="small" />
                    </ListItemIcon>
                    Plätze sperren
                </MenuItem>
                <MenuItem onClick={() => { props.openCourtLocks(); }}>
                    <ListItemIcon>
                        <ListAlt fontSize="small" />
                    </ListItemIcon>
                    Platzsperrungen anzeigen
                </MenuItem>
    </ActionMenu>
  )
}

export default CourtContainerMenu