import { Stack, Typography } from '@mui/material';

import React from 'react'

function CountWidget(props) {
    const { id,count, icon, color, onClick=()=>{}, variant="h6", fontWeight="bold"} = props;
    return (
        <Stack direction="row" alignItems={"center"} spacing={2} onClick={()=>onClick()}>
            {icon}<Typography id={id} variant={variant} color={color} fontWeight={fontWeight}>{count}</Typography>
        </Stack>
    )
}

export default CountWidget