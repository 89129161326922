export const ROOT = "/";
export const DASHBOARD = "/dashboard";
export const LOGIN = "/login";
export const USERS = "/users";
export const ACTIVEUSERS = "/users?last_activity=today";
export const USERSDETAILS = "/users/:id";
export const SIGNUP = "/signup";
export const USERSUPDATE = "/users/update/:id";
export const USERSME = "/users/me";
export const LOG = "/log";
export const BOOKING = "/booking";
export const BOOKING_Date = "/booking/:date"
export const MYBOOKINGS = "/mybookings";
export const MYCONFIRMATION = "/mybookings/confirm";
export const MYTEAM = "/myteam";
export const MYTEAMMATCHES = "/myteammatches";
export const MYTEAMMATCH = "/myteammatches/:id";
export const MYTRAININGGROUPS = "/mytraininggroups";
export const MYEVENTS = "/myevents";
export const MYEVENT = "/myevents/:id";
export const ADM_BOOKING = "/admbooking";
export const ADM_TEAMMATCHES = "/teammatches";
export const ADM_TEAMS = "/teams";
export const ADM_TRAININGGROUPS = "/traininggroups";
export const ADM_SERIES = "/series";
export const ADM_EVENTS = "/events";
export const ADM_EVENT = "/events/:id";
export const TERMS = "/terms";
export const ADM_DASHBOARD = "/adminpanel";
export const ADM_STATISTICS = "/analytics";
export const FEEDBACK = "/feedback";
export const IMPRESSUM = "/impressum";
export const ADM_RESERVATIONS = "/reservations";
export const ADM_COURTS = "/courts";
export const BLOG = "/blog";
export const POST = "/blog/:id";
export const ADM_BLOG = "/blogmanagement";
export const MYREQUESTS = "/myteammatches?tab=requests";
export const ADM_SURVEYS = "/surveys";
export const MYSURVEYS = "/mysurveys";
export const TOURNAMENT = "/tournament/:id";
export const SUPPORT = "/support";
export const SETTINGS = "/settings";
export const MYTEAMCONFIRMATION = "/myteam/invitation/:code"
export const RELEASENOTES = "/releasenotes"
export const LICENSEAGREEMENT = "/licenseagreement"
export const ADM_TEMPLATES = "/templates"
export const ADM_TEAMMATCHESPLANNER= "/teammatches?tab=planning"
export const ADM_COURTLOCKS= "/courtlocks"
export const ADM_COURTDETAILS= "/courts/:id"

