import { Event, FilterAltOff, GridView, Polyline } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Stack, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { formatISO } from 'date-fns';

import React, { useState, useEffect } from 'react'
import { getMyEvents_api } from '../../api/Events/events';
import EventsCard from '../../components/EventCard';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header'
import TitleCard from '../../components/TitleCard';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SecondaryButton from '../../components/SecondaryButton';
import { MYEVENTS } from '../../navigation/CONSTANTS';
import MyTab from '../../components/MyTab';
import EventTimelineItem from '../../components/EventTimelineItem';
import Timeline from '@mui/lab/Timeline';
import EventCard from '../../components/EventCard';
import MainContentContainer from '../../components/MainContentContainer';

function EventsContainer() {

    const [events, setEvents] = useState();
    // const [tournaments, setTournaments] = useState();
    const [all, setAll] = useState();
    const [value, setValue] = useState("Alle");
    const [searchParam, setSearchParam] = useSearchParams();
    const [view, setView] = useState(searchParam.get('view') || "card");
    const { id } = useParams();
    const navigate = useNavigate("");

    const handleView = (event, newView) => {
        setView(newView);
    }

    const getEvents = (ignoreId) => {
        var query = "";
        if (value === "Turnier") {
            query = "?type=turnier&ende__gte=" + formatISO(new Date());
        }
        else if (value === "Veranstaltung") {
            query = "?type=veranstaltung&ende__gte=" + formatISO(new Date());
        }
        else if (id && ignoreId !== true) {
            query = "?id=" + id;
        }
        else {
            query = "?type__neq=Platzbuchung&ende__gte=" + formatISO(new Date());
        }

        var promise = getMyEvents_api(query);
        promise.then((result) => { setEvents(result.data); })
            .catch();
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        getEvents();
        return () => {
        };
    }, []);

    useEffect(() => {
        getEvents();
    }, [value, id]);

    useEffect(() => {
        setView(searchParam.get('view') || view);
    }, [searchParam]);


    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-myevents" title={"Deine Events"} subheader={"Veranstaltungen und Turniere"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>

                <Grid container direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} >

                        {id !== undefined ?
                            <SecondaryButton startIcon={<FilterAltOff />} text="Alle Events anzeigen" onClick={() => { navigate(MYEVENTS + "?view=timeline"); }} />
                            :
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                                <Tabs centered onChange={handleChange} value={value} sx={{ m: 0, p: 0 }}>
                                    <MyTab label="Alle" value="Alle" />
                                    <MyTab label="Turniere" value="Turnier" />
                                    <MyTab label="Veranstaltungen" value="Veranstaltung" />
                                </Tabs>
                            </Box>
                        }

                    </Grid>
                    <Grid item xs={12}>
                        <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small" fullWidth>
                            <ToggleButton value={"card"}> <Stack direction={"row"} spacing={1}><GridView /> <Typography sx={{ textTransform: "none" }}>Kachelansicht</Typography></Stack></ToggleButton>
                            <ToggleButton value={"timeline"}> <Stack direction={"row"} spacing={1}>< Polyline /> <Typography sx={{ textTransform: "none" }}>Zeitstrahl</Typography></Stack></ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid container item xs={12} spacing={4} justifyContent="flex-start">
                        {view == "card" &&
                            events && events.length > 0 ? events.map((event, i) => (
                                <Grid item key={i} xs={12} sm={6} md={4}>
                                    <EventsCard event={event} getEvents={getEvents} adminMode={false}></EventsCard>
                                </Grid>
                            ))
                            : view == "card" && (!events || events.length == 0) ?
                                <Grid item xs={12}>
                                    <Alert severity="info" variant="filled"><Typography>Aktuell gibt es keine Events...</Typography></Alert>
                                </Grid>
                                :
                                <></>
                        }
                        {view == "timeline" ?
                            <Grid item xs={12}>
                                {events && events.length > 0 ?
                                    <Timeline>
                                        {events && events.map((event, i) => (
                                            i == events.length - 1 ?
                                                // <EventTimelineItem key={i} hideConnector={true} event={event} getEvents={getEvents} ></EventTimelineItem>
                                                <EventCard key={i} event={event} getEvents={getEvents} adminMode={false} hideConnector={true} isTimeline />
                                                :
                                                <EventCard key={i} event={event} getEvents={getEvents} adminMode={false} isTimeline/>
                                        ))
                                        }
                                    </Timeline>
                                    :
                                    <Alert severity="info" variant="filled"><Typography>Aktuell gibt es keine Events...</Typography></Alert>
                                }
                            </Grid>
                            :
                            <></>
                        }
                    </Grid>

                </Grid>
            </MainContentContainer>
            <Footer />
        </>
    )
}

export default EventsContainer