import { AccessTime, CheckBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material'
import { Box, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { addMinutes, formatDistanceStrict } from 'date-fns'
import { de } from 'date-fns/locale'
import React from 'react'
import { convertMinutesToRemainingTime } from '../../utils/dateMechanics'

function RuleDialog(props) {
    const { open, onClose, courts, settings } = props

    return (
        <Dialog open={open} onClose={onClose}>

            <DialogTitle>Platzbuchungsregeln</DialogTitle>
            <DialogContent>
                <Typography variant="body1" fontWeight={"bold"}>Buchungszeitraum</Typography>
                {settings && <Typography variant="body2">{"Die Plätze sind in einem Zeitraum von " + settings.booking_starttime + " - " + settings.booking_endtime + " Uhr buchbar und haben einen Zeitraum, indem sie im Voraus buchbar sind. Ggf muss ein Platz vor Spielantritt bestätigt werden."}</Typography>}
                {settings && <Typography variant="body2" sx={{ pt: 1 }}>{"Jedes Mitglied darf max. " + settings['allowed_bookings_per_day'] + " Buchungen am Tag im Voraus und max " + settings['allowed_bookings_total'] + " Buchungen gesamt haben."}</Typography>}
                <Divider sx={{ py: 1 }}></Divider>
                <Typography variant="body1" fontWeight={"bold"}>Buchungsarten</Typography>
                <List dense sx={{ pt: 0, pb: 0 }}>
                    <ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "primary.main", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Mitglieder"></ListItemText>
                        <Typography fontWeight={"bold"}>{`${settings?.member_booking_price}€`}</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "yellow.dark", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Mitglied-Gast"></ListItemText>
                        <Typography fontWeight={"bold"}>{`${settings?.guest_booking_price}€`}</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "warning.main", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Mitglied-Freitext"></ListItemText>
                        <Typography fontWeight={"bold"}>{`0€`}</Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "success.main", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Freitext (ohne Mitglied)"></ListItemText>
                        <Typography fontWeight={"bold"}>{`0€`}</Typography>
                    </ListItem>
                    < ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "secondary.main", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Abonnement"></ListItemText>
                        <Typography fontWeight={"bold"}>{`0€`}</Typography>
                    </ListItem>
                    < ListItem>
                        <ListItemIcon><Box sx={{ backgroundColor: "error.main", width: "12px", height: "12px" }}></Box></ListItemIcon>
                        <ListItemText primary="Sperrung"></ListItemText>
                        <Typography fontWeight={"bold"}>{`0€`}</Typography>
                    </ListItem>

                </List>
                <Divider></Divider>
                <Typography variant="body1" fontWeight={"bold"} sx={{ pt: 1 }} >Löschfrist</Typography>
                {settings && settings['booking_deletetime'] > 0 ?
                    <Typography variant="body2" sx={{ fontWeight: 400 }}>{"bis " + convertMinutesToRemainingTime(settings['booking_deletetime']) + " vor Spielbeginn"}</Typography>
                    : settings && settings['booking_deletetime'] == 0 ?
                        <Typography variant="body2" sx={{ fontWeight: 400 }}>{"bis zum Spielbeginn"}</Typography>
                        :
                        <></>
                }
                <Typography variant="body2" sx={{ fontWeight: 400 }}>{"bis 30 Minuten nach Buchungserstellung"}</Typography>
                <Divider sx={{ py: 1 }}></Divider>
                <Typography variant="body1" fontWeight={"bold"} sx={{ pt: 1 }}>Zeit im Vorausbuchbar & Buchungsbestätigung</Typography>
                <List dense>
                    <ListItem >
                        <ListItemIcon><CheckBoxOutlined /></ListItemIcon>
                        <ListItemText><Typography variant="body2">Buchungsbestätigung notwendig</Typography></ListItemText>
                    </ListItem>
                    <ListItem dense>
                        <ListItemIcon><IndeterminateCheckBoxOutlined /></ListItemIcon>
                        <ListItemText><Typography variant="body2">Keine Buchungsbestätigung notwendig</Typography></ListItemText>
                    </ListItem>
                </List>
                <Divider ></Divider>
                <List>
                    {courts && courts.map((court, i) => (
                        // <Typography>{court.name + " ist " + formatDistanceStrict(new Date(),addMinutes(new Date(),court.time_in_advance),{locale: de})+ " im Voruas buchbar"}</Typography>
                        <ListItem dense key={i}>
                            <ListItemIcon><AccessTime></AccessTime></ListItemIcon>
                            <ListItemText><Stack direction={"row"} justifyContent={"space-between"}>

                                <Typography variant="body2">{court.name}</Typography>

                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    {!court.time_in_advance || court.time_in_advance == 0 ?
                                        <Typography variant="body2">{"unbeschränkt"}</Typography>
                                        :
                                        <Typography variant="body2">{formatDistanceStrict(new Date(), addMinutes(new Date(), court.time_in_advance), { locale: de })}</Typography>
                                    }
                                    {court.time_in_advance && settings && settings.booking_confirmation > 120 ? <CheckBoxOutlined /> : <IndeterminateCheckBoxOutlined />}</Stack>
                            </Stack>
                            </ListItemText>

                        </ListItem>
                    ))
                    }
                </List>
            </DialogContent>
        </Dialog >
    )
}

export default RuleDialog