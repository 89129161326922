import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

function BookingTypeLegend() {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));


    return (

        <Grid item container xs={12} columnSpacing={3} justifyContent={isSmallScreen ? "center" : "center"} sx={{ pl: 1 }} alignItems={"center"}>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "primary.main", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Mitglieder</Typography>
                </Stack>
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "yellow.dark", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Mitglied-Gast</Typography>
                </Stack>
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "warning.main", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Mitglied-Freitext</Typography>
                </Stack>
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "success.main", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Freitext</Typography>
                </Stack>
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "secondary.main", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Abonnement</Typography>
                </Stack>
            </Grid>
            <Grid item >
                <Stack direction="row" spacing={1} alignItems={"center"}>
                    <Box sx={{ backgroundColor: "error.main", width: "6px", height: "12px" }}></Box>
                    <Typography variant="body2">Sperrung</Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default BookingTypeLegend