import { Event, GridView, Lock, LockClockSharp, ViewListSharp, Web } from '@mui/icons-material';
import { Box, Container, Divider, Grid, Stack, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getCourts_api, getCourtsCounts_api } from '../../../api/Courts/courts';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header/Header';
import TitleCard from '../../../components/TitleCard';
import AddEditCourtDialog from './AddEditCourtDialog';
import CourtCard from './CourtCard';
import CourtsLockDialog from './CourtsLockDialog';
import CourtContainerMenu from './CourtContainerMenu';
import MyTab from '../../../components/MyTab';
import CourtStatsStack from '../../../components/CourtStatsStack';
import { useNavigate } from 'react-router-dom';
import { ADM_COURTLOCKS } from '../../../navigation/CONSTANTS';
import MainContentContainer from '../../../components/MainContentContainer';

function AdmCourtsContainer() {
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [courts, setCourts] = useState([]);
    const [selectedCourt, setSelectedCourt] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [dense, setDense] = useState(true);
    const [courtType, setCourtType] = useState("all");
    const [courtCounts, setCourtCounts] = useState();
    const navigate = useNavigate();

    const handleView = (event, newView) => {
        setDense(newView);
    }

    const getCourts = () => {
        var promise = null;
        if (courtType == "all") {
            promise = getCourts_api();
        } else {
            promise = getCourts_api("?type=" + courtType);
        }

        promise.then((result) => { setCourts(result.data) })
            .catch((error) => { });
        promise = getCourtsCounts_api();
        promise.then((result) => { setCourtCounts(result.data[0]) })
            .catch((error) => { });

    }

    const openCourtLocks = () => {
        navigate(ADM_COURTLOCKS);
    }

    useEffect(() => {
        getCourts(courtType);
    }, [courtType]);

    useEffect(() => {
        if (selectedCourt) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        return () => {

        };
    }, [selectedCourt]);

    useEffect(() => {
        if (!open) {
            setSelectedCourt();
        }
       
    }, [open]);

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-courts" title={"Plätze"} subheader={"Verwalte die Plätze"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
                            <Tabs onChange={(event, newValue) => { setCourtType(newValue); }} value={courtType} variant='scrollable' aria-label="Tabs where each tab needs to be selected manually">
                                <MyTab value="all" label="Alle"></MyTab>
                                <MyTab id="tab-courts-outdoor" value="outdoor" label="Außenplätze"></MyTab>
                                <MyTab id="tab-courts-indoor"  value="indoor" label="Hallenplätze"></MyTab>
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" columnSpacing={2} justifyContent="space-between">
                            <Grid item>
                                <Stack direction="row">
                                    <ToggleButtonGroup color="primary" value={dense} exclusive onChange={handleView}>
                                        <ToggleButton value={false}> <GridView /></ToggleButton>
                                        <ToggleButton value={true}> <ViewListSharp /></ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <CourtContainerMenu lockDialog={setOpen} openCourtLocks={openCourtLocks} setOpenCreate={setOpenCreate}></CourtContainerMenu>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={6} justifyContent={"space-around"} sx={{width: "100%"}}>
                            <CourtStatsStack headlineIcon={<Web color="grey" />} headlineText={"Plätze"} indoorToottip={"Hallenplätz"} outdoorTooltip={"Außenplätz"} indoorValue={courtCounts && courtCounts.indoor_courts} outdoorValue={courtCounts && courtCounts.outdoor_courts}></CourtStatsStack>
                            <CourtStatsStack headlineIcon={<Lock color="grey" />} headlineText={"Gesperrt"} indoorToottip={"Hallenplatz | aktive Sperrungen"} outdoorTooltip={"Außenplatz | aktive Sperrungen"} indoorValue={courtCounts && courtCounts.indoor_locked} outdoorValue={courtCounts && courtCounts.outdoor_locked}></CourtStatsStack>
                            <CourtStatsStack headlineIcon={<LockClockSharp color="grey" />} headlineText={"Geplant"} indoorToottip={"Hallenplatz | geplante Sperrungen"} outdoorTooltip={"Außenplatz | geplante Sperrungen"} indoorValue={courtCounts && courtCounts.indoor_upcominglocked} outdoorValue={courtCounts && courtCounts.outdoor_upcominglocked}></CourtStatsStack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item container xs={12} direction="row" alignItems="start" justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                        {courts && courts.map((court, i) => (
                            <Grid item xs={12} md={6} lg={4} key={i}>
                                <CourtCard court={court} getCourts={getCourts} openEdit={setOpenCreate} openLock={setOpen} setSelectedCourt={setSelectedCourt} dense={dense} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </MainContentContainer>
            <CourtsLockDialog open={open} onClose={() => setOpen(false)} getCourts={getCourts} court={selectedCourt} courtType={selectedCourt?.type}></CourtsLockDialog>
            <AddEditCourtDialog open={openCreate} onClose={() => setOpenCreate(false)} getCourts={getCourts} court={selectedCourt} isEdit={isEdit} setSelectedCourt={setSelectedCourt}></AddEditCourtDialog>
            <Footer />
        </>
    )
}

export default AdmCourtsContainer