import { Ballot, Campaign, ChevronLeft, CloseSharp, EmojiEvents, Event, FitnessCenter, Group, Groups, KeyboardTab, LinkSharp, Person, School, Search, Settings, SportsTennis, SupportAgent, Web } from '@mui/icons-material'
import { Box, Collapse, Divider, Drawer, List, Stack, TextField, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import TertiaryButton from '../TertiaryButton';
import PrimaryIconButton from '../PrimaryIconButton';
import HelpMenuListItem from './HelpMenuListItem';

import { useNavigate } from 'react-router-dom';
import { FEEDBACK, SUPPORT } from '../../navigation/CONSTANTS';

import articles from "./articles.json";
import ArticlesStack from './ArticlesStack';

const HelpCenterContext = createContext();

export const useHelpCenter = () => {
    return useContext(HelpCenterContext);
};

export const HelpCenterProvider = ({ children }) => {
    const [menu, setMenu] = useState("menu");
    const [open, setOpen] = useState();
    const [search, setSearch] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);

    const showHelpCenter = (menu = "menu") => {
        setOpen(true);
        setMenu(menu);
    };

    const hideHelpCenter = () => {
        setOpen(false);
    };

    const umlautMap = {
        'ä': '&auml;',
        'ö': '&ouml;',
        'ü': '&uuml;',
        'Ä': '&Auml;',
        'Ö': '&Ouml;',
        'Ü': '&Uuml;',
        'ß': '&szlig;'
    };

    const replaceUmlautsWithEntities = (text) => {
        return text.replace(/[äöüÄÖÜß]/g, match => umlautMap[match]);
    };

    const searchArticles = (data, searchWord) => {
        if (!data || typeof searchWord !== 'string') {
            throw new Error('Invalid input');
        }
        if (searchWord.length < 3) {
            setFilteredArticles([]);
            return null;
        }

        // Hilfsfunktion zum Überprüfen, ob ein Text das Suchwort enthält (unempfindlich gegenüber Groß-/Kleinschreibung)
        const containsSearchWord = (text) => {
            const normalizedText = text.toLowerCase();
            const normalizedSearchWord = searchWord.toLowerCase();
            const searchWordWithEntities = replaceUmlautsWithEntities(normalizedSearchWord);
            return normalizedText.includes(normalizedSearchWord) || normalizedText.includes(searchWordWithEntities);
        };

        // Array für gefilterte Artikel
        let newFilteredArticles = [];

        // Iteriere über alle Kategorien im Datenobjekt
        for (const category in data) {
            if (data.hasOwnProperty(category)) {
                const articles = data[category];

                // Überprüfe, ob der aktuelle Schlüssel eine Liste von Artikeln enthält
                if (Array.isArray(articles)) {
                    articles.forEach(article => {
                        const summaryMatches = containsSearchWord(article.summary);
                        const descriptionMatches = containsSearchWord(article.description);

                        // Wenn entweder summary oder description das Suchwort enthalten, füge das Objekt zu filteredArticles hinzu
                        if (summaryMatches || descriptionMatches) {
                            newFilteredArticles.push({
                                summary: article.summary,
                                description: article.description,
                                category: category
                            });
                        }
                    });
                }
            }
        }
        setFilteredArticles(newFilteredArticles);
    }

    const navigate = useNavigate();

    return (
        <HelpCenterContext.Provider value={{ showHelpCenter, hideHelpCenter }}>
            {children}
            <Drawer anchor='left' open={open} onClose={() => { setOpen(false) }} PaperProps={{ sx: { width: { xs: "100%", md: "700px" }, maxHeight: "calc(100% - 48px)", top: "48px" } }}>
                <Stack sx={{ py: 2, px: 2 }} spacing={1}>
                    <Stack direction="row" sx={{ width: "100%" }} alignItems={"center"} justifyContent={"center"}>
                        <Stack flexGrow={1}>
                            <Box sx={{ width: "40px" }} />
                        </Stack>
                        <Stack direction="row" alignItems={"center"} spacing={1}>
                            <Typography variant="h5" color="text.secondary" align='center'>Hilfecenter</Typography>
                        </Stack>
                        <Stack flexGrow={1} alignItems={'flex-end'}>
                            <PrimaryIconButton icon={<KeyboardTab sx={{ rotate: "180deg" }} />} onClickAction={() => setOpen(false)}></PrimaryIconButton>
                        </Stack>
                    </Stack>
                    <Divider></Divider>
                    {menu == "menu" &&
                        <TextField size="small" variant="standard" value={search} placeholder='Suche' InputProps={{ startAdornment: <Search color="grey" />, endAdornment: search.length > 0 ? <CloseSharp onClick={() => { setSearch(""); setFilteredArticles([]) }} color="grey"></CloseSharp> : <></>}} sx={{ py: 2 }} onChange={(event) => { setSearch(event.target.value); searchArticles(articles, event.target.value); }} />
                    }
                    <Stack>
                        {menu == "menu" ?
                            <>
                                <Stack spacing={1}>

                                    {filteredArticles && filteredArticles.length > 0 &&
                                        <ArticlesStack articles={filteredArticles} stackName={"Suchergebnis"} />
                                    }

                                    <Typography color="secondary" variant="h6">Hilfe nach Themen</Typography>
                                    <List dense>
                                        <HelpMenuListItem primary={"Erste Schritte"} secondary={(articles.firstStepsArticles ? articles.firstStepsArticles.length : 0) + " Artikel"} clickHandler={() => setMenu("firstSteps")} icon={<School />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Platzbuchungen"} secondary={(articles.bookingArticles ? articles.bookingArticles.length : 0) + " Artikel"} clickHandler={() => setMenu("booking")} icon={<SportsTennis />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Punktspiele"} secondary={(articles.teammatchArticles ? articles.teammatchArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("teammatches")} icon={<EmojiEvents />}></HelpMenuListItem>

                                        <HelpMenuListItem primary={"Mannschaften"} secondary={(articles.teamArticles ? articles.teamArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("teams")} icon={<Group />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Umfragen"} secondary={(articles.surveyArticles ? articles.surveyArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("surveys")} icon={<Ballot />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Training"} secondary={(articles.trainingArticles ? articles.trainingArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("training")} icon={<FitnessCenter />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Veranstaltungen & Turniere"} secondary={(articles.eventArticles ? articles.eventArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("events")} icon={<Event></Event>}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Account"} secondary={(articles.accountArticles ? articles.accountArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("profile")} icon={<Person />}></HelpMenuListItem>

                                        <HelpMenuListItem primary={"Vereinseinstellungen"} secondary={(articles.clubSettingArticles ? articles.clubSettingArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("clubsettings")} icon={<Settings />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Platzverwaltung"} secondary={(articles.courtArticles ? articles.courtArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("courtmanagement")} icon={<Web />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Mitgliederverwaltung"} secondary={(articles.usermanagementArticles ? articles.usermanagementArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("usermanagement")} icon={<Groups />}></HelpMenuListItem>
                                        {/* <HelpMenuListItem primary={"Blog"} secondary={(articles.blogArticles ? articles.blogArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("blogmanagement")} icon={<Feed />}></HelpMenuListItem>
                                        <HelpMenuListItem primary={"Analytics"} secondary={(articles.analyticsArticles ? articles.analyticsArticles.length : "0") + " Artikel"} clickHandler={() => setMenu("analytics")} icon={<Insights />}></HelpMenuListItem> */}
                                    </List>
                                </Stack>
                            </>
                            :
                            <>

                                <Stack alignItems={"flex-start"}>
                                    <TertiaryButton color="grey" text="zurück zur Auswahl" startIcon={<ChevronLeft />} onClick={() => setMenu("menu")} />
                                </Stack>
                                <Collapse in={menu != "menu"}>

                                    {menu == "firstSteps" ?
                                        <ArticlesStack articles={articles.firstStepsArticles} stackName={"Erste Schritte"} />
                                        : menu == "booking" ?
                                            <ArticlesStack articles={articles.bookingArticles} stackName={"Platzbuchungen"} />
                                            : menu == "teammatches" ?
                                                <ArticlesStack articles={articles.teammatchArticles} stackName={"Punktspiele"} />
                                                : menu == "teams" ?
                                                    <ArticlesStack articles={articles.teamArticles} stackName={"Mannschaften"} />
                                                    : menu == "surveys" ?
                                                        <ArticlesStack articles={articles.surveyArticles} stackName={"Umfragen"} />
                                                        : menu == "training" ?
                                                            <ArticlesStack articles={articles.trainingArticles} stackName={"Training"} />
                                                            : menu == "events" ?
                                                                <ArticlesStack articles={articles.eventArticles} stackName={"Veranstaltungen & Turniere"} />
                                                                : menu == "profile" ?
                                                                    <ArticlesStack articles={articles.accountArticles} stackName={"Account"} />
                                                                    :
                                                                    menu == "courtmanagement" ?
                                                                        <ArticlesStack articles={articles.courtArticles} stackName={"Platzverwaltung"} />
                                                                        : menu == "usermanagement" ?
                                                                            <ArticlesStack articles={articles.usermanagementArticles} stackName={"Mitgliederverwaltung"} />
                                                                            : menu == "clubsettings" ?
                                                                            <ArticlesStack articles={articles.clubSettingArticles} stackName={"Vereinseinstellungen"} />
                                                                            :
                                                                            <></>
                                    }
                                </Collapse>
                            </>
                        }
                        <Typography color="secondary" variant="h6" mt={2}>Keine Hilfe bekommen?</Typography>

                        <Stack spacing={1}>
                            <TertiaryButton text="Support-Ticket erstellen" startIcon={<SupportAgent />} onClick={() => { navigate(FEEDBACK + "?category=support"); hideHelpCenter(); }}></TertiaryButton>
                            <TertiaryButton text="Meine Support-Tickets" startIcon={<LinkSharp />} onClick={() => { navigate(SUPPORT); hideHelpCenter() }}></TertiaryButton>
                            <TertiaryButton text="Fehlende Artikel? Gib uns Feedback!" startIcon={<Campaign />} onClick={() => { navigate(FEEDBACK + "?category=feedback"); hideHelpCenter(); }}></TertiaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Drawer>
        </HelpCenterContext.Provider>
    );
};
