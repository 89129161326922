import {makeStyles} from '@mui/styles';


export const myStyles = makeStyles(theme=>({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 2,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    tableHeader: {
        // backgroundColor: 'rgb(100,141,174)',
        backgroundColor: 'rgb(249, 250, 251)',
        color: "rgb(85 87 88)",
        fontWeight: "bold",
        //maxWidth: "300px"
    },
    tableCell: {
        //maxWidth: "300px"
    },
    bookingButton:{
        textTransform: "none",
        borderRadius: 20,
        fontWeight: 400,
    },
    formButton:{
        textTransform: "none"
    }
}));