import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Stack, Step, StepLabel, Stepper, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getViewPermissions_api, setEvent_api, updateEvent_api } from '../api/Events/events';
import DateTimeField from './DateTimeField';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { addHours, compareDesc, isValid, setMinutes, setSeconds } from 'date-fns';
import { getUsersNames } from '../api/Users/users';
import TertiaryButton from './TertiaryButton';
import CustomAutocomplete from './CustomAutocomplete';
import { Clear, Groups } from '@mui/icons-material';
import PrimaryIconButton from './PrimaryIconButton';
import AvatarInitials from './AvatarInitials';
import TeamSelect from './TeamSelect';
import QuillEditor from './QuillEditor';
import { useSnackbar } from './SnackbarContext';

function AddEditEventsDialog(props) {
    const { open, onClose, getEvents, isEdit, event } = props;
    const [targetCourts, setTargetCourts] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [startdate, setStartdate] = useState(setSeconds(setMinutes(new Date(), 0), 0));
    const [enddate, setEnddate] = useState(addHours(setSeconds(setMinutes(startdate, 0), 0), 1));
    const [isSubmit, setIsSubmit] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [withParticipants, setWithParticipants] = useState(false);
    const [deadline, setDeadline] = useState(startdate);
    const [type, setType] = useState(props.type || "");
    const [global, setGlobal] = useState(false);
    const [eventMail, setEventMail] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [maxRegistrations, setMaxRegistrations] = useState("");


    const [targetUsers, setTargetUsers] = useState([]);
    const [users, setUsers] = useState([{}]);

    const [activeStep, setActiveStep] = useState(0);
    const [activeTab, setActiveTab] = useState("users");
    const [teamid, setTeamid] = useState("");
    const [targetTeams, setTargetTeams] = useState([]);
    const [step1IsError, setStep1IsError] = useState(false);
    const [step2IsError, setStep2IsError] = useState(false);
    const [step3IsError, setStep3IsError] = useState(false);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [deletedTeams, setDeletedTeams] = useState([]);

    const { showSnackbar } = useSnackbar();

    //autocomplete
    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, firstname: result.data[i].firstname, lastname: result.data[i].surname, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
        })
            .catch(error => { });
    }

    const addTargetUser = (targetUser) => {
        if (targetUser && !targetUsers.includes(targetUser)) {

            setTargetUsers(targetUsers => {
                return [...targetUsers, targetUser];
            })
        }
    }

    const removeTargetUser = (index) => {
        //add user to deleted users
        setDeletedUsers(deletedUsers => {
            return [...deletedUsers, targetUsers[index]];
        })
        //remove user
        setTargetUsers([
            ...targetUsers.slice(0, index),
            ...targetUsers.slice(index + 1)
        ]);
    }

    const addTeam = (targetTeam) => {
        setTeamid(targetTeam.id);
        if (!targetTeams.includes(targetTeam)) {
            setTargetTeams(teams => {
                return [...teams, targetTeam];
            })
        }
    }

    const removeTeam = (index) => {
        //add team to deleted teams
        setDeletedTeams(deletedTeams => {
            return [...deletedTeams, targetTeams[index]];
        })
        //remove team
        setTargetTeams([
            ...targetTeams.slice(0, index),
            ...targetTeams.slice(index + 1)
        ]);
    }
    const nextStep = () => {
        if (activeStep + 1 <= 2) {
            setActiveStep(activeStep + 1);
        }
    }

    const backStep = () => {
        if (activeStep - 1 >= 0) {
            setActiveStep(activeStep - 1);
        }
    }

    const handleChangeTab = (event, newTab) => {
        setActiveTab(newTab);
    }

    const submitHandler = () => {
        setIsSubmit(true);
        var promise = null;

        if (isEdit) {
            promise = updateEvent_api(targetCourts, name, type, startdate, enddate, description, withParticipants, isBooking, deadline, event.id, global, targetUsers, targetTeams, deletedUsers, deletedTeams, eventMail, maxRegistrations);
            promise.then((result) => { setIsSubmit(false); getEvents(); onClose(); showSnackbar("Event erfolgreich editiert", "success"); })
                .catch((error) => { setIsSubmit(false); showSnackbar("Fehlgeschlagen:" + error, "error"); });
        }
        else {
            promise = setEvent_api(targetCourts, name, type, startdate, enddate, description, withParticipants, isBooking, deadline, global, targetUsers, targetTeams, eventMail, maxRegistrations);
            promise.then((result) => { setIsSubmit(false); getEvents(); onClose(); showSnackbar("Event erfolgreich erstellt", "success"); })
                .catch((error) => { setIsSubmit(false); showSnackbar("Fehlgeschlagen:" + error, "error"); });
        }
    }
    const handleChange = (event) => {
        switch (event.target.name) {
            case "isBooking":
                setIsBooking(event.target.checked);
                break;
            case "withParticipants":
                setWithParticipants(event.target.checked);
                break;

            default:
                break;
        }
    }
    const getViewPermissions = () => {
        var promise = getViewPermissions_api(event.id);
        promise.then((result) => { setTargetUsers(result.data.users); setTargetTeams(result.data.teams); })
            .catch();
    }

    const initializeEvent = () => {
        setName(event.name);
        setStartdate(event.start);
        setName(event.name);
        setDescription(event.description);
        setTargetCourts(JSON.parse(event.courts));
        setEnddate(event.ende);
        setWithParticipants(event.withParticipants);
        setIsBooking(event.withBooking);
        setDeadline(event.deadline);
        setType(event.type);
        setGlobal(event.global);
        getViewPermissions();
        setActiveStep(0);
        setMaxRegistrations(event.maxRegistrations == 0 ? "" : event.maxRegistrations);
    }

    useEffect(() => {
        if (open) {
            getUsersList();
            if (event && isEdit) {
                initializeEvent();
            }
        }
        else {
            setName("");
            setStartdate(setSeconds(setMinutes(new Date(), 0), 0));
            setName("");
            setDescription("");
            setTargetCourts([]);
            setEnddate(addHours(setSeconds(setMinutes(new Date(), 0), 0), 1));
            setWithParticipants(false);
            setIsBooking(false);
            setDeadline(setSeconds(setMinutes(new Date(), 0), 0));
            setType(props.type || "");
            setErrorDate(false)
            setEventMail(false);
            setTargetUsers([]);
            setTargetTeams([]);
            setStep1IsError(false);
            setStep2IsError(false);
            setStep3IsError(false);
            setActiveStep(0);
            setMaxRegistrations("");
        }
    }, [open]);

    useEffect(() => {
        if (new Date(startdate) > new Date(enddate) && compareDesc(startdate, enddate) === -1) {
            setErrorDate(true)
            setStep2IsError(true)
        }
        else {
            setErrorDate(false)
            setStep2IsError(false)
        }
    }, [startdate, enddate]);

    useEffect(() => {
        if ((activeStep === 1 || activeStep === 2) && (!name || !type || !description)) {
            setStep1IsError(true);
        }
        else {
            setStep1IsError(false);
        }
        if (activeStep === 2 && !global && targetTeams.length === 0 && targetUsers.length === 0) {
            setStep3IsError(true);
        }
        else {
            setStep3IsError(false);
        }
    }, [activeStep, global, targetTeams, targetUsers]);

    useEffect(() => {
        if (step1IsError || step2IsError || step3IsError) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
    }, [step1IsError, step2IsError, step3IsError]);

    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                {isEdit ? <DialogTitle sx={{ backgroundColor: "#eee" }}>{"Event bearbeiten"}</DialogTitle> : <DialogTitle sx={{ backgroundColor: "#eee" }}>{"Event erstellen"}</DialogTitle>}
                <DialogTitle>
                    {/* <Typography sx={{ typography: { xs: "h5", md: "h5" }, pb: 3 }} align="center">Events</Typography> */}
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step onClick={() => { setActiveStep(0) }}>
                            <StepLabel error={step1IsError}> <Typography variant="body2" sx={{ fontWeight: 700 }}>Event Beschreibung</Typography></StepLabel>
                        </Step>
                        <Step onClick={() => { setActiveStep(1) }}>
                            <StepLabel error={step2IsError}> <Typography variant="body2" sx={{ fontWeight: 700 }}>Event Zeit</Typography></StepLabel>
                        </Step>
                        {type !== "Platzbuchung" ?
                            <Step onClick={() => { setActiveStep(2) }}>
                                <StepLabel error={step3IsError}><Typography variant="body2" sx={{ fontWeight: 700 }}>Teilnehmer auswählen</Typography></StepLabel>
                            </Step>
                            :
                            <></>
                        }

                    </Stepper>
                </DialogTitle>
                <Divider></Divider>
                <DialogContent sx={{ height: "425px", overflowY: "auto"}}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid> */}
                        {activeStep === 0 ?
                            <>
                                {isSubmit ? <Grid item xs={12} align="center"><CircularProgress></CircularProgress> </Grid> : <></>}
                                <Grid item xs={12} md={12}>
                                    <TextField id="txf-eventdialog-name" required fullWidth label={"Name des Events"} value={name} onChange={(e) => { setName(e.target.value) }}></TextField>
                                </Grid>
                                <Grid item xs={12} >
                                    {/* <Alert sx={{ mb: 2 }} color="primary"><Typography> Turniere und Veranstaltung werden den anderen Nutzern angezeigt</Typography></Alert> */}
                                    <FormControl fullWidth id="type">
                                        <InputLabel id="category_select">Typ</InputLabel>
                                        <Select id="sel-eventdialog-type" label="Typ" labelid="Typ" value={type} fullWidth onChange={(event) => { setType(event.target.value); }}>
                                            <MenuItem id="btn-eventdialog-type-tournament" value={"Turnier"}>Turnier</MenuItem>
                                            <MenuItem id="btn-eventdialog-type-event" value={"Veranstaltung"} >Veranstaltung</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <QuillEditor value={description} setValue={setDescription}></QuillEditor>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                        <Stack>
                                            <Typography sx={{ fontWeight: 700 }} variant="body1">E-Mail Benachrichtigung</Typography>
                                            <Typography variant="body2">Soll die Beschreibung per E-Mail and die Teilnehmer verschickt werden?</Typography>
                                        </Stack>
                                        <Switch id="swi-events-notifications" checked={eventMail} onChange={() => { setEventMail(!eventMail); }}></Switch>
                                    </Stack>
                                </Grid>
                            </>
                            : activeStep === 1 ?
                                <>
                                    <Grid item xs={12} md={6}>
                                        <DateTimeField id="dtf-eventdialog-start" label={"Startdatum"} date={new Date(startdate)} setDate={setStartdate} textReadOnly></DateTimeField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateTimeField id="dtf-eventdialog-end" label={"Enddatum"} date={new Date(enddate)} setDate={setEnddate} minDate={isValid(startdate) && new Date(startdate)} textReadOnly></DateTimeField>
                                    </Grid>
                                    {errorDate &&
                                        <Grid item xs={12}>
                                            <Alert severity="warning"><Typography>Bitte korrigieren Sie die Datumseingaben. Das Intervall muss in der Zukunft sein und das Enddatum muss größer als das Startdatum sein.</Typography></Alert>
                                        </Grid>
                                    }
                                </>
                                : activeStep === 2 && type !== "Platzbuchung" ?
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>

                                                <Grid item xs={12}>
                                                    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                                        <Stack>
                                                            <Typography sx={{ fontWeight: 700 }} variant="body1">Vereinsweites Event</Typography>
                                                            <Typography variant="body2">Soll der ganze Verein oder nur bestimmte Personen/Mannschaften das Event sehen können?</Typography>
                                                        </Stack>
                                                        <Switch id="swi-eventdialog-global" checked={global} onChange={() => { setGlobal(!global); }}></Switch>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
                                                        <Stack>
                                                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Anmeldung erforderlich?</Typography>
                                                            <Typography variant="body2">Müssen sich die eingeladenen Teilnehmer für dieses Event anmelden?</Typography>
                                                        </Stack>
                                                        <Switch id="swi-eventdialog-participants" size="medium" name="withParticipants" checked={withParticipants} onChange={handleChange}></Switch>
                                                    </Stack>
                                                </Grid>
                                                {withParticipants ?
                                                    <>
                                                        <Grid item xs={12} sx={{ pl: 2 }}>
                                                            <Stack spacing={2} sx={{ pl: 2 }} >
                                                                <DateTimeField id="dtf-eventdialog-deadline" label={"Anmeldefrist"} date={new Date(deadline)} setDate={setDeadline} maxDate={isValid(new Date(enddate)) && new Date(enddate)} textReadOnly />
                                                                <TextField id="txf-eventdialog-maxregistrations" type="number" label="Maximale Anmeldungen" value={maxRegistrations} onChange={(event) => setMaxRegistrations(event.target.value)} fullWidth helperText={"Keine Angabe für keine Beschränkung"} />
                                                            </Stack>
                                                        </Grid>
                                                        {/* <Grid item xs={12} sx={{ pl: 2 }}>
                                                        </Grid> */}
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                                {!global ?
                                                    <>
                                                        <Grid item xs={12}>
                                                            <ToggleButtonGroup color="primary" value={activeTab} onChange={handleChangeTab} exclusive>
                                                                <ToggleButton value="users" sx={{ textTransform: "none" }}>{"Mitglieder " + (targetUsers && "(" + targetUsers.length + ")")}</ToggleButton>
                                                                <ToggleButton value="teams" sx={{ textTransform: "none" }}>{"Teams " + (targetTeams && "(" + targetTeams.length + ")")}</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </Grid>
                                                        {activeTab === "users" &&
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <CustomAutocomplete id="atc-eventdialog-participants" options={users} label="Mitglied" setTarget={addTargetUser} multipleSelect={true} />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider></Divider>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    {targetUsers && targetUsers.length > 0 ?
                                                                        <List>
                                                                            {targetUsers.map((user, i) => (
                                                                                <ListItem key={i}>
                                                                                    <ListItemAvatar><AvatarInitials firstname={user.firstname} lastname={user.lastname} /></ListItemAvatar>
                                                                                    <ListItemText primary={user.firstname + " " + user.lastname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                                                                    </ListItemText>
                                                                                    <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTargetUser(i); }} sx={{ ml: 2 }} />
                                                                                </ListItem>
                                                                            ))}
                                                                        </List>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </Grid>
                                                            </>
                                                        }
                                                        {activeTab === "teams" &&
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <TeamSelect teamid={teamid} addTeam={addTeam} multipleSelect={true}></TeamSelect>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider></Divider>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {targetTeams && targetTeams.length > 0 ?
                                                                        < List >
                                                                            {targetTeams.map((team, i) => (
                                                                                <ListItem key={i}>
                                                                                    <ListItemAvatar><Groups /></ListItemAvatar>
                                                                                    <ListItemText primary={team.team}>
                                                                                    </ListItemText>
                                                                                    <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTeam(i); }} sx={{ ml: 2 }} />
                                                                                </ListItem>
                                                                            ))}
                                                                        </List>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </Grid>
                                                            </>
                                                        }
                                                        {targetUsers && targetTeams && targetUsers.length === 0 && targetTeams.length === 0 ?
                                                            <Grid item xs={12}>
                                                                <Alert severity='warning'><Typography>Bitte wähle mindestens ein Mitglied oder eine Mannschaft aus</Typography></Alert>
                                                            </Grid>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">
                                                            <Typography>Alle Mitglieder können das Event sehen</Typography>
                                                        </Alert>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <></>

                        }
                    </Grid>

                </DialogContent>
                <Divider></Divider>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text="Abbrechen" onClick={() => { onClose(); }} />

                    <Stack direction="row" spacing={1}>
                        {activeStep != 0 &&
                            <SecondaryButton text="Zurück" onClick={() => { backStep(); }} />
                        }
                        {activeStep != 2 ?
                            <PrimaryButton id="btn-eventdialog-nextstep" disabled={errorDate} text="Weiter" onClick={() => { nextStep() }} />
                            :
                            <PrimaryButton id="btn-eventdialog-submit" disabled={submitDisabled} text={"Bestätigen"} onClick={() => { submitHandler(); }}></PrimaryButton>
                        }

                    </Stack>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default AddEditEventsDialog