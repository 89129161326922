import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header.jsx'
import BookingTable from './BookingTable.jsx';
import { addSeconds, format, isEqual, isValid, isWithinInterval, startOfDay } from 'date-fns';
import { getReservationsByDay_api } from '../../api/Reservations/reservations.js';
import populateReservations from '../../utils/populateReservations.js';
import { AcUnit, EventAvailable, LightMode } from '@mui/icons-material';
import { Alert, Box, Container, Grid, Tabs, Typography } from '@mui/material';
import TitleCard from '../../components/TitleCard.jsx';
import { useParams, useSearchParams } from 'react-router-dom';
import Footer from '../../components/Footer.jsx';
import { getCourts_api } from '../../api/Courts/courts.js';
import { getSettings_api, getSettingsIndoor_api, getSettingsOutdoor_api } from '../../api/Settings/settings.js';
import MyTab from '../../components/MyTab.jsx';
import { useSnackbar } from '../../components/SnackbarContext.jsx';
import MainContentContainer from '../../components/MainContentContainer.jsx';

export default function BookingContainer({ dialogView, dateProp, isShortText, courtTypeProp, setStartdate, adminMode, endtime, targetCourts, setTargetCourts, setEnddate, serieId }) {
    const { date } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [dayFilter, setDayFilter] = useState(dateProp || new Date());
    const [rows, setRows] = useState([]);
    const [reservations, setReservations] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [courts, setCourts] = useState([]);
    const [settings, setSettings] = useState();
    const [courtType, setCourtType] = useState(searchParams.get("courttype") || "outdoor");
    const { showSnackbar } = useSnackbar();


    const populateTable = () => {
        // take care of race condtions with settings and courts!!!!!!
        setIsLoading(true);
        const reservationPromise = getReservationsByDay_api(format(new Date(dayFilter.toString()), "yyyy-MM-dd"), null, courtType);
        reservationPromise.then((result) => { setRows(populateReservations(result.data, courts, settings)); setReservations(result.data); setTimeout(() => { setIsLoading(false) }, 250); });
    }
    // get settings based on active tab (indoor vs outdoor)
    const getSettings = (newCourtType = null) => {
        var promise = null;
        if (newCourtType == "outdoor") {
            promise = getSettingsOutdoor_api();
        }
        else if (newCourtType == "indoor") {
            promise = getSettingsIndoor_api();
        }
        else {
            return null;
        }
        promise.then((result) => { setSettings(result.data[0]) })
            .catch((error) => { });
    }

    const getCourts = () => {
        var promise = getCourts_api("?type=" + courtType);
        promise.then((result) => { setCourts(result.data) })
            .catch((error) => { });
    }

    useEffect(() => {
        setIsLoading(true);
        getSettings(courtType);
    }, [courtType]);

    useEffect(() => {
        // take care of race condtions!!!!!!
        if (settings) {
            getCourts();
            if (isValid(new Date(date))) {
                setDayFilter(new Date(date));
            }
        }

    }, [settings]);

    useEffect(() => {
        if (courts && courts.length > 0 && settings && isValid(dayFilter)) {
            populateTable();
        }
        else if (isLoading) {
            setIsLoading(false);
        }
        else {

        }
    }, [dayFilter, courts]);

    useEffect(() => {
        if (isValid(new Date(dateProp)) && !isEqual(startOfDay(dateProp), startOfDay(dayFilter))) {
            setDayFilter(dateProp)
        }

    }, [dateProp]);

    useEffect(() => {
        if (courtTypeProp == "indoor" || courtTypeProp == "outdoor") {
            setCourtType(courtTypeProp);
        }
    }, [courtTypeProp]);

    //show snackbar warning in case booking collides 
    useEffect(() => {
        try {
            if (reservations?.some(reservation => targetCourts.some(courts => courts.id == reservation.platz && serieId != reservation.seriesID) && isWithinInterval(addSeconds(new Date(reservation.timestamp), 1), { start: dateProp, end: endtime }))) {
                showSnackbar("Buchungszeitraum kollidiert mit einer bestehenden Buchung. Die Buchung kann trotzdem durchgeführt werden.", "warning");
            }
        } catch (error) {

        }

    }, [dateProp, endtime, targetCourts]);

    return (
        <>
            {!dialogView &&
                <Header></Header>
            }
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                {!dialogView ?
                    <>
                        <Grid item xs={12}>
                            <TitleCard id="crd-title-booking" title={"Platzbelegung"} subheader={"Buche deine nächste Partie!"} icon={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></TitleCard>
                        </Grid>
                        {isWarning &&
                            <Grid item xs={12}>
                                <Alert severity="warning" variant="filled"><Typography>Testwarnung alle Plätze sind gesperrt</Typography></Alert>
                            </Grid>
                        }
                    </>
                    :
                    <></>
                }
            </Grid>
            <MainContentContainer px={0} py={0}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
                    <Tabs onChange={(event, newValue) => { setCourtType(newValue); }} value={courtType} variant='scrollable' aria-label="Tabs where each tab needs to be selected manually">
                        <MyTab id="tab-booking-outdoor" value="outdoor" label="Außenplätze" icon={<LightMode color="warning" />} iconPosition="start" sx={{ pb: 0 }} disabled={isLoading} />
                        <MyTab id="tab-booking-indoor" value="indoor" label="Hallenplätze" icon={<AcUnit color="primary" />} iconPosition="start" sx={{ pb: 0 }} disabled={isLoading} />
                    </Tabs>
                </Box>
                <BookingTable serieId={serieId} setTargetCourts={setTargetCourts} startdate={dateProp} endtime={endtime} targetCourts={targetCourts} adminMode={adminMode} setEnddate={setEnddate} setStartdate={setStartdate} rows={rows} courts={courts} settings={settings} dayFilter={dayFilter} setDayFilter={setDayFilter} populateTable={populateTable} reservations={reservations} isLoading={isLoading} isShortText={isShortText} dialogView={dialogView} courtType={courtType}></BookingTable>
            </MainContentContainer>

            {!dialogView &&
                <Footer />
            }
        </>
    );
}
