import { add, differenceInMinutes, format, getHours, getMinutes, setHours, setMinutes, setSeconds } from "date-fns";

function createData(zeit, courts) {

  var array = new Array(courts.length + 1);
  array[0] = zeit;
  for (var i = 0; i < courts.length; i++) {
    array[courts[i].id] = "";
  }
  return array;
}
function countIntervalsBetweenDates(startDate, endDate, intervalInMinutes) {
  //calculcate new date - startdate is still currentDate -> make sure day is same like day of reservation to calcluate only difference between hours not days
  var calculatedDate = endDate;
  calculatedDate = setHours(calculatedDate, getHours(startDate));
  calculatedDate = setMinutes(calculatedDate, getMinutes(startDate));

  const differenceInMinutesValue = differenceInMinutes(endDate, calculatedDate);

  // console.log(differenceInMinutesValue+ " | " +endDate + " | " +calculatedDate);
  return Math.floor(differenceInMinutesValue / intervalInMinutes);
}

function populateReservations(reservationData, courts, settings) {

  // get infos from club settings
  var startHour = settings.booking_starttime;
  var endHour = settings.booking_endtime;
  var intervall = settings.booking_duration; // minutes

  // create start time & end time 
  var startTime = new Date().setHours(startHour);
  startTime = setMinutes(startTime, 0);
  startTime = setSeconds(startTime, 0);
  var endTime = new Date().setHours(endHour);

  var currentDate = new Date().setHours(getHours(startTime));
  currentDate = setMinutes(currentDate, 0);
  currentDate = setSeconds(currentDate, 0);

  // initialize empty data with time column
  var rows = [];
  do {
    rows.push(createData(format(currentDate, "HH:mm"), courts));
    currentDate = add(currentDate, { minutes: intervall });
  }
  while (getHours(currentDate) !== getHours(endTime));


  // populate court matrix with all availabe reservations for this day 
  for (var i = 0; i < reservationData.length; i++) {
    var platz = reservationData[i].platz;
    var names = reservationData[i].name;
    var spieler1 = reservationData[i].spieler1;
    var spieler2 = reservationData[i].spieler2;
    var bookingID = reservationData[i].id;
    var confirmed = reservationData[i].confirmed;
    var spieler1_name = reservationData[i].spieler1_name;
    var spieler2_name = reservationData[i].spieler2_name;
    var spieler1_firstname = reservationData[i].spieler1_firstname;
    var spieler2_firstname = reservationData[i].spieler2_firstname;
    var guestname = reservationData[i].guestname;
    var timestamp = reservationData[i].timestamp;
    var type = reservationData[i].type;
    var serieId = reservationData[i].seriesID;
    var eventId = reservationData[i].eventID;

    //time determines in which row entry is set, dependning on index of row it is displayed in bookingTable
    //-> calulcate in which row reservation needs to be set
    var index = countIntervalsBetweenDates(startTime, new Date(reservationData[i].timestamp), intervall)
    //needs to be hardened regarding corrupt dates
    //added to rule to only display courtlocks and ignore reservations on same spot
    if (index >= rows.length || index < 0) {
      continue;
    }
    else if (rows[index][platz]?.type == "lock") {
      rows[index][platz].collision += 1;
      continue;
    }
    else if (rows[index][platz]?.type != "lock" && rows[index][platz].timestamp) {
      rows[index][platz] = { platz: platz, names: names, spieler1: spieler1, spieler2: spieler2, id: bookingID, confirmed: confirmed, spieler1_name, spieler2_name, guestname, timestamp: timestamp, spieler1_firstname: spieler1_firstname, spieler2_firstname: spieler2_firstname, type: type, serieId: serieId, eventId: eventId, collision: rows[index][platz].collision += 1 };
      //rows[index][platz].collision += 1;
    }
    else {
      rows[index][platz] = { platz: platz, names: names, spieler1: spieler1, spieler2: spieler2, id: bookingID, confirmed: confirmed, spieler1_name, spieler2_name, guestname, timestamp: timestamp, spieler1_firstname: spieler1_firstname, spieler2_firstname: spieler2_firstname, type: type, serieId: serieId, eventId: eventId, collision: 0 };
    }
  }
  return rows;
}

export default populateReservations;
