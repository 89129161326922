import { Launch, LockClockSharp, LockOpenSharp, LockSharp, OpenInBrowser, Person, Update, } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardMedia, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import { addMinutes, format, formatDistanceStrict, isAfter, isBefore, isValid } from 'date-fns';
import React, { useState } from 'react'
import { deleteCourt_api, resetCourt_api } from '../../../api/Courts/courts';
import CourtStatusChip from '../../../components/CourtStatusChip';
import CourtActionMenu from './CourtActionMenu';
import { de } from 'date-fns/locale';
import DeleteDialog from '../../../components/DeleteDialog';
import { useSnackbar } from '../../../components/SnackbarContext';
import IndoorImage from "../../../assets/indoorCourt.jpg"
import OutdoorImage from "../../../assets/nextReservation.jpg"
import TertiaryButton from '../../../components/TertiaryButton';
import { useNavigate } from 'react-router-dom';
import { ADM_COURTDETAILS, ADM_COURTLOCKS, ADM_COURTS } from '../../../navigation/CONSTANTS';


function CourtCard({ court, getCourts, openEdit, setSelectedCourt, dense, ...props}) {

    const [expanded, setExpanded] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Platz löschen", warningText: "Das Löschen eines Platzes ist nicht empfohlen und sollte gut bedacht sein.", openDeleteDialog, deleteObjectName: court.name, deleteHandler: () => { } });
    const { showSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const resetCourt = () => {
        var promise = resetCourt_api(court.id);
        promise.then((result) => { getCourts(); showSnackbar("Platz: " + court.name + " geöffnet", "success") })
            .catch();
    }

    const deleteCourt = () => {
        var promise = deleteCourt_api(court.id);
        promise.then((result) => { getCourts(); showSnackbar("Platz: " + court.name + " gelöscht", "success"); })
            .catch((error) => { });
    }
    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, deleteObjectName: court.name, openDeleteDialog: true, deleteHandler: () => deleteCourt() })
    }

    const handleCourtEdit = () => {
        setSelectedCourt(court)
        openEdit(true);
    }

    const handleCourtLock = () => {
        setSelectedCourt(court);
        props.openLock(true);
    }

    return (
        <>
            <Card id="crd-court" raised sx={{ borderRadius: 2 }}>
                <Box sx={{ position: "relative" }}>
                    {court.type == "outdoor" ?
                        <CardMedia image={OutdoorImage} sx={{ height: "150px" }} onClick={()=>navigate(ADM_COURTS+"/"+court.id)}></CardMedia>
                        :
                        <CardMedia image={IndoorImage} sx={{ height: "150px" }} onClick={()=>navigate(ADM_COURTS+"/"+court.id)}></CardMedia>
                    }
                    {dense &&
                        <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: "calc(100% - 8px)", p: 1 }}>
                            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Stack>
                                    <Typography variant="h6">{court.name}</Typography>
                                    <Typography variant="body1">{court.time_in_advance ? formatDistanceStrict(new Date(), addMinutes(new Date(), court.time_in_advance), { locale: de }) : "unbegrenzt"}</Typography>
                                </Stack>
                                <Stack direction="row" alignItems={"center"} spacing={0}>
                                <CourtStatusChip court={court} />
                                <CourtActionMenu resetCourt={resetCourt} deleteCourt={openDeleteDialogAction} editAction={handleCourtEdit} lockDialog={handleCourtLock}></CourtActionMenu>
                                </Stack>
                            </Stack>
                        </Box>
                    }
                </Box>
                {!dense ?
                    <CardContent onClick={() => { setExpanded(!expanded) }}>
                        <Grid container justifyContent={"space-between"} alignItems="center">
                            <Grid item>
                                <Typography id="txt-courtcard-name" variant="h6" align="center">{court.name}</Typography>
                            </Grid>
                            <Grid>
                                <CourtActionMenu resetCourt={resetCourt} deleteCourt={() => openDeleteDialogAction()} editAction={handleCourtEdit} lockDialog={handleCourtLock}></CourtActionMenu>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider>
                                    <CourtStatusChip court={court} />
                                </Divider>
                            </Grid>
                            <Grid item>
                                <List dense disablePadding>
                                    <ListItem >
                                        <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><Update color="primary"></Update></Avatar></ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1">{court.time_in_advance ? formatDistanceStrict(new Date(), addMinutes(new Date(), court.time_in_advance), { locale: de }) : "unbegrenzt"}</Typography>
                                            <Typography variant="body2" color="text.secondary">im Voraus buchbar</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <ListSubheader>nächste Sperrung</ListSubheader>
                                    {/* {isBefore(new Date(), new Date(court.start_time)) ? */}
                                    <>
                                        <ListItem >
                                            {isBefore(new Date(), new Date(court.locks?.[0]?.startdate)) ?
                                                <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockClockSharp color="primary"></LockClockSharp></Avatar></ListItemIcon>
                                                : isAfter(new Date(), new Date(court.locks?.[0]?.startdate)) && isBefore(new Date(), new Date(court.locks?.[0]?.enddate)) ?
                                                    <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockSharp color="primary"></LockSharp></Avatar></ListItemIcon>
                                                    : isAfter(new Date(), new Date(court.locks?.[0]?.enddate)) ?
                                                        <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockOpenSharp color="primary"></LockOpenSharp></Avatar></ListItemIcon>
                                                        : <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockSharp color="primary"></LockSharp></Avatar></ListItemIcon>

                                            }
                                            <ListItemText>
                                                <Typography variant="body1">{isValid(new Date(court.locks?.[0]?.startdate)) ? format(new Date(court.locks?.[0]?.startdate), "dd.MM. HH:mm 'Uhr'") : "-"}</Typography>
                                                <Typography variant="body2" color="text.secondary">Sperrung</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon><Avatar sx={{ backgroundColor: "#E3F2FD" }}><LockOpenSharp color="primary"></LockOpenSharp></Avatar></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1">{isValid(new Date(court.locks?.[0]?.enddate)) ? format(new Date(court.locks?.[0]?.enddate), "dd.MM. HH:mm 'Uhr'") : "-"}</Typography>
                                                <Typography variant="body2" color="text.secondary">Öffnung</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </>
                                </List>

                            </Grid>
                            {court.locks?.length > 1 ?
                                <Grid xs={12} item>
                                    <TertiaryButton startIcon={<Launch/>} text={court.locks?.length-1 + " weitere Sperrungen vorhanden"} onClick={() => { navigate(ADM_COURTLOCKS)}}></TertiaryButton>
                                </Grid>

                                :
                                <Grid xs={12} item>
                                    <TertiaryButton startIcon={<Launch/>} text={"Keine weiteren Sperrungen vorhanden"} onClick={() => { navigate(ADM_COURTLOCKS)}}></TertiaryButton>
                                </Grid>

                            }

                        </Grid>
                    </CardContent>

                    : <></>}
            </Card >
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
        </>
    )
}

export default CourtCard