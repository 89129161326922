import { ExpandMore, MoreVert } from '@mui/icons-material';
import { Button, IconButton, Menu, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import ResponsiveDrawer from './ResponsiveDrawer';

function ActionMenu({ id,children, buttonColor, button, buttonName = "Menü", isDrawer, drawerTitle, drawerSubtitle, drawerAnchor = "bottom", tooltipTitle, iconSize = "large",...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    //props.setObject(props.object);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 
  return (
    <>
      {button ?
        <Button variant="contained" color="primary" sx={{ textTransform: "none" }}
          id={id || "menu"}
          size="medium"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          endIcon={<ExpandMore />}
          onClick={(e) => { handleClick(e); e.stopPropagation(); }}
        >{buttonName}</Button>
        :
        <Tooltip title={tooltipTitle}>
          <IconButton 
            id={id || "menu"}
            size={iconSize}
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) => { handleClick(e); e.stopPropagation(); }}
            color={"inherit"}
          >
            <MoreVert color={props.color}></MoreVert>
          </IconButton>
        </Tooltip>
      }
      {isDrawer ?
        <ResponsiveDrawer drawerTitle={drawerTitle} drawerSubtitle={drawerSubtitle} openDrawer={open} setOpenDrawer={setAnchorEl} closDrawerOnClick>
          {children}
        </ResponsiveDrawer>
        :
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          onClick={(e) => { handleClose(); e.stopPropagation(); }}
          MenuListProps={{
            'aria-labelledby': 'menu',
          }}
        >
          {children}</Menu>
      }

    </>
  )

}

export default ActionMenu