import { Group, Login, Logout } from '@mui/icons-material';
import { AvatarGroup, Card, CardContent, CardHeader, Collapse, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React, { useState } from 'react'
import { deleteTrainingParticipant_api, getTrainingParticipants_api, setTrainingParticipant_api } from '../api/Trainings/training';
import CountWidget from './CountWidget';
import EventMemberCard from './EventMemberCard';
import PrimaryIconButton from './PrimaryIconButton';
import AvatarInitials from './AvatarInitials';

function TrainingCard(props) {
    const { training, getTrainings, adminMode, elevation, borderRadius } = props;

    const [participants, setParticipants] = useState();
    const [disabled, setDisabled] = useState(false);

    const [expanded, setExpanded] = useState(false);

    const signinHandler = (trainigid) => {
        setDisabled(true);
        var promise = setTrainingParticipant_api(trainigid);
        promise.then((result) => { getTrainings(); setDisabled(false); })
            .catch();
    }

    const signoutHandler = (trainigid) => {
        setDisabled(true);
        var promise = deleteTrainingParticipant_api(trainigid);
        promise.then((result) => { getTrainings(); setDisabled(false); })
            .catch((error) => { setDisabled(false); });
    }

    const getTrainingParticipants = () => {
        var promise = getTrainingParticipants_api(training.id);
        promise.then((result) => { setParticipants(result.data) })
            .catch();
    }


    return (
        <>
            <Card id="crd-training" elevation={typeof elevation !== "undefined" ? elevation : 4} sx={{ borderRadius: typeof borderRadius !== "undefined" ? borderRadius : 1, borderLeft: 3, borderLeftColor: "primary.main" }}>
                <CardHeader title={training.traininggroupName} titleTypographyProps={{ variant: "body1", fontWeight: 500 }} sx={{ backgroundColor: "#eee", p: 1 }}></CardHeader>
                <CardContent onClick={() => { setExpanded(!expanded) }} sx={{ p: 1, "&:last-child": { paddingBottom: 1 } }}>
                    <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"} spacing={2}>
                        <Grid item>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, borderRadius: 0, alignItems: "center" }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{training && format(new Date(training.datetime), "EEEEEE", { locale: de })}</Typography>
                                <Typography variant="body2">{training && format(new Date(training.datetime), "kk:mm 'Uhr'", { locale: de })}</Typography>
                            </Box>
                        </Grid>

                        <Grid item container direction="column" xs alignItems={"flex-start"}>
                            <Grid item zeroMinWidth>
                                <Stack direction={"row"} spacing={1} sx={{width: "100%"}}>
                                    <AvatarGroup total={training.participants.length} spacing={10}>
                                        {training && training.participants.length > 0 && training.participants.map((participant, i) => (
                                            i <= 3 &&
                                            <AvatarInitials firstname={participant.firstname} lastname={participant.surname} key={i} />
                                        ))
                                    }
                                    </AvatarGroup>

                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CountWidget id="txt-trainingcard-totalparticipants" icon={<Group></Group>} count={training.totalParticipants}></CountWidget>
                        </Grid>

                        {!adminMode ?

                            <Grid item >
                                {training.isParticipant === 1 ?
                                    <PrimaryIconButton id="btn-trainingcard-signout" icon={<Logout color="error" />} disabled={disabled} onClickAction={() => { signoutHandler(training.id); }} />
                                    :
                                    <PrimaryIconButton id="btn-trainingcard-signin" icon={<Login color="success" />} disabled={disabled} onClickAction={() => { signinHandler(training.id); }} />
                                }
                            </Grid>
                            :
                            <></>
                        }

                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent sx={{ bgcolor: "#eeeeee" }}>
                        <EventMemberCard participants={training.participants} getParticipants={getTrainingParticipants} getEvents={getTrainings} event={training} expanded={expanded}></EventMemberCard>
                    </CardContent>
                </Collapse>
            </Card>
        </>
    )
}

export default TrainingCard