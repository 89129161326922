import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import MainContentContainer from '../../components/MainContentContainer';
import { Alert, AlertTitle, Card, Stack, Typography } from '@mui/material';
import TitleCard from '../../components/TitleCard';
import TertiaryButton from '../../components/TertiaryButton';
import { Error, Launch, SupportAgent } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SUPPORT } from '../../navigation/CONSTANTS';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Fehlerstatus aktualisieren
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Hier kannst du den Fehler z.B. an einen Logging-Service senden
        console.error("Fehler gefangen:", error, errorInfo);
        console.log("Fehler gefangen:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Header />
                    <TitleCard title={"Fehler"} subheader={"Etwas ist schiefgelaufen"} icon={<Error sx={{ width: 40, height: 40 }} />} />
                    <MainContentContainer>

                        <Stack direction="column" alignItems={"center"} justifyContent={"center"} spacing={1} sx={{pt: 3}}>
                            <Alert severity="warning">
                                <AlertTitle>Fehler</AlertTitle>
                                <Typography>Leider ist ein Fehler aufgetreten. Bei erneutem Auftreten wende dich bitte an den Support!</Typography>
                            </Alert>
                            <TertiaryButton text="Zurück zur vorherigen Seite" startIcon={<Launch />} onClick={() => { window.location.reload() }}></TertiaryButton>
                            <TertiaryButton text="Support" startIcon={<SupportAgent />} onClick={()=>{window.location.href = SUPPORT}}></TertiaryButton>
                        </Stack>

                    </MainContentContainer>
                    <Footer></Footer>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
