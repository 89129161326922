import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getSeries = async (query) => {
  const sessionData = getSessionData();
  return await axios.get(
    process.env.REACT_APP_API_URL + "/series?"+query,
    { headers: sessionData }
  );
};

export const deleteSeries = async (seriesid) => {
  const sessionData = getSessionData();

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/series/" + seriesid,
    { headers: sessionData }
  );
};


export const setSeries_api = async (days, targetCourts, name, startdate, enddate, repeatEnddate, assignments, type,courtType) => {
  const sessionData = getSessionData();

  var data = {
    days: days,
    courts: targetCourts,
    startdate: startdate,
    enddate: enddate,
    name: name,
    repeatEnddate: repeatEnddate,
    assignments: assignments,
    type: type,
    courtType:courtType
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/series", data,
    { headers: sessionData }
  );
};

export const updateSeries_api = async (id, days, targetCourts, name, startdate, enddate, repeatEnddate, assignments, type,courtType) => {
  const sessionData = getSessionData();

  var data = {
    days: days,
    courts: targetCourts,
    startdate: startdate,
    enddate: enddate,
    name: name,
    repeatEnddate: repeatEnddate,
    assignments: assignments,
    type: type,
    courtType
  }
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/series/" + id, data,
    { headers: sessionData }
  );
};