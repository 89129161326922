import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer'
import MainContentContainer from '../../../components/MainContentContainer'
import CourtCard from './CourtCard'
import { useNavigate, useParams } from 'react-router-dom'
import { getCourts_api, getCourtsCounts_api } from '../../../api/Courts/courts'
import TitleCard from '../../../components/TitleCard'
import { ChevronLeft, Info } from '@mui/icons-material'
import TertiaryButton from '../../../components/TertiaryButton'
import { ADM_COURTS } from '../../../navigation/CONSTANTS'
import CourtsLockDialog from './CourtsLockDialog'
import AddEditCourtDialog from './AddEditCourtDialog'

function CourtDetailsContainer() {

    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [courts, setCourts] = useState();
    const [selectedCourt, setSelectedCourt] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [courtType, setCourtType] = useState("all");
    const [courtCounts, setCourtCounts] = useState();

    const [court, setCourt] = useState();
    const { id } = useParams();
    const navigate = useNavigate();



    const getCourt = () => {
        var promise = getCourts_api("?id=" + id);
        promise.then((result) => {
            setCourt(result.data[0]);
        })
            .catch((error) => { });
    }

    const getCourts = () => {
        var promise = null;
        if (courtType == "all") {
            promise = getCourts_api();
        } else {
            promise = getCourts_api("?type=" + courtType);
        }

        promise.then((result) => { setCourts(result.data) })
            .catch((error) => { });
        promise = getCourtsCounts_api();
        promise.then((result) => { setCourtCounts(result.data[0]) })
            .catch((error) => { });

    }

    useEffect(() => {
        getCourts(courtType);
    }, [courtType]);

    useEffect(() => {
        if (selectedCourt) {
            setIsEdit(true);
        }
        else {
            setIsEdit(false);
        }
        return () => {

        };
    }, [selectedCourt]);

    useEffect(() => {
        if (!open) {
            setSelectedCourt();
        }

    }, [open]);

    useEffect(() => {
        getCourt();
    }, []);
    return (
        <>
            <Header></Header>
            <TitleCard title="Platzdetails" subheader={"Verwalte alle Details des Platzes"} icon={<Info sx={{ width: 40, height: 40 }} />}></TitleCard>
            <TertiaryButton startIcon={<ChevronLeft />} text="Zurück zur Platzverwaltung" onClick={() => { navigate(ADM_COURTS) }}></TertiaryButton>
            <MainContentContainer maxWidth='md'>
                {court &&
                    <CourtCard court={court} getCourts={getCourt} openEdit={setOpenCreate} openLock={setOpen} setSelectedCourt={setSelectedCourt}></CourtCard>
                }
            </MainContentContainer>
            <CourtsLockDialog open={open} onClose={() => setOpen(false)} getCourts={getCourt} court={selectedCourt} courtType={selectedCourt?.type}></CourtsLockDialog>
            <AddEditCourtDialog open={openCreate} onClose={() => setOpenCreate(false)} getCourts={getCourt} court={selectedCourt} isEdit={isEdit} setSelectedCourt={setSelectedCourt}></AddEditCourtDialog>
            <Footer></Footer>
        </>
    )
}

export default CourtDetailsContainer