import { Delete } from '@mui/icons-material';
import { Card, CardContent, Grid, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import AvatarInitials from '../../components/AvatarInitials'
import PrimaryIconButton from '../../components/PrimaryIconButton';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';


function PostComment(props) {
    const { message, firstname, lastname, deleteComment, comment } = props;
    const isAdmin = useSelector((state)=>state.isAdmin);

  return (
    <Card elevation={0} sx={{ border: 0 }}>
      <CardContent>
        <Grid container direction="row" spacing={1} alignItems={"center"}>
          <Grid item >
            <AvatarInitials firstname={firstname} lastname={lastname}></AvatarInitials>
          </Grid>
          <Grid item xs>
            <Paper sx={{ backgroundColor: "#ddd", p: 1 }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={"body2"}>{firstname + " " + lastname}</Typography>
                <Typography variant={"body2"}>{comment.reg_date && format(new Date(comment.reg_date.split(" ")[0] + "T" + comment.reg_date.split(" ")[1]), "dd.MM.yy - hh:mm") + " Uhr"}</Typography>
              </Stack>
              <Typography>{message}</Typography>
            </Paper>
          </Grid>
          <Grid item>
            {(isAdmin || comment.isOwner == 1) &&
              <PrimaryIconButton icon={<Delete />} onClickAction={() => { deleteComment(comment.id) }} />
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}


export default PostComment