import { Chip, Typography } from '@mui/material'
import React from 'react'

function FilterChip({ keyName, value, setSearchParam, searchParams, ...props }) {

  const onDelete = () => {
    searchParams.delete(keyName);
    setSearchParam(searchParams);
  }
  return (
    <Chip size="small" label={<Typography>{keyName + ":" + value}</Typography>} onDelete={() => { onDelete() }}></Chip>
  )
}

export default FilterChip