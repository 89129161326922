import { Button } from '@mui/material'
import React from 'react'

function PrimaryButton(props) {
  const {text} = props

  return (
    <Button variant={"contained"} sx={{textTransform: "none"}} {...props}>{text}</Button>
    )
}

export default PrimaryButton