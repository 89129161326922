import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getClubInfo_api = async (query = "") => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/club" + query,
    { headers: sessionData }
  );
};





