
import { Card, CardContent, CardHeader, Collapse,Typography } from '@mui/material'
import React, { useState } from 'react'
import DynamicExpandIcon from './DynamicExpandIcon';
import { sanitize } from 'dompurify';


function DescriptionCard(props) {

    const [expanded, setExpanded] = useState(props.expanded|| false);

    return (
        <Card elevation={0} sx={{border: 0, overflow: "auto", width: "100%",overflowY: "auto", maxHeight: props.maxHeight}}>
            {/* <CardHeader title={"Beschreibung"} subheader={props.subHeader} titleTypographyProps={{ variant: "h6" }} sx={{cursor: "pointer"}} onClick={(e)=>{setExpanded(!expanded);e.stopPropagation();}} action={<DynamicExpandIcon expanded={expanded}></DynamicExpandIcon>}/> */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent onClick={(e)=>{e.stopPropagation();}}>
                    <Typography dangerouslySetInnerHTML={{ __html: sanitize(props.content) }} />
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default DescriptionCard