


import { TimePicker } from '@mui/x-date-pickers';
import {setSeconds } from 'date-fns';


import React from 'react'

function TimeField(props) {
    const { date, setDate, size, label, views, textReadOnly, minutesStep, sx} = props;
    return (

        <TimePicker
            label={label}
            value={new Date(date)}
            disableMaskedInput
            views={views}
            minutesStep={minutesStep}
            onChange={(newValue) => {
                setDate(setSeconds(newValue, 0));
            }}
            // renderInput={(params) => textReadOnly ? <TextField size={size} fullWidth sx={sx} {...params} inputProps={{...params.inputProps,readOnly: true}} /> : <TextField size={size} sx={sx} fullWidth {...params}/>}
            slotProps={{
                textField: {
                    size: size,
                    fullWidth: true,
                    sx: sx,
                    inputProps: textReadOnly ? { readOnly: true } : {},
                },
            }}
        />
    )
}

export default TimeField