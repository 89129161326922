import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer'
import MainContentContainer from '../../../components/MainContentContainer'
import TitleCard from '../../../components/TitleCard'
import { deleteCourtLock_api, getClosedCourts_api, } from '../../../api/Courts/courts'
import { Alert, Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { useSnackbar } from '../../../components/SnackbarContext'
import CourtStatusChip from '../../../components/CourtStatusChip'
import { AcUnit, ChevronLeft, Delete, LightMode, Lock, LockClockSharp } from '@mui/icons-material'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import { format, isAfter, isValid } from 'date-fns'
import CourtLockStatusChip from '../../../components/CourtLockStatusChip'
import CourtStatusCountCard from '../../../components/CourtStatusCountCard'
import TertiaryButton from '../../../components/TertiaryButton'
import { useNavigate } from 'react-router-dom'
import { ADM_COURTS } from '../../../navigation/CONSTANTS'

function AdmCourtLocksContainer() {

    const [courts, setCourts] = useState([]);
    const [locks, setLocks] = useState([]);
    const [refreshCounts, setRefreshCounts] = useState(false);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();


    const getClosedCourts = () => {
        var promise = getClosedCourts_api();
        promise.then((result) => { setLocks(result.data) })
            .catch((error) => { });
    }

    const deleteCourtLock = (id) => {
        var promise = deleteCourtLock_api(id);
        promise.then((result) => { setRefreshCounts(!refreshCounts); getClosedCourts(); showSnackbar("Platzsperrung gelöscht", "success"); })
            .catch((error) => { showSnackbar(error.response.data.error, "error") });
    }

    useEffect(() => {
        getClosedCourts();
    }, []);

    return (
        <>
            <Header></Header>
            <TitleCard title="Platzsperrungen" id={'crd-title-courtlocks'}subheader={"Verwalte alle aktiven und geplanten Platzsperrungen"} icon={<Lock sx={{ width: 40, height: 40 }} />}></TitleCard>
            <MainContentContainer wrapInCard={true}>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <TertiaryButton id="btn-courtlocks-backtocourts" startIcon={<ChevronLeft />} text="Zurück zur Platzverwaltung" onClick={() => { navigate(ADM_COURTS) }}></TertiaryButton>
                    </Grid>
                    <Grid item xs={12}>
                        <CourtStatusCountCard refresh={refreshCounts}></CourtStatusCountCard>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12} md={10} xl={8}>

                        <List>
                            {
                                locks.length > 0 ? locks.map((lock, i) => (
                                    <React.Fragment key={i}>
                                        {/* <CourtLockStatusChip startdate={lock.startdate} enddate={lock.enddate}></CourtLockStatusChip> */}
                                        <ListItem key={i} secondaryAction={<Stack direction="row" alignItems={"center"} spacing={1}>{lock.court_type == "indoor" ? <AcUnit color="primary" fontSize='small' /> : <LightMode color="warning" />}<Divider flexItem orientation='vertical'></Divider><PrimaryIconButton id="btn-courtlocks-delete" icon={<Delete color="primary" />} onClickAction={() => { deleteCourtLock(lock.id) }}></PrimaryIconButton></Stack>}>

                                            <ListItemIcon>
                                                <Avatar sx={{ backgroundColor: "#E3F2FD" }}>
                                                    {
                                                        isAfter(new Date(), new Date(lock.startdate)) ? <Lock color="error" /> : <LockClockSharp color="warning" />
                                                    }
                                                </Avatar>
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography fontWeight={"medium"}>{lock.court_name}</Typography>} secondary={
                                                <Stack>
                                                    <Typography variant="body2" component="span">{isValid(new Date(lock.startdate)) && format(new Date(lock.startdate), 'dd.MM.yy HH:mm')}</Typography>
                                                    {/* <Typography variant="body2" component="span"> - </Typography> */}
                                                    <Typography variant="body2" component="span">{isValid(new Date(lock.enddate)) && format(new Date(lock.enddate), 'dd.MM.yy HH:mm')}</Typography>
                                                </Stack>
                                            }>
                                            </ListItemText>
                                        </ListItem>
                                        <Divider ></Divider>
                                    </React.Fragment>
                                )
                                )
                                    :
                                    <Alert severity="info"><Typography>Keine aktiven oder geplanten Sperrungen</Typography></Alert>
                            }

                        </List>


                    </Grid>
                </Grid>


            </MainContentContainer>
            <Footer></Footer>
        </>
    )
}

export default AdmCourtLocksContainer