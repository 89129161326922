import { Card, CardContent, List, CardHeader } from '@mui/material'
import React from 'react'
import { ADM_RESERVATIONS } from '../navigation/CONSTANTS';
import SimpleListItem from './SimpleListItem';
import SimpleListLink from './SimpleListLink';
import { startOfYear } from 'date-fns';

function ListCard(props) {
    const { link, items, icon, header, divider,year } = props;
    return (
        <Card raised sx={{ borderRadius: 2 }}>
            <CardHeader title={header} />
            <CardContent>
                <List dense>
                    {items && items.map((item, i) => (
                            link ?
                                <SimpleListLink key={i} divider icon={icon} linkURL={ADM_RESERVATIONS+"?price__neq=0.00&user="+item.id+"&day__gte=" +year+"-01-01&day__lte=" +year+"-12-31"  } linkname={item.action} value={item.count} />
                                :
                                <SimpleListItem key={i} divider icon={icon} object={item.action} value={item.count} />
                        ))

                    }
                </List>
            </CardContent>
        </Card>
    )
}

export default ListCard