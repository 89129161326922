import { Alert, Dialog, DialogActions, DialogContent, DialogTitle,Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import TertiaryButton from './TertiaryButton';
import PrimaryButton from './PrimaryButton';

function DeleteDialog({ title, warningText,deleteObjectName,openDeleteDialog, onClose, deleteHandler, ...props }) {
    return (
        <>
            <Dialog open={openDeleteDialog} onClose={() => { onClose() }}>
                <DialogTitle sx={{backgroundColor: "#ddd"}}><Typography variant={"h6"}>{title}</Typography></DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} sx={{mt: 2}}>                                            
                        <Grid item xs={12}>
                            <Stack direction={"row"} spacing={1}>
                                <Typography>{"Möchtest du " + deleteObjectName + " wirklich löschen?"}</Typography>
                            </Stack>
                        </Grid>
                        {warningText &&
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <Typography>{warningText}</Typography>
                                </Alert>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <TertiaryButton text="Abbrechen" onClick={() => { onClose();}}></TertiaryButton>
                    <PrimaryButton id="btn-deletedialog-delete" text="Löschen" onClick={() => { deleteHandler(); onClose(); }}></PrimaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteDialog