import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CourtStatsStack from './CourtStatsStack'
import { Lock, LockClockSharp, Web } from '@mui/icons-material'
import { getCourtsCounts_api } from '../api/Courts/courts';

function CourtStatusCountCard({refresh, ...props}) {
    const [counts, setCourtCounts] = useState(null);

    const getCourtCounts = () => {
        var promise = getCourtsCounts_api();
        promise.then((result) => { setCourtCounts(result.data[0]) })
            .catch((error) => { });
    }

    useEffect(() => {
        getCourtCounts();
    }, [refresh]);

    return (
        <Stack direction="row" spacing={6} justifyContent={"space-around"}>
            <CourtStatsStack headlineIcon={<Web color="grey" />} headlineText={"Plätze"} indoorToottip={"Hallenplätz"} outdoorTooltip={"Außenplätz"} indoorValue={counts && counts.indoor_courts} outdoorValue={counts && counts.outdoor_courts}></CourtStatsStack>
            <CourtStatsStack headlineIcon={<Lock color="grey" />} headlineText={"Gesperrt"} indoorToottip={"Hallenplatz | aktive Sperrungen"} outdoorTooltip={"Außenplatz | aktive Sperrungen"} indoorValue={counts && counts.indoor_locked} outdoorValue={counts && counts.outdoor_locked}></CourtStatsStack>
            <CourtStatsStack headlineIcon={<LockClockSharp color="grey" />} headlineText={"Geplant"} indoorToottip={"Hallenplatz | geplante Sperrungen"} outdoorTooltip={"Außenplatz | geplante Sperrungen"} indoorValue={counts && counts.indoor_upcominglocked} outdoorValue={counts && counts.outdoor_upcominglocked}></CourtStatsStack>
        </Stack>
    )
}

export default CourtStatusCountCard