import { Card, CardMedia, Chip, Grid, ListItemIcon, MenuItem, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import ActionMenu from './ActionMenu';
import { Delete, Edit } from '@mui/icons-material';
import { deletePost_api } from '../api/Posts/post';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import DeleteDialog from './DeleteDialog';

function PostActionMenu(props) {
    return (
        <ActionMenu>
            <MenuItem onClick={() => { props.editAction(); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                Bearbeiten
            </MenuItem>
            <MenuItem onClick={() => { props.deleteAction(); }}>
                <ListItemIcon>
                    <Delete fontSize="small" color="error" />
                </ListItemIcon>
                Löschen
            </MenuItem>
        </ActionMenu>
    );
}

function LightPostCard({ post, ...props }) {
    const ref = useRef();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Beitrag löschen", warningText: "Der Beitrtag und alle Kommentare werden unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: "diesen Beitrag", deleteHandler: () => { } });

    const deletePost = () => {
        var promise = deletePost_api(post.id);
        promise.then((result) => { props.getPosts(); })
            .catch();
    }
    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState,deleteObjectName: '"' + post.title + '"', openDeleteDialog:  true, deleteHandler: () => deletePost() })
    }

    return (
        <>
            <Card>
                <Grid container direction="row" spacing={1} alignItems={"center"}>
                    <Grid item ref={ref}>
                        <CardMedia component="img" src={post.img_url} sx={{ width: 170, height: 96, objectFit: 'cover', borderRadius: 2 }}></CardMedia>
                    </Grid>
                    <Grid item xs sx={{ height: ref.current && ref.current.clientHeight, overflowY: "hidden" }}>
                        <Stack spacing={0.5}>
                            <Typography variant="h6" color="text.secondary" noWrap>{post.title}</Typography>
                            <Typography variant="body1" color="text.secondary">{format(new Date(post.reg_date), "d. MMMM yyyy", { locale: de })}</Typography>
                            <Chip variant="outlined" color="primary" size="small" label={<Typography>{post.category}</Typography>} sx={{ width: "fit-content" }}></Chip>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <PostActionMenu editAction={() => props.openEditPost(post)} deleteAction={openDeleteDialogAction}></PostActionMenu>
                    </Grid>
                </Grid>
            </Card>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({...deleteDialogState ,openDeleteDialog: false})} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
        </>
    )
}

export default LightPostCard