import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import React from 'react'

function ActionMenuItem({ icon, itemText, onClick = {},disabled=false,id,...props }) {
    return (
        <MenuItem id={id} disabled={disabled} onClick={() => { onClick(); }}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <Typography sx={{ pl: { xs: 4, md: 0 }, typography: { xs: "h6", md: "body1" }, color: { xs: "text.secondary", md: "text.primary" } }}> {itemText}</Typography>
        </MenuItem>
    )
}

export default ActionMenuItem