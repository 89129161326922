import { Box, Divider, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DatePickFilter from './DatePickerFilter';
import PrimaryIconButton from './PrimaryIconButton';
import { ChevronLeft, ChevronRight, QuestionMark, TextDecreaseSharp, TextIncreaseSharp } from '@mui/icons-material';
import { addDays, eachDayOfInterval, endOfMonth, endOfWeek, format, isSameDay, isValid, startOfMonth, startOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
import { useTheme } from '@emotion/react';
import RuleDialog from '../pages/booking/RuleDialog';

function BookingDateView({ date, setDate, shortText, setShortText,courts,settings, ...props }) {

    const [openRules, setOpenRules] = useState(false);
    const [formattedDates, setFormatedDates] = useState();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleDate = (event, newView) => {
        if (newView == null) {
            return null;
        }
        else {
            setDate(newView);
        }
    };

    const calcMonthDates = () => {
        if (isValid(new Date(date))) {
            const monthStart = startOfMonth(date);
            const monthEnd = endOfMonth(date);
            const allDates = eachDayOfInterval({ start: monthStart, end: monthEnd });
            var formattedDates = allDates.map(date => ({
                dayChars: format(date, 'EEEE', { locale: de }).slice(0, 2), // First two chars of day
                dayDigits: format(date, 'dd', { locale: de }), // Day number
                dayDate: date,
            }));
            return (formattedDates);
        }
    }

    const calcWeekDates = () => {
        if (isValid(new Date(date))) {
            const start = startOfWeek(date, { weekStartsOn: 1 });
            const end = endOfWeek(date, { weekStartsOn: 1 });
            const allDates = eachDayOfInterval({ start: start, end: end });
            var formattedDates = allDates.map(date => ({
                dayChars: format(date, 'EEEE', { locale: de }).slice(0, 2), // First two chars of day
                dayDigits: format(date, 'dd', { locale: de }), // Day number
                dayDate: date,
            }));
            return (formattedDates);
        }
    }

    useEffect(() => {
        if (isSmallScreen) {
            setFormatedDates(calcWeekDates)
        }
        else {
            setFormatedDates(calcMonthDates)
        }
    }, [date]);
    return (
        <>
            <Grid item xs={12}>
                <Grid container item xs={12} justifyContent="space-between" alignItems={"center"}>
                    <Grid item xs>
                        <Stack direction={"row"} spacing={1}>
                            <Tooltip title={"Platzbuchungsregeln"}>
                                <IconButton onClick={() => { setOpenRules(true); }}><QuestionMark color="primary" /></IconButton>
                            </Tooltip>

                            <Tooltip title={shortText ? "Buchungsnamen erweitern" : "Buchungsnamen abkürzen"}>
                                <IconButton onClick={() => { setShortText(!shortText); }}>{shortText ? <TextIncreaseSharp color="primary" /> : <TextDecreaseSharp color="primary" />}</IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" color="text.secondary">{format(date, "EEEE, dd.MM ", { locale: de })}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Box sx={{ width: "inherit", height: "40px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <DatePickFilter hideInput={true} dayFilter={date} setDayFilter={setDate} iconColor={"primary"} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item sx={{ mt: 1 }} >
                    <Divider variant="fullWidth"></Divider>
                </Grid>
                <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"} wrap='nowrap' >

                    <Grid item>
                        <PrimaryIconButton icon={<ChevronLeft />} onClickAction={() => {
                            setDate(addDays(date, -1));
                        }} />
                    </Grid>

                    <Grid item sx={{ overflowX: "auto" }}>

                        <ToggleButtonGroup variant="contained" color="primary" value={date} onChange={handleDate} exclusive size="medium">
                            {formattedDates && formattedDates?.map(({ dayChars, dayDigits, dayDate }, i) => (
                                <ToggleButton value={dayDate} key={i} selected={isSameDay(dayDate, date)} sx={{ textTransform: "none", border: 0, borderRadius: 0, "&.Mui-selected": { borderTop: 2, backgroundColor: "primary.trans" } }}><Stack><Typography variant="body2">{dayChars}</Typography><Typography variant="body2">{dayDigits}</Typography></Stack></ToggleButton>
                            ))
                            }
                        </ToggleButtonGroup>
                    </Grid>
                    {/* </CardContent> */}
                    <Grid item>
                        <PrimaryIconButton icon={<ChevronRight />} onClickAction={() => {
                            setDate(addDays(date, 1));
                        }} />

                    </Grid>
                </Grid>
            </Grid>
            <RuleDialog open={openRules} onClose={() => setOpenRules(false)} courts={courts} settings={settings}></RuleDialog>
        </>
    )
}

export default BookingDateView