import React, { useState } from "react";
import LoginView from "./LoginView";
import getToken from '../../api/Auth/authentication'
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import setSessionData from "../../utils/setSession";
import initializeUser from "../../utils/initializeUser";
import { useSnackbar } from "../../components/SnackbarContext";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LoginContainer(props) {

  const [forgetPw, setForgetPw] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const {showSnackbar} = useSnackbar();

  let query = useQuery();

  //store dispatcher
  const dispatch = useDispatch();

  //loginhandler
  const navigate = useNavigate();
  const login = () => {

    const emailInput = document.getElementById('email').value;
    
    const passwordInput = document.getElementById('password').value;
    const data = { email: emailInput, password: passwordInput,screenWidth: screenWidth };


      //fetch token with given credentials
      const loginPromise = getToken(data);
      loginPromise.then(function (response) {
        
        //set session credentials in local storage
        setSessionData(response.data.access_token, response.data.user_id);
       
        // set user data 
        initializeUser(dispatch);
        
        //perform redirect if query param next exists
        //perform redirect to last path when token expired
        //else perform default redirect to /dashboard
        if(query.get("next"))
        {
          navigate("/"+query.get("next"));
        }
        else if (props.currentPath && props.currenPath != "/login")
        {
          navigate(props.currentPath.pathname);
          // console.log("route: "+props.currentPath.pathname);
          // console.log("route: "+props.currentPath);
        }
        else
        {
          navigate("/dashboard");
        }
      
      })
        .catch(function (error) {
          localStorage.clear();
          showSnackbar("Login fehlgeschlagen","error");
          
        });
  
  };


  return (
    <>
      <LoginView login={() => login()} forgetPw={forgetPw} setForgetPw={setForgetPw}/>
    </>
  );
};

export default LoginContainer;