import { AcUnit, Launch, LightMode,  Lock, LockClockSharp, Web } from '@mui/icons-material'
import { Alert, Card, CardContent, Divider, Grid, List, Stack, Typography } from '@mui/material'
import React from 'react'
import SimpleListItem from '../../../components/SimpleListItem'
import { ADM_COURTS } from '../../../navigation/CONSTANTS'
import CourtStatusChip from '../../../components/CourtStatusChip'
import { useNavigate } from 'react-router-dom'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import CourtStatsStack from '../../../components/CourtStatsStack'

function AdmCourtStatus(props) {
    const { courts, counts } = props;
    const navigate = useNavigate();
    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                {/* <CardHeader title={"Platz Status"} action={<PrimaryButton text="Status ändern" />} /> */}
                <CardContent>
                    <Grid container>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item xs align="left">
                                <Typography variant="h6" >{"Plätze"}</Typography>
                            </Grid>
                            <Grid item align="right">
                                <PrimaryIconButton icon={<Launch color="primary"></Launch>} onClickAction={() => navigate(ADM_COURTS)} tooltipTitle="Öffne Platzverwaltun"></PrimaryIconButton>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: -1 }}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <Grid item>
                                    <Stack direction="row" spacing={6} >
                                            <CourtStatsStack headlineIcon={<Web color="grey"/>} headlineText={"Plätze"} indoorToottip={"Hallenplätz"} outdoorTooltip={"Außenplätz"} indoorValue={counts && counts.indoor_courts} outdoorValue={counts && counts.outdoor_courts}></CourtStatsStack>
                                            <CourtStatsStack headlineIcon={<Lock color="grey"/>} headlineText={"Gesperrt"} indoorToottip={"Hallenplatz | aktive Sperrungen"} outdoorTooltip={"Außenplatz | aktive Sperrungen"} indoorValue={counts && counts.indoor_locked} outdoorValue={counts && counts.outdoor_locked}></CourtStatsStack>
                                            <CourtStatsStack headlineIcon={<LockClockSharp color="grey"/>} headlineText={"Geplant"} indoorToottip={"Hallenplatz | geplante Sperrungen"} outdoorTooltip={"Außenplatz | geplante Sperrungen"} indoorValue={counts && counts.indoor_upcominglocked} outdoorValue={counts && counts.outdoor_upcominglocked}></CourtStatsStack>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider><Typography color="text.secondary">akt./gepl. Sperrungen</Typography></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    {courts && courts.length > 0 ?
                                        courts.map((court, i) => (
                                            court.locks?.length > 0 &&
                                                <SimpleListItem key={i} divider valueIcon={<CourtStatusChip court={courts[i]} />} icon={court.type =="indoor" ? <AcUnit color="primary" /> : <LightMode color="warning"/> } object={court.name}></SimpleListItem>
                                
                                        ))
                                        :
                                        <Alert severity="info"><Typography>Keine aktiven oder geplanten Sperrungen</Typography></Alert>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default AdmCourtStatus