import { Add, Event, FilterAltOff, GridView, Polyline } from '@mui/icons-material';
import { Alert, Box, Container, Fab, Grid, Stack, Table, TableHead, TablePagination, TableRow, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { getEvents_api, getTournamentGroupPlayers_api, sendEventMail_api } from '../../../api/Events/events';
import AddEditEventsDialog from '../../../components/AddEditEventsDialog';
import EventsCard from '../../../components/EventCard';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header/Header'
import MailDialog from '../../../components/MailDialog';
import TitleCard from '../../../components/TitleCard';
import MyTab from '../../../components/MyTab';
import PrimaryButton from '../../../components/PrimaryButton';
import Timeline from '@mui/lab/Timeline';
import EventTimelineItem from '../../../components/EventTimelineItem';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SecondaryButton from '../../../components/SecondaryButton';
import { ADM_EVENTS } from '../../../navigation/CONSTANTS';
import { useSnackbar } from '../../../components/SnackbarContext';
import EventCard from '../../../components/EventCard';
import MainContentContainer from '../../../components/MainContentContainer';

function AdmEventsContainer() {
  const [events, setEvents] = useState(null);
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openMailDialog, setOpenMailDialog] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [value, setValue] = useState("all");
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const [view, setView] = useState(searchParam.get('view') || "card");
  const { showSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleView = (event, newView) => {
    setView(newView);
  }

  const openEditDialog = (event) => {
    setSelectedEvent(event);
    setIsEdit(true);
    setOpenAddEditDialog(true);
  }

  const openEventMailDialog = (event) => {
    setSelectedEvent(event);
    setOpenMailDialog(true);
  }

  //pagintaion
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getEvents = (ignoreId = false) => {
    var query = "";
    if (value === "tournament") {
      query = "?type=turnier";
    }
    else if (value === "event") {
      query = "?type=veranstaltung";
    }
    else if (id && ignoreId !== true) {
      query = "?id=" + id;
    }
    else {
      query = "?type__neq=Platzbuchung";
    }

    var promise = getEvents_api(query);
    promise.then((result) => { setEvents(result.data); })
      .catch();
  }

  const handleMailSubmit = (message, subject) => {
    setIsSending(true);
    var promise = sendEventMail_api(message, subject, selectedEvent.id);
    promise.then((result) => { setIsSending(false); showSnackbar(result.data, "success"); setOpenMailDialog(false); })
      .catch((error) => { setIsSending(false); });
  }

  useEffect(() => {
    getEvents();
  }, [value, id]);


  useEffect(() => {
    setView(searchParam.get('view') || view);
  }, [searchParam]);

  return (
    <>
      <Header />
      <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
        <Grid item xs={12}>
          <TitleCard id="crd-title-events" title={"Events"} subheader={"Verwalte einmalige Ereignisse - Events"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
        </Grid>
      </Grid>
      <MainContentContainer>
        <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            {id !== undefined ?
              <SecondaryButton startIcon={<FilterAltOff />} text="Alle Events anzeigen" onClick={() => { navigate(ADM_EVENTS + "?view=timeline"); }} />
              :
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center" }}>
                <Tabs onChange={handleChange} value={value}>
                  <MyTab label="Alle" value="all" />
                  <MyTab label="Turniere" value="tournament" />
                  <MyTab label="Veranstaltungen" value="event" />
                </Tabs>
              </Box>
            }
          </Grid>
          <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent={"space-between"}>
                <ToggleButtonGroup color="primary" value={view} exclusive onChange={handleView} size="small">
                  <ToggleButton value={"card"}> <Stack direction={"row"} spacing={1}><GridView /> <Typography sx={{ textTransform: "none" }}>Kachelansicht</Typography></Stack></ToggleButton>
                  <ToggleButton value={"timeline"}> <Stack direction={"row"} spacing={1}>< Polyline /> <Typography sx={{ textTransform: "none" }}>Zeitstrahl</Typography></Stack></ToggleButton>
                </ToggleButtonGroup>


                <Box component={Grid} item sx={{ display: { xs: "flex", md: "none" } }}>
                  <Fab color="primary" size="small" onClick={() => { setOpenAddEditDialog(true); }} sx={{ display: { xs: "flex", md: "none" } }}><Add></Add></Fab>
                </Box>
                <Box component={Grid} item sx={{ display: { xs: "none", md: "flex" } }}>
                  <PrimaryButton id="btn-events-create" color="primary" text="Erstellen" sx={{ display: { xs: "none", md: "flex" }, textTransform: "none" }} startIcon={<Add></Add>} onClick={() => { setOpenAddEditDialog(true); }} />
                </Box>
              </Stack>
            </Grid>
            {view == "card" && !id && events && events.length > 0 ?
              <Grid item>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[20, 50, 100]}
                        count={events.length || 0}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage={"Anzahl"}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ border: 0 }}
                      />
                    </TableRow>
                  </TableHead>
                </Table>
              </Grid>
              :
              <></>
            }
          </Grid>
          {view == "card" ?
            <Grid container item xs={12} spacing={4}>
              {events && events.length > 0 ?
                events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((event, i) => (
                  <Grid item key={i} xs={12} md={4}>
                    <EventsCard event={event} getEvents={getEvents} adminMode={true} openEditDialog={openEditDialog} setOpenMailDialog={openEventMailDialog}></EventsCard>
                  </Grid>
                ))
                :
                <Grid item xs={12}>
                  <Alert severity="info" variant="filled"><Typography>Aktuell gibt es keine Events...</Typography></Alert>
                </Grid>
              }
            </Grid>
            :
            <></>
          }
          {view == "timeline" ?
            <Grid item xs={12}>
              {events && events.length > 0 ?
                <Timeline>
                  {events && events.map((event, i) => (
                    i == events.length - 1 ?
                      <EventCard key={i} isTimeline hideConnector={true} event={event} getEvents={getEvents} openEditDialog={openEditDialog} openEventMailDialog={openEventMailDialog} setOpenMailDialog={setOpenMailDialog} adminMode={true} ></EventCard>
                      :
                      <EventCard key={i} isTimeline event={event} getEvents={getEvents} openEditDialog={openEditDialog} openEventMailDialog={openEventMailDialog} setOpenMailDialog={setOpenMailDialog} adminMode={true}></EventCard>
                  ))
                  }
                </Timeline>
                :
                <Alert severity="info" variant="filled"><Typography>Aktuell gibt es keine Events...</Typography></Alert>
              }
            </Grid>
            :
            <></>
          }
        </Grid>
      </MainContentContainer>
      <Footer />
      <AddEditEventsDialog getEvents={getEvents} open={openAddEditDialog} isEdit={isEdit} event={selectedEvent} onClose={() => { setOpenAddEditDialog(false); setIsEdit(false); }} ></AddEditEventsDialog>
      <MailDialog open={openMailDialog} onClose={() => { setOpenMailDialog(false) }} isSending={isSending} title={"Nachricht an alle Event Teilnehmer"} submitName="Abschicken" handleSubmit={handleMailSubmit} isHidden={true}></MailDialog>
    </>
  )
}

export default AdmEventsContainer