import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography, Grid, Divider, Alert } from '@mui/material'
import React from 'react'
import { ACTIVEUSERS } from '../../../navigation/CONSTANTS';
import { AdminPanelSettings, Groups, Launch, PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PrimaryIconButton from '../../../components/PrimaryIconButton';

function AdmActiveUserCard(props) {
    const { users,counts } = props

    const navigate=useNavigate();

    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>

                <CardContent>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs align="left">
                            <Typography variant="h6" >{"Mitglieder"}</Typography>
                        </Grid>
                        <Grid item align="right">
                            <PrimaryIconButton icon={<Launch color="primary"></Launch>} onClickAction={()=>navigate(ACTIVEUSERS)} tooltipTitle="Öffne Mitglieder"></PrimaryIconButton>
                        </Grid>
                        <Grid item xs={12} sx={{mt: -1}}>
                            <Divider></Divider>
                        </Grid>
                        <Grid item container xs={12} justifyContent="center">
                            <Grid item>
                                <Stack direction="row" spacing={6} >
                                    <Stack alignItems={"center"}>
                                        <Groups color="primary"/>
                                        <Typography color="text.secondary"sx={{ fontWeight: "bold" }}>Users</Typography>
                                        <Typography color="text.secondary">{counts && counts.usersCount}</Typography>
                                    </Stack>
                                    <Stack alignItems={"center"}>
                                        <AdminPanelSettings color="warning"/>
                                        <Typography color="text.secondary"sx={{ fontWeight: "bold" }}>Admins</Typography>
                                        <Typography color="text.secondary">{counts && counts.adminsCount}</Typography>
                                    </Stack>
                                    <Stack alignItems={"center"}>
                                        <PersonAdd color="success"/>
                                        <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Registr.</Typography>
                                        <Typography color="text.secondary">{counts && counts.registrationsCount}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Divider><Typography color="text.secondary">{"Heute online (" + (users && users.length) + ")"}</Typography></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <List dense>
                                {users && users.length > 0
                                    ? users.slice(0, 5).map((user, i) => (
                                        <ListItem key={i}>
                                            <ListItemAvatar><Avatar sx={{ backgroundColor: "primary.main" }}>{user.firstname && user.firstname.charAt(0)}{user.surname && user.surname.charAt(0)}</Avatar></ListItemAvatar>
                                            <ListItemText disableTypography
                                                primary={<Typography variant='body2'>{user.firstname + " " + user.surname}</Typography>}
                                                secondary={user.isOnline === 1
                                                    ? <Stack direction="row" alignItems="center" spacing={1}><Skeleton sx={{ backgroundColor: "success.main" }} animation="pulse" variant="circular" width="10px" height="10px"></Skeleton><Typography variant="body2" sx={{ color: "success.main" }}>Online</Typography></Stack>
                                                    : <Stack direction="row" alignItems="center" spacing={1}><Skeleton sx={{ backgroundColor: "error.main" }} animation="pulse" variant="circular" width="10px" height="10px"></Skeleton><Typography variant="body2" sx={{ color: "error.main" }}>Offline</Typography></Stack>} />
                                        </ListItem>
                                    ))
                                    : <><Alert severity="info"><Typography>Heute waren noch keine User online</Typography></Alert></>}
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </>
    )
}

export default AdmActiveUserCard