import { Alert, Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import PrimaryIconButton from './PrimaryIconButton'
import { Delete, ExpandLessSharp, ExpandMoreSharp, Person } from '@mui/icons-material'
import SecondaryButton from './SecondaryButton'


function TransferList({ targetList,setTargetList,sourceList,setSourceList,isPositioning,secondaryKey,secondaryName,targetListName,sourceListName="Teilnehmer",transferActionName,...props }) {

    const [temporaryTargets, setTemporaryTargets] = useState(targetList);
    const [temporarySource, setTemporarySource] = useState(sourceList);
    const [stateRefresh, setStateRefresh] = useState(false);

    const addTemporarySeed = (participant) => {
        //add participanzt to seeds
        if(!targetList)
        {
            setTargetList([]);
        }
      
        setTargetList(targetList => {
            return [...targetList, participant];
        })
      

        //remove participants from list
        const index = sourceList.indexOf(participant);
        if (index > -1) { // only splice array when item is found
            // setTemporarySource([...temporarySource.slice(0, index),...temporarySource.splice(index+1)])
            var tmp = sourceList;
            // 2nd parameter means remove one item only
            tmp.splice(index, 1);
            setSourceList(tmp)
        }
        setStateRefresh(!stateRefresh);
    }

    const removeTemporarySeed = (participant) => {
        //add participant to seeds
        setSourceList(sourceList => {
            return [...sourceList, participant];
        })
        //remove participants from list
        const index = targetList.indexOf(participant);
        // only splice array when item is found
        if (index > -1) {
            var tmp = targetList;
            // 2nd parameter means remove one item only
            tmp.splice(index, 1);
            setTargetList(tmp)
        }
        setStateRefresh(!stateRefresh);
    }

    const increaseSeedPosition = (seed) => {
        //move item in array one up
        var tmp = temporaryTargets;
        var index = tmp.indexOf(seed)
        if (index > 0) {
            var removedItem = tmp.splice(index, 1)[0];
            tmp.splice(index - 1, 0, removedItem);
            setTargetList(tmp);
        }
        //force state to refresh because it doesnt detect any changes in array
        setStateRefresh(!stateRefresh);
    }

    const decreaseSeedPosition = (seed) => {
        //move item in array one down
        var tmp = temporaryTargets;
        var index = tmp.indexOf(seed)
        if (index < temporaryTargets.length - 1) {
            var removedItem = tmp.splice(index, 1)[0];
            tmp.splice(index + 1, 0, removedItem);
            setTargetList(tmp);
        }
        //force state to refresh because it doesnt detect any changes in array
        setStateRefresh(!stateRefresh);
    }

    return (
        <Grid container direction={{ xs: "row", md: "row-reverse" }} spacing={2}>
            <Grid item xs={12} sm={6}>
                <List dense >
                    <ListSubheader>{targetListName +" (" + (targetList ? targetList.length : 0) + ")"}</ListSubheader>
                    {targetList && targetList.length > 0 ?
                        targetList.map((seed, i) => (
                            <ListItem key={i}>
                                <ListItemAvatar><Avatar sx={{ bgcolor: "secondary.main" }}>{i + 1}</Avatar></ListItemAvatar>
                                <ListItemText primary={seed.firstname + " " + seed.surname} secondary={secondaryName+ ": " + seed[secondaryKey]} secondaryTypographyProps={{ color: "secondary" }} primaryTypographyProps={{ fontWeight: 500 }}></ListItemText>
                                <ListItemSecondaryAction>
                                    <Stack direction="row">
                                        {isPositioning ?
                                            <>
                                                <PrimaryIconButton icon={<ExpandLessSharp color="primary" />} onClickAction={() => increaseSeedPosition(seed)} ></PrimaryIconButton>
                                                <PrimaryIconButton icon={<ExpandMoreSharp color="primary" />} onClickAction={() => decreaseSeedPosition(seed)}  ></PrimaryIconButton>
                                                <Divider flexItem orientation="vertical"></Divider>
                                            </>
                                            :
                                            <></>
                                        }

                                        <PrimaryIconButton icon={<Delete color="primary"/>} onClickAction={() => removeTemporarySeed(seed)}></PrimaryIconButton>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                        :
                        <Alert severity={"info"}><Typography>Keine Spieler ausgewählt</Typography></Alert>
                    }
                </List>
            </Grid>
            <Grid item xs={12} sm={6}>
                <List dense >
                    <ListSubheader>{sourceListName+" (" + (sourceList && sourceList.length) + ")"}</ListSubheader>
                    {sourceList && sourceList.map((participant, i) => (
                        <ListItem key={i}>
                            <ListItemIcon><Person color="primary"></Person></ListItemIcon>
                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={secondaryName+ ": " + participant[secondaryKey]}></ListItemText>
                            <SecondaryButton text={transferActionName} onClick={() => { addTemporarySeed(participant) }} size={"small"} />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}

export default TransferList