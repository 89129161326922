import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton} from '@mui/material'
import React from 'react'

function DynamicExpandIcon({expanded,onClick,...props}) {
   
  return (
    <IconButton onClick={()=>{onClick && onClick()}} {...props}>{expanded ? <ExpandLess></ExpandLess>: <ExpandMore></ExpandMore>}</IconButton>
  )
}

export default DynamicExpandIcon