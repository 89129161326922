import { useTheme } from '@emotion/react';
import { Divider, Drawer, Stack, SwipeableDrawer, Typography, useMediaQuery } from '@mui/material'
import React from 'react'


function ResponsiveDrawer({ children, drawerTitle, drawerSubtitle, openDrawer, setOpenDrawer,closDrawerOnClick=false, ...props }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <SwipeableDrawer anchor={isSmallScreen ? "bottom" : "left"} PaperProps={{ sx: { minWidth: isSmallScreen ? "auto" : "700px",width: isSmallScreen ? "100%" : "700px",minHeight: "35vh", maxHeight: isSmallScreen ? '80vh' : "calc(100% - 48px)", top: !isSmallScreen && "48px", overflowY: "auto", pb: 3} }} open={openDrawer} onOpen={()=>{}} onClose={() => setOpenDrawer(false)}  onClick={(e) => {if(closDrawerOnClick){setOpenDrawer(false);e.stopPropagation();}}}>
            {drawerSubtitle || drawerTitle ?
                <>
                    <Stack sx={{ py: 1,position: "sticky", top: "0px", left: "0px", zIndex: 10000, backgroundColor: "#fff" }}>
                        <Typography variant="h5" align="center">{drawerTitle}</Typography>
                        <Typography variant="h6" align='center' color="secondary" sx={{ margin: 0 }}>{drawerSubtitle}</Typography>
                    </Stack>
                    <Divider></Divider>
                </>
                :
                <></>
            }

            {children}
        </SwipeableDrawer>

    )
}

export default ResponsiveDrawer