import { Box, CardMedia, Stack, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { de } from 'date-fns/locale'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

function HeadlinePost({ id, post,imageHeight={xs: "300px",md: "500px"},headlineSize={xs: "h5",md: "h4"},minHeight={xs:"50px" ,md:"70px"}, ...props }) {

    const ref = useRef();
    const navigate= useNavigate();
    
    const openPost = () => {
        navigate("/blog/"+post.id);
    }

    useEffect(() => {       
    }, [ref]);

    return (
        <Box sx={{ position: "relative" }} onClick={()=>openPost()}>
            <CardMedia id={id} component="img" src={post.img_url} sx={{ width: "100%", height: imageHeight, objectFit: "cover"}} ref={ref}></CardMedia>
            <Box sx={{ position: "absolute", color: "#fff", bottom: "0%", left: "0%", backgroundColor: "rgba(0, 0, 0, .6)", width: /*ref.current && ref.current.clientWidth-16 */ "calc(100% - 16px)", minHeight: minHeight, p: 1}}>
                <Stack spacing={1}>
                <Typography sx={{typography: headlineSize}}>{post.title}</Typography>
                <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant="body2" sx={{typography: {xs: "body2",sm: "body1"}}}>{post && formatDistanceToNow(new Date(post.reg_date), { locale: de, addSuffix: "vor" })}</Typography>
                <Typography variant="body2" sx={{typography: {xs: "body2",sm: "body1"}}}>#{post.category}</Typography>
                </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default HeadlinePost