import { EventBusy, EventRepeat, Launch, Mail, Pause, PlayArrow, Settings, Stop, Subscriptions, SubscriptionsSharp, UpdateSharp } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Card, CardContent, Chip, Divider, Grid, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { format, isFuture } from 'date-fns'
import React, { useState } from 'react'
import SubscriptionDialog from '../../../components/SubscriptionDialog'
import PrimaryIconButton from '../../../components/PrimaryIconButton'
import { useNavigate } from 'react-router-dom'
import { ACTIVEUSERS } from '../../../navigation/CONSTANTS'



function AdmSubscriptionCard({ subscription,/*status, expiration,plan,subscriptionName,*/ ...props }) {

    const [openSubDialog, setOpenSubDialog] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Card raised sx={{ borderRadius: 2 }}>
                {/* <CardHeader title="Wer is online?" action={<Link variant="body2" color="primary" onClick={() => { clickHandler(USERS) }}>More</Link>} /> */}
                <CardContent>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs align="left">
                            <Typography variant="h6" sx={{ height: "40px" }}>{"Abonnement"}</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: -1 }}>
                            <Divider></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <SubscriptionsSharp color="primary" />
                                    <Typography color="text.secondary" fontWeight={500}>{"Lizenz: "}</Typography>
                                </Stack>
                                <Typography>{subscription && subscription.subscription || "Keine Lizenz"}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <EventBusy color="primary" />
                                    <Typography color="text.secondary" fontWeight={500}>{"Auslaufdatum: "}</Typography>
                                </Stack>
                                <Typography>{subscription && subscription.subscription_expiry_date ? format(new Date(subscription.subscription_expiry_date), "dd.MM.yy") : ""}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                <Stack direction={"row"} spacing={1} alignItems="center">
                                    <UpdateSharp color="primary" />
                                    <Typography color="text.secondary" fontWeight={500}>{"Abonnement: "}</Typography>
                                </Stack>
                                <Chip label={subscription && subscription.subscription_plan === "active" ? "fortlaufend" : subscription && subscription.subscription_plan === "paused" ? "pausiert" : "gestoppt"} icon={subscription && subscription.subscription_plan === "active" ? <PlayArrow /> : subscription && subscription.subscription_plan === "paused" ? <Pause /> : <Stop />} color={subscription && subscription.subscription_plan === "active" ? "success" : subscription && subscription.subscription_plan === "paused" ? "warning" : "error"} size="small" sx={{ color: "#fff" }}></Chip>
                            </Stack>
                        </Grid>
                        {subscription && subscription.subscription_reactivation_date &&

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                    <Stack direction={"row"} spacing={1} alignItems="center">
                                        <EventRepeat color="primary" />
                                        <Typography color="text.secondary" fontWeight={500}>{"Reaktivierungsdatum: "}</Typography>
                                    </Stack>
                                    <Typography >{subscription && subscription.subscription_reactivation_date ? format(new Date(subscription.subscription_reactivation_date), "dd.MM.yy") : ""}</Typography>
                                </Stack>
                            </Grid>
                        }
                        {/* <Grid item xs={12}>
                            <Alert severity="info" sx={{ pr: 3}}>
                                <AlertTitle>Aktuelles Abonnement</AlertTitle>

                                <ListItem sx={{ p: 0 }}>
                                    <ListItemIcon><Subscriptions color="primary" /></ListItemIcon>
                                    <ListItemText primary={subscription && subscription.subscription} secondary={"Lizenz"} />
                                </ListItem>
                                <ListItem sx={{ p: 0 }}>
                                    <ListItemIcon><UpdateSharp color="primary" /></ListItemIcon>
                                    <ListItemText primary={subscription && subscription.subscription_plan == "active" ? "Fortlaufend" : subscription.subscription_plan == "paused" ? "Pausiert" : "Gekündigt"} secondary={"Abonnement-Status"} />
                                </ListItem>
                                <ListItem sx={{ p: 0 }}>
                                    <ListItemIcon>{subscription && subscription.subscription_plan == "active" ? <EventRepeat color="primary" /> : <EventBusy color="primary" />}</ListItemIcon>
                                    <ListItemText primary={subscription && format(new Date(subscription.subscription_expiry_date), "dd.MM.yy")} secondary={"Auslaufdatum"} />
                                </ListItem>
                                {subscription.subscription_reactivation_date &&
                                    <ListItem sx={{ p: 0 }}>
                                        <ListItemIcon>{subscription && subscription.subscription_plan == "active" ? <EventRepeat color="primary" /> : <EventBusy color="primary" />}</ListItemIcon>
                                        <ListItemText primary={subscription && format(new Date(subscription.subscription_reactivation_date), "dd.MM.yy")} secondary={"Reaktivierungsdatum"} />
                                    </ListItem>
                                }

                            </Alert>
                        </Grid> */}
                        <Grid item xs={12}>
                            {subscription && isFuture(new Date(subscription.subscription_expiry_date)) ?
                                <Alert severity="success">
                                    <AlertTitle>Systemstatus</AlertTitle>
                                    <Typography variant='body2'>{"Das System kann aktuell von allen Mitgliedern und Admins genutzt werden."}</Typography></Alert>
                                :
                                <Alert severity="warning">
                                    <AlertTitle>Systemstatus</AlertTitle>
                                    <Typography variant='body2'>{"Das System kann aktuell nicht genutzt werden."}</Typography></Alert>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {subscription && subscription.subscription_plan === "active" ?
                                <Alert severity="info">
                                    <AlertTitle>Abonnement</AlertTitle>
                                    <Typography variant='body2'>{"Das Abonnement wird automatisch zum Auslaufdatum um 1 Monat verlängert."}</Typography></Alert>
                                : subscription && subscription.subscription_plan === "paused" ?
                                    <Alert severity="warning">
                                        <AlertTitle>Abonnement</AlertTitle>
                                        <Typography variant='body2'>{"Das Abonnement ist ab dem Auslaufdatum pausiert und wird erst zum Reaktivierungsdatum automatisch um 1 Monat verlängert."}</Typography></Alert>
                                    :
                                    <Alert severity="error">
                                        <AlertTitle>Abonnement</AlertTitle>
                                        <Typography variant='body2'>{"Das Abonnement ist ab dem Auslaufdatum gekündigt. Es gibt keine Reaktivierung!"}</Typography></Alert>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent={"space-between"} spacing={1}>
                                <Button startIcon={<Mail></Mail>} size="small" variant="text" fullWidth onClick={() => { window.open("mailto:contact@myclubportal.de") }}>Kontakt</Button>
                                <Button startIcon={<Settings></Settings>} size="small" variant="contained" onClick={() => { setOpenSubDialog(true) }} fullWidth>Abo ändern</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <SubscriptionDialog subscription={subscription} open={openSubDialog} onClose={() => { setOpenSubDialog(false); props.getSubscription(); }}></SubscriptionDialog>
        </>
    )
}

export default AdmSubscriptionCard