import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react'

function CustomAutocomplete(props) {
    const { label, options, setTarget, size, initialInputValue, multipleSelect,sx,id} = props;

    const [value, setValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const autocompletehandler = (newValue) => {
        if (newValue) {
            if (multipleSelect) {
                setTarget({id: newValue['id'],firstname: newValue['firstname'], lastname: newValue['lastname'], name: newValue['label']});
                //setTarget({ id: newValue['id'], name: newValue['label'] });
            }
            else {
                setTarget(newValue['id']);
            }
            setValue(newValue);
        }
        else {
            setTarget(null);
            setValue(newValue);
        }
    }

    useEffect(() => {
        if (initialInputValue) {
            setValue(initialInputValue);
        }
        return () => {
        };
    }, [initialInputValue]);

    return (
        <Autocomplete id={id} fullWidth size={size} options={options} value={value} groupBy={(option)=>option.type} inputValue={inputValue} onInputChange={(event, newInputValue) => { setInputValue(newInputValue); }} onChange={(event, newValue) => { autocompletehandler(newValue); }} renderInput={(params) => <TextField  {...params} label={label} sx={sx}/>}></Autocomplete>
    )
}

export default CustomAutocomplete