import { EmojiEvents, GroupRemove, Groups, Launch } from '@mui/icons-material'
import { Card, CardContent, Grid, Stack, Typography, Link, Divider, Alert, Tabs } from '@mui/material'
import React, { useState } from 'react'
import BorderedIcon from '../../components/BorderedIcon'
import TeamCardLight from '../../components/TeamCardLight'
import MyTab from '../../components/MyTab'
import { useNavigate } from 'react-router-dom'
import { MYTEAM } from '../../navigation/CONSTANTS'


function DashboardTeamsCard({ teams, counts, ...props }) {

    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    return (
        <Card raised sx={{ borderRadius: 2, border: 0, width: 'inherit' }}>
            <CardContent sx={{ pb: 0 }}>
                <Grid container item direction="row" alignItems="center" spacing={2}>

                    <Grid container item direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs align="left">
                            <Stack direction="row" spacing={3}>
                                <BorderedIcon icon={<Groups color="primary"></Groups>} />
                                <Typography variant="h6" >Mannschaft(en)</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={"auto"} align="right">
                            {/* <InternalLink linkname="Mehr" route={BLOG} /> */}
                            <Launch color="primary" onClick={() => navigate(MYTEAM)}></Launch>
                        </Grid>

                        {teams &&
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </CardContent>

            {teams && teams.length > 0 ?
                <>
                    {teams.length != 1 ?
                        <CardContent sx={{ pb: 1 }}>
                            <Grid container item direction="row" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <Tabs value={index} onChange={(event, newValue) => setIndex(newValue)} variant="scrollable">
                                        {teams.map((team, i) => (
                                            <MyTab value={i} key={i} label={team.team}></MyTab>
                                        ))
                                        }
                                    </Tabs>
                                </Grid>
                            </Grid>
                        </CardContent >
                        :
                        <></>
                    }
                    <CardContent >
                        <Grid container item direction="row" alignItems="center" spacing={2}>
                            {/* {teams && teams.length > 0 ?
                                <Grid item align="right">
                                    <Link rel="noreferrer" target="_blank" href={teams[index].grouplink}><Typography variant="body2">NuLiga Profil</Typography></Link>
                                </Grid>
                                : <></>
                            } */}
                            <Grid item xs={12}>
                                <TeamCardLight team={teams && teams[index]}></TeamCardLight>
                            </Grid>
                            <Grid item xs={12} >

                                <Stack direction="row" spacing={2} justifyContent="space-around">
                                    <Stack alignItems={"center"}>
                                        <Groups color="secondary" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>Mannschaften</Typography>
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>{counts.teamsCount}</Typography>
                                    </Stack>
                                    <Stack alignItems={"center"}>
                                        <EmojiEvents color="primary" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>Punktspiele</Typography>
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>{counts.teamMatchesCount}</Typography>
                                    </Stack>
                                    <Stack alignItems={"center"}>
                                        <GroupRemove color="warning" />
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>Unterbesetzt</Typography>
                                        <Typography color="text.secondary" sx={{ fontWeight: "medium" }}>{counts.teamMatchesUnderstaffedCount}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </>
                :
                <>
                    <CardContent>
                        <Alert severity='info'><Typography>Du bist in keiner Mannschaft</Typography></Alert>
                    </CardContent>
                </>
            }
        </Card >

    )
}

export default DashboardTeamsCard