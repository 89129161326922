import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { Alert, AlertTitle, Box, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Container, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Slide, Stack, Switch, Tabs, TextField, Typography } from '@mui/material'
import TitleCard from '../../../components/TitleCard'
import Footer from '../../../components/Footer'
import { AccessTime, EuroSymbol, Help,Settings } from '@mui/icons-material'
import { getSettings_api, getSettingsIndoor_api, getSettingsOutdoor_api, updateSettingsIndoor_api, updateSettingsOutdoor_api } from '../../../api/Settings/settings'
import PrimaryButton from '../../../components/PrimaryButton'
import TertiaryButton from '../../../components/TertiaryButton'
import { useSnackbar } from '../../../components/SnackbarContext'
import MyTab from '../../../components/MyTab'
import MainContentContainer from '../../../components/MainContentContainer'

function SettingsContainer() {

    const [settings, setSettings] = useState({ booking_duration: 60 });
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [errorTimes, setErrorTimes] = useState(false);
    const [errorValidBookings, setErrorValidBookings] = useState(false)
    const [openInfo, setOpenInfo] = useState(false);
    const validDurations = [30, 60];
    const { showSnackbar } = useSnackbar();
    const [courtType, setCourtType] = useState("outdoor");


    const getSettings = () => {
        var promise = null;
        if (courtType == "outdoor") {
            promise = getSettingsOutdoor_api();
        }
        else if (courtType == "indoor") {
            promise = getSettingsIndoor_api();
        }
        else {
            promise = getSettings_api();
        }
        promise.then((result) => { setSettings(result.data[0]) })
            .catch((error) => { });
    }

    const handleChange = (e) => {

        const { name, value, checked } = e.target;
        // console.log(e.target);
        switch (name) {
            case "bookings_enabled":
                setSettings(prevState => ({ ...prevState, [name]: +checked }));
                break;
            case "button_price_enabled":
                setSettings(prevState => ({ ...prevState, [name]: +checked }));
                break;
            case "allow_guest_bookings":
                setSettings(prevState => ({ ...prevState, [name]: +checked }));
                break;
            case "allow_freetext_bookings":
                setSettings(prevState => ({ ...prevState, [name]: +checked }));
                break;
            case "booking_confirmation":
                setSettings(prevState => ({ ...prevState, [name]: +checked }));
                break;
            default:
                setSettings(prevState => ({ ...prevState, [name]: value }));
                break;
        }
    }

    const updateSettings = () => {
        if (settings.allowed_bookings_per_day > settings.allowed_bookings_total) {
            setSubmitDisabled(true);
            return;
        }
        var promise = null;
        if (courtType == "indoor") {
            promise = updateSettingsIndoor_api(settings);
        }
        else {
            promise = updateSettingsOutdoor_api(settings);
        }

        promise.then((result) => { getSettings(); showSnackbar("Einstellungen erfolgreich geändert", "success") })
            .catch((error) => { showSnackbar("Etwas ist schiefgelaufen", "error") });
    }

    useEffect(() => {
        getSettings();
    }, [courtType]);

    useEffect(() => {
        if (parseInt(settings.allowed_bookings_per_day) > parseInt(settings.allowed_bookings_total)) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false);
        }
    }, [settings]);

    useEffect(() => {
        //console.log(parseInt(settings.allowed_bookings_total))
        if (parseInt(settings.allowed_bookings_per_day) > parseInt(settings.allowed_bookings_total)) {
            setErrorValidBookings(true);
        }
        else {
            setErrorValidBookings(false);
        }
    }, [settings]);

    useEffect(() => {
        if (parseInt(settings.booking_starttime) < 0 || settings.booking_starttime > 24 || settings.booking_endtime < 0 || settings.booking_endtime > 24) {
            setErrorTimes(true);
        }
        else {
            setErrorTimes(false);
        }
    }, [settings]);
    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-settings" title={"Einstellungen"} subheader={"Verwalte die Einstellungen des Vereins"} icon={<Settings sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Card>
                    <CardHeader title="Buchungen"></CardHeader>
                    <CardContent sx={{ pt: 0 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 1 }}>
                                    <Tabs value={courtType} centered onChange={(event, newValue) => { setCourtType(newValue) }}>
                                        <MyTab id="tab-settings-outdoor" value="outdoor" label="Außenplätze" ></MyTab>
                                        <MyTab id="tab-settings-indoor" value="indoor" label="Hallenplätze" ></MyTab>
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack>
                                        <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>{courtType == "indoor" ? "Hallenplätze aktiviert" : "Außenplätze aktiviert"}</Typography>
                                        <Typography variant="body2" fontWeight={"Regular"}>{(courtType == "indoor" ? "Hallenplätze" : "Außenplätze") + " sind sichtbar im Buchungsbereich. Buchungen können gemäß der Einstellungen durchgeführt werden."}</Typography>
                                    </Stack>
                                    <Switch id="swi-settings-bookingsEnabled" name="bookings_enabled" checked={Boolean(settings["bookings_enabled"])} onChange={handleChange}></Switch>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity={settings.bookings_enabled ? "success" : "warning"}>
                                    <AlertTitle>{(courtType == "indoor" ? "Hallenplatzbuchungen" : "Außenplatzbuchungen") + (settings.bookings_enabled ? " sind aktiviert." : " sind deaktiviert.")}</AlertTitle>
                                    {/* <Typography></Typography> */}
                                </Alert>

                            </Grid>

                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">Buchungszeiten</Typography></Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField id="txf-settings-starttime" error={errorTimes} type="number" onWheel={(e) => e.target.blur()} helperText="Ab wann dürfen Buchungen getätigt werden (in Stunden 0-24)" name="booking_starttime" value={settings['booking_starttime']} label="Startzeit Buchungen" onChange={handleChange} fullWidth InputProps={{ endAdornment: <AccessTime color="primary" /> }} InputLabelProps={{ shrink: true }}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField id="txf-settings-endtime"error={errorTimes} type="number" onWheel={(e) => e.target.blur()} helperText="Ab wann sind keine Buchungen mehr möglich (in Stunden 0-24)" name="booking_endtime" value={settings['booking_endtime']} label="Endzeit Buchungen" onChange={handleChange} fullWidth InputProps={{ endAdornment: <AccessTime color="primary" /> }} InputLabelProps={{ shrink: true }}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* <TextField  error={errorTimes} helperText="Wie lange dauert eine Buchung (in Minuten zb.30 oder 60)" name="booking_duration" value={settings['booking_duration']} label="Buchungsdauer" onChange={handleChange} fullWidth InputLabelProps={{ shrink: true }}></TextField> */}
                                <FormControl fullWidth id="booking_duration">
                                    <InputLabel>Buchungsdauer</InputLabel>
                                    <Select id="sel-settings-duration" label="Buchungsdauer" labelid="Buchungsdauer" name="booking_duration" value={settings['booking_duration']} onChange={handleChange} fullWidth >
                                        <MenuItem id="btn-settings-duration-60minutes" value={60}>60 Minuten</MenuItem>
                                        <MenuItem id="btn-settings-duration-30minutes"value={30}>30 Minuten</MenuItem>
                                    </Select>
                                    <FormHelperText>Wie lange dauert eine Buchung - Änderungen sind nicht empfohlen</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">Löschfristen</Typography></Divider>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"flex-start"} flexWrap={"wrap"} spacing={0.5} useFlexGap>
                                    <Chip label="bis zum Spielbeginn" color={settings['booking_deletetime'] == 0 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 0 }))} />
                                    <Chip label="1 Stunde" color={settings['booking_deletetime'] == 60 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 60 }))} />
                                    <Chip label="2 Stunden" color={settings['booking_deletetime'] == 120 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 120 }))} />
                                    <Chip label="1 Tag" color={settings['booking_deletetime'] == 1440 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 1440 }))} />
                                    <Chip label="2 Tage" color={settings['booking_deletetime'] == 2880 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 2880 }))} />
                                    <Chip label="7 Tage" color={settings['booking_deletetime'] == 10080 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': 10080 }))} />
                                    <Chip label="keine Stornierung" color={settings['booking_deletetime'] < 0 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'booking_deletetime': -1 }))} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField error={errorTimes} type="number" onWheel={(e) => e.target.blur()} helperText="Bis wann kann spätestens eine Buchung gelöscht werden (Minuten bis zur Buchung)" name="booking_deletetime" value={settings['booking_deletetime']} label="Löschfrist Buchungen" onChange={handleChange} fullWidth InputProps={{ endAdornment: <AccessTime color="primary" /> }} InputLabelProps={{ shrink: true }}></TextField>
                                <Alert severity='info' sx={{ mt: 1 }}>
                                    <AlertTitle>Hinweis</AlertTitle>
                                    <Typography variant="body2">Eine Buchung kann immer bis 30 Minuten nach Erstellungszeitpunkt storniert werden.</Typography>
                                </Alert>
                            </Grid>
                            {errorTimes &&
                                <Grid item xs={12}>
                                    <Alert severity='warning'><Typography>Die Eingaben sind nicht valide!</Typography></Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">Zulässige Buchungen</Typography></Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField id="txf-settings-allowedBookingsTotal" InputProps={{ endAdornment: <Chip label="unbegrenzt" color={settings['allowed_bookings_total'] == 999 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'allowed_bookings_total': 999 }))} /> }}
                                    error={errorValidBookings} type="number" onWheel={(e) => e.target.blur()} helperText="Mitlgieder dürfen insgesamt nur X Buchungen im voraus haben" name="allowed_bookings_total" value={settings['allowed_bookings_total']} label="Zulässige Buchungen insgesamt" onChange={handleChange} fullWidth InputLabelProps={{ shrink: true }}></TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField id="txf-settings-allowedBookingsPerDay"InputProps={{ endAdornment: <Chip label="unbegrenzt" color={settings['allowed_bookings_per_day'] == 999 ? "primary" : "default"} onClick={() => setSettings(prevState => ({ ...prevState, 'allowed_bookings_per_day': 999 }))} /> }}
                                    error={errorValidBookings} type="number" onWheel={(e) => e.target.blur()} helperText="Mitlgieder dürfen insgesamt nur X Buchungen pro Tag haben" name="allowed_bookings_per_day" value={settings['allowed_bookings_per_day']} label="Zulässige Buchungen pro Tag" onChange={handleChange} fullWidth InputLabelProps={{ shrink: true }}></TextField>
                            </Grid>
                            {errorValidBookings &&
                                <Grid item xs={12}>
                                    <Alert severity='warning'><Typography>Die Anzahl zulässiger Buchungen gesamt muss größer oder gleich der Azahl zulässiger Buchungen pro Tag sein!</Typography></Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack>
                                        <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>Gastbuchungen</Typography>
                                        <Typography variant="body2" fontWeight={"Regular"}>Dürfen Mitglieder Platzbuchungen mit Gästen durchführen?</Typography>
                                    </Stack>
                                    <Switch id="swi-settings-guestbooking" name="allow_guest_bookings" checked={Boolean(settings["allow_guest_bookings"])} onChange={handleChange}></Switch>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack>
                                        <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>Freitextbuchunugen (Mitglieder)</Typography>
                                        <Typography variant="body2" fontWeight={"Regular"}>Dürfen Mitglieder Platzbuchungen ohne Partner(Freitext) durchführen? Diese Buchungen werden nicht vom verf. Kontigent abgezogen. Zum Beispiel für Vereisnmeisterschaften.</Typography>
                                    </Stack>
                                    <Switch id="swi-settings-freetextbooking" name="allow_freetext_bookings" checked={Boolean(settings["allow_freetext_bookings"])} onChange={handleChange}></Switch>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">Buchungsbestätigung</Typography></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack>
                                        <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>Buchungsbestätigung erforderlich</Typography>
                                        <Typography variant="body2" fontWeight={"Regular"}>Müssen Spieler eine Platzbuchung vorher bestätigen?</Typography>
                                    </Stack>
                                    <Switch id="swi-settings-bookingconfirmation" name="booking_confirmation" checked={Boolean(settings["booking_confirmation"])} onChange={handleChange}></Switch>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider><Typography variant="body2" color="text.secondary">Preise</Typography></Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack>
                                    <Stack direction="row" justifyContent={"end"}>
                                        <TertiaryButton endIcon={<Help color="primary" />} text="Hilfe" onClick={() => { setOpenInfo(!openInfo) }}></TertiaryButton>
                                    </Stack>
                                    <Collapse in={Boolean(openInfo)}>
                                        <Alert severity="info" onClose={() => { setOpenInfo(false) }}>
                                            <AlertTitle>Preisberechnung und Darstellung</AlertTitle>
                                            <Typography variant={"body2"}>Die Preise werden im Buchungsdialog angezeigt. Bei 0€ werden weder Kosten angezeigt noch berechnet. Grundsätzlich trackt und berechnet das Portal die Preise nur - es wird keine Bezahlungsabwicklung durchgeführt!</Typography>
                                        </Alert>
                                    </Collapse>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="txf-settings-priceMemberbooking" type="number" onWheel={(e) => e.target.blur()} InputProps={{ endAdornment: <EuroSymbol color="primary" /> }} helperText="Preis für eine Mitgliederbuchung" name="member_booking_price" value={settings['member_booking_price']} label={"Preis Mitgliederbuchung " + (courtType == "indoor" ? "Hallenplatz" : "Außenplatz")} onChange={handleChange} fullWidth InputLabelProps={{ shrink: true }}></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={Boolean(settings['allow_guest_bookings'])}>
                                    <TextField id="txf-settings-priceGuestbooking" type="number" onWheel={(e) => e.target.blur()} InputProps={{ endAdornment: <EuroSymbol color="primary" /> }} helperText="Preis für eine Gastbuchung" name="guest_booking_price" value={settings['guest_booking_price']} label={"Preis Gastbuchung " + (courtType == "indoor" ? "Hallenplatz" : "Außenplatz")} onChange={handleChange} fullWidth InputLabelProps={{ shrink: true }}></TextField>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack>
                                        <Typography variant="body1" fontWeight={"Medium"} sx={{ textDecoration: "underline" }}>Preisindikation im Buchungsbutton</Typography>
                                        <Typography variant="body2" fontWeight={"Regular"}>Soll eine "ab X€" Preisangabe im Buchungsbutton angezeigt werden?</Typography>
                                    </Stack>
                                    <Switch id="swi-settings-priceIndication" name="button_price_enabled" checked={Boolean(settings["button_price_enabled"])} onChange={handleChange}></Switch>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider></Divider>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <PrimaryButton id="btn-settings-submit" fullWidth text={courtType == "indoor" ? "Hallenplatz Einstellungen speichern" : "Außenplatz Einstellungen speichern"} disabled={submitDisabled} onClick={() => { updateSettings() }}></PrimaryButton>
                    </CardActions>
                </Card>
           </MainContentContainer>
            <Footer></Footer>
        </>
    )
}

export default SettingsContainer