import {Grid, Stack} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";

import { Feed } from "@mui/icons-material";
import TitleCard from "../../components/TitleCard";
import { getPosts_api } from "../../api/Posts/post";
import HeadlinePost from "./HeadlinePost";
import { useNavigate} from "react-router-dom";
import MainContentContainer from "../../components/MainContentContainer";


const BlogContainer = () => {

    const [posts, setPosts] = useState();
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const navigate = useNavigate();

    const openPost = (id) => {
        navigate("/blog/" + id);
    }
    const getPosts = () => {
        setLoading(true);
        var promise;
        promise = getPosts_api();
        promise.then((result) => { setPosts(result.data); setLoading(false); })
            .catch((error) => { setLoading(false); });

    }
    useEffect(() => {
        getPosts();
    }, []);
    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-blog" title={process.env.REACT_APP_CLUB_ABBREVATION + " Blog"} subheader={"News vom " + process.env.REACT_APP_CLUB_NAME} icon={<Feed sx={{ width: 40, height: 40 }}></Feed>}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer maxWidth={"lg"} px={0} py={0}>
                <Stack spacing={0.5}>
                    {posts && posts.length > 0 && posts.map((post, i) => (
                        <HeadlinePost post={post} key={i}/>
                    ))}
                </Stack>

            </MainContentContainer>
            <Footer />
        </>
    );
};

export default BlogContainer;

