import { ExpandLess, ExpandMore, Send } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, CardMedia, Chip, Collapse, Divider, IconButton, Stack, TextField, Typography, formControlClasses } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { deleteComment_api, getComments_api, setComment_api } from '../../api/Comments/comments'
import AvatarInitials from '../../components/AvatarInitials'
import ImageDialog from '../../components/ImageDialog'
import TertiaryButton from '../../components/TertiaryButton'
import PostComment from './PostComment'
import { sanitize } from 'dompurify';
import { updateAnalytics_api } from '../../api/Analytics/analytics'
import { format, formatDistanceToNow } from 'date-fns'
import { de } from 'date-fns/locale'

function BlogPost(props) {

    const { title, src, author, content, commentsCount,collapsable = true, post } = props;
    const [expandContent, setExpandContent] = useState(true);
    const [expandComments, setExpandComments] = useState(true);
    const [comments, setComments] = useState(null);
    const [message, setMessage] = useState("");
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const ref = useRef();

    const getComments = () => {
        var promise = getComments_api(post.id);
        promise.then((result) => { setComments(result.data) })
            .catch();
    }

    const setComment = () => {
        if (message == "") {
            return null;
        }
        var promise = setComment_api(message, post.id);
        promise.then((result) => { setComments(result.data); getComments(); setExpandComments(true); setMessage(""); })
            .catch();
    }

    const deleteComment = (commentID) => {
        var promise = deleteComment_api(post.id, commentID);
        promise.then((result) => { getComments(); setExpandComments(true); })
            .catch();
    }

    const changeHandler = (event) => {
        setMessage(event.target.value)
    }

    const updateAnalytics = () => {
        var promise = updateAnalytics_api("readPost");
        promise.then()
            .catch();
    }

    useEffect(() => {
        if (expandComments) {
            getComments();
        }

        return () => {

        };
    }, [expandComments]);

    useEffect(() => {
        if (!expandContent) {
            //update readPost
            updateAnalytics();
        }
        return () => {

        };
    }, [expandContent]);

    return (
        <Card elevation={0} sx={{ border: 0, borderRadius: 0 }}>

            {/* display image */}
            {src &&
                <CardMedia component="img" src={src} onClick={() => { setOpenImageDialog(true) }} sx={{height: {xs: 200, sm: 300 ,md: 400, lg: 500}, objectFit: 'fit'}}></CardMedia>
               
            }
            <CardHeader title={title} subheader={"Datum: " + format(new Date(post.reg_date), "d. MMMM yyyy", { locale: de })} subheaderTypographyProps={{ variant: "body2" }}></CardHeader>
            <CardContent>
                {/* Display first twio lines, when collapses display all */}
                {collapsable ?
                <>
                <Typography dangerouslySetInnerHTML={{ __html: sanitize(content) }} sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: expandContent ? 3 : 1000,
                    WebkitBoxOrient: 'vertical',
                }}></Typography>
                <Stack>
                    <TertiaryButton endIcon={expandContent ? <ExpandMore /> : <ExpandLess />} text={expandContent ? "weiterlesen" : "einklappen"} onClick={() => { setExpandContent(!expandContent) }}></TertiaryButton>
                </Stack>
                </>
                :
                <Typography dangerouslySetInnerHTML={{ __html: sanitize(content) }} ></Typography>
            }
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Chip variant="outlined" size="small" color="primary" label={"#" + post.category}></Chip>
                    <TertiaryButton text={commentsCount + " Kommentare"} onClick={() => { setExpandComments(!expandComments) }}></TertiaryButton>
                </Stack>
            </CardContent>
            {props.isAuthenticated &&
                <>
                    <Collapse in={expandComments} transition="auto" unmountOnExit>
                        <>
                            {/* add Comment */}
                            <Divider variant="middle"></Divider>
                            <CardContent>
                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                    <AvatarInitials firstname={props.firstname} lastname={props.lastname}></AvatarInitials>
                                    <TextField id="comment" name="comment" value={message} required label="Schreibe einen Kommentar" fullWidth variant="outlined" onChange={changeHandler} onKeyUp={(event) => { if (event.key === "Enter") { setComment(); } }} InputProps={{ endAdornment: <IconButton variant="outlined" color="primary" size="small" onClick={() => setComment()}><Send /></IconButton> }} />
                                </Stack>
                            </CardContent>

                        </>

                        {/* loop over comments of Post XY*/}
                        <Stack spacing={1}>
                            {comments && comments.length > 0 ? comments.map((comment, i) =>
                                <React.Fragment key={i}>
                                    <PostComment message={comment.comment} firstname={comment.author_firstname} lastname={comment.author_lastname} comment={comment} deleteComment={deleteComment}></PostComment>
                                </React.Fragment>
                            )
                                :
                                <></>
                            }
                        </Stack>
                    </Collapse>
                </>
            }
            <ImageDialog open={openImageDialog} setOpen={setOpenImageDialog} imageURL={src}></ImageDialog>
            <Divider></Divider>
        </Card>
    )
}

export default BlogPost