import { AppBar, Grid, Toolbar, Typography, Link, CardContent, Card, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import semver from '../assets/semver.txt'
import { FEEDBACK, IMPRESSUM, RELEASENOTES, TERMS } from '../navigation/CONSTANTS';

function Footer(props) {
    const [version, setVersion] = useState(null);
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const navigate = useNavigate();


    const clickHandler = (path) => {
        navigate(path);
    }

    //read out version
    useEffect(() => {
        fetch(semver)
            .then(r => r.text())
            .then(text => {
                setVersion(text);
            });
        return () => {
        };
    }, []);
    return (
        <>
            {/* <AppBar elevation={24} sx={{ backgroundColor: "#fff" }}>
                <Toolbar variant="dense" > */}
            <Card sx={{ width: "100%", backgroundColor: "#eee", mt: 3 }}>
                <CardContent>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item xs={12}>
                            <Stack>
                                {/* <Typography textAlign="center" variant="h6" fontWeight={"bold"} color="text.secondary">MyClubPortal</Typography> */}
                                <Link color="text.secondary" onClick={() => { window.open("https://myclubportal.de",'_blank')}}><Typography textAlign="center" variant="h6" fontWeight={"bold"} color="text.secondary">MyClubPortal</Typography></Link>
                                <Link color="text.secondary" onClick={() => { clickHandler(RELEASENOTES) }}><Typography variant="body2" textAlign={"center"}>{"v" + version}</Typography></Link>
                            </Stack>

                        </Grid>
                        <Grid item xs={4}>
                            {isAuthenticated &&
                                <Link variant="body2" color="text.secondary" onClick={() => { clickHandler(FEEDBACK) }}>Support/Feedback</Link>
                            }
                        </Grid>
                        <Grid container item xs={4} justifyContent="center">
                            <Grid item >
                                <Link variant="body2" align='center' color="text.secondary" onClick={() => { clickHandler(IMPRESSUM) }}>Impressum</Link>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Link color="text.secondary" onClick={() => { clickHandler(TERMS) }}><Typography textAlign={"right"} variant="body2">AGB/Datenschutz</Typography></Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {/* </Toolbar>
            </AppBar> */}
            {/* <Toolbar sx={{ minHeight: "48px", top: 'auto', bottom: 0 }}></Toolbar> */}
        </>
    )
}


export default Footer;