import React, { useState } from 'react'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { format, isFuture } from 'date-fns'
import { de } from 'date-fns/locale'
import { Ballot, CheckCircleOutline, Clear, Group, HelpOutline, HighlightOffOutlined, Home, Login, Logout, ThumbDownOutlined, ThumbUpOutlined, TimeToLeave } from '@mui/icons-material'
import { ButtonGroup, Divider, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Stack, Typography } from '@mui/material'
import PrimaryIconButton from './PrimaryIconButton'
import Survey from './Survey'
import ResponsiveDrawer from './ResponsiveDrawer'

function TeammatchTimelineItem({ teammatch, hideConnector, getTeammatches, getGuestMatches, isGuest, showTeam, adminMode, TeammatchMenu, ...props }) {
    const [showContent, setShowContent] = useState(false);
    const [openParticipants, setOpenParticipants] = useState(false);
    const [openSurveys, setOpenSurveys] = useState(false);


    return (
        <>
            <TimelineItem sx={{
                [`& .${timelineOppositeContentClasses.root}`]: { flex: { xs: 0.4, lg: 1, }, },
            }} >
                <TimelineOppositeContent textAlign="center" variant={'body1'} color={isFuture(new Date(teammatch.date)) ? "primary" : "text.secondary"} sx={{ py: '12px', px: 2 }} onClick={() => { setShowContent(!showContent) }} >
                    <Stack justifyContent={"center"} alignItems={"flex-end"}>
                        <Typography variant='body2'>  {teammatch.date && format(new Date(teammatch.date), "HH:mm 'Uhr", { locale: de })}</Typography>
                        <Typography fontWeight={500}>  {teammatch.date && format(new Date(teammatch.date), "dd. MMM yy", { locale: de })}</Typography>
                    </Stack>
                </TimelineOppositeContent>
                <TimelineSeparator onClick={() => { setShowContent(!showContent) }} >
                    <TimelineDot color={isFuture(new Date(teammatch.date)) ? (teammatch.location === "(H)" ? "primary" : "secondary") : "grey"} variant="outlined">
                        {teammatch.location === "(H)" ?
                            <Home color={isFuture(new Date(teammatch.date)) ? "primary" : "grey"} />
                            :
                            <TimeToLeave color={isFuture(new Date(teammatch.date)) ? "secondary" : "grey"} />
                        }</TimelineDot>
                    {!hideConnector ? <TimelineConnector /> : <></>}
                </TimelineSeparator>
                <TimelineContent component={'span'} sx={{ py: '12px', px: 2 }}>
                    <Stack onClick={() => { }} >
                        <Typography variant={'body2'} color={isFuture(new Date(teammatch.date)) ? (teammatch.location === "(H)" ? "primary" : "secondary") : "grey"} sx={{ textTransform: "uppercase" }}>{teammatch.location === "(H)" ? "Heim" : "Auswärts"}</Typography>

                        <Typography variant={'h6'} color="text.secondary">{teammatch.opponent}</Typography>
                        {showTeam &&
                            <Typography variant="body2" color="text.secondary">{teammatch.teamName}</Typography>
                        }
                        <Stack direction="row" spacing={2} sx={{ pt: 0.5 }}>
                            {TeammatchMenu}
                            {!adminMode ?
                                teammatch.isParticipant == 1 ?
                                    <PrimaryIconButton size="small" icon={<CheckCircleOutline color="grey" />} />
                                    : teammatch.isParticipant == 0 ?
                                        <PrimaryIconButton size="small" icon={<HighlightOffOutlined color="grey" />} />
                                        :
                                        <PrimaryIconButton size="small" icon={<HelpOutline color="grey" />} />
                                :
                                <></>
                            }

                            <PrimaryIconButton size="small" tooltipTitle="Teilnhehmerliste" onClickAction={() => setOpenParticipants(!openParticipants)} icon={<Stack direction="row" alignItems={"center"} spacing={0.5}><Group fontSize="small" color="grey" /><Typography variant="body2" color="text.secondary">{teammatch.totalParticipants}</Typography></Stack>
                            } />
                            {teammatch && teammatch.assignmentsData && teammatch.assignmentsData.length > 0 ?
                                <PrimaryIconButton size="small" tooltipTitle="verknüpfte Umfragen" onClickAction={() => { setOpenSurveys(!openSurveys) }} icon={<Stack direction="row" alignItems={"center"} spacing={0.5}><Ballot fontSize="small" color="grey" /><Typography variant="body2" color="text.secondary">{teammatch.assignmentsData.length}</Typography></Stack>} />
                                : <></>}
                        </Stack>

                    </Stack>

                </TimelineContent>
            </TimelineItem>
            <ResponsiveDrawer drawerTitle={teammatch.opponent} drawerSubtitle={"Teilnehmerliste"} openDrawer={openParticipants} setOpenDrawer={() => setOpenParticipants()}>
                <List sx={{ minHeight: "50vh" }}>
                    {teammatch.participants && teammatch.participants.map((participant, i) => (
                        <ListItem key={i}>
                            {participant.status === null ? <ListItemAvatar><HelpOutline sx={{ color: 'warning.main' }}/></ListItemAvatar> : <></>}
                            {participant.status === 1 ? <ListItemAvatar><CheckCircleOutline sx={{ color: 'success.main' }} /></ListItemAvatar> : <></>}
                            {participant.status === 0 ? <ListItemAvatar><HighlightOffOutlined sx={{ color: 'secondary.main' }} /></ListItemAvatar> : <></>}
                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + participant.nuliga_lk} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                            {teammatch.isCaptain === 1 || props.isAdmin === true ?
                                <ButtonGroup>
                                    <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch.id, 1, participant.user_id, participant.status) }} />
                                    <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch.id, 0, participant.user_id, participant.status) }} />
                                    <PrimaryIconButton icon={<Clear />} disabled={props.disabled} onClickAction={() => { props.deleteStatusTeammatch(teammatch.id, participant.user_id) }} />
                                </ButtonGroup> : <></>}
                        </ListItem>
                    ))}
                    {/* {teammatch.participants.length > 5 && <ListSubheader inset={true}><PrimaryIconButton icon={expParticipants ? <ExpandLess /> : <ExpandMore />} onClickAction={() => { setExpParticipants(!expParticipants); }}></PrimaryIconButton></ListSubheader>} */}
                    {teammatch.guests && teammatch.guests.length > 0 && <ListSubheader><Divider>{"Aushilfen"}</Divider></ListSubheader>}
                    {teammatch.guests && teammatch.guests.map((participant, i) => (
                        <ListItem key={i}>
                            {participant.status === null ? <ListItemAvatar><HelpOutline sx={{ color: 'warning.main' }} /></ListItemAvatar> : <></>}
                            {participant.status === 1 ? <ListItemAvatar><CheckCircleOutline sx={{ color: 'success.main' }} /></ListItemAvatar> : <></>}
                            {participant.status === 0 ? <ListItemAvatar><HighlightOffOutlined sx={{ color: 'secondary.main' }} /></ListItemAvatar> : <></>}
                            <ListItemText primary={participant.firstname + " " + participant.surname} secondary={"LK: " + participant.nuliga_lk} primaryTypographyProps={{ variant: "body1", fontWeight: 'bold', color: "text.primary" }} />
                            {teammatch.isCaptain === 1 || props.isAdmin === true ?
                                <ButtonGroup>
                                    <PrimaryIconButton icon={<Login color="success" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch.id, 1, participant.id, participant.status) }} />
                                    <PrimaryIconButton icon={<Logout color="error" />} disabled={props.disabled} onClickAction={() => { props.setStatusTeammatch(teammatch.id, 0, participant.id, participant.status) }} />
                                    <PrimaryIconButton icon={<Clear></Clear>} disabled={props.disabled} onClickAction={(e) => { props.deleteGuest(teammatch.id, participant.id) }} />
                                </ButtonGroup>
                                : <></>}
                        </ListItem>
                    ))}
                </List>
            </ResponsiveDrawer>
            <ResponsiveDrawer drawerTitle={teammatch.opponent} drawerSubtitle={"Verknüpfte Umfragen"} openDrawer={openSurveys} setOpenDrawer={setOpenSurveys} >
                <Stack spacing={2}>
                    {teammatch.assignmentsData && teammatch.assignmentsData.map((survey, i) => (
                        <Survey key={i} variant="raised" surveyData={survey} getSurveys={() => { getTeammatches() }} openEditDialog={props.openEditDialog} />
                    ))}
                </Stack>
            </ResponsiveDrawer>
        </>
    )
}

export default TeammatchTimelineItem