import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getSettings_api = async () => {
  const sessionData = getSessionData();

  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/settings",
    { headers: sessionData }
  );
};

export const getSettingsIndoor_api = async () => {
  const sessionData = getSessionData();

  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/settings?type=indoor",
    { headers: sessionData }
  );
};

export const getSettingsOutdoor_api = async () => {
  const sessionData = getSessionData();

  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/settings?type=outdoor",
    { headers: sessionData }
  );
};

export const updateSettingsIndoor_api = async (data) => {
  const sessionData = getSessionData();
  data["type"] = "indoor";

  //fetch reservation filtered by day data
  return await axios.put(
    process.env.REACT_APP_API_URL + "/settings", data, { headers: sessionData }
  );
};

export const updateSettingsOutdoor_api = async (data) => {
  const sessionData = getSessionData();
  data["type"] = "outdoor";

  //fetch reservation filtered by day data
  return await axios.put(
    process.env.REACT_APP_API_URL + "/settings", data, { headers: sessionData }
  );
};