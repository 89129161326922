import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import MainContentContainer from '../../components/MainContentContainer';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import Terms from './Terms';

function TermsContainer(open) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <MainContentContainer maxWidth="lg" py={5}>
        <Terms />
      </MainContentContainer>
      <Footer></Footer>
    </>

  )
}

export default TermsContainer