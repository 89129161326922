import { Alert, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import SecondaryButton from './SecondaryButton'
import PrimaryButton from './PrimaryButton'
import { LocationOn, Navigation } from '@mui/icons-material'

function DirectionsDialog({ address, open, onClose, ...props }) {

    const openDirectionsLink = () => {
        address = address.replace("(Clubhaus - bewirtschaftet)","");
        window.open("https://www.google.de/maps/place/" + decodeURI(address), "_blank")
    }

    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>Anfahrt</DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Card elevation={0} variant="outlined" sx={{mt: 1}}>
                    <CardContent>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                <LocationOn color="primary"></LocationOn>
                                <Typography>{address}</Typography>
                            </Stack>
                            <Divider></Divider>
                            <Alert severity="info">Überprüfen sie die Addressinformationen ggf. auf Plausibilität</Alert>

                        </Stack>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <SecondaryButton text="Abbrechen" onClick={() => onClose()}></SecondaryButton>
                <PrimaryButton startIcon={<Navigation />} text="Google Maps" onClick={() => openDirectionsLink()}></PrimaryButton>
            </DialogActions>
        </Dialog>
    )
}

export default DirectionsDialog