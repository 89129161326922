import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Switch, Tabs, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import QuillEditor from './QuillEditor';
import MyTab from './MyTab';
import LaunchIcon from '@mui/icons-material/Launch';
import { Edit, Mail } from '@mui/icons-material';
import { getMailTemplates_api } from '../api/Mail/mail';
import { format, isValid, previousMonday } from 'date-fns';
import PrimaryIconButton from './PrimaryIconButton';
import { connect, useSelector } from 'react-redux';

function MailDialog(props) {
    const { handleSubmit, open, onClose, title, submitName, isSending, isHidden, isMailActivated = true } = props;

    const [isMail, setIsMail] = useState(false);
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [tabValue, setTabValue] = useState("editor");
    const [templates, setTemplates] = useState();

    const isAdmin = useSelector((state) => state.isAdmin);

    const getTemplates = () => {
        var promise = getMailTemplates_api();
        promise.then((result) => { setTemplates(result.data) })
            .catch((error) => { })
    }

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'Message':
                setMessage(event.target.value);
                break;
            case 'Subject':
                setSubject(event.target.value)
                break;
            case "isMail":
                setIsMail(event.target.checked);
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        setMessage("");
        setSubject("");
        setIsMail(isMailActivated);
        getTemplates();
    }, [open]);


    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {title}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    {isAdmin && isMail ?
                        <Box sx={{ width: "100%", borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                            <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
                                <MyTab label={"Editor"} value="editor"></MyTab>
                                <MyTab label={"Vorlagen"} value="template"></MyTab>
                            </Tabs>
                        </Box>
                        :
                        <></>
                    }
                    {tabValue == "editor" ?
                        <Grid container spacing={2}>


                            {isHidden ?
                                <></>
                                :
                                <Grid item xs={12}>
                                    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                        <Stack>
                                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Benachrichtigung</Typography>
                                            <Typography variant="body2">Sollen die Spieler per Mail benachrichtigt werden?</Typography>
                                        </Stack>
                                        <Switch size="medium" name="isMail" id="isMail" checked={isMail} onChange={handleChange}></Switch>
                                    </Stack>
                                </Grid>
                            }
                            {isMail ?
                                <>
                                    <Grid item xs={12}>
                                        <TextField size="small" name="Subject" value={subject} onChange={handleChange} label={"Betreff"} fullWidth></TextField>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    <TextField label="Nachricht" name="Message" value={message} onChange={handleChange} fullWidth multiline minRows={3} />
                                </Grid> */}
                                    <Grid item xs={12}>
                                        <QuillEditor value={message} setValue={setMessage}></QuillEditor>
                                    </Grid>
                                </>
                                :
                                <></>
                            }
                            {isSending ?
                                <Grid item xs={12} align={"center"}>
                                    <CircularProgress></CircularProgress>
                                </Grid>
                                : <></>}
                        </Grid>
                        :
                        <List sx={{ maxHeight: "50vh", overflow: "auto" }}>
                            {templates && templates.map((template, i) => (
                                <ListItem key={i} secondaryAction={<PrimaryIconButton icon={<LaunchIcon color="primary" />} onClickAction={() => { setTabValue("editor"); setMessage(template.content); }}></PrimaryIconButton>}>
                                    <ListItemIcon><Mail color="primary"></Mail></ListItemIcon>
                                    <ListItemText primary={template.name} secondary={template.modified_date && isValid(new Date(template.modified_date)) ? format(new Date(template.modified_date), "dd.MM.yyyy HH:mm") : ""}></ListItemText>
                                </ListItem>
                            ))
                            }
                        </List>
                    }
                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text="Abbrechen" onClick={onClose} />
                    <PrimaryButton id="btn-maildialog-submit" text={submitName} onClick={() => { handleSubmit(message, subject) }} />
                </DialogActions>
            </Dialog >
        </>
    )
}


export default MailDialog