import { Clear } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import AvatarInitials from './AvatarInitials';
import CustomAutocomplete from './CustomAutocomplete';
import PrimaryIconButton from './PrimaryIconButton';

function AddUserDialog(props) {

    const { openAddUserDialog, onClose, title, users, addUserDialogSubmit, multipleSelect, warning, warningTitle } = props;
    const [targetUsers, setTargetUsers] = useState([]);
    const [targetUser, setTargetUser] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const addTargetUser = (targetUser) => {
        if (targetUser) {
            if (multipleSelect) {
                setTargetUsers(targetUsers => {
                    return [...targetUsers, targetUser];
                })
            }
            else {
                setTargetUser(targetUser);
            }
        }
    }

    const removeTargetUser = (index) => {
        setTargetUsers([
            ...targetUsers.slice(0, index),
            ...targetUsers.slice(index + 1)
        ]);
    }

    useEffect(() => {
        if (!openAddUserDialog) {
            setTargetUsers([]);
        }
        return () => {

        };
    }, [openAddUserDialog]);

    useEffect(() => {
        if (multipleSelect && targetUsers && targetUsers.length == 0) {
            setSubmitDisabled(true)
        }
        else if(!multipleSelect && !targetUser)
        {
            setSubmitDisabled(true)
        }
        else {
            setSubmitDisabled(false)
        }
        
    }, [targetUsers,targetUser]);

    return (
        <>
            <Dialog open={openAddUserDialog} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"} onClick={(e) => { e.stopPropagation() }}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {title}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Grid container spacing={2}>
                        {warning && warning != "" ?
                            <Grid item xs={12}>
                                <Alert severity="warning"><AlertTitle>{warningTitle}</AlertTitle><Typography varaint="body2">{warning}</Typography></Alert>
                            </Grid>
                            : <></>
                        }
                        <Grid item xs={12}>
                            <CustomAutocomplete id="atc-addmember" options={users} label="Mitglied" setTarget={addTargetUser} multipleSelect={multipleSelect} />
                        </Grid>
                        {multipleSelect &&
                            <Grid item xs={12}>
                                <List>
                                    {targetUsers && targetUsers.length > 0 && targetUsers.map((user, i) => (
                                        <ListItem key={i}>
                                            <ListItemAvatar><AvatarInitials firstname={user.firstname} lastname={user.lastname} /></ListItemAvatar>
                                            <ListItemText primary={user.firstname + " " + user.lastname} primaryTypographyProps={{ variant: "body2", fontWeight: 'bold' }}>
                                            </ListItemText>
                                            <PrimaryIconButton icon={<Clear />} onClickAction={(e) => { removeTargetUser(i); console.log(i) }} sx={{ ml: 2 }} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <Divider></Divider>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Button variant="outlined" onClick={onClose}>Abbrechen</Button>
                    {multipleSelect === true ?
                        <Button id="btn-adduserdialog-submit" variant="contained" disabled={submitDisabled}  onClick={() => { addUserDialogSubmit(targetUsers) }}>Bestätigen</Button>
                        :
                        <Button id="btn-adduserdialog-submit" variant="contained" disabled={submitDisabled} onClick={() => { addUserDialogSubmit(targetUser)}}>Bestätigen</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddUserDialog