import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getTraininggroups = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/traininggroups",
    { headers: sessionData }
  );
};

export const getMyTraininggroups = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mytraininggroups",
    { headers: sessionData }
  );
};

export const createMyTraininggroup = async (name) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  var data={
    name: name
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/mytraininggroups/",data,
    { headers: sessionData }
  );
};

export const createTraininggroup = async (name,owner) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  var data={
    name: name,
    owner: owner
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/traininggroups/",data,
    { headers: sessionData }
  );
};

export const updateTraininggroup = async (name,owner,traininggroupid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  var data={
    name: name,
    owner: owner
  };

  return await axios.patch(
    process.env.REACT_APP_API_URL + "/traininggroups/"+ traininggroupid,data,
    { headers: sessionData }
  );
};


export const getTraininggroupParticpants = async (traininggroupid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/traininggroups/"+ traininggroupid + "/members",
    { headers: sessionData }
  );
};

export const setTraininggroupParticpants = async (traininggroupid,userid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  var data={
    userid: userid,
    traininggroupid: traininggroupid
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/traininggroups/" + traininggroupid + "/members/",data,
    { headers: sessionData }
  );
};

export const deleteTraininggroupParticpants = async (traininggroupid, id) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/traininggroups/"+ traininggroupid + "/members/" + id,
    { headers: sessionData }
  );
};

export const deleteTraininggroup = async (traininggroupid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/traininggroups/"+ traininggroupid,
    { headers: sessionData }
  );
};

export const leaveMyTraininggroup = async (traininggroupid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/mytraininggroups/leave/"+ traininggroupid,
    { headers: sessionData }
  );
};

export const sendTraininggroupInvitation_api = async (traininggroupid,userid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data={
    userid: userid
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/traininggroups/"+ traininggroupid + "/invitation",data,
    { headers: sessionData }
  );
};




