import { AccessTime, ExpandMore, Person, Send } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Chip, Divider, Grid, Icon, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SupportMessage from './SupportMessage';
import { sendResponse_api, setSupportCaseStatus_api } from '../api/Support/support';
import PrimaryIconButton from './PrimaryIconButton';
import SecondaryButton from './SecondaryButton';
import { useSnackbar } from './SnackbarContext';

function SupportCard(props) {
    const { supportcase, getSupportCases} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [messages, setMessages] = useState(null);
    const [response, setResponse] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const {showSnackbar} = useSnackbar();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const sendResponse = () => {
        var promise = sendResponse_api(supportcase.id, response);
        promise.then((result) => { setResponse(""); getSupportCases(); showSnackbar("Antwort gesendet", "success"); })
            .catch((error) => showSnackbar("Fehler", "error"));
    }

    const changeStatus = (status) => {
        var promise = setSupportCaseStatus_api(supportcase.id, status);
        promise.then((result) => { getSupportCases(); showSnackbar("Status geändert", "success"); })
            .catch((error) => showSnackbar("Fehler", "error"));
    }

    useEffect(() => {
        if (!response) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false);
        }
    }, [response]);

    return (
        <Accordion sx={{ borderLeft: 2, borderColor: supportcase.status == "closed" ? "success.main" : supportcase.status == "new" ? "secondary.main" : "primary.main" }}>
            <AccordionSummary sx={{ backgroundColor: "#eee" }} expandIcon={<ExpandMore></ExpandMore>} >
                <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Typography>{supportcase.title}</Typography>
                    <Chip size="small" color={supportcase.status === "closed" ? "success"
                        : supportcase.status === "new" ? "secondary" :
                            supportcase.status === "open" ? "primary" :
                                "action"} label={supportcase.status} sx={{ color: "#fff" }}></Chip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails >
                <Grid container direction={{ xs: "column", sm: "space-a" }} spacing={1}>
                    <Grid item container direction={{ xs: "column", sm: "row" }} justifyContent={{ xs: "center", sm: "space-between" }} spacing={1}>
                        <Grid item>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                <Avatar variant="rounded" sx={{ backgroundColor: "#eee" }}><Person color="primary"></Person></Avatar>
                                <Stack>
                                    <Typography variant="body2" color="text.secondary">{supportcase.customer_name}</Typography>
                                    <Typography variant="body2" color="primary">{supportcase.email}</Typography>
                                </Stack>

                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                <Avatar variant="rounded" sx={{ backgroundColor: "#eee" }}><Icon><AccessTime color="primary"></AccessTime></Icon></Avatar>
                                <Stack direction="column">
                                    <Typography variant="body2" color="text.secondary">erstellt am</Typography>
                                    <Typography variant="body2" color="primary">{supportcase.reg_date}</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Box sx={{ border: 1, borderRadius: 1, borderColor: "#bbb", p: 1 }}>
                            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{supportcase.description}</Typography>
                        </Box>
                    </Grid>
                    {supportcase.messages && supportcase.messages.length > 0 &&
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                    }
                    {supportcase.messages && supportcase.messages.map((message, i) => (
                        <Grid item xs={12} key={i}>
                            <SupportMessage message={message}></SupportMessage>
                        </Grid>
                    ))
                    }
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Schreibe eine Antwort..." multiline maxRows={6} value={response} onChange={(event) => { setResponse(event.target.value) }} InputProps={{ endAdornment: <PrimaryIconButton disabled={submitDisabled} onClickAction={() => sendResponse()} icon={<Send ></Send>}></PrimaryIconButton> }}></TextField>
                    </Grid>
                </Grid>
            </AccordionDetails>
            <Divider></Divider>

            <AccordionDetails>
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    {/* <Button variant="outlined">Delete</Button> */}
                    {supportcase.status == "closed" ?
                        <Button variant="contained" onClick={() => changeStatus("open")}>Erneut öffnen</Button>
                        :
                        <Button variant="contained" onClick={() => changeStatus("closed")}>gelöst melden</Button>
                    }
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}

export default SupportCard