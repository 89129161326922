import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer'
import { Alert, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material'
import TitleCard from '../../components/TitleCard'
import { EventAvailable } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { confirmTeamInvitation_api, getInvitationByCode_api } from '../../api/Invitations/invitations'
import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'
import { getTeamByCode_api } from '../../api/Teams/teams'
import TeamCardLight from '../../components/TeamCardLight'
import TertiaryButton from '../../components/TertiaryButton'
import { MYTEAM } from '../../navigation/CONSTANTS'
import MainContentContainer from '../../components/MainContentContainer'

function MyTeamConfirmationContainer() {
    const { code } = useParams();
    const [confirmationStatus, setConfirmationStatus] = useState(false);
    const [invitation, setInvitation] = useState(null);
    const [error, setError] = useState(false);
    const [team, setTeam] = useState(null);
    const navigate = useNavigate();

    const confirmTeam = () => {
        var promise = confirmTeamInvitation_api(invitation.object_id, invitation.code);
        promise.then((result) => { setConfirmationStatus(true); })
            .catch(error => { });
    }

    const getTeamInvitation = () => {
        var promise = getInvitationByCode_api(code)
        promise.then((result) => { if (result.data.length != 0) { setInvitation(result.data[0]) } else { setError(true) } })
            .catch((error) => setError(true));
    }

    const getTeam = () => {
        var promise = getTeamByCode_api(invitation.object_id, invitation.code);
        promise.then((result) => { if (result.data.length != 0) { console.log(result.data[0]); setTeam(result.data[0]) } else { setError(true) } })
            .catch((error) => setError(true));
    }

    useEffect(() => {
        if (code != null) {
            getTeamInvitation();
        }

    }, [code]);

    useEffect(() => {
        if (invitation) {
            getTeam();
        }
        return () => {

        };
    }, [invitation]);

    return (
        <>
            <Header />
            <TitleCard title={"Mannschaftseinladung"} subheader={"Tritt einer Mannschaft bei!"} icon={<EventAvailable sx={{ width: 40, height: 40 }}></EventAvailable>}></TitleCard>

            <MainContentContainer>
                {!error ?
                    <>
                        {team &&
                            <Card>
                                <TeamCardLight team={team}></TeamCardLight>
                                {!confirmationStatus ?
                                    <CardContent>
                                        <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                                            <Grid item xs={12}>
                                                <Stack direction="row" justifyContent={"space-between"}>
                                                    <SecondaryButton text="Einladung löschen" onClick={() => { }}></SecondaryButton>
                                                    <PrimaryButton text="Mannschaft beitreten" onClick={() => { confirmTeam() }}></PrimaryButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    :
                                    <CardContent>
                                        <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                                            <Grid item xs={12}>
                                                <Alert severity='success' variant="filled">
                                                    <Typography>Team erfolgreich beigetreten</Typography>
                                                </Alert>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction="row" justifyContent={"center"}>
                                                    <TertiaryButton text="zu meinen Mannschaften" onClick={() => { navigate(MYTEAM) }} ></TertiaryButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                }
                            </Card>
                        }
                    </>

                    :
                    <Alert severity='error'>
                        <Typography>Etwas ist schief gelaufen - die Einladung existiert nicht mehr.</Typography>
                        <Typography>Bei dauerhaften Problemen wende dich an den Vereinsadmin oder den Support.</Typography>
                    </Alert>}
            </MainContentContainer >
            <Footer />
        </>
    )
}

export default MyTeamConfirmationContainer