import { Box } from '@mui/material';
import React, { useEffect } from 'react'
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';


function QuillEditor({ value, setValue }) {

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            /*[{font: []}],*/
            ["bold", "italic", "underline"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            [{ 'align': [] }],
            ["link"],
            ['clean']
        ]

    };

    useEffect(() => {
        if (value === "<p><br></p>") {
            setValue(null);
        }
    }, [value]);

    return (
        <Box id="quillContainer" sx={{ '& .ql-editor': { minHeight: '150px' } }}>
            <ReactQuill id="quillEditor" theme="snow" value={value} onChange={setValue} modules={modules} bounds={"#quillContainer"} />
        </Box>
    )
}

export default QuillEditor