import { EmojiEvents, FitnessCenter, Group, Groups, SportsTennis} from '@mui/icons-material';
import { Card, CardContent, CardHeader, List} from '@mui/material'
import React from 'react'
import SimpleListItem from '../../../components/SimpleListItem';


function AdmStatsCard(props) {
    const { items } = props;

    return (
        <Card raised sx={{ borderRadius: 2 }}>
            <CardHeader title={process.env.REACT_APP_CLUB_ABBREVATION+" Übersicht"} />
            <CardContent>
                <List dense>
                    <SimpleListItem value={items.userCount} icon={<Groups color="primary"/>} object={"Mitglieder"}></SimpleListItem>
                    <SimpleListItem value={items.teamCount} icon={<Group color="primary"/>} object={"Teams"}></SimpleListItem>
                    <SimpleListItem value={items.teamMatchesCount} icon={<EmojiEvents color="primary"/>} object={"Punktspiele"}></SimpleListItem>
                    <SimpleListItem value={items.traininggroupsCount} icon={<FitnessCenter color="primary"/>} object={"Trainingsgruppen"}></SimpleListItem>
                    <SimpleListItem value={items.trainingCount} icon={<SportsTennis color="primary"/>} object={"Trainings"}></SimpleListItem>
                </List>
            </CardContent>
        </Card>

    )
}

export default AdmStatsCard