
import React, { useState, useEffect } from 'react'
import { getLogsFiltered } from '../../api/Log/log';
import Header from '../../components/Header/Header';
import TitleCard from '../../components/TitleCard';
import ArticleIcon from '@mui/icons-material/Article';
import LogTable from './LogTable';
import { Grid } from '@mui/material';
import Footer from '../../components/Footer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSnackbar } from '../../components/SnackbarContext';
import MainContentContainer from '../../components/MainContentContainer';
function LogContainer() {

    const [state, setState] = useState({});
    const [logData, setLogData] = useState([]);
    const [filter, setFilter] = useState("");
    const [status, setStatus] = useState("all");
    const [searchParam, setSearchParam] = useSearchParams({});
    const [queryParams, setQueryParams] = useState({});
    const { pathName, search } = useLocation();
    const { showSnackbar } = useSnackbar();


    const refreshHandler = () => {
        //const data = getLogsFiltered("?https_status="+http_status+"&action__like="+filter+"&reg_date__gte="+format(new Date(),"yyyy-MM-dd"));
        const data = getLogsFiltered(searchParam.toString());
        data.then(result => { setLogData(result.data); })
            .catch(error => { showSnackbar("Fehler: " + error.response.data.error, "error"); });

    }

    const addParamToQuesryString = (newKey, newValue) => {
        if (searchParam.has(newKey)) {
            searchParam.set(newKey, newValue);
        }
        else {
            searchParam.append(newKey, newValue)
        }
        setSearchParam(searchParam);
    }

    useEffect(() => {
        addParamToQuesryString("action__like", filter);
        return () => {
            setState({});
        }
    }, [filter])

    useEffect(() => {
        refreshHandler();
        return () => {
            setState({});
        }
    }, [search])

    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-logs" title={"Logs"} subheader={"Hier siehst du alle Aktionen im System!"} icon={<ArticleIcon sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} xs={12} md={12} rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>
                        <LogTable rows={logData} searchParam={searchParam} setSearchParam={setSearchParam} addParamToQuesryString={addParamToQuesryString} filter={filter} setStatus={setStatus} setFilter={setFilter} adminMode={true} />
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer />
        </>
    );
}

export default LogContainer
