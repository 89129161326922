import {  Dialog, DialogContent, DialogTitle, Grid, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'

import { getUser } from '../../api/Users/users';
import { CrisisAlert, Info, Person } from '@mui/icons-material';

function DetailedLogDialog(props) {
    const { open, onClose, logData } = props;

    const [name, setName] = useState(null);
    const [details, setDetails] = useState("keine Informationen vorhanden");

    const getUsersName = (id) => {
        var promise = getUser(id);
        promise.then((result) => { setName(result.data[0].firstname + " " + result.data[0].surname) })
            .catch();
    }

    // const getLogDetails = () => {
    //     var promise = getLogDetails_api(logData.id);
    //     promise.then((result) => { setDetails(result.data[0]); })
    //         .catch();
    // }

    useEffect(() => {
        if (open) {
            getUsersName(logData.userid);
            // getLogDetails();
        }
        else {
            setName("");
            setDetails("");
        }
    }, [open]);

    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth maxWidth={"sm"}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>Log-Details</DialogTitle>
                <DialogContent sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={4}>
                                <Tooltip title="Initiator">
                                    <Person color="primary" />
                                </Tooltip>
                                <Typography variant="body1" color="text.secondary" fontWeight={500}>{name}</Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={4}>
                                <Tooltip title="Zielobjekt">
                                    <CrisisAlert color="primary" />
                                </Tooltip>
                                <Typography variant="body1" color="text.secondary" fontWeight={500}>{logData && logData.objectID}</Typography>
                            </Stack>
                            {/* <Typography variant="body1">Object ID:  {logData && logData.objectID}</Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={4}>
                                <Info color="primary"/>
                                  <Typography color="text.secondary" fontWeight={500}>{logData && logData.details || "keine Details vorhanden"}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DetailedLogDialog