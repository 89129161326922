import { Delete, DeleteForever, Edit, Groups, HowToVote, Link, MoreVert } from '@mui/icons-material';
import { Card, CardContent, Chip, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { deleteSurvey_api, removeAllVotes_api, removeVote_api, submitVotes_api } from '../api/Surveys/surveys';
import PrimaryButton from './PrimaryButton';
import SurveyChoice from './SurveyChoice'
import TertiaryButton from './TertiaryButton';
import DeleteDialog from './DeleteDialog';
import { useNavigate } from 'react-router-dom';
import { MYSURVEYS } from '../navigation/CONSTANTS';
import { useSnackbar } from './SnackbarContext';

function SurveyActionMenu({ ...props }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        //props.setObject(props.object);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();

    return (
        <div>
            <IconButton
                id="menu"
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => { handleClick(e); }}
                color="inherit"
            >
                <MoreVert />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
                MenuListProps={{
                    'aria-labelledby': 'menu',
                }}
            >
                {/* {props.isAdmin && props.adminMode ? */}

                <MenuItem onClick={() => { setAnchorEl(null); props.openEditDialog(props.survey) }}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    Bearbeiten
                </MenuItem>

                <MenuItem onClick={() => { setAnchorEl(null); props.removeAllVotes(); }}>
                    <ListItemIcon>
                        <DeleteForever fontSize="small" />
                    </ListItemIcon>
                    Anworten zurücksetzen
                </MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); props.deleteSurvey(); }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Umfrage löschen
                </MenuItem>
            </Menu>
        </div >
    );
}


function Survey(props) {
    const { surveyData, dense } = props;
    const [survey, setSurvey] = useState(surveyData);
    const [votes, setVotes] = useState([]);
    const [canSubmit, setCanSubmit] = useState(true);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Umfrage löschen", warningText: "Die Umfrage und alle Votes werden unwiderruflich gelöscht", openDeleteDialog, deleteObjectName: "diese Umfrage", deleteHandler: () => { } });
    // const [lock, setLock] = useState(surveyData.voted);
    const isAdmin = useSelector((state)=>state.isAdmin);
    const { showSnackbar } = useSnackbar();

    const addVote = (vote) => {
        if (survey.is_multiple_votes) {
            setVotes(votes => {
                return [...votes, vote];
            });
        }
        else {
            setVotes([vote]);
        }
    }

    const removeVote = (choiceID) => {
        //var filteredArray = arr.filter(function(e) { return e !== 'seven' })
        if (survey.is_multiple_votes) {
            setVotes(votes => {
                return votes.filter(function (e) { return e !== choiceID });
            });
        }
        else {
            setVotes(() => { return [choiceID] });
        }
    }

    const removeAllVotes = () => {
        var promise = removeAllVotes_api(survey.id);
        promise.then((result) => { props.getSurveys(); })
            .catch();
    }

    const submitVotes = () => {
        var promise = submitVotes_api(survey.id, votes);
        promise.then((result) => { props.getSurveys(); showSnackbar("Erfolgreich abgestimmt",'success')})
            .catch(error => {showSnackbar("Fehler beim Abstimmer - eventuell fehlen Berechtigungen",'error')});
    }

    const resetMyVote = () => {
        var promise = removeVote_api(survey.id);
        promise.then((result) => { console.log(result.data); props.getSurveys(); })
            .catch((error) => { console.log(error) });
    }

    const deleteSurvey = () => {
        var promise = deleteSurvey_api(survey.id);
        promise.then((result) => { props.getSurveys(); })
            .catch();
    }

    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: true, deleteHandler: () => deleteSurvey() })
    }

    useEffect(() => {
        if (votes.length > 0) {
            setCanSubmit(false);
        }
        else {
            setCanSubmit(true);
        }

        return () => {

        };
    }, [votes]);

    useEffect(() => {
        setSurvey(surveyData);
        return () => {

        };
    }, [surveyData]);

    return (
        <>
            <Card variant={props.variant}>
                <CardContent>
                    <Grid container direction="column" rowSpacing={1} columnSpacing={1} justifyContent="center">
                        <Grid item sx={{ width: "100%" }}>
                            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                <Stack sx={{ minWidth: 0 }}>
                                    <Typography variant="h6">{surveyData.text}</Typography>
                                    {surveyData && surveyData.assignmentData.length > 0 &&
                                        <>
                                            < Typography variant="body2" noWrap color="text.secondary">{"Verknüpfungen ("+surveyData.assignmentData.length+"):"}
                                           
                                                {surveyData.assignmentData.length > 0 &&
                                                    surveyData.assignmentData.map((teammatch, i) => (
                                                         " "+teammatch.opponent 
                                                    ))
                                                }
                                    </Typography>
                                        </>
                                    }
                                </Stack>
                                {isAdmin || surveyData.isOwner == 1 ?
                                    <SurveyActionMenu deleteSurvey={() => { openDeleteDialogAction() }} removeAllVotes={() => { removeAllVotes() }} survey={survey} {...props} />
                                    : <></>
                                }
                            </Stack>
                        </Grid>
                        {!dense &&
                            <>
                                <Grid item>
                                    <Divider></Divider>
                                </Grid>
                                {survey.choices && survey.choices.map((choice, i) => (
                                    <Grid item xs={12} key={i}>
                                        <SurveyChoice survey={survey} addVote={addVote} removeVote={removeVote} votes={votes} choiceID={choice.id} choiceText={choice.text} choiceVotesRel={(choice.votes / survey.total_votes) * 100} choiceVotesAbs={choice.votes} participants={choice.participants} />
                                    </Grid>
                                ))}
                            </>
                        }
                        <Grid item>
                            <Divider></Divider>
                        </Grid>
                        <Grid container item justifyContent={"space-between"}>
                            {survey.voted ?
                                <Grid item>
                                    <TertiaryButton text={"Vote zurücknehmen"} onClick={() => { resetMyVote(survey.id); }}></TertiaryButton>
                                </Grid>
                                :
                                <Grid item>
                                    <PrimaryButton text="Abstimmen" disabled={canSubmit} onClick={() => { submitVotes() }}></PrimaryButton>
                                </Grid>
                            }
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Grid item>
                                    {/* <Chip color="primary" label={survey.participants_voted + " Teiln." + " / " + survey.total_votes + " Stimmen"}></Chip> */}
                                    <Chip color="primary" label={<Stack direction="row" spacing={1}><Typography>{survey.participants_voted}</Typography><Groups></Groups><Typography>{survey.total_votes}</Typography><HowToVote></HowToVote></Stack>}></Chip>
                                </Grid>
                            </Stack>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card >
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
        </>
    )
}



export default Survey