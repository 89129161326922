import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/PrimaryButton'
import SecondaryButton from '../../components/SecondaryButton'

function AddMemberDialog(props) {
    const { open, onClose, submit } = props

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("user");
    const [submitDisabled, setSubmitDisabled] = useState(false);


    const handleChange = (event) => {
        switch (event.target.name) {
            case "firstName":
                setFirstname(event.target.value)
                break;
            case "lastName":
                setLastName(event.target.value)
                break;
            case "email":
                setEmail(event.target.value)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!firstname || !lastname || !email || !role) {
            setSubmitDisabled(true)
        }
        else {
            setSubmitDisabled(false)
        }
    }, [firstname, lastname, email, role]);

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {"Mitglied erstellen"}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                            <TextField name="firstName" required value={firstname} fullWidth id="firstName" label="Vorname" autoFocus onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField required fullWidth value={lastname} id="lastName" label="Nachname" name="lastName" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required fullWidth value={email} id="email" label="Email" name="email" onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
                                <Select name="frontend" label="Rolle" value={role} onChange={(event) => setRole(event.target.value)} fullWidth>
                                    <MenuItem value={"user"}>User</MenuItem>
                                    <MenuItem value={"admin"}>Admin</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider></Divider>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text="Abbrechen" onClick={onClose} />
                    <PrimaryButton text="Bestätigen" disabled={submitDisabled} onClick={() => { submit(firstname, lastname, email, role) }} />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddMemberDialog