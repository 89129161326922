import { ContactPage, LiveHelp, Mail } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, CardMedia, Divider, Grid, Stack, Toolbar, Typography } from '@mui/material'
import React from 'react'

import Footer from '../../components/Footer'
import Header from '../../components/Header/Header'
import SimpleListItem from '../../components/SimpleListItem'

function AttributionCard({ image, imageName, authorName, authorLink, platformName, platformLink, ...props }) {
    return (
        <Card variant="outlined" sx={{ borderRadius: 2 }}>
            <CardMedia component="img" image={image} sx={{ height: "100px", objectFit: "contain", p: 1 }}></CardMedia>
            <CardContent>
                <Stack>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Typography variant="body2">Image:</Typography>
                        <Button variant="text" sx={{ textDecoration: "underline", textTransform: "none", typography: "body2" }} onClick={() => { window.open(image, '_blank').focus() }}>{imageName}</Button>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Typography variant="body2">Author:</Typography>
                        <Button variant="text" sx={{ textDecoration: "underline", textTransform: "none", typography: "body2" }} onClick={() => { window.open(authorLink, '_blank').focus() }}>{authorName}</Button>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Typography variant="body2">Platform:</Typography >
                        <Button variant="text" sx={{ textDecoration: "underline", textTransform: "none", typography: "body2" }} onClick={() => { window.open(platformLink, '_blank').focus() }}>{platformName}</Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

function ImpressumContainer() {
    return (
        <>
            <Header />
            <Toolbar></Toolbar>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid container item justifyContent={"center"} spacing={2}>
                    <Grid item xs={12} md={10}>
                        <Card raised sx={{ boderRadius: 1 }}>
                            <CardHeader title={"Impressum"} />
                            <CardContent>
                                <Grid container rowSpacing={2}>
                                    <Grid item container direction="column" alignItems={"center"}>
                                        <Grid item><Typography variant="h6">{process.env.REACT_APP_IMPRINT_NAME || "MyClubPortal"}</Typography></Grid>
                                        <Grid item> <Typography variant="body2">{process.env.REACT_APP_IMPRINT_ADRESS_1 || "MyClubPortal"}</Typography></Grid>
                                        <Grid item> <Typography variant="body2">{process.env.REACT_APP_IMPRINT_ADRESS_2 || "MyClubPortal"}</Typography></Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid item container direction="column" alignItems={"center"} justifyContent="center" xs={12}>
                                        <Grid item>
                                            <Stack direction="column">
                                                <SimpleListItem icon={<Mail color="primary"></Mail>} object={process.env.REACT_APP_CONTACTMAIL || "contact@myclubportal.de"}></SimpleListItem>
                                                <SimpleListItem icon={<ContactPage color="primary" />} object={process.env.REACT_APP_CONTACTPAGE || "https://myclubportal.de"}></SimpleListItem>
                                                <SimpleListItem icon={<LiveHelp color="primary" />} object={process.env.REACT_APP_SUPPORTMAIL || "contact@myclubportal.de"}></SimpleListItem>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider></Divider>
                                    </Grid>
                                    {/* <Grid item container direction="column" alignItems={"center"} xs={12}>
                                        <Typography variant="body2">Der HTC ist im Vereinsregister der Stadt Braunschweig mit der Nr. 3058 eingetragen.</Typography>
                                    </Grid> */}
                                    <Grid item container direction="column" alignItems={"center"}>
                                        <Typography variant="body2">Copyright Jan-Paul Brückmann 2022</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Card raised sx={{ boderRadius: 1 }}>
                            <CardHeader title="Bildmaterial"></CardHeader>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/vektoren-kostenlos/flache-designillustration-kundenbetreuung_23-2148887720.jpg?t=st=1714569581~exp=1714573181~hmac=4445a87f0727d817b29d886ce90a6cbee63b528fdce9ec8a69f468492bbc629b&w=740"}
                                            imageName={"flat-design-illustration-customer-support_12982910"}
                                            authorName={"freepik"}
                                            authorLink={"https://de.freepik.com/autor/freepik"}
                                            platformName={"Freepik"}
                                            platformLink={"https://freepik.com"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/free-photo/tennis-court-render-3d-illustration_654080-1384.jpg?t=st=1714570085~exp=1714573685~hmac=580e5a070942304e9184da48cb82b1d82d1cd059448720b252012a503b3423ae&w=1380"}
                                            imageName={"tennis-court-render-3d-illustration_29155628"}
                                            authorName={"virapordesign"}
                                            authorLink={"https://www.freepik.com/author/viarprodesign"}
                                            platformName={"freepik"}
                                            platformLink={"https://www.freepik.com"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/free-photo/close-up-athlete-playing-tennis_23-2150845514.jpg?t=st=1714637276~exp=1714640876~hmac=f63a0dd8dffbebada97dd15478981e42faae5fe0ac9c810f78572b5c2666a957&w=1380"}
                                            imageName={"close-up-athlete-playing-tennis_72628516"}
                                            authorName={"freepik"}
                                            authorLink={"https://de.freepik.com/autor/freepik"}
                                            platformName={"Freepik"}
                                            platformLink={"https://freepik.com"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/free-photo/tennis-girl-professional-tennis-court_654080-1209.jpg?t=st=1714637743~exp=1714641343~hmac=7d8b7e7ef0e211c48b7fc3d92068fbc8ee56c73b986b2ff0b615b574fe10fcc0&w=1380"}
                                            imageName={"tennis-girl-professional-tennis-court_28993913"}
                                            authorName={"virapordesign"}
                                            authorLink={"https://www.freepik.com/author/viarprodesign"}
                                            platformName={"Freepik"}
                                            platformLink={"https://freepik.com"} />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/free-vector/news-grunge-text_460848-9369.jpg?w=1380&t=st=1714637595~exp=1714638195~hmac=1f85233f16a42b333fbdc6fbf5948c7ed918a658a24736bcc87ee8ca74a6f5a6"}
                                            imageName={"news-grunge-text_22664977"}
                                            authorName={"Rochak Shukla"}
                                            authorLink={"https://www.freepik.com/author/viarprodesign"}
                                            platformName={"Freepik"}
                                            platformLink={"https://freepik.com"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://images.unsplash.com/photo-1694234326056-5e4f43c5a6d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                                            imageName={"eine-schwarze-tasche-gefullt-mit-tennisballen-auf-einem-tennisplatz-7EqamP0pnxY"}
                                            authorName={"Angelina Yan"}
                                            authorLink={"https://unsplash.com/de/@kukaka"}
                                            platformName={"Unsplash"}
                                            platformLink={"https://unsplash.com/"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://images.pexels.com/photos/16140853/pexels-photo-16140853.jpeg"}
                                            imageName={"Luftaufnahme Von Mir Und Meinem Freund Beim Tennisspielen"}
                                            authorName={"Tik Rudari"}
                                            authorLink={"https://www.pexels.com/de-de/@adriatikrudari/"}
                                            platformName={"Pexels"}
                                            platformLink={"https://pexels.com/"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://images.pexels.com/photos/171568/pexels-photo-171568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                                            imageName={"Leute, Die Auf Blauem Und Grünem Tennisplatz Stehen"}
                                            authorName={"Raj Tatavarthy"}
                                            authorLink={"https://www.pexels.com/de-de/@rajtatavarthy/"}
                                            platformName={"Pexels"}
                                            platformLink={"https://pexels.com/"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://images.unsplash.com/photo-1568663469495-b09d5e3c2e07?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                                            imageName={"menschen-im-tennisstadion-J6DFFKBtxSk"}
                                            authorName={"Sudan Ouyang"}
                                            authorLink={"https://unsplash.com/de/@sdoy1995"}
                                            platformName={"Unsplash"}
                                            platformLink={"https://unsplash.com/"} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://images.unsplash.com/photo-1567220720374-a67f33b2a6b9?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                                            imageName={"blauer-tennisplatz-iiWhddT4SB4"}
                                            authorName={"Tim Chow"}
                                            authorLink={"https://unsplash.com/de/@rkdamedia?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash"}
                                            platformName={"Unsplash"}
                                            platformLink={"https://unsplash.com/"} />
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <AttributionCard
                                            image={"https://img.freepik.com/free-photo/abstract-indoor-tennis-court_155003-15516.jpg?t=st=1725252826~exp=1725256426~hmac=18b2d30236df3a2ebf9c40a546b99243f8c286f4eb238fc875278340b92a1681&w=1380"}
                                            imageName={"The abstract indoor tennis court"}
                                            authorName={"@master1305"}
                                            authorLink={"https://www.freepik.com/author/master1305"}
                                            platformName={"Freepik"}
                                            platformLink={"https://www.freepik.com/"} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Grid>
            <Footer />

            {/* Foto von <a href="https://unsplash.com/de/@rkdamedia?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Tim Chow</a> auf <a href="https://unsplash.com/de/fotos/blauer-tennisplatz-iiWhddT4SB4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a> */}
  
        </>
    )
}

export default ImpressumContainer

