import { Alert, AlertTitle, Box, Card, CircularProgress, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Select, Stack, Tabs, TextField, Tooltip, Typography, useForkRef } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { createTeam, getNuligaTeams_api, updateTeam } from '../api/Teams/teams';
import { getUsersNames } from '../api/Users/users';
import CustomAutocomplete from './CustomAutocomplete';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import { AddBox, AutoFixNormal, ChevronLeft, ChevronRight, Close, CopyAll, Group, Help, Language, ManageAccounts, PublishedWithChanges } from '@mui/icons-material';
import DynamicExpandIcon from './DynamicExpandIcon';
import TertiaryButton from './TertiaryButton';
import { useSnackbar } from './SnackbarContext';
import MyTab from './MyTab';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import MyMenuItem from './MyMenuItem';
import ChangeSeasonContainer from '../pages/Admin/Teams/ChangeSeasonContainer';
import SeasonChangeListItem from '../pages/Admin/Teams/SeasonChangeListItem';

function AddEditTeamDialog(props) {
    const { open, onClose, getAllTeams, isEdit, team, teamNames, teams } = props;
    const [targetUserId, setTargetUserID] = useState(null);
    const [teamname, setTeamname] = useState("");
    const [tnbid, setTnbid] = useState("");
    const [season, setSeason] = useState("");
    const [users, setUsers] = useState([{}]);
    const [initialInputValue, setInitialInputValue] = useState("");
    const [reqTeammatchSize, setReqTeammatchSize] = useState(4);
    const [teamType, setTeamType] = useState("men");
    const [nuligaTeams, setNuligaTeams] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTeamIndex, setSelectedTeamIndex] = useState(null)
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [expanded, setExpanded] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [expandInfo1, setExpandInfo1] = useState(false);
    const [expandInfo2, setExpandInfo2] = useState(false);
    const [teamCategory, setTeamCategory] = useState("all");
    const [mode, setMode] = useState("menu");
    const [teamCategoryFilter, setTeamCategoryFilter] = useState("all");
    const [teamSeason, setTeamSeason] = useState("");
    const [teamSeasonFilter, setTeamSeasonFilter] = useState("");
    const [] = useState();

    const { showSnackbar } = useSnackbar();

    const submitHandler = () => {
        var promise = null;
        if (isEdit) {
            promise = updateTeam(teamname, targetUserId, tnbid, season, team.id, reqTeammatchSize, teamType);
            promise.then((result) => { onClose(); getAllTeams(); showSnackbar("Team erfolgreich bearbeitet", "success"); })
                .catch(()=>showSnackbar("Die Mannschaft konnte nicht bearbeitet werden", "error"));
        }
        else {
            promise = createTeam(teamname, targetUserId, tnbid, season, reqTeammatchSize, teamType);
            promise.then((result) => { onClose(); getAllTeams(); showSnackbar("Team erfolgreich erstellt", "success"); })
                .catch();
        }

    }

    const directSubmit = (teamname, targetUserId, tnbid, season, reqTeammatchSize,) => {
        var promise = null;
        var type = "women"
        if (teamname.includes("Herren") || teamname.includes("Junioren ")) {
            type = "men"
        }
        promise = createTeam(teamname, targetUserId, tnbid, encodeURIComponent(season), reqTeammatchSize, type);
        promise.then((result) => { getAllTeams(); showSnackbar("Mannschaft erfolgreich erstellt!", "success") })
            .catch(() => showSnackbar("Fehler beim Erstellen der Mannschaft - evtl. ist die Mannschaft bereits vorhanden.", "warning"));

    }

    const changeHandler = (event) => {
        switch (event.target.name) {
            case "teamname":
                setTeamname(event.target.value);
                break;
            case "tnbid":
                setTnbid(event.target.value);
                break;
            case "season":
                setSeason(encodeURIComponent(event.target.value));
                break;
            case "reqTeammatchSize":
                setReqTeammatchSize(event.target.value);
                break;
            default:
                break;
        }

    }
    const getUsersList = () => {
        var promise = getUsersNames();
        promise.then((result) => {
            var users_autocomplete = [];
            for (var i = 0; i < result.data.length; i++) {
                users_autocomplete.push({ label: result.data[i].firstname + " " + result.data[i].surname + " | " + result.data[i].id, id: result.data[i].id });
            }
            setUsers(users_autocomplete);
            setInitialInputValue(users_autocomplete.filter(function (element) { return element.id === team.captain })[0]);
        })
            .catch(error => { });
    }

    const parseNuliga = () => {
        setIsLoading(true);
        var promise = getNuligaTeams_api();
        promise.then((result) => { setIsLoading(false); setNuligaTeams(result.data); })
            .catch((error) => { setIsLoading(false); console.log(error) });
    }

    const applyTeamData = (team, i) => {
        setTeamname(team['name']);
        setTnbid(team['clubId']);
        setSeason(encodeURIComponent(team['season']));
        setSelectedTeamIndex(i);
    }


    useEffect(() => {
        if (team) {
            setTeamname(team.team);
            setTnbid(team.tnbid);
            setSeason(team.tnbchampionship);
            setTargetUserID(team.captain);
            setTeamType(team.type);
            setReqTeammatchSize(team.req_teammatch_size);
            setExpanded(false);
        }
        if (open) {
            getUsersList();
            parseNuliga();
            if (props.menuMode) {
                setMode(props.menuMode)
            }
        }
        else {
            setTeamname("");
            setTnbid("");
            setSeason("");
            setTargetUserID("");
            setTeamType("men");
            setReqTeammatchSize(4);
            setNuligaTeams();
            setSelectedTeamIndex(null);
            setTeamCategory("all");
            setMode("menu");
        }
        // if(open && props.menuMode=="seasons")
        // {
        //     parseNuliga();
        // }
    }, [open]);

    useEffect(() => {
        if (teamname && teamname != "") {
            if (teamname.includes("Herren")) {
                setTeamType("men");
            }
            else if (teamname.includes("Damen")) {
                setTeamType("women");
            }
            else {
            }
        }
    }, [teamname]);

    useEffect(() => {
        if (teamname != "" && teamname != null) {
            setSubmitDisabled(false)
        }
        else {
            setSubmitDisabled(true);
        }
    }, [teamname]);

    useEffect(() => {
        if (teamCategory == "all") {
            setTeamCategoryFilter("");
        }
        else if (teamCategory == "men") {
            setTeamCategoryFilter("Herren");
        }
        else if (teamCategory == "women") {
            setTeamCategoryFilter("Damen");
        }
        else if (teamCategory == "boys") {
            setTeamCategoryFilter("Junioren");
        }
        else if (teamCategory == "girls") {
            setTeamCategoryFilter("Juniorinnen");
        }
        else {
            setTeamCategoryFilter("");
        }


    }, [teamCategory]);

    useEffect(() => {
        if (teamSeason == "all") {
            setTeamSeasonFilter(["", ""]);
        }
        else if (teamSeason == "summer") {
            setTeamSeasonFilter([" S ", "Sommer"]);
        }
        else if (teamSeason == "winter") {
            setTeamSeasonFilter([" W ", "/"]);
        }
        else {
            setTeamSeasonFilter(["", ""]);
        }
    }, [teamSeason]);

    useEffect(() => {
        if (props.menuMode) {
            setMode(props.menuMode)
        }
    }, [props.menuMode]);


    return (
        <>
            <Dialog open={open} onClose={onClose} fullScreen elevation={10} fullWidth>
                <DialogTitle sx={{ backgroundColor: "#eee" }}>
                    {isEdit || mode == "seasons" ? "Mannschaft bearbeiten" : "Mannschaft erstellen"}
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <Container maxWidth="lg" sx={{ px: 0 }}>
                        <Grid container spacing={2}>
                            <TertiaryButton text="Zurück zu Mannschaften" startIcon={<ChevronLeft></ChevronLeft>} onClick={() => { onClose() }} />

                            {mode == "menu" ?
                                <List sx={{ width: '100%' }}>

                                    <MyMenuItem primaryText={"Nuliga Schnittstelle"} onClick={() => { setMode("nuliga"); parseNuliga(); }}
                                        secondaryText={!isEdit ? "Mannschaft aus Nuliga übernehmen (empfohlen)" : "Mannschaft neu verlinken / neue Saison einstellen"}
                                        icon={<Language color="primary"/>}
                                        >
                                    </MyMenuItem>
                                    <MyMenuItem primaryText={"Manuell"} onClick={() => { setMode("manual") }}
                                        secondaryText={!isEdit ? "Mannschaft manuell erstellen" : "Mannschaft manuell bearbeiten / Mannschaftsführer"}
                                        icon={<ManageAccounts color="primary"/>}>
                                    </MyMenuItem>
                                </List>
                                :
                                <></>
                            }
                            {mode == "nuliga" ?
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent={"flex-end"} sx={{ width: "100%" }}>
                                        {/* <TertiaryButton text="Zurück zu Mannschaften" startIcon={<ChevronLeft></ChevronLeft>} onClick={() => { onClose() }} /> */}
                                        {!openAlert &&
                                            <TertiaryButton text="Hilfe" startIcon={<Help></Help>} onClick={() => setOpenAlert(true)} />
                                        }
                                    </Stack>
                                    {openAlert &&
                                        <Alert variant="outlined" severity='info' action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenAlert(false); }}><Close fontSize="inherit" /></IconButton>} >
                                            <Stack direction={"row"} alignItems={"center"} onClick={() => { setExpandInfo1(!expandInfo1) }}>
                                                <AlertTitle>Warum werden Mannschaften doppelt angezeigt?</AlertTitle>
                                                <DynamicExpandIcon expanded={expandInfo1} onClick={() => setExpandInfo1(!expandInfo1)}></DynamicExpandIcon>
                                            </Stack>

                                            <Collapse in={expandInfo1}>
                                                <Typography variant="body2">Doppelte Mannschaften haben bereits Gruppeneinteilungen für die Winter&Sommer Saison. Wähle die gewünschte Mannschaft für die aktuelle Saison aus. Sobald eine neue Saison beginnt, kann die bereits bestehende Mannschaft bearbeitet und auf die neue Saison verlinkt werden.</Typography>
                                            </Collapse>
                                            <Divider></Divider>
                                            <Stack direction={"row"} alignItems={"center"} onClick={() => { setExpandInfo2(!expandInfo2) }}>
                                                <AlertTitle sx={{ mt: 1 }}>Warum werden nicht alle Mannschaften angezeigt?</AlertTitle>
                                                <DynamicExpandIcon expanded={expandInfo2} onClick={() => setExpandInfo2(!expandInfo2)}></DynamicExpandIcon>
                                            </Stack>
                                            <Collapse in={expandInfo2}>
                                                <Typography variant="body2">Aus technischen Gründen können nur die Mannschaften vorgeschlagen werden, die eine Gruppeneinteilung haben. Fehlende Mannschaften müssen manuell erstellt und ggf. später mit Nuliga verlinkt werden.</Typography>
                                            </Collapse>
                                            <Divider></Divider>
                                            <Stack spacing={1} sx={{ pt: 1 }}>
                                                <AlertTitle>Legende</AlertTitle>
                                                <Stack direction="row" spacing={1} alignItems={"center"}>
                                                    <CopyAll color="primary"></CopyAll>
                                                    <Typography variant="body2" color="text.secondary"> Kopiert die Mannschaft in die Felder der Eingabemaske. Die Werte können vor dem Bestätigen bearbeitet werden</Typography>
                                                </Stack>
                                                <Divider variant="inset"></Divider>
                                                {isEdit ?
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <PublishedWithChanges color="secondary" />
                                                        <Typography variant="body2" color="text.secondary">Saisonwechsel durchführen</Typography>
                                                    </Stack>
:
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <AddBox color="success" />
                                                        <Typography variant="body2" color="text.secondary"> Erstellt direkt eine Mannschaft ohne Anpassung der Informationen</Typography>
                                                    </Stack>
                                                }

                                            </Stack>
                                        </Alert>
                                    }
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "flex-start", mb: 1, alignItems: "baseline" }}>
                                        <Typography color="primary" sx={{ width: "80px", pr: 2 }}>{"Mannschaft:"}</Typography>
                                        {/* <Box width={"100px"}></Box> */}
                                        <Tabs value={teamCategory} variant="scrollable" onChange={(event, newValue) => { setTeamCategory(newValue) }}>
                                            <MyTab value="all" label="Alle" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="men" label="Herren" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="women" label="Damen" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="boys" label="Junioren" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="girls" label="Juniorinnen" xsSize="body1" mdSize="h6"></MyTab>
                                        </Tabs>
                                    </Box>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "flex-start", mb: 1, alignItems: "baseline" }}>
                                        <Typography color="primary" sx={{ width: "80px", pr: 2 }}>{"Saison: "}</Typography>
                                        <Tabs value={teamSeason} variant="scrollable" onChange={(event, newValue) => { setTeamSeason(newValue) }}>
                                            <MyTab value="" label="Alle" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="summer" label="Sommer" xsSize="body1" mdSize="h6"></MyTab>
                                            <MyTab value="winter" label="Winter" xsSize="body1" mdSize="h6"></MyTab>
                                        </Tabs>
                                    </Box>
                                    <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>

                                        {isLoading ?
                                            <Stack alignItems={"center"} sx={{ pt: 4 }} spacing={1}>
                                                <CircularProgress ></CircularProgress>
                                                <Typography color={"primary"}> Lade Mannschaften</Typography>
                                            </Stack>
                                            :
                                            nuligaTeams && nuligaTeams.length > 0 ?
                                                <>
                                                    <List dense sx={{ width: "100%" }}>
                                                        {isEdit &&
                                                            <>
                                                                <ListSubheader disableSticky>Akuelle Saison</ListSubheader>
                                                                <ListItem>
                                                                    <ListItemIcon ><Group color={"primary"} /></ListItemIcon>
                                                                    <Stack>
                                                                        <Typography fontWeight={500}>{team.team}</Typography>
                                                                        <Typography variant="body2" color="text.secondary" fontWeight={500}>{decodeURIComponent(team.tnbchampionship)}</Typography>
                                                                    </Stack>
                                                                </ListItem>
                                                            </>
                                                        }
                                                        {isEdit && <>
                                                            <ListSubheader disableSticky>Vorschlag</ListSubheader>
                                                            {isEdit && nuligaTeams.filter((nuligaTeam) => (nuligaTeam.season != decodeURIComponent(team.tnbchampionship) && team.team == nuligaTeam.name)).length > 0 ?
                                                                nuligaTeams.filter((nuligaTeam) => (nuligaTeam.season != decodeURIComponent(team.tnbchampionship))).map((nuligaTeam, i) => (
                                                                    team.team == nuligaTeam.name &&
                                                                    <ChangeSeasonContainer key={i} nuligaTeams={nuligaTeams} isLoading={isLoading} teamNames={teamNames} teams={[team]} getAllTeams={getAllTeams} />
                                                                ))
                                                                :
                                                                <Alert severity='info'>
                                                                    <AlertTitle>Kein Vorschlag vorhanden</AlertTitle>
                                                                    <Typography>Es konnten keine weiteren Saisons für diese Mannschaft bei NuLiga gefunden werden.</Typography>
                                                                </Alert>

                                                            }
                                                        </>}
                                                        {isEdit && <ListSubheader disableSticky>
                                                            <Stack direction="row">{"Alle Mannschaften (" + nuligaTeams.length + ")"}<DynamicExpandIcon expanded={expanded} onClick={() => { setExpanded(!expanded); }} /></Stack>
                                                        </ListSubheader>}
                                                        {expanded && nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name.includes(teamCategoryFilter) && (nuligaTeam.season.includes(teamSeasonFilter[0]) || nuligaTeam.season.includes(teamSeasonFilter[1])) && !teamNames.includes(nuligaTeam.name))).length > 0
                                                            ? nuligaTeams.filter((nuligaTeam) => (nuligaTeam.name.includes(teamCategoryFilter) && (nuligaTeam.season.includes(teamSeasonFilter[0]) || nuligaTeam.season.includes(teamSeasonFilter[1])) && !teamNames.includes(nuligaTeam.name))).map((nuligaTeam, i) => (
                                                                <div key={i}>
                                                                    <ListItem  secondaryAction={
                                                                        <Stack direction="row" spacing={0}>
                                                                            <Tooltip title="in die Eingabemaske kopieren"><><IconButton onClick={() => { applyTeamData(nuligaTeam, i); setMode("manual"); }} ><CopyAll color={"primary"} /></IconButton></></Tooltip>
                                                                            {!isEdit && <Tooltip title="ohne Bearbeiten erstellen"><> <IconButton onClick={() => { directSubmit(nuligaTeam.name, null, nuligaTeam.clubId, nuligaTeam.season, 4) }}><AddBox size="small" color="success" /></IconButton></></Tooltip>}
                                                                        </Stack>}>
                                                                        <ListItemIcon ><Group color={"primary"} /></ListItemIcon>
                                                                        <Stack>
                                                                            <Typography fontWeight={selectedTeamIndex == i ? 500 : 300} noWrap>{nuligaTeam.name}</Typography>
                                                                            <Typography variant="body2" color="text.secondary" fontWeight={selectedTeamIndex == i ? 500 : 300}>{decodeURIComponent(nuligaTeam.season)}</Typography>
                                                                        </Stack>
                                                                    </ListItem>
                                                                    <Divider></Divider>
                                                                </div>
                                                            ))
                                                            : !isEdit && nuligaTeams.length > 0 ?
                                                                <Alert severity='info'>
                                                                    <AlertTitle>Keine Mannschaften vorhanden</AlertTitle>
                                                                    <Typography>Es wurden entweder bereits alle NuLiga Mannschaften hinzugefügt oder es gibt keine Ergebinsse für die gesetzte Filterkonfiguration.</Typography>
                                                                </Alert>
                                                                : isEdit ?
                                                                    <></>
                                                                    :
                                                                    <Alert severity='info'>
                                                                        <AlertTitle>Keine Mannschaften gefunden</AlertTitle>
                                                                        <Typography>Es konnten keine Mannschaften für deinen Verein bei NuLIga gefunden werden.</Typography>
                                                                    </Alert>
                                                        }
                                                    </List>
                                                </>
                                                :
                                                <Alert severity='info'>
                                                    <AlertTitle>Keine Mannschaften gefunden</AlertTitle>
                                                    <Typography>Es konnten keine Mannschaften für deinen Verein bei NuLIga gefunden werden.</Typography>
                                                </Alert>
                                        }
                                    </Stack>
                                </Grid>
                                :
                                <></>

                            }
                            {mode == "manual" ?
                                <>
                                    <Grid item xs={12}>
                                        <TextField fullWidth required value={teamname || ""} onChange={changeHandler} name="teamname" label="Name der Mannschaft"></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomAutocomplete options={users} label="Mannschaftsführer" setTarget={setTargetUserID} initialInputValue={initialInputValue} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Typ</InputLabel>
                                            <Select value={teamType} label="Geschlecht" onChange={(event) => setTeamType(event.target.value)}>
                                                <MenuItem value="men">Herren</MenuItem>
                                                <MenuItem value="women">Damen</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField fullWidth type={"number"} value={reqTeammatchSize} onChange={changeHandler} name="reqTeammatchSize" label="Mindestspieleranzahl"></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth value={tnbid || ""} onChange={changeHandler} name="tnbid" label="NuLiga ID"></TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth value={decodeURIComponent(season) || ""} onChange={changeHandler} name="season" label="NuLiga Season"></TextField>
                                    </Grid>
                                </>
                                :
                                <></>
                            }
                            {mode == "seasons" && !isEdit ?
                                <Grid item xs={12}>
                                    <ChangeSeasonContainer nuligaTeams={nuligaTeams} isLoading={isLoading} teamNames={teamNames} teams={teams} getAllTeams={getAllTeams} subheader showAlerts/>
                                </Grid>
                                :
                                <></>
                            }
                        </Grid>
                    </Container>
                </DialogContent>
                <Divider></Divider>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                    {mode == "nuliga" || mode == "seasons" ?
                        <PrimaryButton text={"Fertig"} onClick={onClose}></PrimaryButton>
                        :
                        <PrimaryButton text={"Bestätigen"} disabled={submitDisabled} onClick={() => { submitHandler() }}></PrimaryButton>
                    }

                </DialogActions>
            </Dialog >
        </>
    )
}

export default AddEditTeamDialog