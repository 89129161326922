import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getTrainings_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/trainings",
    { headers: sessionData }
  );
};

export const getTrainingsFromGroup = async (traininggroup) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/traininggroups/" + traininggroup + "/trainings/",
    { headers: sessionData }
  );
};

export const getTrainingsFromUser_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mytrainings",
    { headers: sessionData }
  );
};

export const getTrainingParticipants_api = async (trainingid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/trainings/" + trainingid+"/participants",
    { headers: sessionData }
  );
};

export const createTraining = async (traininggroup,datetime) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data={
    datetime: datetime
  }
  return await axios.post(
    process.env.REACT_APP_API_URL + "/traininggroups/" + traininggroup + "/trainings/", data,
    { headers: sessionData }
  );
};

export const deleteTraining = async (traininggroup,trainingid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/traininggroups/" + traininggroup + "/trainings/"+ trainingid,
    { headers: sessionData }
  );
};

export const setTrainingParticipant_api = async (trainingid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var data={
  };

  return await axios.post(
    process.env.REACT_APP_API_URL + "/trainings/" +  trainingid + "/participants",data,
    { headers: sessionData }
  );
};

export const deleteTrainingParticipant_api = async (trainingid) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data

  return await axios.delete(
    process.env.REACT_APP_API_URL + "/trainings/" +  trainingid + "/participants",
    { headers: sessionData }
  );
};


