import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getInvitations_api = async (id) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/invitations?id="+id, { headers: sessionData })
};

export const getInvitationByCode_api = async (code) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
    .get(process.env.REACT_APP_API_URL + "/invitations?code="+code, { headers: sessionData })
};

export const confirmTeamInvitation_api = async (teamId,code) => {
  const sessionData = getSessionData();
  //fetch users data

  var data = {};
  return await axios
    .patch(process.env.REACT_APP_API_URL + "/teams/" + teamId+"/invitation?code="+code,data,{ headers: sessionData })
};


