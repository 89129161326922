import { format } from "date-fns";

export function exportDataToCSV(data,fileName)
{
    const blob = new Blob([data], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName+"_"+format(new Date(),"dd_MM_yyyy")+'.csv'); // Set the download attribute with the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}