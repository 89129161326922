import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getSupportCases_api = async(query) => {
  const sessionData = getSessionData();

  if(query){
  //fetch users data
  return await axios
      .get(process.env.REACT_APP_API_URL + "/support"+query, { headers: sessionData })
  }
  else
  {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/support", { headers: sessionData })
  }
};

export const createSupportCase_api = async (message,title) => {
  const sessionData = getSessionData();

  var data ={
      message: message,
      title: title
  }
  
  //fetch reservation filtered by day data
  return await axios.post(
    process.env.REACT_APP_API_URL + "/support",data,
    { headers: sessionData }
  );
};

export const setSupportCaseStatus_api = async(id,status) => {
  const sessionData = getSessionData();

  var data = {
      status: status
  }
  //fetch users data
  return await axios
      .patch(process.env.REACT_APP_API_URL + "/support/"+id,data,{ headers: sessionData })
};

export const getSupportCaseMessages_api = async(id) => {
  const sessionData = getSessionData();
  //fetch users data
  return await axios
      .get(process.env.REACT_APP_API_URL + "/support/"+id+"/messages", { headers: sessionData })
};

export const sendResponse_api = async(id,message) => {
  const sessionData = getSessionData();

  var data = {
      message: message
  }
  //fetch users data
  return await axios
      .post(process.env.REACT_APP_API_URL + "/support/"+id+"/messages",data,{ headers: sessionData })
};