import {  Feed, Notifications } from '@mui/icons-material'
import { Alert, Badge, Card, CardContent, IconButton, Menu, MenuItem, Stack, Typography, useScrollTrigger } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { deleteAllNotificationOfUser_api, getNotifications_api, readAllNotificationOfUser_api } from '../../api/Notifications/notifications';

import TertiaryButton from '../TertiaryButton';
import Notification from './Notification';


function NotificationMenu(props) {

    const [notifications, setNotifications] = useState();
    const [unseenCount,setUnseenCount] = useState(0);
    const [state,setState] = useState();
    const [scrollTarget, setScrollTarget] = useState(null);

    const has_notifications = useSelector((state)=>state.has_notifications);
    const userID = useSelector((state)=>state.id);
    const scrollTrigger = useScrollTrigger({disableHysteresis: true,threshold: 0,target: scrollTarget ? scrollTarget : undefined});

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const handleClick = (event) => {
        //props.setObject(props.object);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getNotifications = () => {
        var promise = getNotifications_api(userID);
        promise.then((result) => { 
            setNotifications(result.data);
            var tmpcount=0;
            for(var i=0;i<result.data.length;i++)
            {
                if(result.data[i].seen==0)
                {
                    tmpcount+=1;
                }
            }
            setUnseenCount(tmpcount);
            
        })
            .catch();        
    }

    const deleteAllNotifcations=()=>{
        var promise=deleteAllNotificationOfUser_api(userID);
        promise.then(()=>{getNotifications()})
        .catch();
    }

    const readAllNotifcations=()=>{
        var promise=readAllNotificationOfUser_api(userID);
        promise.then(()=>{getNotifications()})
        .catch();
    }

    //get notification on component start 
    useEffect(() => {
        getNotifications();
        return () => {
            setState(null);
        };
    }, []);

    return (
        <>
            <IconButton id="btn-notification" onClick={(event) => { handleClick(event) }} >
                {has_notifications ?
                    <Badge overlap="circular" size="small" color="success" badgeContent={unseenCount && unseenCount} sx={{color: "white"}}><Notifications color="white" sx={{color: "#fff"}}></Notifications></Badge>
                    :
                    <Notifications color="white"></Notifications>
                }
            </IconButton>
            <Menu
                id="notifcation-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                dense="true"
                //ref={targetRef} 
                PaperProps={{
                    style: {
                        maxHeight: 56*5,
                        maxWidth: "100vw"
                    },
                    ref: node => {if (node) { setScrollTarget(node)}} // Papper needs to be ref to determine scroll event in notification menu
                }}
                // selected={true}
                MenuListProps={{'aria-labelledby': 'menu', sx: {pt:0,pb: 0}}}
                sx={{pt:0}}
                //getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Card elevation={scrollTrigger ? 2 : 0} sx={{position: "sticky", left: 0, top:0, zIndex: 2, backgroundColor:"white", pb:0, pt:1, borderRadius: 0}}>
                <CardContent sx={{pb:0, pt:0, "&:last-child": {paddingBottom: 0}}}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>Benachrichtigungen</Typography>
                    <TertiaryButton id="btn-notification-deleteall" size="small" onClick={(e) => {e.stopPropagation(); deleteAllNotifcations();}} text={"alle löschen"} sx={{textTransform: "none"}}></TertiaryButton>
                    <TertiaryButton size="small" onClick={(e) => {e.stopPropagation(); readAllNotifcations();}} text={"alle gesehen"} sx={{textTransform: "none"}}></TertiaryButton>
                    </Stack>
                </CardContent>
                </Card>
                {/* Loop over notifications*/}
                {notifications && notifications.length > 0 ?
                    notifications.map((notification, i) => (
                        <Notification notification={notification} handleClose={handleClose} handleRefresh={getNotifications} icon={<Feed></Feed>} key={i}></Notification>
                    ))
                    :
                    <MenuItem>
                        <Alert severity="info"><Typography>Keine Benachrichtigungen</Typography></Alert>
                    </MenuItem>
                }
            </Menu>
        </>
    )
}


export default NotificationMenu