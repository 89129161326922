import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getSubscriptionData_api= async () => {
    const sessionData = getSessionData();
    return axios.get(process.env.REACT_APP_API_URL + "/subscription", { headers: sessionData })
};

export const setSubscriptionPause_api= async (reactivationDate) => {
    const sessionData = getSessionData();

    var data = {
        reactivationDate: reactivationDate
    }
    return axios.put(process.env.REACT_APP_API_URL + "/subscription",data, { headers: sessionData })
};

export const stopSubscription_api= async () => {
    const sessionData = getSessionData();

    return axios.delete(process.env.REACT_APP_API_URL + "/subscription",{ headers: sessionData })
};

export const createSubscription_api= async () => {
    const sessionData = getSessionData();
    var data={};
   
    return axios.post(process.env.REACT_APP_API_URL + "/subscription",data, { headers: sessionData })
};

