import { Avatar } from '@mui/material';
import React from 'react'

function AvatarInitials(props) {
    const { firstname, lastname } = props;
    return (
        <>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
                {firstname && firstname.charAt(0)}{lastname && lastname.charAt(0)}
            </Avatar>
        </>)
}

export default AvatarInitials