import { Link } from '@mui/material';
import React from 'react'
import {  useNavigate } from 'react-router-dom'


function InternalLink(props) {
    const {route,linkname} = props;
    const navigate = useNavigate();
    const clickHandler = (route) => {
        navigate(route);
    }
  return (
    <Link variant="body2" color="primary" sx={{cursor: "pointer",alignSelf: "center"}} onClick={() => { clickHandler(route) }} {...props}>{linkname}</Link>
  )
}

export default InternalLink